import axios from "./axiosConfig";

const CLIENT_URL = process.env.REACT_APP_NODE_URL + "/api/v3";

export const getOrganization = (
  token: string,
  limit?: number,
  page?: number,
  search?: string
) => {
  let getOrgURI = `${CLIENT_URL}/organizations?`;
  if (limit && page) {
    getOrgURI = getOrgURI + `limit=${limit}&page=${page}`;
  }
  if (search) {
    getOrgURI = getOrgURI + `&search=${search}`;
  }

  return axios
    .get(getOrgURI, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);
};
