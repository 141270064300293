import 'bootstrap/dist/css/bootstrap.min.css';
import '../../shared/css/table.css';

import {
  ChangeEvent,
  ChangeEventHandler,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  changeDocProcessingStatus,
  completedFileList,
  completedUserList
} from '../../../controllers/file';

import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Autocomplete,
  Popover,
  IconButton,
  Table as VMTable,
} from '@mui/material';

import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { withStyles } from '@mui/styles';
import SearchIcon from '../../../utils/icons';
import VMButton from '../../common/Button';
import VMModal from '../../common/Modal';

// import { Table } from "reactstrap";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from 'moment';
// import Modal from "react-bootstrap/Modal";

import InfiniteScroll from 'react-infinite-scroll-component';

import { Spinner } from '../../Spinner';
import { toastify } from '../../common/notification';
import { DropDownMenu } from '../../shared/DropDown';

import { changeDocsStatusAddComment } from '../../../controllers/documents';

import { changeDocStatus } from '../../../controllers/file';

import UserContext from '../../../context/userContext';
import { useScrolls } from '../../../hooks/useScrolls';

const CssTextField = withStyles({
  root: {
    '&.Mui-focused': {
      color: 'black',
    },
  },
})(OutlinedInput);

const CompletedQueue = () => {
  const history = useHistory();
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState<unknown[]>([]);
  const [search, setSearch] = useState('');
  const [processedState, setProcessedState] = useState(2);
  const [processedDropdownState, setProcessedDropdownState] = useState(false);
  const [showMovePendingModal, setShowMovePendingModal] = useState(false);
  const [comment, setComment] = useState('');

  const [filtersSort, setFiltersSort] = useState('asc');
  const [filterUser, setFilterUser] = useState<any>([]);
  const [filterUserId, setFilterUserId] = useState<any>([]);
  const [completedUsers, setCompletedUsers] = useState([]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const { authToken, role, name } = useContext(UserContext);

  const showFiltersModal = Boolean(anchorEl);

  const {
    limit,
    page,
    sortBy,
    hasMore,
    fetchMoreDocsOnScroll,
    sortDocuments,
    setSortBy,
    setDocLength,
  } = useScrolls(filteredDocuments.length);

  const { project_id }: { project_id: string } = useParams();

  const handleProcessedStateChange = (evt: ChangeEvent<{ value: unknown }>) => {
    setProcessedState(Number(evt.target.value));
    filterDocuments(Number(evt.target.value), documents);
  };

  const filterDocuments = (state: number, data: any) => {
    switch (state) {
      case 0:
        setFilteredDocuments(() =>
          data.filter((doc: any) => doc.isProcessed === false)
        );
        break;
      case 1:
        setFilteredDocuments(() =>
          data.filter((doc: any) => doc.isProcessed === true)
        );
        break;
      case 2:
        setFilteredDocuments(() => data);
        break;
      default:
        break;
    }
  };

  const handleMenu = (action: string, record: any) => {
    switch (action) {
      case 'view':
        history.push(
          `/projects/${project_id}/document/review/${record._id}/completed`
        );
        break;
      case 'mv_pending':
        changeDocStatus(authToken, record._id, project_id, 'pending')
          .then((res) => {
            if (res) {
              toastify('success', 'document moved to pending');
              // completedDocumentList(authToken, project_id, limit, page, sortBy)
              //   .then((res) => {
              //     setDocuments(res.data);
              //     filterDocuments(processedState, res.data);
              //   })
              //   .catch((err) => {
              //     console.log(err);
              //   });
              getAllCompletedDocs();
            }
          })
          .catch((err) => {
            toastify('failure', 'document status change failed');
            console.log(err);
          });
        break;
      case 'mv_review':
        changeDocStatus(authToken, record._id, project_id, 'marked_for_review')
          .then((res) => {
            toastify('success', 'document moved to Review');
            // completedDocumentList(authToken, project_id, limit, page, sortBy)
            //   .then((res) => {
            //     setDocuments(res.data);
            //     filterDocuments(processedState, res.data);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            getAllCompletedDocs();
          })
          .catch((err) => {
            toastify('failure', 'document status change failed');
            console.log(err);
          });
        break;
      case 'mv_change_status':
        changeDocProcessingStatus(
          authToken,
          record._id,
          project_id,
          !record.isProcessed
        )
          .then((res) => {
            toastify('success', 'Document status changed');
            // completedDocumentList(authToken, project_id, limit, page, sortBy)
            //   .then((res) => {
            //     setDocuments(res.data);
            //     filterDocuments(processedState, res.data);
            //   })
            //   .catch((err) => {
            //     console.log(err);
            //   });
            getAllCompletedDocs();
          })
          .catch((err) => {
            toastify('failure', 'Document processing status change failed');
            console.log(err);
          });
        break;
      default:
        break;
    }
  };

  const handleMultiSelect: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, checked } = e.target;

    if (name === 'all') {
      const updateDocs = documents.map((document) => {
        // @ts-ignore
        document.selected = checked;
        return document;
      });
      setDocuments(updateDocs);
    } else {
      const updateDocs = documents.map((document) => {
        // @ts-ignore
        if (document._id === name) {
          // @ts-ignore
          document.selected = checked;
        }
        return document;
      });
      setDocuments(updateDocs);
    }
  };

  const getAllCompletedDocs = () => {
    completedFileList(authToken, project_id, limit, page, sortBy, filterUserId)
      .then((res) => {
        setDocuments(res.data);
        setTimeout(() => {
          if (page > 1) {
            setFilteredDocuments((currentFilteredDocs) => [
              ...currentFilteredDocs,
              ...res.data,
            ]);
          } else {
            setFilteredDocuments(res.data);
          }

          setIsLoading(false);
        }, 500);
        setDocLength(res.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCompletedUserList = () => {
    completedUserList(authToken, project_id)
      .then((res) => {
        setCompletedUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(getAllCompletedDocs, [
    limit,
    page,
    sortBy,
    filterUserId,
    setDocLength,
    authToken,
    project_id,
  ]);

  useEffect(getAllCompletedUserList, [])

  const handleMoveReview = () => {
    const selectedDocs = documents
      // @ts-ignore
      .filter((document) => document.selected)
      // @ts-ignore
      .map((document) => document._id);

    changeDocsStatusAddComment(
      'marked_for_review',
      {
        docs: selectedDocs,
        comment: {
          comment,
          commentedOn: new Date(),
          commentedBy: name,
        },
        project_id,
        fromStatus: 'completed',
      },
      authToken
    )
      .then(() => {
        getAllCompletedDocs();
      })
      .then((res) => {
        setShowMovePendingModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleApplyFilters = () => {
    console.log("Sort Technique", filtersSort);
    console.log("Filter User", filterUser);
    const userIds = filterUser.map((user: any) => user.userId);
    setSortBy(filtersSort);
    setFilterUserId(userIds);
    setAnchorEl(null);
  }

  // const handleAssignUsers = (event: any,newValue: any[]) => {
  //   const newUserIds = newValue.map((user:any) => user._id);
  //   setAssignedUsers([
  //     ...fixedAssignUsers,
  //     ...newUserIds
  //   ])
  //   setDuplicateAssignedUsers(newValue);
  // }

  const tableHeader = [
    { key: 'sno', title: 'S.No', width: 50 },
    { key: 'document_name', title: 'Document Name', width: 100 },
    { key: 'completed_by', title: 'Completed By', width: 50 },
    { key: 'state', title: 'State', width: 50 },
    { key: 'completed_on', title: 'Completed On', width: 50 },
    { key: 'action', title: 'Action', width: 50 },
  ];

  const ItemsCompletedOn = [
    { value: "asc", title: "Earliest" },
    { value: "desc", title: "Latest" }
  ]

  return (
    <div className="d-flex flex-column h-100">
      <div className="hed-row d-flex justify-content-between py-1 my-1">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: '37rem' }}
        >
          <Typography variant="h4">Completed Documents</Typography>
          <FormControl>
            <CssTextField
              id="searchProject"
              margin="none"
              style={{ width: '20rem' }}
              size="small"
              placeholder="Search Document"
              onChange={(e: any) => {
                setSearch(e.target.value ? e.target.value : '');
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="d-flex justify-content-right">
          {role !== 'analyst' && (
            <VMButton
              variant="primary"
              disabled={!documents.some((document: any) => document.selected)}
              onClick={() => setShowMovePendingModal(true)}
            >
              Move to Review
            </VMButton>
          )}
          {/* <IconButton
            style={{margin: "0 0 0 2rem"}}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget) }
          >
            <FilterAltIcon />
          </IconButton> */}
        </div>
      </div>
      <hr />
      <div className="my-2 table-wrapper">
        <InfiniteScroll
          className="doc-table-scroll"
          dataLength={filteredDocuments.length}
          next={fetchMoreDocsOnScroll}
          hasMore={hasMore}
          height={400}
          loader={isLoading && <Spinner />}
          endMessage={
            <div style={{ textAlign: 'center' }}>
              <p style={{ color: 'gray' }}>No More Documents!</p>
            </div>
          }
        >
          {/* {
            <Table className="table mx-auto">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      name="all"
                      onChange={handleMultiSelect}
                      checked={
                        // @ts-ignore
                        documents.filter((document) => document.selected)
                          .length === documents.length
                      }
                    />
                  </th>
                  <th scope="col">S.No</th>
                  <th className="w-25" scope="col">
                    DocumentsName
                  </th>
                  <th scope="col">Completed By</th>
                  <th scope="col">
                    <Select
                      labelId="demo-controlled-open-select-label"
                      id="demo-controlled-open-select"
                      open={processedDropdownState}
                      onClose={() => setProcessedDropdownState(false)}
                      onOpen={() => setProcessedDropdownState(true)}
                      value={processedState}
                      onChange={handleProcessedStateChange}
                    >
                      <MenuItem value={2}>All State</MenuItem>
                      <MenuItem value={1}>Processed Only</MenuItem>
                      <MenuItem value={0}>Not Processed</MenuItem>
                    </Select>
                  </th>
                  <th
                    scope="col"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDocuments()}
                  >
                    Completed On{" "}
                    <img src={sortSvg} alt="sort-icon" width={20} />
                  </th>
                  only avaiable for qc lead
                  <th scope="col">Actions</th>
                  only avaiable for qc lead
                </tr>
              </thead>
              <tbody>
                {filteredDocuments
                  .filter((doc: any) => {
                    return search.length >= 1
                      ? doc.name.toLowerCase().includes(search.toLowerCase()) ||
                          doc.verifiedBy?.name.startsWith(search.toLowerCase())
                      : true;
                  })
                  .map((doc: any, key) => (
                    <tr key={doc._id}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={handleMultiSelect}
                          name={doc._id}
                          checked={doc.selected}
                        />
                      </td>
                      <th scope="row">{key + 1}</th>
                      <td>{doc.name}</td>
                      <td>{doc.verifiedBy.name}</td>
                      <td>{doc.isProcessed ? "Yes" : "No"}</td>
                      <td>{moment(doc.qcCompletedTime).format("L")}</td>
                      only avaiable for qc lead
                      <td>
                        <DropDownMenu
                          options={
                            role === "superadmin"
                              ? [
                                  { name: "View Document", value: "view" },
                                  {
                                    name: "Move to Pending",
                                    value: "mv_pending",
                                  },
                                  {
                                    name: "Move to Review",
                                    value: "mv_review",
                                  },
                                  {
                                    name: "Change Status",
                                    value: "mv_change_status",
                                  },
                                ]
                              : role === "org_admin"
                              ? [
                                  { name: "View Document", value: "view" },
                                  {
                                    name: "Move to Pending",
                                    value: "mv_pending",
                                  },
                                  {
                                    name: "Move to Review",
                                    value: "mv_review",
                                  },
                                  {
                                    name: "Change Status",
                                    value: "mv_change_status",
                                  },
                                ]
                              : role === "lead"
                              ? [
                                  { name: "View Document", value: "view" },
                                  {
                                    name: "Move to Pending",
                                    value: "mv_pending",
                                  },
                                  {
                                    name: "Move to Review",
                                    value: "mv_review",
                                  },
                                  {
                                    name: "Change Status",
                                    value: "mv_change_status",
                                  },
                                ]
                              : role === "analyst"
                              ? [
                                  { name: "View Document", value: "view" },
                                  {
                                    name: "Change Status",
                                    value: "mv_change_status",
                                  },
                                ]
                              : []
                          }
                          record={doc}
                          handleMenu={handleMenu}
                        />
                      </td>
                      only avaiable for qc lead
                    </tr>
                  ))}
              </tbody>
            </Table>
          } */}
          <TableContainer component={Paper}>
            <VMTable sx={{ minWidth: '72rem' }}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 50 }}>
                    <input
                      type="checkbox"
                      name="all"
                      onChange={handleMultiSelect}
                      checked={
                        // @ts-ignore
                        documents.filter((document) => document.selected)
                          .length === documents.length
                      }
                    />
                  </TableCell>
                  {tableHeader.map((item) =>
                    item.key === 'state' ? (
                      <TableCell align="center" sx={{ width: 50 }}>
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={processedDropdownState}
                          onClose={() => setProcessedDropdownState(false)}
                          onOpen={() => setProcessedDropdownState(true)}
                          value={processedState}
                          onChange={handleProcessedStateChange}
                        >
                          <MenuItem value={2}>All State</MenuItem>
                          <MenuItem value={1}>Processed Only</MenuItem>
                          <MenuItem value={0}>Not Processed</MenuItem>
                        </Select>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={item.key}
                        sx={{ width: item.width }}
                        align="center"
                      >
                        {item.title}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDocuments
                  .filter((doc: any) => {
                    return search.length >= 1
                      ? doc.name.toLowerCase().includes(search.toLowerCase()) ||
                      doc.verifiedBy?.display_name.startsWith(
                        search.toLowerCase()
                      )
                      : true;
                  })
                  .map((doc: any, key) => (
                    <TableRow key={doc._id}>
                      <TableCell align="center">
                        <input
                          type="checkbox"
                          onChange={handleMultiSelect}
                          name={doc._id}
                          checked={doc.selected}
                        />
                      </TableCell>
                      <TableCell align="center">{key + 1}</TableCell>
                      <TableCell align="center">{doc.name}</TableCell>
                      <TableCell align="center">
                        {doc.verifiedBy.display_name}
                      </TableCell>
                      <TableCell align="center">
                        {doc.isProcessed ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell align="center">
                        {moment(doc.qcCompletedTime).format('L')}
                      </TableCell>
                      {/* only avaiable for qc lead */}
                      <TableCell align="center">
                        <DropDownMenu
                          options={
                            role === 'superadmin'
                              ? [
                                { name: 'View Document', value: 'view' },
                                {
                                  name: 'Move to Pending',
                                  value: 'mv_pending',
                                },
                                {
                                  name: 'Move to Review',
                                  value: 'mv_review',
                                },
                                {
                                  name: 'Change Status',
                                  value: 'mv_change_status',
                                },
                              ]
                              : role === 'org_admin'
                                ? [
                                  { name: 'View Document', value: 'view' },
                                  {
                                    name: 'Move to Pending',
                                    value: 'mv_pending',
                                  },
                                  {
                                    name: 'Move to Review',
                                    value: 'mv_review',
                                  },
                                  {
                                    name: 'Change Status',
                                    value: 'mv_change_status',
                                  },
                                ]
                                : role === 'lead'
                                  ? [
                                    { name: 'View Document', value: 'view' },
                                    {
                                      name: 'Move to Pending',
                                      value: 'mv_pending',
                                    },
                                    {
                                      name: 'Move to Review',
                                      value: 'mv_review',
                                    },
                                    {
                                      name: 'Change Status',
                                      value: 'mv_change_status',
                                    },
                                  ]
                                  : role === 'analyst'
                                    ? [
                                      { name: 'View Document', value: 'view' },
                                      {
                                        name: 'Change Status',
                                        value: 'mv_change_status',
                                      },
                                    ]
                                    : []
                          }
                          record={doc}
                          handleMenu={handleMenu}
                        />
                      </TableCell>
                      {/* only avaiable for qc lead */}
                    </TableRow>
                  ))}
              </TableBody>
            </VMTable>
          </TableContainer>
        </InfiniteScroll>
      </div>

      <VMModal
        open={showMovePendingModal}
        handleClose={() => setShowMovePendingModal(false)}
      >
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '35rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Move to Review</h4>
          </div>
          <hr />
          <div style={{ justifyContent: 'center' }}>
            <div className="mv_pending__cot">
              <label htmlFor="review__area">Enter a comment :</label>
              <textarea
                name="review__area"
                id="review__area"
                rows={4}
                style={{ width: '100%' }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter your review comment"
              />
              <VMButton
                style={{ marginTop: '0.5rem' }}
                variant="primary"
                disabled={comment.length === 0}
                onClick={handleMoveReview}
              >
                Submit
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>
      <Popover
        open={showFiltersModal}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ width: 500, height: 500 }}
      >
        <div
          style={{
            backgroundColor: 'white',
            minWidth: "25rem",
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h6>Filters</h6>
          </div>
          <hr />
          <div style={{ justifyContent: 'center' }}>
            <div className="mv_pending__cot">
              {/* <FormControl fullWidth size="small">
              <Select
                variant="outlined"
                value={filtersSort}
                onChange={(event: ChangeEvent<{ value: unknown }>) => {
                  setFiltersSort(event.target.value as string);
                }}
              >
               <MenuItem value={'asc'}>Earliest</MenuItem>
               <MenuItem value={'desc'}>Latest</MenuItem>
              </Select>
              </FormControl> */}
              <TextField
                select
                fullWidth
                variant='outlined'
                label='Completed On'
                size='small'
                sx={{ '& .MuiInputBase-input': { paddingLeft: "10px" } }}
                value={filtersSort}
                onChange={(event: ChangeEvent<{ value: unknown }>) => {
                  setFiltersSort(event.target.value as string);
                }}
              >
                <MenuItem key='1' value={'asc'}>Earliest</MenuItem>
                <MenuItem key='2' value={'desc'}>Latest</MenuItem>
              </TextField>
              <Autocomplete
                multiple
                sx={{ margin: "1rem 0" }}
                value={filterUser}
                options={completedUsers}
                onChange={(event: any, newValue: any[]) => {
                  setFilterUser(newValue);
                }}
                getOptionLabel={(option: any) => `${option.display_name}: ${option.role}`}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select Users" size="small" />
                )}
              />
              <VMButton
                style={{ margin: '0.5rem 0 1rem 0', float: "right" }}
                variant="primary"
                disabled={false}
                onClick={handleApplyFilters}
              >
                Apply
              </VMButton>
            </div>
          </div>
        </div>
      </Popover>

      {/* <Modal
        show={showMovePendingModal}
        onHide={() => setShowMovePendingModal(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Move to Pending
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mv_pending__cot">
            <label htmlFor="review__area">Enter a comment :</label>
            <textarea
              name="review__area"
              id="review__area"
              rows={4}
              style={{ width: "100%" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your review comment"
            />
            <button
              className="btn btn-primary mt-3"
              disabled={comment.length === 0}
              onClick={handleMoveReview}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default CompletedQueue;
