import { createTheme } from "@mui/system";

const Palette = () => {
  return createTheme({
    palette: {
      common: {
        black: "#000",
        white: "#fff",
      },
      // background: {
      //   default: "#f9f9f9",
      // },
      // primary:{
      //   main:'#69bec6',
      //   dark:"#46adb6"
      // },
      // secondary:{
      //   main:'#aaa'
      // }
    }
  });
};

export default Palette;
