import "bootstrap/dist/css/bootstrap.min.css";
import "react-dropdown/style.css";

import "../shared/css/table.css";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Dropdown from "react-dropdown";
import { useContext, useState } from "react";
import UserContext from "../../context/userContext";
import { validateInput } from "./validation";
import { updateUser } from "../../controllers/users";
import { toastify } from "../common/notification";

import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

import VMModal from "../common/Modal";
import VMButton from "../common/Button";
import { toast } from "react-toastify";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface OptionT {
  label: string;
  value: string;
}
interface Props {
  isEditOpen: any;
  setIsEditOpen: (value: Boolean) => void;
  userData: any;
  selectedOrgs: OptionT[];
}

const EditForm = ({
  isEditOpen,
  setIsEditOpen,
  userData,
  selectedOrgs,
}: Props) => {
  const { authToken, role } = useContext(UserContext);
  const [editUser, setEditUser] = useState({
    name: userData.display_name,
    email: userData.email,
    role: userData.role,
    organizationIds:
      userData.organizationIds?.map((org: any) => {
        return {
          label: org.name,
          value: org._id,
        };
      }) || [],
    nameError: "",
    emailError: "",
    // passwordError: ""
  });

  const handleUpdateUser = () => {
    const isValid = validateInput(editUser);
    console.log("isValid", isValid);
    if (editUser.nameError == "" && editUser.emailError == "") {
      updateUser(authToken, userData._id, {
        ...editUser,
        organizationIds: editUser.organizationIds.map(
          (org: OptionT) => org.value
        ),
      })
        .then(() => {
          setIsEditOpen(false);
          toastify("success", "User updated Successfully");
        })
        .catch((err) => {
          toastify("failure", err);
          console.log(err);
        });
      // .catch(err => console.log(err))
    } else {
      setEditUser({
        name: isValid.name,
        email: isValid.email,
        role: "analyst",
        organizationIds: [],
        nameError: isValid.nameError,
        emailError: isValid.emailError,
        // passwordError: isValid.passwordError
      });
    }
  };

  return (
    // <ReactModal isOpen={isEditOpen} toggle={() => setIsEditOpen(false)}>
    //     <ModalHeader className="w-100 p-0">
    //         <div className="d-flex justify-content-between w-100">
    //             <h4>Edit User</h4>
    //             {/* <h4>Create User</h4> */}
    //             <span
    //                 className="close pointer"
    //                 onClick={() => {
    //                     setIsEditOpen(false);
    //                 }}
    //             >
    //                 <img src="/Icons/close_icon.svg" alt="close icon" width="12px" />
    //             </span>
    //         </div>
    //     </ModalHeader>
    //     <ModalBody style={{ overflow: "auto" }}>
    //         <div className="d-flex flex-column createModal">
    //             <div className="form-control border-0 d-flex flex-wrap">
    //                 <div className="form-group">
    //                     <label>Name</label>
    //                     <input
    //                         className="form-control"
    //                         type="text"
    //                         name="name"
    //                         value={editUser.name}
    //                         onChange={(event: any) => {
    //                             setEditUser((data) => ({
    //                                 ...data,
    //                                 name: event.target.value
    //                             }));
    //                         }}
    //                     />
    //                     {editUser.nameError && <p className="error">{editUser.nameError}</p>}
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Role</label>
    //                     <Dropdown
    //                         options={role === 'superadmin' ? [
    //                             { value: "superadmin", label: "Super Admin" },
    //                             { value: "org_admin", label: "Org Admin" },
    //                             { value: "lead", label: "QC Lead" },
    //                             { value: "analyst", label: "QC Analyst" },
    //                         ] : role === 'org_admin' ? [
    //                             { value: "lead", label: "QC Lead" },
    //                             { value: "analyst", label: "QC Analyst" },
    //                         ] : role === 'lead' ? [
    //                             { value: "analyst", label: "QC Analyst" },
    //                         ] : []}
    //                         onChange={(event: any) => {
    //                             setEditUser((data) => ({ ...data, role: event.value }));
    //                         }}
    //                         value={editUser.role}
    //                         placeholder="Select an option"
    //                     />
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Email</label>
    //                     <input
    //                         className="form-control"
    //                         type="email"
    //                         name="email"
    //                         value={editUser.email}
    //                         onChange={(event: any) => {
    //                             setEditUser((data) => ({
    //                                 ...data,
    //                                 email: event.target.value,
    //                             }));
    //                         }}
    //                     />
    //                     {editUser.emailError && <p className="error">{editUser.emailError}</p>}
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Password</label>
    //                     <input
    //                         className="form-control"
    //                         type="password"
    //                         name="password"
    //                         value={editUser.password}
    //                         onChange={(event: any) => {
    //                             setEditUser((data) => ({
    //                                 ...data,
    //                                 password: event.target.value,
    //                             }));
    //                         }}
    //                     />
    //                     {editUser.passwordError && <p className="error">{editUser.passwordError}</p>}
    //                 </div>
    //              </div>
    //             <div className="d-flex w-100 justify-content-center">
    //                 <Button
    //                     color="primary"
    //                     className="mt-3 btn-custom-primary"
    //                     onClick={handleUpdateUser}
    //                 >
    //                     Update User
    //                 </Button>
    //             </div>
    //         </div>
    //     </ModalBody>
    // </ReactModal>
    <>
      <VMModal open={isEditOpen} handleClose={() => setIsEditOpen(false)}>
        <div
          style={{
            backgroundColor: "white",
            minWidth: "35rem",
            borderRadius: "2rem",
          }}
        >
          <div style={{ padding: "0.5rem", margin: "1rem" }}>
            <h4>Edit User</h4>
          </div>
          <hr />
          <div className="d-flex flex-column createModal">
            <div className="form-control border-0 d-flex flex-wrap">
              <div className="form-group">
                <label>Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  value={editUser.name}
                  onChange={(event: any) => {
                    setEditUser((data) => ({
                      ...data,
                      name: event.target.value,
                    }));
                  }}
                />
                {editUser.nameError && (
                  <p className="error">{editUser.nameError}</p>
                )}
              </div>
              <div className="form-group">
                <label>Role</label>
                <Dropdown
                  options={
                    role === "superadmin"
                      ? [
                          { value: "superadmin", label: "Super Admin" },
                          { value: "org_admin", label: "Org Admin" },
                          { value: "lead", label: "QC Lead" },
                          { value: "analyst", label: "QC Analyst" },
                        ]
                      : role === "org_admin"
                      ? [
                          { value: "lead", label: "QC Lead" },
                          { value: "analyst", label: "QC Analyst" },
                        ]
                      : role === "lead"
                      ? [{ value: "analyst", label: "QC Analyst" }]
                      : []
                  }
                  onChange={(event: any) => {
                    setEditUser((data) => ({ ...data, role: event.value }));
                  }}
                  value={editUser.role}
                  placeholder="Select an option"
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  value={editUser.email}
                  onChange={(event: any) => {
                    setEditUser((data) => ({
                      ...data,
                      email: event.target.value,
                    }));
                  }}
                />
                {editUser.emailError && (
                  <p className="error">{editUser.emailError}</p>
                )}
              </div>
              {userData.role !== "superadmin" && (
                <div className="form-group">
                  <label>Organizations</label>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    limitTags={2}
                    // value={userData.organizationIds}
                    value={editUser.organizationIds}
                    options={selectedOrgs}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(event: any, value) => {
                      if (value.length >= 1) {
                        setEditUser((data) => ({
                          ...data,
                          organizationIds: value.map((val) => val),
                        }));
                      } else
                        toast.warning("Select atleast 1 organization", {
                          position: "top-center",
                        });
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li
                        {...props}
                        style={{
                          textTransform: "capitalize",
                          background: "#F7F7F7",
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Organizations"
                        required
                      />
                    )}
                    sx={{
                      position: "absolute",
                      width: "20%",
                    }}
                  />
                </div>
              )}
              {/* <div className="form-group">
                            <label>Password</label>
                            <input
                                className="form-control"
                                type="password"
                                name="password"
                                value={editUser.password}
                                onChange={(event: any) => {
                                    setEditUser((data) => ({
                                        ...data,
                                        password: event.target.value,
                                    }));
                                }}
                            />
                            {editUser.passwordError && <p className="error">{editUser.passwordError}</p>}
                        </div> */}
            </div>
          </div>
          <div>
            <div className="d-flex w-100 justify-content-center">
              <VMButton
                variant="primary"
                style={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}
                onClick={handleUpdateUser}
              >
                Update User
              </VMButton>
              {/* <Button
                  color="primary"
                  className="mt-3 mb-4 btn-custom-primary"
                  onClick={handleUpdateUser}
                >
                  Update User
                </Button> */}
            </div>
          </div>
        </div>
      </VMModal>
    </>
  );
};
export default EditForm;
