import { withStyles } from "@mui/styles";

import { OutlinedInput, Autocomplete } from "@mui/material";

const CssTextField = withStyles({
  root: {
    "&.Mui-focused": {
      color: "black",
    },
  },
})(OutlinedInput);

export default CssTextField;

export const AutoCompleteTextField = withStyles({
  root: {
    "&..MuiAutocomplete-tag": {
      margin: "0",
    },
  },
})(Autocomplete);
