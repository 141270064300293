import { useMemo } from "react";
import PropTypes from "prop-types";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import Typography from "./typography";
import Palette from "./palette";

import ComponentOverrides from './overrides';

export default function ThemeCustomization({ children }: any) {
  const themeTypography = Typography(`'Poppins'`);

  const theme = Palette();

  const themeOptions: any = useMemo(
    () => ({
      typography: themeTypography,
      palette: theme.palette,
    }),
    [theme,themeTypography]
  );

  const themes:any = createTheme(themeOptions);
  // themes.components = ComponentOverrides(themes)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.proTypes = {
  children: PropTypes.node,
};
