import axios from "axios";
import { toastify } from "../components/common/notification";
axios.defaults.baseURL = process.env.REACT_APP_NODE_URL;

axios.interceptors.request.use(
  (config: any) => {
    // if (!config.headers.Authorization) {
    //   const token = localStorage.getItem("$AuthToken");

    //   if (token) {
    //     config.headers.Authorization = `Bearer ${token}`;
    //   }
    // }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data === "Invalid Token") {
      localStorage.removeItem("$AuthToken");
      toastify("failure", "Authentication expired. Please login again!");
      setTimeout(() => {
        window.location.href = `${window.location.origin}/login`;
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default axios;
