import './index.css'

const SpinnerOutlined = () => {
  return (
    <div className="spinnerOutlined-wrapper">
        <span id="spinner"></span>
    </div>
  )
}

export default SpinnerOutlined