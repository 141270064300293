import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import 'react-tabs/style/react-tabs.css';
import '../shared/css/table.css';

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ReactTooltip from 'react-tooltip';
import UserContext from '../../context/userContext';

import moment from 'moment';
import { FiCalendar } from 'react-icons/fi';
import { CompletionReport } from '../../controllers/accuracy';
import { projectList } from '../../controllers/projects';
import DateFilterPopper from '../common/datefilter';

const formatTime = (value: any) => {
  let ms = moment.duration(value);
  // let fromMinutes = Math.floor(duration.asMinutes());
  // let fromSeconds = Math.floor(duration.asSeconds() - fromMinutes * 60);

  // return Math.floor(duration.asSeconds()) >= 60
  //   ? (fromMinutes <= 9 ? '0' + fromMinutes : fromMinutes) +
  //       ' Mins ' +
  //       (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds) +
  //       ' Secs'
  //   : (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds) + ' Secs';

  const days = Math.floor(ms.asDays());
  const daysFormatted = days ? `${days}d ` : '';

  //Get Hours
  const hours = ms.hours();
  const hoursFormatted = `${hours}h `;

  //Get Minutes
  const minutes = ms.minutes();
  const minutesFormatted = `${minutes}m `;

  const seconds = ms.seconds();
  const secondsFormatted = `${seconds}s`;

  return [
    daysFormatted,
    hoursFormatted,
    minutesFormatted,
    secondsFormatted,
  ].join('');
};

const Report = () => {
  const { authToken, role, organizationIds } = useContext(UserContext);

  const [options, setOptions] = useState([
    { name: 'Active', id: 'active' },
    { name: 'On Hold', id: 'on hold' },
  ]);
  const defaultFrom = {
    year: 2019,
    month: 3,
    day: 4,
  };

  const defaultTo = {
    year: 2019,
    month: 3,
    day: 7,
  };

  const defaultRange = {
    from: defaultFrom,
    to: defaultTo,
  };

  const [selectedDayRange, setSelectedDayRange] = useState(defaultRange);
  const [data, setData] = useState([
    {
      id: 1,
      type: 'Driving License Document',
      completed: 10,
      total: 100,
      average: '00 Sec',
      status: 'Active',
      modelEfficiency: '10',
      overallEfficiency: '14',
      totalPages: '23',
      correctClassifications: '66',
      wrongClassificarions: '12',
      accuracy: '98',
    },
  ]);
  const [reports, setReports] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(['']);
  const [selectedValue, setSelectedValue] = useState('');
  const [reportData, setReportData] = useState<any>([]);
  const [projectId, setProjectId] = useState('');
  const [data1, setData1] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [dateFilter, setDateFilter] = useState<{
    startDate: any;
    endDate: any;
  }>({
    startDate: '',
    endDate: '',
  });

  const handleClick = (event: any) => {
    setAnchorEl(() => (anchorEl ? null : event.currentTarget));
    setOpen(!open);
  };

  const handleDatePicker = async (event: any) => {
    await setSelectedDayRange(event);
  };
  const toolTipList = (list: string[]) => {
    let template = '';
    list.map((element, key) => {
      template += `${key + 1}. ${element}<br>`;
      return template;
    });
    return template;
  };

  const callingApi = (startDate: any, endDate: any) => {
    CompletionReport(authToken, projectId, startDate, endDate)
      .then((res: any) => {
        setReports(res.data);
      })
      .catch((error) => console.log(error));
  };

  const handleChange = (selectedList: any) => {
    const response: any = selectedList.map((list: any) => {
      return list.id;
    });
    setFilter(response);
  };

  const handleDateFilterChange = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    setDateFilter({
      startDate,
      endDate,
    });
    callingApi(startDate, endDate);
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   // const from = Date.parse(new Date("2020-12-31") as any);
  //   // const to = Date.parse(new Date() as any);
  //   reports(authToken).then((res) => {
  //     setReportData(res.data);
  //     console.log(res.data, "resData");
  //   });
  // }, []);

  useEffect(() => {
    callingApi(dateFilter.startDate, dateFilter.endDate);
  }, [projectId]);

  useEffect(() => {
    organizationIds.length &&
      projectList(authToken, 1000, 1, '_id',"").then((res) => {
        console.log({ res });
        setTimeout(() => {
          setData1(res.data);
          setProjectId(res.data[0]._id);
        }, 500);
      });
  }, []);

  return (
    <div className="d-flex flex-column h-100">
      <div className="hed-row d-flex justify-content-between py-1 my-1">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: '27rem' }}
        >
          <Typography variant="h4">Reports</Typography>
          {/* <input
            className="form-control"
            placeholder="Search Projects"
            onChange={(e: any) => {
              setSearch(e.target.value || "");
            }}
          /> */}
          <FormControl style={{ marginLeft: '0.2rem', width: '20rem' }}>
            <InputLabel id="project-selection">Select Project</InputLabel>
            <Select
              labelId="project-selection"
              id="project-selection"
              value={projectId}
              label="Select Project"
              onChange={(e: any) => setProjectId(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {data1.map((item: any) => (
                <MenuItem value={item._id}>{item.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="d-flex justify-content-right">
          <IconButton onClick={handleClick}>
            <FiCalendar />
          </IconButton>
          <DateFilterPopper
            openPopper={open}
            dateFilterPopperAnchorEl={anchorEl}
            onChangeDateFilter={handleDateFilterChange}
            onClickAway={() => {
              setAnchorEl(null);
              setOpen(false);
            }}
          />
        </div>
      </div>
      <hr />

      {role === 'org_admin' || role === 'lead' ? (
        <Tabs>
          <TabList>
            <Tab>Completion report</Tab>
            {/* <Tab>Efficiency report</Tab> */}
            {/* <Tab>Classification report</Tab> */}
          </TabList>

          <TabPanel>
            <div className="my-2 table-wrapper">
              <table className="table mx-auto">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Project Name</th>
                    <th scope="col">Volume Completed</th>
                    <th scope="col">Total Volume</th>
                    <th scope="col">Average TAT</th>
                    <th scope="col">Active Time</th>
                  </tr>
                </thead>
                <tbody>
                  {reports
                    // .filter((doc: any) => {
                    //   if (
                    //     filter?.length > 0 &&
                    //     filter.includes(doc.status.toLowerCase())
                    //   )
                    //     return search?.length >= 1
                    //       ? doc.type
                    //           .toLowerCase()
                    //           .includes(search.toLowerCase())
                    //       : doc.type;
                    //   else if (filter?.length === 0 || filter[0] === "") {
                    //     return search?.length >= 1
                    //       ? doc.type
                    //           .toLowerCase()
                    //           .includes(search.toLowerCase())
                    //       : doc.type;
                    //   }
                    // })
                    .map((doc: any, key: any) => (
                      <tr key={doc.id}>
                        <th scope="row">{key + 1}</th>
                        <td>{doc.name}</td>
                        <td>{doc.completed}</td>
                        <td>{doc.totalDocument}</td>
                        <td>
                          {doc.totalTime
                            ? formatTime(Math.round(doc.totalTime))
                            : ''}
                        </td>
                        <td>
                          {doc.activeTime
                            ? formatTime(Math.round(doc.activeTime))
                            : ''}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="my-2 table-wrapper">
              <table className="table mx-auto">
                <thead>
                  <tr>
                    <th scope="col">S.NO</th>
                    <th scope="col">Document Type Name</th>
                    <th scope="col">Volume Completed</th>
                    <th scope="col">Model efficiency</th>
                    <th scope="col">Overall efficiency</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData
                    // .filter((doc: any) => {
                    //   if (
                    //     filter?.length > 0 &&
                    //     filter.includes(doc.status.toLowerCase())
                    //   )
                    //     return search?.length >= 1
                    //       ? doc.type
                    //           .toLowerCase()
                    //           .includes(search.toLowerCase())
                    //       : doc.type;
                    //   else if (filter?.length === 0 || filter[0] === "") {
                    //     console.log("here");
                    //     return search?.length >= 1
                    //       ? doc.type
                    //           .toLowerCase()
                    //           .includes(search.toLowerCase())
                    //       : doc.type;
                    //   }
                    // })
                    .map((doc: any, key: any) => (
                      <tr key={doc.id}>
                        <th scope="row">{key + 1}</th>
                        <td>{doc.name}</td>
                        <td>{doc.volumeCompleted}</td>
                        <td>{Math.round(doc.overallEfficiency * 100) / 100}</td>
                        <td>
                          {Math.round(doc.documentLevelEfficiency * 100) / 100}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="my-2 table-wrapper">
              <table className="table mx-auto">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Document Type Name</th>
                    <th scope="col">Total pages</th>
                    <th scope="col">Correctly classified</th>
                    <th scope="col">Wrongly classified</th>
                    <th scope="col">accuracy</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData
                    // .filter((doc) => {
                    //   if (
                    //     filter?.length > 0 &&
                    //     filter.includes(doc.status.toLowerCase())
                    //   )
                    //     return search?.length >= 1
                    //       ? doc.type
                    //           .toLowerCase()
                    //           .includes(search.toLowerCase())
                    //       : doc.type;
                    //   else if (filter?.length === 0 || filter[0] === "") {
                    //     console.log("here");
                    //     return search?.length >= 1
                    //       ? doc.type
                    //           .toLowerCase()
                    //           .includes(search.toLowerCase())
                    //       : doc.type;
                    //   }
                    // })
                    .map((doc: any, key: any) => (
                      <tr key={doc.id}>
                        <th scope="row">{key + 1}</th>
                        <td>{doc.name}</td>
                        <td>{doc.totalPages}</td>
                        <td>{doc.correctlyClassified}</td>
                        <td>{doc.totalPages - doc.correctlyClassified}</td>
                        <td>{Math.round(doc.accuracy * 100) / 100}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>
      ) : (
        <div className="my-2 table-wrapper">
          <table className="table mx-auto">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Document Type Name</th>
                <th scope="col">Volume Completed</th>
                <th scope="col">Total Volume</th>
                <th scope="col">Average TAT</th>
              </tr>
            </thead>
            <tbody>
              {reportData
                // .filter((doc) => {
                //   if (
                //     filter?.length > 0 &&
                //     filter.includes(doc.status.toLowerCase())
                //   )
                //     return search?.length >= 1
                //       ? doc.type.toLowerCase().includes(search.toLowerCase())
                //       : doc.type;
                //   else if (filter?.length === 0 || filter[0] === "") {
                //     console.log("here");
                //     return search?.length >= 1
                //       ? doc.type.toLowerCase().includes(search.toLowerCase())
                //       : doc.type;
                //   }
                // })
                .map((doc: any, key: any) => (
                  <tr key={doc.id}>
                    <th scope="row">{key + 1}</th>
                    <td>{doc.name}</td>
                    <td>{doc.volumeCompleted}</td>
                    <td>{doc.total_documents}</td>
                    <td>{formatTime(Math.round(doc.tat))}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      <ReactTooltip />
    </div>
  );
};

export default Report;
