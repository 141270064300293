import React, { Fragment, useEffect, useState } from "react";

import { useLocation } from "react-router";

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import * as R from "ramda";
import "./ds.css";
import "./index.css";

import FieldForm from "./FieldForm";

import VMButton from "../../common/Button";

import {
  documentPageSegmentFieldsT,
  documentSectionT,
  projectDocumentPageSegmentFieldsT,
  randomCaseIdGenerator,
} from "../Review/index";


type Props = {
  fieldsFromReviewState: documentPageSegmentFieldsT[];
  allFields: documentPageSegmentFieldsT[];
  sections: documentSectionT[];
  showBoundingBox: boolean;
  tableDisplay: boolean;
  confirmPage: boolean;
  setFieldKey: (key: string) => void;
  handleFieldChange: (value: string, fieldKey: string) => void;
  setBoundingBox: Function;
  setTableProps: (tableProps: any) => void;
  setTableDisplay: (bool: boolean) => void;
  isSubmitting: boolean;
  handleSubmit: () => void;
  completed: boolean;
  role: string;
  lastPage: boolean;
  depedency_dropdown: any;
  triggerCheck: boolean;
  completedMandatory: boolean;
  hide: boolean;
  setTriggerMandatory: (bool: boolean) => void;
  setDependencyDropdown: any;
  projectFields: projectDocumentPageSegmentFieldsT[];
  handleConfirmPage: () => void;
  setTriggerCheck: any;
};

interface formContainerField extends documentPageSegmentFieldsT {
  error?: Boolean;
}

const checkapplicable = (fieldkey: string, dropdownValue: any, fieldSetId: any, fields: any) => {
  const { value, path } = dropdownValue[fieldSetId];
  let retrieveDropdownValues: { error: boolean, dropdownValues: any, path: any } = {
    error: false,
    dropdownValues: [],
    path: []
  };

  if (path[`keys-${fieldkey}`].length) {
    const pathofValue = path[`keys-${fieldkey}`];
    let narrowDropdown: any = value;

    for (let i = 0; i < pathofValue.length; i++) {
      const currentPath = pathofValue[i];
      const fieldToUpdate = fields.find((fiel: any) => `keys-${fiel.key}` === currentPath);

      if (fieldToUpdate && fieldToUpdate.qcUpdatedValue !== "" && narrowDropdown[`keys-${fieldToUpdate.key}`]) {
        const updatedValue = narrowDropdown[`keys-${fieldToUpdate.key}`][fieldToUpdate.qcUpdatedValue];
        narrowDropdown = updatedValue;
      } else {
        retrieveDropdownValues.error = true
        retrieveDropdownValues.dropdownValues = []
        retrieveDropdownValues.path = currentPath
        break;
      }
    }

    if (typeof narrowDropdown === 'object' && narrowDropdown[`keys-${fieldkey}`] !== undefined) {
      if (typeof narrowDropdown[`keys-${fieldkey}`] === 'string') {
        retrieveDropdownValues.dropdownValues = [narrowDropdown[`keys-${fieldkey}`]];
      } else {
        retrieveDropdownValues.dropdownValues = Object.keys(narrowDropdown[`keys-${fieldkey}`]);
      }
    }
  } else {
    retrieveDropdownValues.dropdownValues = Object.keys(value[`keys-${fieldkey}`]);
  }

  return retrieveDropdownValues;
};

const FieldsContainer = ({
  fieldsFromReviewState,
  allFields,
  showBoundingBox,
  sections,
  tableDisplay,
  setFieldKey,
  handleFieldChange,
  setBoundingBox,
  setTableProps,
  setTableDisplay,
  isSubmitting,
  confirmPage,
  handleSubmit,
  completed,
  role,
  depedency_dropdown,
  lastPage,
  completedMandatory,
  projectFields,
  hide,
  setDependencyDropdown,
  setTriggerCheck,
  handleConfirmPage,
  setTriggerMandatory,
  triggerCheck
}: Props) => {


  const getDateFromValue = (value: string) => {
    const date = new Date();
    const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      date
    );
    const month = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(
      date
    );
    const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    if (value)
      if (value.length === 8) {
        const extractedDate = value.substr(0, 2);
        const extractedMonth = value.substr(2, 2);
        const extractedYear = value.substr(4, 4);
        if (
          !isNaN(
            new Date(
              `${extractedYear}-${extractedMonth}-${extractedDate}`
            ).getTime()
          )
        ) {
          return `${extractedYear}-${extractedMonth}-${extractedDate}`;
        }
      }
    return `${year}-${month}-${day}`;
  };
  const { pathname } = useLocation();
  const [fields, setFields] = useState<formContainerField[]>([])

  const getValueFromDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}${month}${year}`;
  };

  const removalChildDropdownValue = (fieldkey: string, userValue: string) => {
    const dropdownValue = depedency_dropdown
    const field = projectFields.find((item) => item.key === fieldkey)
    const fieldSetId = field ? field.dependencydropdownvaluesetId : ""
    const { value, path } = fieldSetId !== "" ? dropdownValue[fieldSetId] : dropdownValue[Object.keys(dropdownValue)[0]]

    let UpdatedFields = fields.map((item) => {
      if (item.key === fieldkey) {
        return { ...item, qcUpdatedValue: userValue }
      }
      return item
    })

    Object.keys(path).map((item: any) => {
      const children = path[item]
      if ((children[children.length - 1]) === `keys-${fieldkey}`) {
        const checksChildren = checkapplicable(item.split('-')[1], depedency_dropdown, fieldSetId, UpdatedFields)
        const findtheValueAvailable = UpdatedFields.find((field) => field.key === item.split("-")[1])
        if (findtheValueAvailable) {
          const findTheChildrenValue = UpdatedFields.filter((field) => field.key === item.split('-')[1])[0]
          if (!checksChildren.dropdownValues.includes(findTheChildrenValue.qcUpdatedValue)) {
            let fieldsRemoveQcValue = UpdatedFields.map((field) => {
              if (findTheChildrenValue.id === item.split("-")[1]) {
                return { ...field, qcUpdatedValue: "" }
              }
              return field
            })
            setFields(fieldsRemoveQcValue)
            handleFieldChange("", item.split("-")[1])
          }
        }
      }
    })

  }

  const retrieveDependencyDropdownValue = (fieldkey: string) => {
    const dropdownValue = depedency_dropdown
    const field = projectFields.find((item) => item.key === fieldkey)
    const fieldSetId = field ? field.dependencydropdownvaluesetId : ""
    const { value, path } = fieldSetId !== "" ? dropdownValue[fieldSetId] : dropdownValue[Object.keys(dropdownValue)[0]]
    let retrieveDropdownValues: { error: boolean, dropdownValues: any, path: any } = {
      error: false,
      dropdownValues: [],
      path: []
    };

    if (path[`keys-${fieldkey}`].length) {
      const pathofValue = path[`keys-${fieldkey}`];
      let narrowDropdown: any = value;

      for (let i = 0; i < pathofValue.length; i++) {
        const currentPath = pathofValue[i];
        const fieldToUpdate = fields.find((fiel: any) => `keys-${fiel.key}` === currentPath);

        if (fieldToUpdate && fieldToUpdate.qcUpdatedValue !== "" && narrowDropdown[`keys-${fieldToUpdate.key}`]) {
          const updatedValue = narrowDropdown[`keys-${fieldToUpdate.key}`][fieldToUpdate.qcUpdatedValue];
          narrowDropdown = updatedValue;
        } else {
          break;
        }
      }

      //console.log("check", typeof narrowDropdown === undefined, narrowDropdown)
      if (narrowDropdown === undefined) {
        retrieveDropdownValues.error = true
        retrieveDropdownValues.dropdownValues = []
        retrieveDropdownValues.path = path[`keys-${fieldkey}`]
      }

      if (typeof narrowDropdown === 'object' && narrowDropdown[`keys-${fieldkey}`] !== undefined) {
        if (typeof narrowDropdown[`keys-${fieldkey}`] === 'string') {
          retrieveDropdownValues.dropdownValues = [narrowDropdown[`keys-${fieldkey}`]];
        } else {
          retrieveDropdownValues.dropdownValues = Object.keys(narrowDropdown[`keys-${fieldkey}`]);
        }
      }
    } else {
      retrieveDropdownValues.dropdownValues = Object.keys(value[`keys-${fieldkey}`]);
    }

    if (retrieveDropdownValues.error) {
      const updateFields: any = fields.map((item) => {
        if (retrieveDropdownValues.path.includes(`keys-${item.key}`)) {
          return { ...item, error: true }
        }
        return item
      })
      setFields(updateFields)
      retrieveDropdownValues.error = true
      retrieveDropdownValues.dropdownValues = []
      return retrieveDropdownValues

    } else {
      return retrieveDropdownValues;
    }

  };


  useEffect(() => {
    if (triggerCheck) {
      console.log("Accepted")
      fieldsFromReviewState.map((field) => {
        let fieldType = allFields.find((projectField) => field.key === projectField.key)?.type
        if (fieldType === "dependency_dropdown") {
          retrieveDependencyDropdownValue(field.key)
        }
      })
    }
  }, [triggerCheck])


  const addDropdownValue = (fieldkey: string, newAddedValue: string) => {
    console.log("entered", depedency_dropdown)
    let { path, value } = depedency_dropdown['65c08ba9e3242a1362d39dd9']
    let currentObj = value

    if (path[`keys-${fieldkey}`].length === 0) {
      for (const pathKey of path[`keys-${fieldkey}`]) {
        currentObj = currentObj[pathKey]
      }
    } else {
      const pathofValue = path[`keys-${fieldkey}`];
      const field: any = fields.find((field) => `keys-${field.key}` === pathofValue[0])
      let narrowDropdown = value[pathofValue[0]][field?.qcUpdatedValue];


    }


    const identifyObjectOrString = Object.keys(path).some((item) => path[item].includes(`keys-${fieldkey}`))

    if (identifyObjectOrString) {
      const parentValue = currentObj[`keys-${fieldkey}`]
      const updatedObj = { ...parentValue, [`${newAddedValue}`]: {} };
      currentObj = { ...value, [`keys-${fieldkey}`]: updatedObj };
      const updatedDependency = {
        ...depedency_dropdown,
        ['65c08ba9e3242a1362d39dd9']: {
          ...depedency_dropdown['65c08ba9e3242a1362d39dd9'],
          value: currentObj
        }
      };
      setDependencyDropdown(updatedDependency)
    } else {
      console.log("sd", currentObj)

    }

    console.log("depdency", currentObj)
  }

  useEffect(() => {
    const fieldsWithError = fieldsFromReviewState.map((field) => {
      return { ...field, "error": false }
    })
    setFields(fieldsWithError)
    setTriggerMandatory(
      fieldsFromReviewState.filter((field) => {
        if (field.type === "non-dependency_dropdown") {
          return (
            field.mandatory === true &&
            field.fieldValues.filter(
              (val) => val.qcUpdatedValue === null || val.qcUpdatedValue === ""
            ).length > 0
          );
        } else {
          return (
            field.mandatory === true &&
            (field.qcUpdatedValue === null || field.qcUpdatedValue === "")
          );
        }
      }).length > 0
    );
  }, [fieldsFromReviewState]);


  const fetchSections = (
    sections: documentSectionT[],
    fields: documentPageSegmentFieldsT[]
  ): documentSectionT[] => {
    return sections.filter((section) => {
      if (fields.filter((field) => field.section === section.name).length > 0) {
        return true;
      }
      return false;
    });
  };

  const fetchExtraFields = () => {
    const fieldsMap = allFields.reduce((accm: any, value) => {
      accm[value.key] = value;
      return accm;
    }, {});

    const extraField = projectFields.reduce((accm: any, value) => {
      return R.dissoc(value.key, accm);
    }, fieldsMap);

    return Object.keys(extraField)
      .filter((key: string) => fields.map((e) => e.key).includes(key))
      .map((key: string) => {
        const field = extraField[key];
        return {
          ...field,
          mandatory: false,
          name: key,
          type: "short_text",
          section: "extra fields",
        };
      });
  };

  const outOfSections = () => {
    const fieldsMap = fields.reduce(
      (accm: any, value: documentPageSegmentFieldsT) => {
        accm[value.key] = value;
        return accm;
      },
      {}
    );

    const section = Array.from(
      new Set(fetchSections(sections, fields).map((item) => item.name))
    );

    return projectFields.reduce(
      (
        accm: documentPageSegmentFieldsT[],
        value: projectDocumentPageSegmentFieldsT
      ) => {
        if (fieldsMap[value.key]) {
          if (!section.includes(value.section))
            return accm.concat(fieldsMap[value.key]);
        }
        return accm;
      },
      []
    );
  };

  const projectFieldsInDoc = () => {
    const fieldsMap = fields.reduce(
      (accm: any, value: documentPageSegmentFieldsT) => {
        accm[value.key] = value;
        return accm;
      },
      {}
    );


    return projectFields.reduce(
      (
        accm: documentPageSegmentFieldsT[],
        value: projectDocumentPageSegmentFieldsT
      ) => {
        if (fieldsMap[value.key]) {
          return accm.concat(fieldsMap[value.key]);
        }
        return accm;
      },
      []
    );
  };

  return (
    <>
      <div
        id="fields-container"
        style={{ position: 'relative', opacity: confirmPage ? '0.5' : '' }}
      >
        {confirmPage ? (
          <div>
            {sections.length > 0 &&
              fetchSections(sections, fields).map((section, idx) => {
                const updatedFieldsSection = fields.filter(
                  (field) => field.section === section.name
                );

                const fieldsInSection = updatedFieldsSection.sort(
                  (a, b) =>
                    projectFields.findIndex(
                      (projectField) => projectField.name === a.name
                    ) -
                    projectFields.findIndex(
                      (projectField) => projectField.name === b.name
                    )
                );

                if (fieldsInSection.length > 0) {
                  const rows = Array(
                    Math.max(
                      ...fieldsInSection.map((field) =>
                        field.fieldValues ? field.fieldValues.length : 0
                      )
                    )
                  ).fill(randomCaseIdGenerator("row"));

                  const cols = Array(fieldsInSection.length).fill(
                    randomCaseIdGenerator("col")
                  );

                  if (section.isSectionTable) {
                    return (
                      <Fragment key={idx}>
                        <span
                          onClick={(e) => {
                            e.stopPropagation()
                            setTableDisplay(!tableDisplay);
                            setTableProps({
                              rows: rows,
                              cols: cols,
                              fieldsInSection: fieldsInSection,
                              showBoundingBox: showBoundingBox,
                              tableDisplay: tableDisplay,
                              setFieldKey: setFieldKey,
                              setBoundingBox: setBoundingBox,
                              getDateFromValue: getDateFromValue,
                              getValueFromDate: getValueFromDate,
                              setTableDisplay: setTableDisplay,
                            });
                          }}
                          className="section-tab"
                          style={{
                            padding: "1rem 1.25rem",
                            border: "1px solid rgba(0,0,0,.125)",
                            borderRadius: "calc(calc(0.25rem - 1px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              flexGrow: 1,
                              color: "black",
                              marginLeft: "1rem",
                            }}
                          >
                            {section.name}
                          </span>
                          <img
                            src="/Icons/toggle.svg"
                            alt="toggle"
                            style={{
                              width: "1.25rem",
                              height: "1.25rem",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </span>
                      </Fragment>
                    );
                  } else {
                    return (
                      <Accordion key={idx}>
                        <AccordionSummary style={{ padding: '0rem 2.25rem' }}>
                          {section.name}
                        </AccordionSummary>
                        <AccordionDetails>
                          <>
                            {fieldsInSection.map(
                              (field: documentPageSegmentFieldsT, index) => (
                                <>
                                  {!section.isSectionTable ? (
                                    <FieldForm
                                      key={field.id}
                                      field={field}
                                      completed={completed}
                                      setBoundingBox={setBoundingBox}
                                      setFieldKey={setFieldKey}
                                      handleFieldChange={handleFieldChange}
                                      getDateFromValue={getDateFromValue}
                                      getValueFromDate={getValueFromDate}
                                      iIndex={index}
                                      jIndex={0}
                                      isTable={false}
                                      setTableDisplay={setTableDisplay}
                                      tableDisplay={tableDisplay}
                                      retrieveDependencyDropdownValue={retrieveDependencyDropdownValue}
                                      addDropdownValue={addDropdownValue}
                                      removalChildDropdownValue={removalChildDropdownValue}
                                      dropdownValues={
                                        field.type === "non-dependency_dropdown"
                                          ? projectFields.filter(
                                            (item) => item.key === field.key
                                          )[0]
                                          : field.type === "dependency_dropdown" ? { "dropDownValues": [] } : []
                                      }
                                    />
                                  ) : (
                                    <span />
                                  )}
                                </>
                              )
                            )}
                          </>
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                }
                return <></>
              })}
            {outOfSections().map(
              (field: documentPageSegmentFieldsT, index: number) => {
                // setFieldKey(field.key);
                return (
                  <FieldForm
                    key={field.id}
                    completed={completed}
                    field={field}
                    setBoundingBox={setBoundingBox}
                    setFieldKey={setFieldKey}
                    handleFieldChange={handleFieldChange}
                    getDateFromValue={getDateFromValue}
                    getValueFromDate={getValueFromDate}
                    retrieveDependencyDropdownValue={retrieveDependencyDropdownValue}
                    addDropdownValue={addDropdownValue}
                    removalChildDropdownValue={removalChildDropdownValue}
                    iIndex={index}
                    jIndex={index}
                    isTable={false}
                    setTableDisplay={setTableDisplay}
                    tableDisplay={tableDisplay}
                    dropdownValues={
                      field.type === "non-dependency_dropdown"
                        ? projectFields.filter(
                          (item) => item.key === field.key
                        )[0]
                        : field.type === "dependency_dropdown" ? { "dropDownValues": [] } : []
                    }
                  />
                );
              }
            )}
            {fetchExtraFields().length > 0
              ? fetchExtraFields().map((field, index) => {
                // setFieldKey(field.key);
                return (
                  <span style={{ color: "red" }} title={"Extra field"}>
                    <FieldForm
                      key={field.id}
                      field={field}
                      completed={completed}
                      setBoundingBox={setBoundingBox}
                      setFieldKey={setFieldKey}
                      handleFieldChange={handleFieldChange}
                      retrieveDependencyDropdownValue={retrieveDependencyDropdownValue}
                      addDropdownValue={addDropdownValue}
                      removalChildDropdownValue={removalChildDropdownValue}
                      getDateFromValue={getDateFromValue}
                      getValueFromDate={getValueFromDate}
                      iIndex={index}
                      jIndex={index}
                      isTable={false}
                      setTableDisplay={setTableDisplay}
                      tableDisplay={tableDisplay}
                      dropdownValues={
                        field.type === "non-dependency_dropdown"
                          ? projectFields.filter(
                            (item) => item.key === field.key
                          )[0]
                          : field.type === "dependency_dropdown" ? { "dropDownValues": [] } : []
                      }
                    />
                  </span>
                );
              })
              : null}
          </div>
        ) : (
          <div>
            {sections.length > 0 &&
              fetchSections(sections, fields).map((section, idx) => {
                const updatedFieldsSection = fields.filter(
                  (field) => field.section === section.name
                );

                const fieldsInSection = updatedFieldsSection.sort(
                  (a, b) =>
                    projectFields.findIndex(
                      (projectField) => projectField.name === a.name
                    ) -
                    projectFields.findIndex(
                      (projectField) => projectField.name === b.name
                    )
                );

                if (fieldsInSection.length > 0) {
                  const rows = Array(
                    Math.max(
                      ...fieldsInSection.map((field) =>
                        field.fieldValues ? field.fieldValues.length : 0
                      )
                    )
                  ).fill(randomCaseIdGenerator("row"));

                  const cols = Array(fieldsInSection.length).fill(
                    randomCaseIdGenerator("col")
                  );

                  if (section.isSectionTable) {
                    return (
                      <Fragment key={idx}>
                        <span
                          onClick={() => {
                            setTableDisplay(!tableDisplay);
                            setTableProps({
                              rows: rows,
                              cols: cols,
                              fieldsInSection: fieldsInSection,
                              showBoundingBox: showBoundingBox,
                              tableDisplay: tableDisplay,
                              setFieldKey: setFieldKey,
                              setBoundingBox: setBoundingBox,
                              getDateFromValue: getDateFromValue,
                              getValueFromDate: getValueFromDate,
                              setTableDisplay: setTableDisplay,
                            });
                          }}
                          className="section-tab"
                          style={{
                            padding: "1rem 1.25rem",
                            border: "1px solid rgba(0,0,0,.125)",
                            borderRadius: "calc(calc(0.25rem - 1px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              flexGrow: 1,
                              color: "black",
                              marginLeft: "1rem",
                            }}
                          >
                            {section.name}
                          </span>
                          <img
                            src="/Icons/toggle.svg"
                            alt="toggle"
                            style={{
                              width: "1.25rem",
                              height: "1.25rem",
                              transform: "rotate(90deg)",
                            }}
                          />
                        </span>
                      </Fragment>
                    );
                  } else {
                    return (
                      <Accordion key={idx}>
                        <AccordionSummary style={{ padding: '0rem 2.25rem' }}>
                          {section.name}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {fieldsInSection.map(
                              (field: documentPageSegmentFieldsT, index) => (
                                <>
                                  {!section.isSectionTable ? (
                                    <FieldForm
                                      key={field.id}
                                      completed={completed}
                                      field={field}
                                      setBoundingBox={setBoundingBox}
                                      setFieldKey={setFieldKey}
                                      handleFieldChange={handleFieldChange}
                                      getDateFromValue={getDateFromValue}
                                      getValueFromDate={getValueFromDate}
                                      retrieveDependencyDropdownValue={retrieveDependencyDropdownValue}
                                      addDropdownValue={addDropdownValue}
                                      removalChildDropdownValue={removalChildDropdownValue}
                                      iIndex={index}
                                      jIndex={0}
                                      isTable={false}
                                      setTableDisplay={setTableDisplay}
                                      tableDisplay={tableDisplay}
                                      dropdownValues={
                                        field.type === "non-dependency_dropdown"
                                          ? projectFields.filter(
                                            (item) => item.key === field.key
                                          )[0]
                                          : field.type === "dependency_dropdown" ? { "dropDownValues": [] } : []
                                      }
                                    />
                                  ) : (
                                    <span />
                                  )}
                                </>
                              )
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                }
              })}
            {outOfSections().map(
              (field: documentPageSegmentFieldsT, index: number) => {
                // setFieldKey(field.key);
                return (
                  <FieldForm
                    key={field.id}
                    field={field}
                    completed={completed}
                    setBoundingBox={setBoundingBox}
                    setFieldKey={setFieldKey}
                    handleFieldChange={handleFieldChange}
                    getDateFromValue={getDateFromValue}
                    getValueFromDate={getValueFromDate}
                    retrieveDependencyDropdownValue={retrieveDependencyDropdownValue}
                    addDropdownValue={addDropdownValue}
                    removalChildDropdownValue={removalChildDropdownValue}
                    iIndex={index}
                    jIndex={index}
                    isTable={false}
                    setTableDisplay={setTableDisplay}
                    tableDisplay={tableDisplay}
                    dropdownValues={
                      field.type === "non-dependency_dropdown"
                        ? projectFields.filter(
                          (item) => item.key === field.key
                        )[0]
                        : field.type === "dependency_dropdown" ? { "dropDownValues": [] } : []
                    }
                  />
                );
              }
            )}
            {fetchExtraFields().length > 0
              ? fetchExtraFields().map((field, index) => {
                // setFieldKey(field.key);
                return (
                  <span style={{ color: "red" }} title={"Extra field"}>
                    <FieldForm
                      key={field.id}
                      field={field}
                      completed={completed}
                      setBoundingBox={setBoundingBox}
                      setFieldKey={setFieldKey}
                      handleFieldChange={handleFieldChange}
                      getDateFromValue={getDateFromValue}
                      getValueFromDate={getValueFromDate}
                      retrieveDependencyDropdownValue={retrieveDependencyDropdownValue}
                      addDropdownValue={addDropdownValue}
                      removalChildDropdownValue={removalChildDropdownValue}
                      iIndex={index}
                      jIndex={index}
                      isTable={false}
                      setTableDisplay={setTableDisplay}
                      tableDisplay={tableDisplay}
                      dropdownValues={
                        field.type === "non-dependency_dropdown"
                          ? projectFields.filter(
                            (item) => item.key === field.key
                          )[0]
                          : field.type === "dependency_dropdown" ? { "dropDownValues": [] } : []
                      }
                    />
                  </span>
                );
              })
              : null}
          </div>
        )}
        {completed && (role === "analyst" || role === "org_admin") ? (
          <></>
        ) : (
          <React.Fragment>
            {completedMandatory && lastPage && (
              <div className="mx-3 mt-3" style={{ color: "red" }}>
                Fill all * Mandatory fields
              </div>
            )}
            {pathname.indexOf("/view") > 0 ? "" : lastPage ? "" : " "}
            <VMButton
              variant="primary"
              style={
                !confirmPage
                  ? {
                    cursor: 'pointer',
                    marginTop: '16px',
                    // borderRadius: "8px",
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // fontSize: "1.2rem",
                    marginLeft: '12px',
                    // width: "50%",
                  }
                  : {
                    cursor: 'auto',
                    marginTop: '16px',
                    // borderRadius: "8px",
                    height: '35px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // fontSize: "1.2rem",
                    marginLeft: '12px',
                    // width: "50%",
                  }
              }
              onClick={() => handleConfirmPage()}
              disabled={confirmPage}
            >
              Confirm Page
            </VMButton>
          </React.Fragment>
        )}
      </div>
      {confirmPage && (
        <div
          style={{
            position: "absolute",
            top: "6.3%",
            left: "79.7%",
            width: "20.2%",
            height: "95vh",
            backgroundColor: "transparent",
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "93.5vh", width: "100%" }}
          >
            <VMButton
              variant="primary"
              style={{ width: "30%" }}
              onClick={() => handleConfirmPage()}
            >
              Edit
            </VMButton>
          </div>
        </div>
      )}
    </>
  );
};

export default FieldsContainer;
