import React from 'react';

import './commentListItem.css';

interface ICommentListItem {
    comment: string;
    name: string;
    time: string;
}

function CommentListItem({ comment, name, time }: ICommentListItem) {
    return (
        <div className='comment_container'>
            <div className='comment_header'>
                <p>{name}</p>
                <time>{time}</time>
            </div>
            <div id='comment'>
                <p>{comment}</p>
            </div>
        </div>
    );
}

export default CommentListItem;
