import React, { useState, useContext } from "react";
import VMButton from "../common/Button";

import { Container, Typography,FormControl,TextField } from "@mui/material";


import { useHistory, useParams } from "react-router-dom";
import { toastify } from "../common/notification";
import UserContext from "../../context/userContext";

import "./index.css";
import { updatePassword } from "../../controllers/users";

const UpdatePassword = () => {
  const { authToken } = useContext(UserContext);

  return (
    <Container maxWidth="xl" className="p-0">
      <div className="rowContainer" style={{ display: "flex" }}>
        <div className="leftside" style={{ width: "90vh" }}>
          <LeftPanel />
        </div>
        <div className="rightside" style={{ width: "90vh" }}>
          <RightPanel token={authToken} />
        </div>
      </div>
    </Container>
  );
};

const RightPanel: React.FC<{ token: any }> = ({ token }: { token: any }) => {
  const history = useHistory();

  const [password, setPassword] = useState<any>("");
  const [password2, setPassword2] = useState<string>("");

  const handleResetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (password === password2 && password !== "") {
      updatePassword(token, password)
        .then((res) => {
          console.log(res);
          toastify("success", "Password updated successfully");
          history.push("/");
        })
        .catch((err) => {
          toastify("failure", "Password update failed");
          console.log(err);
        });
      console.log("token", token);
    } else {
      toastify(
        "failure",
        "Password doesn't match! \n Please enter matching password."
      );
    }
  };

  return (
    <div>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh", flexDirection: "column" }}
      >
        <div
          style={{
            marginTop: "2%",
            width: "450px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{marginLeft:"2.5rem"}}>Please update your Password</Typography>
          <Typography variant="subtitle2" className="mt-3">
            Enter your new password
          </Typography>
        </div>
        <div
          style={{
            height: "250px",
            width: "450px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "5%",
            marginLeft: "10%"
          }}
        >
          <form onSubmit={handleResetPassword} id="login">
            <div style={{ margin: "5%" }}>
              <div style={{margin:"3%"}}>
                {/* <InputGroup className="input">
                  <FormControl
                    placeholder="Enter Password"
                    aria-label="password"
                    name="password1"
                    type="password1"
                    id="password1"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                <InputGroup className="input">
                  <FormControl
                    placeholder="Confirm Password"
                    aria-label="password"
                    name="password2"
                    type="password2"
                    id="password2"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    aria-describedby="basic-addon1"
                  />
                </InputGroup> */}
                <FormControl>
                    <div>
                      <TextField
                        variant="outlined"
                        label="Password"
                        placeholder="Type New Password"
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        sx={{ width: "22rem" }}
                      />
                    </div>
                    <div style={{marginTop:"1rem"}}>
                    <TextField
                      variant="outlined"
                      label="Confirm Password"
                      placeholder="Type New Confirm Password"
                      type="password"
                      onChange={(e) =>  setPassword2(e.target.value)}
                      fullWidth
                      sx={{ width: "22rem" }}
                    />
                    </div>
                  </FormControl>
              </div>
              <div>
                <VMButton
                  variant="primary"
                  type="submit"
                  style={{
                    width: "85%",
                    marginLeft: "4%",
                    padding: "3%",
                    marginTop: "3%",
                  }}
                >
                  Submit
                </VMButton>
                <p style={{ marginLeft: "4%", marginTop: "24px" }}>
                  Note: Make sure both the passwords are same.
                </p>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </div>
  );
};

const LeftPanel = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{ height: "90vh" }}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <img
          src={"/Icons/verify-me.svg"}
          alt="Verify Me icon"
          style={{ marginLeft: 30, width: "24%" }}
        />
        <div style={{ marginTop: "10%" }}>
          <h1 style={{ color: "#FFFF", marginLeft: 30 }}>
            Welcome to Verify Me
          </h1>
        </div>
      </div>
      <div style={{ paddingBottom: "5%", height: "10vh" }}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div style={{ width: "20vh" }}>
            <p style={{ color: "#FFFF" }}>&#169; 2021 Verify Me</p>
          </div>
          <div style={{ width: "35vh" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Privacy</p>
                </a>
              </div>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Terms</p>
                </a>
              </div>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Contact</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdatePassword;
