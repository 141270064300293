import { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import './App.css';

import ThemeCustomization from './themes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/Login';
import UserManagement from './components/User';
import Report from './components/report';

import DocumentType from './components/DocumentType';
import DocumentReview from './components/Documents/Review';
import OrganizationManagement from './components/Organizations';
import ProjectManagement from './components/Project';
import CompletedQueue from './components/Project/CompletedQueue';
import PendingQueue from './components/Project/PendingQueue';
import ReviewQueue from './components/Project/ReviewQueue';
import ReferenceFile from './components/Project/ReferenceFile';

import ContentBody from './components/shared/contentBody';
import Sidebar from './components/shared/sidebar';

import Loader from './components/shared/loader';

import sidebarItems from './components/shared/sidebar/SideBarlements';

import { tokenData } from './controllers/users';

import Analytics from './components/Analytics';
import ForgetPassword from './components/ForgetPassword';
import CreateProject from './components/Project/CreateProject/index';
import ResetPassword from './components/ResetPassword';
import UpdatePassword from './components/UpdatePassword';
import UserContext from './context/userContext';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
} & RouteProps;

type UserT = {
  name: string;
  email: string;
  role: string;
  authToken: string;
  organizationIds: any;
};

function ProtectedRoute({
  isAuthenticated,
  ...routeProps
}: ProtectedRouteProps) {
  if (!isAuthenticated) {
    <Redirect to={{ pathname: '/login' }} />;
    return null;
  } else {
    return <Route {...routeProps} />;
  }
}

const WithSideBar = ({ render }: { render: Function }) => {
  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div className="non-sidebar">{render()}</div>
    </div>
  );
};

const WithoutSideBar = ({ render }: { render: Function }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div className="complete_window">{render()}</div>
    </div>
  );
};

const App = () => {
  const [user, setUser] = useState<UserT>({
    name: '',
    email: '',
    role: '',
    authToken: '',
    organizationIds: [],
  });

  const [loading, setLoading] = useState<boolean>(true);

  const [firstRoute, setFirstRoute] = useState('');
  const [allowdRoutes, setAllowedRoutes] = useState<any | null>([]);

  useEffect(() => {
    setLoading(true);
    const firstElement = sidebarItems.find((item: any) =>
      item.users?.includes(user.role)
    );

    const sideItems = sidebarItems.filter((item: any) =>
      item.users?.includes(user.role)
    );

    setAllowedRoutes(sideItems.map((item: any) => item.route));

    setFirstRoute(firstElement?.route || '/login');

    setTimeout(() => setLoading(false), 600);
  }, [user]);

  useEffect(() => {
    const token = localStorage.getItem('$AuthToken');
    if (token) {
      tokenData(token)
        .then((data) => {
          if (!data) {
            localStorage.removeItem('$AuthToken');
          } else {
            setUser({
              name: data?.user.display_name,
              email: data?.user.email,
              role: data?.user.role,
              organizationIds: data?.user.organizationIds,
              authToken: token,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          // localStorage.removeItem("$AuthToken");
        });
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <ThemeCustomization>
        <div className="App">
          <UserContext.Provider
            value={{
              name: user.name,
              email: user.email,
              role: user.role,
              authToken: user.authToken,
              organizationIds: user.organizationIds,
              setUser: setUser,
            }}
          >
            <ContentBody>
              {loading ? (
                <Loader />
              ) : (
                <Router>
                  <Switch>
                    <Route
                      path="/login"
                      render={() => <WithoutSideBar render={() => <Login />} />}
                    />
                    <Route
                      path="/forgot-password"
                      render={() => (
                        <WithoutSideBar render={() => <ForgetPassword />} />
                      )}
                    />
                    <Route
                      path="/reset-password/:token"
                      render={() => (
                        <WithoutSideBar render={() => <ResetPassword />} />
                      )}
                    />
                    <Route
                      path="/update-password"
                      render={() => (
                        <WithoutSideBar render={() => <UpdatePassword />} />
                      )}
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('users')}
                      path="/users"
                      render={() => (
                        <WithSideBar render={() => <UserManagement />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('organizations')}
                      path="/organizations"
                      render={() => (
                        <WithSideBar
                          render={() => <OrganizationManagement />}
                        />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('users')}
                      path="/document-type"
                      render={() => (
                        <WithSideBar render={() => <DocumentType />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects"
                      render={() => (
                        <WithSideBar render={() => <ProjectManagement />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/new"
                      render={() => (
                        <WithSideBar render={() => <CreateProject />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/edit/:projectId"
                      render={() => (
                        <WithSideBar render={() => <CreateProject />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/reference-file/:projectId"
                      render={() => (
                        <WithSideBar render={() => <ReferenceFile />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/:project_id/documents/review"
                      render={() => (
                        <WithSideBar render={() => <ReviewQueue />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/:project_id/documents/pending"
                      render={() => (
                        <WithSideBar render={() => <PendingQueue />} />
                      )}
                      exact
                    />

                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/:project_id/documents/completed"
                      render={() => (
                        <WithSideBar render={() => <CompletedQueue />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/:project_id/document/:document_id/pendingdoc"
                      render={() => (
                        <WithoutSideBar render={() => <DocumentReview />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/:project_id/document/review/:document_id/completed"
                      render={() => (
                        <WithoutSideBar render={() => <DocumentReview />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/:project_id/document/review/:document_id"
                      render={() => (
                        <WithoutSideBar render={() => <DocumentReview />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('projects')}
                      path="/projects/:project_id/document/review/:document_id/view"
                      render={() => (
                        <WithoutSideBar render={() => <DocumentReview />} />
                      )}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('report')}
                      path="/report"
                      render={() => <WithSideBar render={() => <Report />} />}
                      exact
                    />
                    <ProtectedRoute
                      isAuthenticated={allowdRoutes.includes('Analytics')}
                      path="/Analytics"
                      render={() => (
                        <WithSideBar render={() => <Analytics />} />
                      )}
                      exact
                    />
                    {user.authToken !== '' ? (
                      <Redirect to={`/${firstRoute}`} />
                    ) : (
                      <Redirect to={'/login'} />
                    )}
                  </Switch>
                </Router>
              )}
              <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </ContentBody>
          </UserContext.Provider>
        </div>
      </ThemeCustomization>
    </>
  );
};

export default App;
