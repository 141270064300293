import React from "react";

import { makeStyles } from "@mui/styles";

import "./contentbody.css";

type Props = {
  children: React.ReactNode;
};


const useStyles:any = makeStyles((theme:any) => ({
    mainContent : {
      backgroundColor : theme.palette.background.default
    }
}));

function ContentBody({ children }: Props) {
  const classes = useStyles();

  return <div className={`${classes.mainContent}`} style={{overflow:"hidden"}}>{children}</div>;
}

export default ContentBody;
