import { Button } from "react-bootstrap";

import "./button.css";

const VMButton = (props: any) => {
  return props.variant === "primary" ? (
    <Button
      variant="primary"
      size={props.size}
      className={`primary-button ${props.className}`}
      {...props}
    >
      {props.children}
    </Button>
  ) : (
    <Button variant="outline-secondary" size={props.size} {...props}>
      {props.children}
    </Button>
  );
};

export default VMButton;
