import { useState } from "react";
import { toast } from "react-toastify";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      // if app accessed in http protocol the navigator.clipboard will not be accessible
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        (document.getSelection() as Selection).rangeCount > 0
          ? (document.getSelection() as Selection).getRangeAt(0)
          : false;
      el.select();
      const success = document.execCommand("copy", true, text);
      document.body.removeChild(el);
      if (selected) {
        (document.getSelection() as Selection).removeAllRanges();
        (document.getSelection() as Selection).addRange(selected);
      }
      setCopiedText(text);
      return success;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      setTimeout(() => {
        setCopiedText(null);
      }, 1000);
      return true;
    } catch (error) {
      console.warn(error);
      toast.error("Copy failed");
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}
