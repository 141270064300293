import VMButton from '../common/Button';
import VMModal from '../common/Modal';

import ClearIcon from '@material-ui/icons/Clear';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { createCustomDocTypeList } from '../../controllers/projects';

import AddingFieldTableContainer from './AddingFieldTableContainer';

import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router';
import UserContext from '../../context/userContext';
import { tokenData } from '../../controllers/users';
import { toastify } from '../common/notification';

const formatName = (role: string) => {
  return role
    .toLowerCase()
    .split('_')
    .map((word: string) => {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ')
    .replace('Qc', 'QC');
};

const DocumentType = () => {
  const [toggleUploadFile, setToggleUploadFile] = useState(false);
  const [organization, setOrganization] = useState<any>([]);
  const [toggleSection, setToggleSection] = useState<any>(false);
  const [sections, setSections] = useState<any>([]);
  const { authToken } = useContext(UserContext);
  const [currentSection, setCurrentSection] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [customDocTypeDefine, setCustomDocTypeDefine] = useState<any>({
    docType: '',
    description: '',
    organizationIds: [],
    fields: [],
    isTemplate: false,
  });
  const [fileName, setFileName] = useState('');
  const history = useHistory();
  const [fieldKeys, setFieldKeys] = useState([]);

  const onDrop = (acceptedFiles: any, rejectedFiles: any) => {
    const reader = new FileReader();
    setFileName(acceptedFiles[acceptedFiles.length - 1].name);
    reader.readAsText(acceptedFiles[acceptedFiles.length - 1]);
    reader.onload = () => {
      if (reader.result && typeof reader.result === 'string') {
        const fieldKeyValues = reader.result.split(',').map((v) => v.trim());
        setFieldKeys(fieldKeyValues as any);

        fieldKeyValues.forEach((key, idx) => {
          const fieldValues: any = customDocTypeDefine.fields;
          fieldValues.push({
            key: key,
            name: formatName(key),
            type: 'short_text',
            dropDownValues: [],
            section: '',
            mandatory: false,
          });
          setCustomDocTypeDefine({
            ...customDocTypeDefine,
            fields: fieldValues,
          });
        });
      }
    };
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop });

  useEffect(() => {
    tokenData(authToken)
      .then((res) => {
        let data = res.user.organizationIds;
        let organizationForDropdown: Object[] = [];
        data.forEach((item: any) => {
          organizationForDropdown.push({ value: item._id, label: item.name });
        });
        setOrganization(organizationForDropdown);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSections = (keyCode: number, value: string) => {
    if (keyCode === 13) {
      // const values = value.split(';');
      setSections(sections.concat([{ name: value, isSectionTable: false }]));
      setCurrentSection('');
    }
    // else {
    //     setCurrentSection(value);
    // }
  };

  const toggleSectionTable = (section: string) => {
    setSections(
      sections.map((item: any) =>
        item.name === section
          ? { ...item, isSectionTable: !item.isSectionTable }
          : item
      )
    );
  };

  const removeSection = (section: string) => {
    setSections(sections.filter((item: any) => item.name !== section));
  };

  const clearAllFilesSection = () => {
    // acceptedFilesSection.length = 0;
    // acceptedFilesSection.splice(0, acceptedFilesSection.length);
    setSectionName('');
    // setFieldKeys([]);
    // setProjectForm({ ...projectForm, fields: [] });
  };

  const handleCreate = () => {
    createCustomDocTypeList(authToken, customDocTypeDefine)
      .then((res) => {
        history.push('/projects');
        toastify('success', 'DocumentType created Successfully');
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className="w-100 d-flex flex-column align-items-center"
      style={{ overflowY: 'scroll' }}
    >
      <div className="heading_cont my-3">
        <h1 style={{ fontWeight: 600, fontSize: '2.6rem' }}>
          Create Document Type
        </h1>
      </div>
      <div className="form_container_cp">
        <div className="form_element_cp">
          <label>Document Type Name</label>
          <input
            className="form-control"
            placeholder="Enter Document Type Name"
            value={customDocTypeDefine.docType}
            onChange={(event) =>
              setCustomDocTypeDefine((data: any) => ({
                ...data,
                docType: event.target.value,
              }))
            }
          />
        </div>

        <div className=" form_element_cp">
          <label>Description</label>
          <textarea
            rows={5}
            style={{ marginLeft: '8px' }}
            className="form-control"
            placeholder="Enter the description"
            value={customDocTypeDefine.description}
            onChange={(event) =>
              setCustomDocTypeDefine((data: any) => ({
                ...data,
                description: event.target.value,
              }))
            }
          />
        </div>

        <div className="form_element_cp">
          <label>Organization</label>
          <Dropdown
            options={organization}
            value={customDocTypeDefine.organization}
            onChange={(event) => {
              setCustomDocTypeDefine((data: any) => ({
                ...data,
                organizationIds: event.value,
              }));
            }}
            placeholder="Select organization"
          />
        </div>

        <div className="w-100 d-flex flex-column align-items-center justify-content-center classification mb-3">
          {/* <label>Create using CSV File</label> */}
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={toggleUploadFile}
                  // style={{ color: '#69bec6' }}
                  onClick={() => setToggleUploadFile(!toggleUploadFile)}
                />
              }
              label="Create using CSV File"
            />
          </FormGroup>
        </div>
        <div className="form-group d-flex align-items-center">
          <label htmlFor="tagSection" style={{ marginBottom: '0.5px' }}>
            Tag Section
          </label>
          <input
            id="tagSection"
            type="checkbox"
            checked={toggleSection}
            onChange={(event) => setToggleSection(true)}
          />
        </div>
        {toggleUploadFile && (
          <div className="upload_tag_csv text-center">
            <h5>Upload values as CSV</h5>
            <a
              href="/sample-csv/sample.csv"
              className="sample_csv_tags"
              download
            >
              Sample CSV Format
            </a>
            <div className="upload_cont">
              <button className="btn btn-success">
                Upload a CSV file
                <input {...getInputProps()} className="drop-input" />
              </button>
              <span>OR</span>
              <span>Drag & Drop a file here.</span>
            </div>
          </div>
        )}
        {!toggleUploadFile && (
          <AddingFieldTableContainer
            sections={sections}
            setCustomDocTypeDefine={setCustomDocTypeDefine}
            customDocTypeDefine={customDocTypeDefine}
          />
        )}
        <div className="d-flex w-100 justify-content-center gap-3 mb-5">
          <div className="col-sm-3 mt-3">
            <VMButton
              variant="primary"
              //   className="mt-5 btn-custom-primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </VMButton>
          </div>
          <div className="col-sm-3 mt-3">
            <VMButton variant="primary" onClick={() => handleCreate()}>
              Create
            </VMButton>
          </div>
        </div>
      </div>

      <VMModal open={toggleSection} handleClose={() => setToggleSection(false)}>
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '50rem',
            height: '45rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Define Section</h4>
          </div>
          <hr />
          <div className="d-flex flex-column createModal">
            <div style={{ padding: '1rem' }}>
              <input
                className="form-control"
                placeholder="Enter Section Name"
                style={{ width: '49rem' }}
                value={currentSection}
                onChange={(e) => setCurrentSection(e.target.value)}
                onKeyUp={(e: any) => {
                  console.log(e);
                  handleSections(e.keyCode, e.target.value);
                }}
              />
              {sections.length > 0 && (
                <div
                  className="section_list_cl"
                  style={{ height: '8rem', overflow: 'scroll' }}
                >
                  <div className="section_head_cl">
                    <span>Section Name</span>
                    <span>Mark as Table</span>
                  </div>
                  {sections.map((item: any, key: any) => (
                    <div key={key} className="section_elem_cl">
                      <div className="mx-2 m-0">{item.name}</div>
                      <div className="section_tb_actions">
                        <input
                          type="checkbox"
                          className="m-0"
                          checked={item.isSectionTable}
                          onChange={() => toggleSectionTable(item.name)}
                        />
                        <span
                          className="tag_elem_close_cl"
                          onClick={() => removeSection(item.name)}
                        >
                          <img
                            src="/Icons/close_icon.svg"
                            alt="close icon"
                            width="10px"
                            style={{ filter: 'invert(1)' }}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="or_line">-----------OR-----------</div>
              <div
                className="upload_tag_csv text-center w-100"
                // {...getRootPropsSection()}
                data-cy-field-keys-dropzone
              >
                {sectionName ? (
                  <div className="uploadedFileCont upload_cont align-items-center gap-3">
                    <img
                      src="/Icons/document.svg"
                      alt="document images"
                      width="84px"
                    />
                    <span className="file-name" data-cy-field-keys-file>
                      {sectionName}
                      <ClearIcon
                        onClick={clearAllFilesSection}
                        style={{ color: 'red', fontSize: '1.5em' }}
                      />
                    </span>
                  </div>
                ) : (
                  // <div className="fileUploadCont">
                  //   <h5>Upload values as CSV</h5>
                  //   <a
                  //     href="/sample-csv/sample.csv"
                  //     className="sample_csv_tags"
                  //     download
                  //   >
                  //     Sample CSV Format
                  //   </a>
                  //   <div className="upload_cont" {...getRootPropsSection()}>
                  //     {isDragActiveSection ? (
                  //       <div className="d-flex flex-column align-items-center gap-3">
                  //         <img
                  //           src="/Icons/document.svg"
                  //           className="dropbox-image"
                  //           alt="dropbox document"
                  //           width="84px"
                  //         />
                  //         <span>Uploading a file...</span>
                  //       </div>
                  //     ) : (
                  //       <div
                  //         className="upload_cont"
                  //         style={{
                  //           border: 0,
                  //           margin: 0,
                  //           width: "100%",
                  //           padding: 0,
                  //         }}
                  //       >
                  //         <button className="btn btn-success">
                  //           Upload a CSV file
                  //           <input
                  //             {...getInputPropsSection()}
                  //             className="drop-input"
                  //           />
                  //         </button>
                  //         <span>OR</span>
                  //         <span>Drag & Drop a file here.</span>
                  //       </div>
                  //     )}
                  //   </div>
                  // </div>
                  <div></div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex w-100 justify-content-center">
              <VMButton
                variant="primary"
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                onClick={() => setToggleSection(false)}
              >
                Define
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>
    </div>
  );
};

export default DocumentType;
