import "bootstrap/dist/css/bootstrap.min.css";
import "react-dropdown/style.css";
import "../shared/css/table.css";

import { useState, useEffect, useContext } from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useHistory } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Typography from "@mui/material/Typography";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

import {
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Table as VMTable,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import VMModal from "../common/Modal";

import SearchIcon from "../../utils/icons";

import VMButton from "../common/Button";

import ReactTooltip from "react-tooltip";
import Dropdown from "react-dropdown";
// import {
//   Table,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
//   UncontrolledDropdown,
// } from "reactstrap";

// import { Table } from "reactstrap";

import {
  userList,
  createUser,
  deleteUser,
  updateUser,
} from "../../controllers/users";
import UserContext from "../../context/userContext";
import { validateInput } from "./validation";
// import CustomModal from "../shared/modal";

import { UserT } from "../../types";
import moment from "moment";
import { DropDownMenu } from "../shared/DropDown";
import { toastify } from "../common/notification";

import { tokenData } from "../../controllers/users";

import EditForm from "./Editmodal";

import sortSvg from "../../assets/icons/sort.svg";
import { useScrolls } from "../../hooks/useScrolls";
import { Spinner } from "../Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { getOrganization } from "../../controllers/organization";
import { toast } from "react-toastify";
import { OrganizationT } from "../Organizations/types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface UserType extends UserT {
  projects: any[];
}

export interface OptionT {
  label: string;
  value: string;
}

const CssTextField = withStyles({
  root: {
    "&.Mui-focused": {
      color: "black",
    },
  },
})(OutlinedInput);

const useStyles = makeStyles((theme) => ({
  flex: {
    display: "flex",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;

const UserManagement = () => {
  const [users, setUsers] = useState<UserType[]>([]);
  const [search, setSearch] = useState("");
  const [isCreateOpen, openCreateModal] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState<Boolean>(false);
  const [selectedOrgs, setSelectedOrgs] = useState<OptionT[]>([]);
  const [orgFilter, setOrgFilter] = useState<string[]>([]);
  const [actionCaret, setActionCaret] = useState(999);
  const [userForm, setUserForm] = useState({
    name: "",
    email: "",
    role: "analyst",
    password: "",
    organizationIds: [] as string[],
    nameError: "",
    emailError: "",
    passwordError: "",
    organizationError: "",
  });
  const [userOrg, setUserOrg] = useState<{ organizationIds: OrganizationT[] }>({
    organizationIds: [],
  });
  const [userId, setUserId] = useState("");
  const history = useHistory();
  const { authToken, role } = useContext(UserContext);
  const [isDelete, setIsDelete] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();

  const {
    limit,
    page,
    sortBy,
    hasMore,
    sortDocuments,
    fetchMoreDocsOnScroll,
    setDocLength,
  } = useScrolls(users.length);

  const fetchUsers = () => {
    userList(authToken, limit, page, sortBy).then((res) => {
      setTimeout(() => {
        if (page > 1) {
          setUsers((prevUsers) => [...prevUsers, ...res.data]);
        } else {
          setUsers(res.data);
        }
        setIsLoading(false);
      }, 500);
      setDocLength(res.length);
    });
  };

  useEffect(() => {
    const token = localStorage.getItem("$AuthToken");
    if (token) {
      tokenData(token)
        .then((data) => {
          if (!data) {
            localStorage.removeItem("$AuthToken");
          } else {
            setUserOrg({
              organizationIds: data?.user.organizationIds,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          localStorage.removeItem("$AuthToken");
        });
    } else {
      // setLoading(false);
    }
  }, []);

  const fetchOrgs = async () => {
    const organization =
      role === "superadmin"
        ? await getOrganization(authToken)
        : { data: userOrg.organizationIds };
    let options: OptionT[] = [];
    organization.data.forEach((org: any) => {
      options.push({
        label: org.name,
        value: org._id,
      });
    });
    options.sort((a, b) => a.label.localeCompare(b.label));
    setSelectedOrgs(options);
  };

  useEffect(fetchUsers, [
    limit,
    page,
    sortBy,
    authToken,
    setDocLength,
    orgFilter,
  ]);

  useEffect(() => {
    fetchOrgs();
  }, [authToken, userOrg]);

  useEffect(() => fetchUsers(), [isEditOpen]);
  const [data, setData] = useState<any[]>([]);

  const tableActions = [
    { name: "Edit", value: "edit" },
    { name: "Delete", value: "delete" },
  ];

  const handleMenu = (action: string, record: any) => {
    switch (action) {
      case "edit":
        setIsEditOpen(true);
        setUserId(record._id);
        break;
      case "delete":
        setIsDelete(true);
        setRecordId(record._id);
        break;
      default:
        break;
    }
  };
  const handleDelete = () => {
    deleteUser(authToken, recordId)
      .then((_res) => {
        toastify("success", "User deleted successfully");
        userList(authToken, limit, page, sortBy).then((res) => {
          setData(res.data);
          setIsDelete(false);
        });
        fetchUsers();
      })
      .catch((err) => {
        toastify("failure", "User Deletion failed");
        console.log(err);
      });
  };

  const handleCreate = () => {
    //setUserForm(validateInput(userForm));
    const isValid = validateInput(userForm);
    if (
      userForm.nameError === "" &&
      userForm.emailError === "" &&
      userForm.passwordError === "" &&
      userForm.organizationError === "" &&
      (userForm.organizationIds.length || userForm.role === "superadmin")
    ) {
      createUser(userForm, authToken).then(() => {
        fetchUsers();
        openCreateModal(false);
        setUserForm({
          name: "",
          email: "",
          role: "analyst",
          password: "",
          nameError: "",
          emailError: "",
          passwordError: "",
          organizationIds: [],
          organizationError: "",
        });
      });
    } else {
      toast.warning("Please choose atleast 1 organization", {
        position: "top-center",
      });
      setUserForm({
        name: isValid.name,
        email: isValid.email,
        role: "analyst",
        password: isValid.password,
        organizationIds: isValid.organizationIds,
        nameError: isValid.nameError,
        emailError: isValid.emailError,
        passwordError: isValid.passwordError,
        organizationError: isValid.organizationError,
      });
    }
  };

  const tableHeader = [
    { key: "sno", title: "S.No", width: 80 },
    { key: "name", title: "Name", width: 100 },
    { key: "role", title: "Role", width: 50 },
    { key: "email", title: "Email", width: 50 },
    { key: "organization", title: "Organization", width: 50 },
    { key: "projects_assigned", title: "Projects Assigned", width: 50 },
    { key: "created_date", title: "Created Date", width: 50 },
    { key: "actions", title: "Actions", width: 50 },
  ];

  console.log({ orgFilter });

  return (
    <div className="d-flex flex-column h-100">
      <div className="hed-row d-flex justify-content-between py-1 my-1">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "25rem" }}
        >
          <Typography variant="h4">Users</Typography>
          <FormControl>
            <CssTextField
              id="searchProject"
              margin="none"
              style={{ width: "20rem" }}
              size="small"
              placeholder="Search User"
              onChange={(e: any) => {
                setSearch(e.target.value ? e.target.value : "");
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "40%" }}
        >
          {/* <input
            className="form-control"
            placeholder="Search Users"
            onChange={(e: any) => {
              setSearch(e.target.value ? e.target.value : "");
            }}
          /> */}
          {/* 
            <button
              className="btn btn-primary mx-2"
              onClick={() => {
                openCreateModal(true);
                setUserId("");
              }}
            >
              Create User
            </button>
          */}
          <div className="w-100">
            <FormControl sx={{ m: 1, width: 280 }}>
              <InputLabel size="small" id="demo-multiple-checkbox-label">
                Organization
              </InputLabel>
              <Select
                labelId="demo-multiple-checkbox-label"
                fullWidth
                multiple
                value={orgFilter}
                onChange={(event: any) => setOrgFilter(event.target.value)}
                input={<OutlinedInput label="Organization" />}
                renderValue={(selected: any) => selected.join(", ")}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                      width: 250,
                    },
                  },
                }}
                size="small"
              >
                {selectedOrgs.map((org) => (
                  <MenuItem key={org.value} value={org.label}>
                    <Checkbox checked={orgFilter.indexOf(org.label) > -1} />
                    <ListItemText primary={org.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            {role !== "superadmin" && (
              <VMButton
                variant="primary"
                onClick={() => {
                  openCreateModal(true);
                  setUserId("");
                }}
              >
                Create User
              </VMButton>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="my-2 table-wrapper">
        <InfiniteScroll
          className="doc-table-scroll"
          dataLength={users.length}
          next={fetchMoreDocsOnScroll}
          hasMore={hasMore}
          height={400}
          loader={isLoading && <Spinner />}
          endMessage={
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "gray" }}>No More Documents!</p>
            </div>
          }
        >
          {/* <Table className="table mx-auto">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th className="w-25" scope="col">
                  Name
                </th>
                <th scope="col">Role</th>
                <th scope="col">Email</th>
                <th scope="col">Projects Assigned</th>
                <th
                  scope="col"
                  onClick={() => sortDocuments()}
                  style={{ cursor: "pointer" }}
                >
                  Created Date <img src={sortSvg} alt="sort-icon" width={20} />
                </th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {users
                .filter((user: UserType) => {
                  return search.length >= 1
                    ? user.name.toLowerCase().includes(search.toLowerCase())
                    : true;
                })
                .map((user: UserType, key) => (
                  <tr key={key}>
                    <th scope="row">{key + 1}</th>
                    <td>{user.name}</td>
                    <td>{user.role}</td>
                    <td>{user.email}</td>
                    <td>
                      {user.projects.length}
                      <img
                        src="Icons/info-circle-solid.svg"
                        className="info"
                        alt="options"
                        data-tip={user.projects
                          .map((e: any) => e.name)
                          .map((element, key) => {
                            const template = `${key + 1}. ${element} <br>`;
                            return template;
                          })
                          .join()}
                        data-html="true"
                        data-for="project-list"
                      />
                      <ReactTooltip
                        id="project-list"
                        place="top"
                        effect="solid"
                      ></ReactTooltip>
                    </td>
                    <td>{moment(user.createdAt).format("L")}</td>
                    <td style={{ position: "relative" }}>
                      <DropDownMenu
                        options={tableActions}
                        record={user}
                        handleMenu={handleMenu}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table> */}
          <TableContainer component={Paper}>
            <VMTable sx={{ minWidth: "72rem" }}>
              <TableHead>
                <TableRow>
                  {tableHeader.map((item) =>
                    item.key === "created_date" ? (
                      <TableCell
                        key={item.key}
                        sx={{ width: item.width, cursor: "pointer" }}
                        onClick={() => sortDocuments()}
                        align="center"
                      >
                        {item.title}
                        <img src={sortSvg} alt="sort-icon" width={20} />
                      </TableCell>
                    ) : (
                      <TableCell
                        key={item.key}
                        sx={{ width: item.width }}
                        align="center"
                      >
                        {item.title !== "Actions" && role === "superadmin"
                          ? item.title
                          : role !== "superadmin" && item.title}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .filter((user: UserType) => {
                    if (search.length >= 1) {
                      return user.display_name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    } else if (
                      !user.role.includes("superadmin") &&
                      orgFilter.length >= 1
                    ) {
                      return user.organizationIds.find((org) =>
                        orgFilter.find(
                          (_org) =>
                            _org.toLowerCase() ===
                            (org as any).name.toLowerCase()
                        )
                      );
                    }
                    return true;
                  })
                  .map(
                    (user: UserType, key) =>
                      !user.role.includes("developer") && (
                        <TableRow key={key}>
                          <TableCell align="center">{key + 1}</TableCell>
                          <TableCell align="center">
                            {user.display_name}
                          </TableCell>
                          <TableCell align="center">{user.role}</TableCell>
                          <TableCell align="center">{user.email}</TableCell>
                          <TableCell align="center">
                            {user.organizationIds?.length || 0}
                            <img
                              src="Icons/info-circle-solid.svg"
                              className="info"
                              alt="options"
                              data-tip={
                                user.organizationIds?.length
                                  ? user.organizationIds
                                      .map((e: any) => e.name)
                                      .map((element, key) => {
                                        const template = `${
                                          key + 1
                                        }.${element} <br>`;
                                        return template;
                                      })
                                      .join()
                                  : "-"
                              }
                              data-html="true"
                              data-for="project-list"
                            />
                            <ReactTooltip
                              id="project-list"
                              place="top"
                              effect="solid"
                            ></ReactTooltip>
                          </TableCell>
                          <TableCell align="center">
                            {user.projects.length}
                            <img
                              src="Icons/info-circle-solid.svg"
                              className="info"
                              alt="options"
                              data-tip={user.projects
                                .map((e: any) => e.name)
                                .map((element, key) => {
                                  const template = `${
                                    key + 1
                                  }. ${element} <br>`;
                                  return template;
                                })
                                .join()}
                              data-html="true"
                              data-for="project-list"
                            />
                            <ReactTooltip
                              id="project-list"
                              place="top"
                              effect="solid"
                            ></ReactTooltip>
                          </TableCell>
                          <TableCell align="center">
                            {moment(user.createdAt).format("L")}
                          </TableCell>
                          {role !== "superadmin" && (
                            <TableCell
                              style={{ position: "relative" }}
                              align="center"
                            >
                              <DropDownMenu
                                options={tableActions}
                                record={user}
                                handleMenu={handleMenu}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      )
                  )}
              </TableBody>
            </VMTable>
          </TableContainer>
        </InfiniteScroll>
      </div>

      {/* <ReactModal isOpen={isCreateOpen} toggle={() => openCreateModal(false)}>
        <ModalHeader className="w-100 p-0">
          <div className="d-flex justify-content-between w-100"> */}
      {/* <h4>{!userId ? "Create User" : "Edit User"}</h4> */}
      {/* <h4>Create User</h4>
            <span
              className="close pointer"
              onClick={() => {
                openCreateModal(false);
              }}
            >
              <img src="/Icons/close_icon.svg" alt="close icon" width="12px" />
            </span>
          </div>
        </ModalHeader> */}
      {/* <ModalBody style={{ overflow: "auto" }}>
          <div className="d-flex flex-column createModal">
            <div className="form-control border-0 d-flex flex-wrap">
              <div className="form-group">
                <label>Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={(event: any) => {
                    setUserForm((data) => ({
                      ...data,
                      name: event.target.value,
                    }));
                  }}
                />
                {userForm.nameError && (
                  <p className="error">{userForm.nameError}</p>
                )}
              </div>
              <div className="form-group">
                <label>Role</label>
                <Dropdown
                  options={
                    role === "superadmin"
                      ? [
                          { value: "superadmin", label: "Super Admin" },
                          { value: "org_admin", label: "Org Admin" },
                          { value: "lead", label: "QC Lead" },
                          { value: "analyst", label: "QC Analyst" },
                        ]
                      : role === "org_admin"
                      ? [
                          { value: "lead", label: "QC Lead" },
                          { value: "analyst", label: "QC Analyst" },
                        ]
                      : role === "lead"
                      ? [{ value: "analyst", label: "QC Analyst" }]
                      : []
                  }
                  onChange={(event: any) => {
                    setUserForm((data) => ({ ...data, role: event.value }));
                  }}
                  value={"analyst"}
                  placeholder="Select an option"
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  onChange={(event: any) => {
                    setUserForm((data) => ({
                      ...data,
                      email: event.target.value,
                    }));
                  }}
                />
                {userForm.emailError && (
                  <p className="error">{userForm.emailError}</p>
                )}
              </div>
              <div className="form-group">
                <label>Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  onChange={(event: any) => {
                    setUserForm((data) => ({
                      ...data,
                      password: event.target.value,
                    }));
                  }}
                />
                {userForm.passwordError && (
                  <p className="error">{userForm.passwordError}</p>
                )}
              </div>
            </div>
            <div className="d-flex w-100 justify-content-center">
              <VMButton
                color="primary"
                className="mt-3 btn-custom-primary"
                onClick={handleCreate}
              >
                Create User
              </VMButton>
            </div>
          </div>
        </ModalBody>
      </ReactModal> */}
      <VMModal open={isCreateOpen} handleClose={() => openCreateModal(false)}>
        <div
          style={{
            backgroundColor: "white",
            minWidth: "30rem",
            borderRadius: "2rem",
          }}
        >
          <div style={{ padding: "0.5rem", margin: "1rem" }}>
            <h4>Create User</h4>
          </div>
          <hr />
          <div>
            <div className="d-flex flex-column createModal">
              <div className="form-control border-0 d-flex flex-wrap">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    onChange={(event: any) => {
                      setUserForm((data) => ({
                        ...data,
                        name: event.target.value,
                      }));
                    }}
                  />
                  {userForm.nameError && (
                    <p className="error">{userForm.nameError}</p>
                  )}
                </div>
                <div className="form-group">
                  <label>Role</label>
                  <Dropdown
                    options={
                      role === "superadmin"
                        ? [
                            { value: "superadmin", label: "Super Admin" },
                            { value: "org_admin", label: "Org Admin" },
                            { value: "lead", label: "QC Lead" },
                            { value: "analyst", label: "QC Analyst" },
                          ]
                        : role === "org_admin"
                        ? [
                            { value: "lead", label: "QC Lead" },
                            { value: "analyst", label: "QC Analyst" },
                          ]
                        : role === "lead"
                        ? [{ value: "analyst", label: "QC Analyst" }]
                        : []
                    }
                    onChange={(event: any) => {
                      setUserForm((data) => ({ ...data, role: event.value }));
                    }}
                    value={"analyst"}
                    placeholder="Select an option"
                  />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    onChange={(event: any) => {
                      setUserForm((data) => ({
                        ...data,
                        email: event.target.value,
                      }));
                    }}
                  />
                  {userForm.emailError && (
                    <p className="error">{userForm.emailError}</p>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-center w-100">
                  {userForm.role !== "superadmin" && (
                    <div className="form-group">
                      <label>Organizations</label>

                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={2}
                        options={selectedOrgs}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(event: any, value) => {
                          setUserForm((data) => ({
                            ...data,
                            organizationIds: value.map((val) => val.value),
                          }));
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              textTransform: "capitalize",
                              background: "#F7F7F7",
                            }}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.label}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select Organizations"
                            required
                          />
                        )}
                        sx={{
                          position: "absolute",
                          width: "20%",
                        }}
                      />
                      {userForm.organizationError && (
                        <p className="error">{userForm.organizationError}</p>
                      )}
                    </div>
                  )}
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      onChange={(event: any) => {
                        setUserForm((data) => ({
                          ...data,
                          password: event.target.value,
                        }));
                      }}
                    />
                    {userForm.passwordError && (
                      <p className="error">{userForm.passwordError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-center">
                <VMButton
                  variant="primary"
                  style={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}
                  onClick={handleCreate}
                >
                  Create User
                </VMButton>
              </div>
            </div>
          </div>
        </div>
      </VMModal>
      {isDelete && (
        <VMModal open={isDelete} handleClose={() => setIsDelete(false)}>
          <div
            style={{
              backgroundColor: "white",
              minWidth: "35rem",
              borderRadius: "2rem",
            }}
          >
            <div style={{ padding: "0.5rem", margin: "1rem" }}>
              <h4>Delete User</h4>
            </div>
            <hr />
            <div className={classes.flex} style={{ justifyContent: "center" }}>
              <h5>Are you sure want to delete?</h5>
            </div>
            <div
              className={classes.flex}
              style={{ margin: "1rem", justifyContent: "space-around" }}
            >
              <div>
                <VMButton onClick={() => setIsDelete(false)}>Cancel</VMButton>
              </div>
              <div>
                <VMButton variant="primary" onClick={handleDelete}>
                  Delete
                </VMButton>
              </div>
            </div>
          </div>
        </VMModal>
      )}
      {isEditOpen && (
        <EditForm
          selectedOrgs={selectedOrgs}
          isEditOpen={isEditOpen}
          setIsEditOpen={setIsEditOpen}
          userData={users.filter((item) => item._id === userId)[0]}
        />
      )}
      {/* </div> */}
    </div>
  );
};

export default UserManagement;
