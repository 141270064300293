import React, { useContext, useEffect, useState } from 'react';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import 'react-day-picker/dist/style.css';
// import "react-tabs/style/react-tabs.css";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select,
  Table, TableBody, TableCell, TableHead, TableRow, Paper, TextField, Box, Button, Card, CardContent, IconButton
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { differenceInDays, format, add } from 'date-fns';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, withStyles } from '@mui/styles';
import { Dayjs } from 'dayjs';
import { FiCalendar, FiSearch } from 'react-icons/fi';
import { MdOutlineClear } from 'react-icons/md';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import VMButton from '../common/Button';

import { projectList } from '../../controllers/projects';
import UserContext from "../../context/userContext";
import { Accuracy, dateAccuracy, fieldAccuracy, projectFieldAverage } from '../../controllers/accuracy';
import { useParams } from 'react-router';
import moment from 'moment';
import DateFilterPopper from '../common/datefilter';




ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


interface Params {
  projectId: any;
  startingDate: any;
  endingDate: any
}


const useStyles = makeStyles((theme) => ({
  container1: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // width: '100px',
    // paddingLeft: '530px'
  },
  container2: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // width: '100px',
    // paddingRight: '100px',
    // paddingLeft: '150px',
    // paddingTop: '0px',
    // overflowY: 'scroll'
  },
  textField: {
    width: 200,
  },

  // root: {
  //   Width: 70,
  // },
  title: {
    fontSize: 14,
    paddingRight: '200PX'
  },
  formControl: {
    width: "200px",
    paddingLeft: "0px",
  },
  bar: {
    // height: "3rem",
    width: "30rem",
  },
  bar1: {
    width: '40rem',
    paddingLeft: '1rem',
    // marginLeft: '40rem',
    height: '15rem',
  }
}));


const CssTextField = withStyles({
  root: {
    "&.Mui-focused": {
      color: "black",
    },
  },
})(OutlinedInput);



const Analytics = () => {

  const { authToken, role,organizationIds } = useContext(UserContext);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [selectionDocType, setSelectionDocType] = useState<String>("")

  const [Values, setValues] = useState<object>();
  const [projectId, setProjectId] = useState('')
  const [data1, setData1] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startDate1, setStartDate1] = useState<Dayjs | null>(null);
  const [avg, setAvg] = useState<any>([]);
  const [date, setDate] = useState<any>([]);
  const [fieldData, setFieldData] = useState<any>([]);
  const [logs, setLogs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true)
  const [labelForChart, setLabelForChart] = useState<any>([])
  const [labelsChart, setLabelsChart] = useState<String[]>([])
  const [accuracylogsLoading, setAccuracylogsLoading] = useState<Boolean>(true)

  const [accuracyLogsDataSet, setAccuracyLogsDataSet] = useState<any>({})

  const [dateFilter, setDateFilter] = useState<{
    startDate: any;
    endDate: any;
  }>({
    startDate: "",
    endDate: "",
  });

  const [dataSetForProject, setDataSetForProject] = useState<String[]>([])
  const [dataSetForProjectSampleSize, setDataSetForProjectSampleSize] = useState<String[]>([])


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(() => (anchorEl ? null : event.currentTarget));
    setOpen(!open);
  };

  const accuracyLogs = (logs: any, selection: any, count: any, labels: any) => {
    let filterData = logs.filter((item: any) => item.docType === selection)

    let keys = Array.from(new Set(filterData.map((item: any) => item.fieldName)))
    const d: any = keys.reduce((obj: any, item: any) => {
      return {
        ...obj,
        [item]: { sampleSize: new Array(count).fill(0), efficiency: new Array(count).fill(0), date: [] },
      };
    }, {});

    filterData.map((item: any) => {
      d[item.fieldName]['sampleSize'][labels.indexOf(item.date)] = item.sampleSize
      d[item.fieldName]['efficiency'][labels.indexOf(item.date)] = item.efficiency
      d[item.fieldName]['date'].push(item.date)
    })

    return d
  }




  // const { projectId, startingDate, endingDate } = useParams<Params>();
  const classes = useStyles();

  const callingApi = (startDate: string, endDate: string) => {

    let start = new Date(startDate)
    let end = new Date(endDate)
    let countingDays = differenceInDays(end, start)
    let labels: any = []
    let displayName = []
    let selectionType: String;
    let dataSetForDateAccuracy = new Array((differenceInDays(end, start))).fill(0)
    let dateSetForDateAccuracySampleSize = new Array((differenceInDays(end, start))).fill(0)
    // console.log('datalegntj',differenceInDays(end,start))
    for (var i = 0; i <= differenceInDays(end, start); i++) {
      labels.push(format(add(start, { days: i }), 'yyyy-MM-dd'))
      displayName.push(format(add(start, { days: i }), 'dd/MM/yy'))
    }
    setLabelForChart(labels)

    dateAccuracy(authToken, projectId, startDate, endDate).then((res) => {
      if(res.data.length){
        let data = res.data
        setDate(() => data)
        data.map((item: any) => {
          let index = labels.indexOf(item.date)
          dataSetForDateAccuracy[index] = item.totalFieldAverage
          dateSetForDateAccuracySampleSize[index] = item.sampleSize
        })
      }
    })
      .catch((error) => console.log(error));


    fieldAccuracy(authToken, projectId, startDate, endDate).then((res: any) => {
      if(res.data.length){
        setFieldData(res.data)
        let docTypeList: String[] = Array.from(new Set(res.data.map((item: any) => item.docType)))
        setSelectionDocType(() => docTypeList[0])
        selectionType = docTypeList[0]
      }
    })
    .catch((error) => console.log(error));

    projectFieldAverage(authToken, projectId, startDate, endDate).then((res: any) => {
      if(res.data.length){
        setAvg(res.data[0])
      }
    })
    setDataSetForProject(dataSetForDateAccuracy)
    setDataSetForProjectSampleSize(dateSetForDateAccuracySampleSize)


    Accuracy(authToken, projectId, startDate, endDate).then((res: any) => {
      if(res.data.length){
        setLogs(res.data)
        let dataSet = accuracyLogs(res.data, selectionType, countingDays, labels)
        setAccuracyLogsDataSet(dataSet)
      }
    }).catch((error) => console.log(error));

    setIsLoading(false)
    setAccuracylogsLoading(false)
    setExpanded(true)
  }

  const handleDateFilterChange = ({
    startDate,
    endDate,
  }: {
    startDate: string;
    endDate: string;
  }) => {
    callingApi(startDate, endDate)
    setDateFilter({
      startDate,
      endDate,
    });
    setAnchorEl(null)
  };


  const handleChange = (e: any) => {
    setProjectId(e.target.value);
    console.log("e.target.value", e.target.value)
  };


  const handleDocTypeChange = (e: any) => {
    let selectionType = e.target.value
    let start = new Date(dateFilter.startDate)
    let end = new Date(dateFilter.endDate)
    let countingDays = differenceInDays(end, start)
    setSelectionDocType(e.target.value)
    console.log("logs111", logs)
    setAccuracylogsLoading(true)
    let dataSet = accuracyLogs(logs, selectionType, countingDays, labelForChart)
    setAccuracyLogsDataSet(dataSet)
    setAccuracylogsLoading(false)
  }


  var d = new Date();
  d.setMonth(d.getMonth() - 2);
  // const start = moment(new Date()).format("YYYY-MM-DD");
  // const end = moment(d).format('YYYY-MM-DD')
  const start = "2023-01-04"
  const end = "2023-02-14"

  useEffect(() => {
    projectList(authToken, 1000, 1, "_id","").then((res) => {
      console.log({ res })
      setTimeout(() => {
        setData1(res.data);
        setProjectId(res.data[0]._id);
      }, 500);
    })
  }, []);

  useEffect(() => {
    setIsLoading(true)
    if (dateFilter.startDate && dateFilter.endDate)
      callingApi(dateFilter.startDate, dateFilter.endDate);
  }, [projectId, dateFilter])





  const options = {
    elements: {
      bar: {
        borderWidth: '2'
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Chart.js Bar Chart',
      },
    },
  };


  // if(dateFilter.startDate){
  //   let daa = new Date(dateFilter.startDate)
  //   let daa1 = new Date(dateFilter.endDate)
  //   console.log("daa",differenceInDays(daa1,daa))
  //   // console.log(":add",format(add(daa,{days:1}),'dd/MM/yyyy'))
  //   console.log(":::1",labels)
  // }


  const chart1 = {
    // labels: [format(dateFilter.startDate,'YYY-MMM-DD'),format(dateFilter.endDate,'YYY-MMM-DD')],
    labels: labelForChart,
    datasets: [
      {
        label: 'TotalField Average',
        data: dataSetForProject,
        backgroundColor: '#46adb6',
      },
      // {
      //   label: 'Sample Size',
      //   data: dataSetForProjectSampleSize,
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],

  };


  return (
    <div className="d-flex flex-column h-100" style={{ width: "80vw" }}>
      <div className="hed-row d-flex justify-content-between py-1 my-1">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "27rem" }}
        >
          <Typography variant="h4">Analytics</Typography>
          {/* <input
            className="form-control"
            placeholder="Search Projects"
            onChange={(e: any) => {
              setSearch(e.target.value || "");
            }}
          /> */}
          <FormControl style={{ marginLeft: "0.2rem", width: "20rem" }}>
            <InputLabel id="project-selection">Select Project</InputLabel>
            <Select
              labelId="project-selection"
              id="project-selection"
              value={projectId}
              label="Select Project"
              onChange={(e: any) => setProjectId(e.target.value)}
            >
              {data1.map((item: any) =>
                <MenuItem value={item._id}>{item.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <div className="d-flex justify-content-right">
          <IconButton
            onClick={handleClick}
          >
            <FiCalendar />
          </IconButton>
          <DateFilterPopper
            openPopper={open}
            dateFilterPopperAnchorEl={anchorEl}
            onChangeDateFilter={handleDateFilterChange}
            onClickAway={() => {
              setAnchorEl(null);
              setOpen(false);
            }}
          />

        </div>
      </div>
      <hr />
      {dateFilter.startDate && dateFilter.endDate && !isLoading ? (
        <div style={{ overflowY: "scroll" }}>
          <div className="d-flex justify-content-around mt-3" >
            {/* <Paper style={{ display: 'flex', justifyContent: 'space-around' }}> */}
            {/* <Table>
                 <TableHead>
                   <TableRow>
                     <TableCell align="center">sample size</TableCell>
                     <TableCell align="center">Efficinecy (100%)</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {avg.map((row: any) => (
                     <TableRow key={row.name}>
                       <TableCell align="center">{row.sampleSize}</TableCell>
                       <TableCell align="center">{row.fieldAverage}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table> */}
            <Box
              sx={{
                width: 250,
                height: 250,
                border: '1px solid #cccc',
                boxShadow: "20rem",
                display: "flex",
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: "center"
              }}
            >
              <div>
                <Typography variant="h5">Sample Size</Typography>
              </div>
              <div>
                <Typography variant="h5">{avg?.sampleSize}</Typography>
              </div>
            </Box>
            <Box
              sx={{
                width: 250,
                height: 250,
                border: '1px solid #cccc',
                boxShadow: "20rem",
                display: "flex",
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: "center"
              }}
            >
              <div>
                <Typography variant="h5">Efficinecy (100%)</Typography>
              </div>
              <div>
                <Typography variant="h5">{avg?.fieldAverage}</Typography>
              </div>
            </Box>
            {/* </Paper>{" "} */}
          </div>

          <div style={{ marginTop: '13px', width: "60rem", marginLeft: '5rem' }}>
            <Paper>
              <Bar data={chart1} options={{
                scales: {
                  y: {
                    min: 0,
                    max: 100,
                    ticks: {
                      stepSize: 25
                    }
                  }
                }
              }} />
            </Paper>
          </div>



          <div style={{ border: "1px solid #ccc", marginTop: '2rem' }}>
            <Accordion
              expanded={expanded === true}
              onChange={() => setExpanded(!expanded)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Field Accuracy</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl style={{ marginLeft: "0.2rem", width: "20rem", marginTop: '0.5rem' }}>
                  <InputLabel id="project-selection">Select Document Type</InputLabel>
                  <Select
                    labelId="project-selection"
                    id="project-selection"
                    value={selectionDocType}
                    label="Select Project"
                    onChange={(e: any) => handleDocTypeChange(e)}
                  >
                    {Array.from(new Set(fieldData.map((item: any) => item.docType))).map((item: any, index: Number) =>
                      <MenuItem value={item}>{item}</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {selectionDocType && (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Project Name</TableCell>
                        <TableCell align="center">Field name</TableCell>
                        <TableCell align="center">sample size</TableCell>
                        <TableCell align="center">Efficinecy (100%)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fieldData.filter((item: any) => item.docType == selectionDocType).map((row: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{row.projectName}</TableCell>
                          <TableCell align="center">{row.fieldName}</TableCell>
                          <TableCell align="center">{row.sampleSize}</TableCell>
                          <TableCell align="center">{row.fieldAvg}</TableCell>
                        </TableRow>

                      ))}
                    </TableBody>

                  </Table>
                )}

              </AccordionDetails>
            </Accordion>
          </div>


          {/* <div style={{ marginTop: '2rem', width: '90rem' }}>
            {!accuracylogsLoading && (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.keys(accuracyLogsDataSet).map((item: any) => (
                  <Box className={classes.bar1} style={{ marginTop: "5rem" }}>
                    <Paper >
                      <Bar data={{
                        labels: labelForChart,
                        datasets: [
                          {
                            label: "Efficiency",
                            data: accuracyLogsDataSet[item]['efficiency'],
                            backgroundColor: '#46adb6',
                          },
                          {
                            label: "Sample Size",
                            data: accuracyLogsDataSet[item]['sampleSize'],
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                          }
                        ]
                      }}
                        options={{
                          scales: {
                            y: {
                              min: 0,
                              max: 100,
                              ticks: {
                                stepSize: 25
                              }
                            }
                          }
                        }}
                      />
                    </Paper>
                  </Box>
                ))}
              </div>
            )}
          </div> */}
        </div>
      ) : <></>}
    </div>
  );


}
export default Analytics;
