import 'bootstrap/dist/css/bootstrap.min.css';
import '../../shared/css/table.css';

import { ChangeEventHandler, useContext, useEffect, useState } from 'react';

import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table as VMTable,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import SearchIcon from '../../../utils/icons';
import VMButton from '../../common/Button';
import VMModal from '../../common/Modal';

import { changeFileStatus, reviewFileList } from '../../../controllers/file';

import moment from 'moment';
import { useHistory, useParams } from 'react-router';

import UserContext from '../../../context/userContext';
import {
  changeDocsStatusAddComment,
  reviewDocumentList,
} from '../../../controllers/documents';
import CommentListItem from '../../CommentListItem';
import { toastify } from '../../common/notification';
import { documentDetailsT } from '../../Documents/Review';
import { DropDownMenu } from '../../shared/DropDown';

import InfiniteScroll from 'react-infinite-scroll-component';
import sortSvg from '../../../assets/icons/sort.svg';
import { useScrolls } from '../../../hooks/useScrolls';
import { Spinner } from '../../Spinner';

interface documentDetailsI extends documentDetailsT {
  selected: boolean;
}

const CssTextField = withStyles({
  root: {
    '&.Mui-focused': {
      color: 'black',
    },
  },
})(OutlinedInput);

const useStyles = makeStyles((theme: any) => ({
  createButton: {
    backgroundColor: '#69bec6',
    '& .hover': {
      backgroundColor: '#46adb6',
    },
  },
  table: {
    backgroundColor: theme.palette.common.white,
  },
  root: {
    background: 'red',
  },
  inputLabel: {
    borderColor: '#ccc !important',
  },
  flex: {
    display: 'flex',
  },
}));

const ReviewQueue = () => {
  const history = useHistory();
  const classes = useStyles();
  const { authToken, name, role } = useContext(UserContext);

  const { project_id }: { project_id: string } = useParams();
  const [documents, setDocuments] = useState<documentDetailsI[]>([]);
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState('');
  const [showMovePendingModal, setShowMovePendingModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<documentDetailsI>();

  const [isLoading, setIsLoading] = useState(true);

  const {
    limit,
    page,
    sortBy,
    hasMore,
    sortDocuments,
    fetchMoreDocsOnScroll,
    setDocLength,
  } = useScrolls(documents.length);

  const getAllReviewDocuments = () => {
    reviewFileList(authToken, project_id, limit, page, sortBy)
      .then((res) => {
        setTimeout(() => {
          if (page > 1) {
            setDocuments((currentDocuments) => [
              ...currentDocuments,
              ...res.data,
            ]);
          } else {
            setDocuments(res.data);
          }
          setIsLoading(false);
        }, 500);
        setDocLength(res.length);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleMenu = (action: string, record: any) => {
    switch (action) {
      case 'view':
        history.push(`/projects/${project_id}/document/review/${record._id}/view`);
        break;
      case 'mv_pending':
        changeFileStatus(authToken, record._id, project_id, 'pending')
          .then((res) => {
            toastify('success', 'document moved to pending');
            reviewFileList(authToken, project_id, limit, page, sortBy)
              .then((res: any) => {
                setDocuments(res.data);
              })
              .catch((err: any) => {
                console.log(err);
              });
          })
          .catch((err) => {
            toastify('failure', 'document status change failed');
            console.log(err);
          });
        break;
    }
  };

  useEffect(getAllReviewDocuments, [
    limit,
    page,
    sortBy,
    setDocLength,
    authToken,
    project_id,
  ]);

  const handleMultiSelect: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, checked } = e.target;

    if (name === 'all') {
      const updateDocs = documents.map((document) => {
        const newUpdatedDocs = { ...document, selected: checked };
        return newUpdatedDocs;
      });
      setDocuments(updateDocs);
    } else {
      const updateDocs = documents.map((document) => {
        if (document._id === name) {
          return { ...document, selected: checked };
        }
        return document;
      });
      setDocuments(updateDocs);
    }
  };

  const handleMovePending = () => {
    const selectedDocs = documents
      .filter((document) => document.selected)
      .map((document) => document._id);

    changeDocsStatusAddComment(
      'pending',
      {
        docs: selectedDocs,
        comment: {
          comment,
          commentedOn: new Date(),
          commentedBy: name,
        },
        project_id,
        fromStatus: 'review',
      },
      authToken
    )
      .then(() => {
        getAllReviewDocuments();
      })
      .then((res) => {
        setShowMovePendingModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(
    'filter',
    documents.filter((doc: any) => {
      return search.length >= 1
        ? doc.name.toLowerCase().includes(search.toLowerCase()) ||
            doc?.markedForReviewBy?.display_name.startsWith(
              search.toLowerCase()
            )
        : true;
    })
  );

  const tableHeader = [
    { key: 'sno', title: 'S.No', width: 50 },
    { key: 'document_name', title: 'Document Name', width: 100 },
    { key: 'marked_by', title: 'Marked By', width: 50 },
    { key: 'marked_on', title: 'Marked On', width: 50 },
    { key: 'review_comments', title: 'Review Comments', width: 50 },
    { key: 'action', title: 'Action', width: 50 },
  ];

  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="hed-row d-flex justify-content-between py-1 my-1">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ width: '34rem' }}
          >
            <Typography variant="h4">Review Documents</Typography>
            <FormControl>
              <CssTextField
                id="searchProject"
                margin="none"
                style={{ width: '20rem' }}
                size="small"
                placeholder="Search Document"
                onChange={(e: any) => {
                  setSearch(e.target.value || '');
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div className="d-flex justify-content-right">
            {role !== 'analyst' && (
              <VMButton
                variant="primary"
                disabled={!documents.some((document) => document.selected)}
                onClick={() => setShowMovePendingModal(true)}
              >
                Move to pending
              </VMButton>
            )}
          </div>
        </div>
        <hr />
        <div className="my-2 table-wrapper">
          <InfiniteScroll
            className="doc-table-scroll"
            dataLength={documents.length}
            next={fetchMoreDocsOnScroll}
            hasMore={hasMore}
            height={400}
            loader={isLoading && <Spinner />}
            endMessage={
              <div style={{ textAlign: 'center' }}>
                <p style={{ color: 'gray' }}>No More Documents!</p>
              </div>
            }
          >
            <TableContainer component={Paper}>
              <VMTable sx={{ minWidth: '72rem' }}>
                <TableHead>
                  <TableRow>
                    {role !== 'analyst' && (
                      <TableCell style={{ width: 50 }} align="center">
                        <input
                          type="checkbox"
                          name="all"
                          onChange={handleMultiSelect}
                          checked={
                            documents.filter((document) => document.selected)
                              .length === documents.length
                          }
                        />
                      </TableCell>
                    )}
                    {tableHeader.map((item) =>
                      item.key === 'marked_on' ? (
                        <TableCell
                          style={{ width: 50 }}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => sortDocuments()}
                        >
                          {item.title}
                          <img src={sortSvg} alt="sort-icon" width={20} />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={item.key}
                          sx={{ width: item.width }}
                          align="center"
                        >
                          {item.title}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents
                    .filter((doc: any) => {
                      return search.length >= 1
                        ? doc.name
                            .toLowerCase()
                            .includes(search.toLowerCase()) ||
                            doc.markedForReviewBy?.display_name.startsWith(
                              search.toLowerCase()
                            )
                        : true;
                    })
                    .map((doc: any, key) => (
                      <TableRow key={key}>
                        {role !== 'analyst' && (
                          <TableCell align="center">
                            <input
                              type="checkbox"
                              onChange={handleMultiSelect}
                              name={doc._id}
                              checked={doc.selected || false}
                            />
                          </TableCell>
                        )}
                        <TableCell scope="row" align="center">
                          {key + 1}
                        </TableCell>
                        <TableCell align="center">{doc.name || '-'}</TableCell>
                        <TableCell align="center">
                          {doc.markedForReviewBy?.display_name || '-'}
                        </TableCell>
                        <TableCell align="center">
                          {moment(doc.markedForReviewOn).format('L') || '-'}
                        </TableCell>
                        <TableCell align="center">
                          <VMButton
                            variant="secondary"
                            onClick={() => {
                              setSelectedDocument(doc);
                              setShow(true);
                            }}
                            style={{ width: '5rem' }}
                            size="small"
                          >
                            View comments
                          </VMButton>
                        </TableCell>

                        <TableCell align="center">
                          <DropDownMenu
                            options={
                              role === 'superadmin'
                                ? [
                                    {
                                      name: 'View Document',
                                      value: 'view',
                                    },
                                    {
                                      name: 'Move to Pending',
                                      value: 'mv_pending',
                                    },
                                  ]
                                : role === 'org_admin'
                                ? [
                                    {
                                      name: 'View Document',
                                      value: 'view',
                                    },
                                    {
                                      name: 'Move to Pending',
                                      value: 'mv_pending',
                                    },
                                  ]
                                : role === 'lead'
                                ? [
                                    {
                                      name: 'View Document',
                                      value: 'view',
                                    },
                                    {
                                      name: 'Move to Pending',
                                      value: 'mv_pending',
                                    },
                                  ]
                                : role === 'analyst'
                                ? [{ name: 'View Document', value: 'view' }]
                                : []
                            }
                            record={doc}
                            handleMenu={handleMenu}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </VMTable>
            </TableContainer>
          </InfiniteScroll>
        </div>
      </div>
      <VMModal open={show} handleClose={() => setShow(false)}>
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '35rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Comments</h4>
          </div>
          <hr />
          <div style={{ justifyContent: 'center' }}>
            {selectedDocument?.commentHistory &&
            selectedDocument.commentHistory.length > 0
              ? selectedDocument?.commentHistory
                  .sort((a: any, b: any) => {
                    return (
                      new Date(b.commentedOn).getTime() -
                      new Date(a.commentedOn).getTime()
                    );
                  })
                  .map((comment: any, index: number) => (
                    <CommentListItem
                      name={comment.commentedBy}
                      comment={comment.comment}
                      key={index}
                      time={moment(comment.commentedOn).fromNow()}
                    />
                  ))
              : 'No Comments'}
          </div>
        </div>
      </VMModal>

      <VMModal
        open={showMovePendingModal}
        handleClose={() => setShowMovePendingModal(false)}
      >
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '35rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Move to Pending</h4>
          </div>
          <hr />
          <div style={{ justifyContent: 'center' }}>
            <div className="mv_pending__cot">
              <label htmlFor="review__area">Enter a comment :</label>
              <textarea
                name="review__area"
                id="review__area"
                rows={4}
                style={{ width: '100%' }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Enter your review comment"
              />
              <VMButton
                variant="primary"
                disabled={comment.length === 0}
                onClick={handleMovePending}
                style={{ marginTop: '0.5rem' }}
              >
                Submit
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>
    </>
  );
};

export default ReviewQueue;
