import InfoIcon from "@mui/icons-material/Info";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles, withStyles } from "@mui/styles";
import moment from "moment";
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHistory, useLocation, useParams } from "react-router";

import * as R from "ramda";

import { ListGroup, Overlay } from "react-bootstrap";

import { markReview } from "../../../controllers/file";

import UserContext from "../../../context/userContext";
import {
  accessVerification,
  autoSaveDocument,
  changePriority,
  getDocument,
  nextInReviewDocByTime,
  undoSaveDocument,
  updateDocument,
  verifyChangeDocument,
} from "../../../controllers/documents";

import useLongPress from "../../../hooks/useLongPress1";
import {
  AutoSaveIcon,
  FileDownloadIcon,
  Logo,
  PageDoneIcon,
  ZoomInDocumentIcon,
  ZoomOutDocumentIcon,
  ZoomResetIcon,
} from '../../../utils/icons';
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import SpinnerOutlined from '../../Spinner/SpinnerOutlined';
import VMButton from '../../common/Button';
import VMModal from '../../common/Modal';
import OptionMenu from '../../common/OptionMenu';
import { toastify } from '../../common/notification';
import Loader from '../../shared/loader';

import CommentListItem from "../../CommentListItem";
import FieldsContainer from "../FormContainer";
import ImageContainer from "../ImageContainer";

import { addActivity, changeFileStatus } from "../../../controllers/file";

import "../FormContainer/ds.css";
import "./index.css";

export interface documentSectionT {
  isSectionTable: boolean;
  name: string;
}

export interface documentPageSegmentFieldsT {
  id: string;
  name: string;
  type: string;
  dropdownValues: string[];
  section: string;
  key: string;
  extractedValue: string;
  qcUpdatedValue: string;
  qcUpdatedCoordinates: number[];
  fieldValues: {
    modalExtractedValue: string;
    qcUpdatedValue: string;
    modelConfidentScore: number;
    isFieldUpdated: boolean;
    coordinates: number[];
  }[];
  coordinates: number[];
  createdAt: Date;
  mandatory: boolean;
}

export interface projectDocumentPageSegmentFieldsT {
  _id: string;
  key: string;
  name: string;
  type: string;
  dropDownValues: any[];
  section: string;
  mandatory: boolean;
  dependencydropdownvaluesetId: string;
  nondependencydropdownvaluesetId: string;
}

interface documentPageSegmentsT {
  classificationType: string;
  fields: documentPageSegmentFieldsT[];
  id: string;
  name: string;
  qcClassificationType: string;
  url: string;
}

export interface documentDetailsT {
  classificationValues: string[];
  hasSegments: boolean;
  id: string;
  _id: string;
  name: string;
  pageSegments: documentPageSegmentsT[];
  pages: string[];
  projectId: string;
  projectName: string;
  projectType: string;
  file_name: string;
  sections: documentSectionT[];
  showBoundingBox: boolean;
  status: string;
  url: string;
  createdAt: string;
  priority?: boolean;
  commentHistory: [];
  documents: any;
  docTypeProject: any;
  projectFields: projectDocumentPageSegmentFieldsT[];
  cases: any;
  customValidation?: string
  metadata: Record<string, string>;
}

export interface tablePropsT {
  rows: string[];
  cols: string[];
  setRowList: any;
  fieldsInSection: documentPageSegmentFieldsT[];
  showBoundingBox: boolean;
  tableDisplay: boolean;
  setFieldKey: () => {};
  setBoundingBox: () => {};
  getDateFromValue: () => {};
  setTriggerMandatory: any;
  getValueFromDate: () => {};
  setTableDisplay: (value: boolean) => {};
}

const BlueOnGreenTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    fontSize: "14px",
  },
})(Tooltip);

function randomColor(): string {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function multiplePageSelectionMove(
  document: any,
  sourceSelectionIndex: any,
  destinationSelectionIndex: any,
  option: string,
  selectedId: string
) {
  let documentDetails = document.documents;
  const removingSelectionIndex = documentDetails
    .filter((item: any) => {
      if (
        item.pageNumber >= sourceSelectionIndex &&
        item.pageNumber <= destinationSelectionIndex
      ) {
        return false;
      }
      return true;
    })
    .map((_item: any) => _item);

  let poppedDocuments = documentDetails.filter((item: any, index: any) => {
    if (
      item.pageNumber >= sourceSelectionIndex &&
      item.pageNumber <= destinationSelectionIndex
    ) {
      return item;
    }
  });

  let insertionIndex = 0;

  if (option === "end") {
    let endIndex = 0;
    removingSelectionIndex.find((item: any, index: any) => {
      if (item.documentId === selectedId) {
        endIndex = index + 1;
        insertionIndex = endIndex;
        return;
      }
    });
  } else {
    let startIndex = 0;
    removingSelectionIndex.find((item: any, index: any) => {
      if (item.documentId === selectedId) {
        startIndex = index;
        insertionIndex = startIndex;
        return true;
      }
    });
  }
  poppedDocuments.map((item: any, index: any) => {
    removingSelectionIndex.splice(insertionIndex + index, 0, item);
  });

  return { ...document, documents: removingSelectionIndex };
}

function labelCreator(
  caseList: any,
  recreationLabel: Boolean,
  previousCaseListWithLabel?: any
) {
  if (recreationLabel) {
    const newPreviousCaseListWithLabel = previousCaseListWithLabel;

    let iteratorVariableForCaseList = caseList.length;

    while (iteratorVariableForCaseList) {
      let lengthOfTheCurrentKeys = Object.keys(
        newPreviousCaseListWithLabel
      ).length;

      newPreviousCaseListWithLabel[caseList[iteratorVariableForCaseList - 1]] =
        String.fromCharCode(lengthOfTheCurrentKeys + 65);

      --iteratorVariableForCaseList;
    }
    return newPreviousCaseListWithLabel;
  }

  const caseListWithLabel: any = {};

  caseList.map((item: any, index: number) => {
    caseListWithLabel[item] = String.fromCharCode(index + 65);
  });

  return caseListWithLabel;
}

function checkMandatory(documents: any, docTypeProject: any, sections: any) {
  // mapped the docType with associcated documentID
  let groupDocTypeandNumberOfInstance: any = {};

  documents.forEach((item: any) => {
    if (item.documentId !== null) {
      groupDocTypeandNumberOfInstance[item.docType1] = [];
    }
  });

  documents.forEach((item: any) => {
    if (item.documentId !== null) {
      if (
        !groupDocTypeandNumberOfInstance[item.docType1].includes(
          item.documentId
        )
      ) {
        groupDocTypeandNumberOfInstance[item.docType1] =
          groupDocTypeandNumberOfInstance[item.docType1].concat(
            item.documentId
          );
      }
    }
  });

  //Checking for Each DocType

  const results = Object.keys(groupDocTypeandNumberOfInstance).map(
    (docType) => {
      //getting the mandatory fields for the specific docType
      const SpecificDocType = docTypeProject.filter(
        (item: any) => item.docType === docType
      )[0].fields;
      const mandatoryFields = SpecificDocType.map((item: any) => {
        if (item.mandatory) {
          return item.key;
        }
      }).filter((item: string | undefined) => item !== undefined);

      //loop for each instance for the docType
      const oneInstanceMandatory = groupDocTypeandNumberOfInstance[docType].map(
        (instanceId: string) => {
          let checkFields: any = {};

          mandatoryFields.forEach((item: string) => {
            checkFields[item] = 0;
          });

          documents.forEach((item: any) => {
            if (item.documentId == instanceId) {
              item.fields.forEach((field: any) => {
                if (mandatoryFields.includes(field.key)) {
                  if (
                    field.qcUpdatedValue !== null &&
                    field.qcUpdatedValue !== ""
                  ) {
                    checkFields[field.key] = 1;
                  }
                  if (field.key === "vendor_name_drop") {
                    console.log("field", field);
                  }
                }
              });
            }
          });

          return Object.values(checkFields).every((item) => item === 1);
        }
      );

      return oneInstanceMandatory;
    }
  );

  // const sectionsAreTable = sections.map((section: any) => {
  //   if (section.isSectionTable) {
  //     return section.name
  //   }
  // }).filter((item: any) => item !== undefined)
  // const result = documents.map((item: any) => {
  //   let { fields } = item;
  //   const filterFields = fields.filter((item: any) => item.mandatory)
  //   const data = filterFields.map((item: any) => {
  //     if (sectionsAreTable.includes(item.section)) {
  //       if (item.fieldValues.length > 0) {
  //         const data1 = item.fieldValues.filter((item: any) => item.qcUpdatedValue === "")
  //         if (data1.length) {
  //           return false
  //         }
  //         else {
  //           return true
  //         }
  //       }
  //       else {
  //         return false
  //       }
  //     }
  //   })
  //   return data.filter((item: any) => item !== undefined)
  // })

  return results.flat().every((item: any) => item === true);
}

function removeUnusedLabels(caseListWithLabel: any, documents: any) {
  const caseId = Array.from(
    new Set(documents.map((doc: any) => doc.caseId))
  ).filter((item) => item !== null);
  const caseIdInCaseListWithLabel = Object.keys(caseListWithLabel).filter(
    (item) => caseId.includes(item)
  );

  const asArray = Object.entries(caseListWithLabel);

  const filtered = asArray.filter(([key, value]) => caseId.includes(key));
  const newCaseListWithLabel = Object.fromEntries(filtered);

  return newCaseListWithLabel;
}

export function randomCaseIdGenerator(idFor: String) {
  var d = new Date().getTime();
  var d2 =
    (typeof performance !== "undefined" &&
      performance.now &&
      performance.now() * 1000) ||
    0;
  return `temp-${idFor}-xxxxxxxx-xxxx`.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

function validateAdjacentPage(pageNumberSelection: any) {
  const isAdjacentArray = pageNumberSelection.map((number: any, index: any) => {
    if (pageNumberSelection[index + 1] === number + 1) {
      return true;
    } else {
      return false;
    }
  });

  return isAdjacentArray.length === 2
    ? isAdjacentArray[0]
    : isAdjacentArray.every(
      (element: number) => element === isAdjacentArray[0]
    );
}

function reorderPage(documentDetails: any, source: any, destination: any) {
  const documents = documentDetails.documents;

  const removedSourcePage = documents.filter((doc: any, index: number) => {
    if (index !== source.index) {
      return doc;
    }
  });

  const addedSourcePage = documents[source.index];

  if (
    removedSourcePage[
      destination.index > 0 ? destination.index - 1 : destination.index
    ].document_id === addedSourcePage.document_id
  ) {
    removedSourcePage.splice(destination.index, 0, addedSourcePage);
    return { ...documentDetails, documents: removedSourcePage };
  }

  return documentDetails;
}

function checkReorderPageValid(
  documentDetails: any,
  source: any,
  destination: any
) {
  const documents = documentDetails.documents;

  const sourceDocument = documents[source.index];
  const destinationDocument = documents[destination.index - 1];

  if (sourceDocument.documentId === destinationDocument.documentId) return true;

  return false;
}

function addPage(
  documentDetails: any,
  currentPageIndex: any,
  selectionList: any,
  addedPage?: number
) {
  const document = documentDetails.documents;

  if (selectionList.length) {
    const addedSourcePageData = document[currentPageIndex];

    const addedSourcePage = document
      .map((item: any) => {
        if (selectionList.includes(item.id)) {
          let data = {
            ...item,
            caseId: addedSourcePageData.caseId,
            documentId: addedSourcePageData.documentId,
            docType1: addedSourcePageData.docType1,
          };
          return data;
        }
      })
      .filter((item: any) => item !== undefined);

    let removeSourcePage = document.filter((item: any) => {
      if (!selectionList.includes(item.id)) {
        return item;
      }
    });

    removeSourcePage = R.insertAll(
      currentPageIndex - 1,
      addedSourcePage,
      removeSourcePage
    );

    return removeSourcePage;
  } else {
    const addedSourcePageData =
      addedPage === 0 ? document[addedPage] : document[0];

    const addedSourcePage = document
      .map((item: any) => {
        if (item.id === addedSourcePageData.id) {
          let data = {
            ...item,
            caseId: addedSourcePageData.caseId,
            documentId: addedSourcePageData.documentId,
            docType1: addedSourcePageData.docType1,
          };
          return data;
        }
      })
      .filter((item: any) => item !== undefined);

    // let removeSourcePage = document.filter((item: any) => {
    //   if (!selectionList.includes(item.id)) {
    //     return item;
    //   }
    // });
    let removeSourcePage: any = document.filter((item: any, index: Number) => {
      if (index !== addedPage) {
        return item;
      }
    });

    removeSourcePage = R.insertAll(
      currentPageIndex - 1,
      addedSourcePage,
      removeSourcePage
    );

    return removeSourcePage;
  }
}

function docType(documentDetails: any, currentPage: any, option: String) {
  if (documentDetails.documents[currentPage.documentIndex].docType1 !== null) {
    const docId =
      documentDetails.documents[currentPage.documentIndex].documentId;

    const document = documentDetails.documents.map(
      (item: any, index: number) => {
        if (item.documentId === docId) {
          return {
            ...item,
            docType1: option,
          };
        }
        return item;
      }
    );
    return { ...documentDetails, documents: document };
  } else {
    throw new Error("Already DocType is Unassignable");
  }
}

function dissociate(
  documentDetails: any,
  selectionList: any,
  currentPage: number
) {
  if (selectionList.length >= 1) {
    const document = documentDetails.documents.map((item: any) => {
      if (selectionList.includes(item.id)) {
        return { ...item, documentId: null, caseId: null, docType1: null };
      }
      return item;
    });
    return { ...documentDetails, documents: document };
  }

  if (selectionList.length < 1) {
    const document = documentDetails.documents.map(
      (item: any, index: number) => {
        if (index == currentPage) {
          return { ...item, documentId: null, caseId: null, docType1: null };
        }
        return item;
      }
    );
    return { ...documentDetails, documents: document };
  }
}

function caseViseOrdering(documentDetails: any) {
  const documents = documentDetails.documents;
  const caseIds = Array.from(new Set(documents.map((doc: any) => doc.case_id)));
  const casesf: any = [];

  caseIds.map((item) => {
    const filterData = documents.filter((item1: any) => item1.case_id === item);
    casesf.push(filterData);
  });
  return { ...documentDetails, documents: R.flatten(casesf) };
}

function validateDocumentsType(selectionList: any, documentDetails: any) {
  if (selectionList.length) {
    let data: any = [];
    const selectionList1 = selectionList.map((item: any) => {
      data.push(
        documentDetails.documents.filter((item1: any) => {
          if (item1.id === item) {
            return item1.id;
          }
        })
      );
    });
    const selectionList2 = R.flatten(data);
    const data1 = Array.from(
      new Set(selectionList2.map((doc: any) => doc.documentId))
    );
    if (data1.length !== 1) {
      return true;
    }
    return false;
  }
  return false;
}

function assigningColorForDocument(colorCodeForDocument: any, documents: any) {
  let keysofDocumentId = Object.keys(colorCodeForDocument);
  let documentsOfDocumentId = Array.from(
    new Set(documents.map((item: any) => item.documentId))
  );
  documentsOfDocumentId.map((item: any) => {
    if (!keysofDocumentId.includes(item) && item !== null) {
      let color = randomColor();
      colorCodeForDocument = { ...colorCodeForDocument, [item]: color };
    }
  });

  return colorCodeForDocument;
}


function validateMandatory(documentDetails: any) {
  const { docTypeProject, documents } = documentDetails
  let mandatoryFieldsValidationFlag = true
  docTypeProject.map((doc: any) => {
    let { fields, docType } = doc
    let mandatoryFields = fields.filter((field: any) => field.mandatory).map((item: any) => item.key)
    documents.map((document: any) => {
      if (document.docType1 === docType) {
        let { fields } = document
        fields.map((field: any) => {
          if (mandatoryFields.includes(field.key)) {
            if (field.fieldValues.length > 0) {
              field.fieldValues.map((fieldValue: any) => {
                if (fieldValue.qcUpdatedValue === "") {
                  mandatoryFieldsValidationFlag = false
                }
              })
            } else {
              if (field.qcUpdatedValue === "") {
                mandatoryFieldsValidationFlag = false
              }
            }
          }
        })
      }
    })
  })

  return mandatoryFieldsValidationFlag
}

const useStyles = makeStyles((theme: any) => ({
  primaryButton: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const TabPanel = (props: {
  children?: React.ReactNode;
  value: number;
  index: number;
}) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const retrieveDependencyDropdownValue = (dropdownValue: any, fieldSetId: string, fieldkey: string, fields: any) => {
  const { value, path } = dropdownValue[fieldSetId];
  let retrieveDropdownValues: { error: boolean, dropdownValues: any, path: any } = {
    error: false,
    dropdownValues: [],
    path: []
  };

  if (path[`keys-${fieldkey}`].length) {
    const pathofValue = path[`keys-${fieldkey}`];
    let narrowDropdown: any = value;

    for (let i = 0; i < pathofValue.length; i++) {
      const currentPath = pathofValue[i];
      const fieldToUpdate = fields.find((fiel: any) => `keys-${fiel.key}` === currentPath);

      if (fieldToUpdate && fieldToUpdate.qcUpdatedValue !== "" && narrowDropdown[`keys-${fieldToUpdate.key}`]) {
        const updatedValue = narrowDropdown[`keys-${fieldToUpdate.key}`][fieldToUpdate.qcUpdatedValue];
        narrowDropdown = updatedValue;
      } else {
        retrieveDropdownValues.error = true
        retrieveDropdownValues.dropdownValues = []
        retrieveDropdownValues.path = currentPath
        break;
      }
    }

    if (narrowDropdown === undefined) {
      retrieveDropdownValues.error = true
      retrieveDropdownValues.dropdownValues = []
      retrieveDropdownValues.path = path[`keys-${fieldkey}`]
      return
    }

    if (typeof narrowDropdown === 'object' && narrowDropdown[`keys-${fieldkey}`] !== undefined) {
      if (typeof narrowDropdown[`keys-${fieldkey}`] === 'string') {
        retrieveDropdownValues.dropdownValues = [narrowDropdown[`keys-${fieldkey}`]];
      } else {
        retrieveDropdownValues.dropdownValues = Object.keys(narrowDropdown[`keys-${fieldkey}`]);
      }
    }
  } else {
    retrieveDropdownValues.dropdownValues = Object.keys(value[`keys-${fieldkey}`]);
  }

  return retrieveDropdownValues;
};


const ValidateOnSubmitForDependencyDropdown = (documentDetails: any) => {
  const { documents, docTypeProject } = documentDetails;
  let result = true
  const groupingthedocumentsbasedondocType = documents.reduce((groupDocument: any, current: any) => {
    if (current['docType1'] in groupDocument) {
      groupDocument[current['docType1']] = groupDocument[current['docType1']].push(current)
    } else {
      groupDocument[current['docType1']] = [current]
    }
    return groupDocument
  }, {})

  const resultOfValidate = Object.keys(groupingthedocumentsbasedondocType).map((doctype) => {
    const listOfDocType = docTypeProject.find((type: any) => type.docType === doctype)
    const listOfDependencyDropdown = listOfDocType.fields.filter((field: any) => field.type === 'dependency_dropdown')
    groupingthedocumentsbasedondocType[doctype].map((page: any) => {
      const { fields } = page
      listOfDependencyDropdown.map((item: any) => {
        const field = fields.find((field: any) => field.key === item.key)
        const valueRetrive = retrieveDependencyDropdownValue(listOfDocType.depedency_value, item.dependencydropdownvaluesetId, item.key, fields);
        if (!valueRetrive?.error) {
          result = false
        }
      })
    })
  })


  return result


}

function DocumentReview() {
  const { authToken, role, name } = useContext(UserContext);
  const history = useHistory();
  const { pathname } = useLocation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queue_type = queryParams.get('queue_type');

  const {
    project_id,
    document_id,
  }: { project_id: string; document_id: string } = useParams();

  const initialValue: documentDetailsT = {
    classificationValues: [],
    _id: "",
    hasSegments: false,
    id: "",
    name: "",
    file_name: "",
    pageSegments: [],
    pages: [],
    projectId: "",
    projectName: "",
    projectType: "",
    sections: [],
    showBoundingBox: false,
    status: "",
    url: "",
    createdAt: "",
    documents: [],
    commentHistory: [],
    projectFields: [],
    docTypeProject: [],
    cases: [],
    metadata: {},
  };
  const [documentDetails, setDocumentDetails] = useState<documentDetailsT>({
    classificationValues: [],
    _id: "",
    hasSegments: false,
    id: "",
    name: "",
    file_name: "",
    pageSegments: [],
    pages: [],
    projectId: "",
    projectName: "",
    projectType: "",
    sections: [],
    showBoundingBox: false,
    status: "",
    url: "",
    createdAt: "",
    documents: [],
    commentHistory: [],
    projectFields: [],
    docTypeProject: [],
    cases: [],
    metadata: {},
  });

  const [currentPage, setCurrentPage] = useState<any>({
    documentIndex: 0,
    pageIndex: 0,
  });

  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [triggerMandatory, setTriggerMandatory] = useState(false);

  const [loading, setLoading] = useState<boolean>(true); //overall loader
  const [showSubmit, setShowSubmit] = useState(true); // while submitting
  const [isVerificationCallRequested, setIsVerificationCallRequested] =
    useState(false);

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const [triggerNotValidateFieldsIndication, setTriggerNotValidateFieldsIndication] = useState<boolean>(false);

  const [fieldKey, setFieldKey] = useState<string>("");
  const [currentBoundingBox, setCurrentBoundingBox] = useState<number[]>([]);
  const [showBoundingBox, setShowBoundingBox] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [reviewCont, setReviewCont] = useState<boolean>(false);
  const [reviewComment, setReviewComment] = useState<string>("");
  const [tableProps, setTableProps] = useState<tablePropsT | null>(null);
  const [tabelDisplay, setTableDisplay] = useState<boolean>(false);
  const [
    showAccessVerificationFailedModal,
    setShowAccessVerificationFailedModal,
  ] = useState(false);

  const [actionType, setActionType] = useState<string>("review");

  const [reload, setReload] = useState<boolean>(false);

  const target = useRef<any>(null);

  const [anchorEl, setAnchorEl] = useState<Number>(0);

  const [showFloatMenu, setShowFloatMenu] = useState<boolean>(false);
  const [warn, setWarn] = useState<boolean>(false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState<number[]>([]);
  const [renderFirstTime, setRenderFirstTime] = useState(true);
  const [restrictedMode, setRestrictedMode] = useState<boolean>(false);
  const [triggerMode, setTriggerMode] = useState<boolean>(false);
  const [pageNumberSelection, setPageNumberSelection] = useState<any>([]);
  const [showValidationError, setShowValidationError] = useState<Boolean>(false);
  const [showRotateAlert, setShowRotateAlert] = useState<Boolean>(false);
  const [rotateDirection, setRotateDirection] = useState("");

  const [doctypeFloatToastShow, setDocTypeFloatToastShow] =
    useState<boolean>(false);
  const [selectionList, setSelectionList] = useState<any>([]);
  const [selectOption, setSelectOption] = useState<String>("");
  const [caseSelectOption, setCaseSelectOption] = useState<String>("");
  const [casesWithLabel, setCasesWithLabel] = useState<Object>({});
  const [typeOfToast, setTypeOfToast] = useState<String>("newDocument");
  const [colorCodeForDocument, setColorCodeForDocument] = useState<any>({});
  const [dependency_dropdownValues, setDependency_dropdownValues] = useState<any>({});

  const [addedPageDocumentIndex, setAddedPageDocumentIndex] =
    useState<number>(0);

  const [isAutoSave, setIsAutoSave] = useState<boolean>(false);

  const [action, setAction] = useState<String>("");
  const [selectMode, setSelectMode] = useState<boolean>(false);
  const [updatedTableFieldsWithCoordinates, setUpdatedTableFieldsWithCoordinates] = useState<{ key: string; coordinates: number[]; idx: number }[] | undefined>();
  const [selectedTableField, setSelectedTableField] = useState<{
    idx?: number;
    key?: string;
  }>({
    idx: undefined,
    key: undefined,
  });


  const classes = useStyles();

  const [options, setOptions] = useState<any>([]);
  const activity = {
    actionType: actionType,
    startTime: localStorage.getItem("activityStart"),
    endTime: moment().format(),
    endTrigger: "",
  };

  const [value, setValue] = useState(0);
  const [fieldsToFieldsContainer, setFieldsToContainer] = useState([])
  const [showMetaData, setShowMetaData] = useState(false);

  const switchTab = (evt: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const triggerActivity = (params: any) => {
    activity.endTrigger = params;
    addActivity(authToken, document_id, activity);
    localStorage.removeItem("activityStart");
  };

  const cleanUp = (cleanupMode: Boolean) => {
    if (cleanupMode) {
      setSelectMode(false);
      setAction("");
      setShowFloatMenu(false);
      setRestrictedMode(false);
      setTriggerMode(false);
      setDocTypeFloatToastShow(false);
      setSelectionList([]);
      setPageNumberSelection([]);
      setSelectOption("");
      setCaseSelectOption("");
      setTypeOfToast("");
    }
  };

  const isSelectedPageUnassign = () => {
    if (documentDetails.documents[currentPage.documentIndex].docType1)
      return false;
    return true;
  };


  useEffect(() => {
    if (documentDetails.documents && !isSubmitting && !loading) {
      let mandatory = checkMandatory(
        documentDetails.documents,
        documentDetails.docTypeProject,
        documentDetails.sections
      );
      setShowSubmit(mandatory);
    }
  }, [fieldKey]);

  useEffect(() => {
    localStorage.setItem("activityStart", moment().format());
    if (document_id && pathname.split("/").includes("review")) {
      // const interval = setInterval(() => {
      //   console.log('INTERVAL', document_id, pathname);
      //   accessVerification(authToken, document_id)
      //     .then((res) =>
      //       console.log('ACCESS VERIFICATION SUCCESS', res.message)
      //     )
      //     .catch((error) => {
      //       setShowAccessVerificationFailedModal(true);
      //     });
      // }, 5000);
      // return () => clearInterval(interval);
    } else {
      console.log("ACCESS VERIFICATION SKIPPED");
    }
  }, [document_id]);

  useLayoutEffect(() => {
    const scrollDiv: HTMLElement | any =
      document.getElementById("commentsContainer");
    if (scrollDiv) {
      scrollDiv.scrollTop = scrollDiv.scrollHeight;
    }
  }, [reviewCont]);

  const updateBoundingBox = (
    fieldKey: string,
    updatedBoundingBox: number[]
  ) => {
    setDocumentDetails((prevState) => {
      const segment = prevState.documents[
        currentPage.documentIndex
      ] as documentPageSegmentsT;

      if (segment) {
        const fields = segment.fields.map((field: documentPageSegmentFieldsT) =>
          field.key === fieldKey
            ? {
              ...field,
              qcUpdatedCoordinates: updatedBoundingBox,
            }
            : field
        );

        return {
          ...prevState,
          documents: prevState.documents.map(
            (segment: documentPageSegmentsT, idx: number) =>
              currentPage.documentIndex === idx
                ? {
                  ...segment,
                  fields,
                }
                : segment
          ),
        };
      }

      return prevState;
    });
  };

  useEffect(() => {
    const start = Date.now();
    setLoading(true);
    getDocument(authToken, document_id).then((res) => {
      let caseList = Array.from(
        new Set(res.documents.map((doc: any) => doc.caseId))
      ).filter((item) => item !== null);
      const documentsSettingConfirmStage = res.documents.map((item: any) => {
        return { ...item, confirmPage: false };
      });
      let dependency_dropdown = {}
      res.docTypeProject.map((doc: any) => {
        Object.assign(dependency_dropdown, doc.depedency_value)
      })
      setDependency_dropdownValues(dependency_dropdown)
      let arrangingColor: any = {};
      const colorDocumentSetup = Array.from(
        new Set(
          documentsSettingConfirmStage.map((item: any) => item.documentId)
        )
      );
      colorDocumentSetup.map((documentId: any) => {
        if (documentId !== null) {
          let color = randomColor();
          arrangingColor[documentId] = color;
        }
      });
      setColorCodeForDocument(arrangingColor);
      setCasesWithLabel(labelCreator(caseList, false));
      setDocumentDetails({ ...res, documents: documentsSettingConfirmStage });
      setOptions(
        Array.from(new Set(res.docTypeProject.map((doc: any) => doc.docType)))
      );
      setAction("");
      setScale(1);
      setRenderFirstTime(true);
      setCurrentPage({
        documentIndex: 0,
        pageIndex: 0,
      });
      setLoading(false);
      const end = Date.now();
      console.log(`UI Get Document took ${end - start}ms to execute.`);
    });
  }, [document_id]);


  useEffect(() => {

    if (rotate.length) {
      setDocumentDetails((prevState) => {
        let dup = prevState;
        dup.documents[currentPageIndex].rotationAngle = (rotate[currentPageIndex]) % 360;

        return dup
      })

      console.log("Document Details on rotate", documentDetails.documents);
    }

  }, [rotate])

  useEffect(() => {
    const start = Date.now();
    if (
      document_id &&
      pathname.split("/").includes("pendingdoc") &&
      !isSubmitting &&
      documentDetails.documents.id !== ""
    ) {
      setActionType("qc");
      const interval = setInterval(() => {
        console.log("INTERVAL", document_id, pathname);
        setIsVerificationCallRequested(true);
        accessVerification(authToken, document_id)
          .then((res) => {
            console.log("ACCESS VERIFICATION SUCCESS", res.message);
            setIsVerificationCallRequested(false);
          })
          .catch((error) => {
            setShowAccessVerificationFailedModal(true);
          });
      }, 10000);
      return () => clearInterval(interval);
    }
    const end = Date.now();
    console.log(`UI Access Verification took ${end - start}ms to execute.`);
  }, [document_id]);

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 16) {
        cleanUp(true);
      }
    };
    window.addEventListener("keydown", handleEsc);

    const handleLeftButton = (event: any) => {
      if (event.button === 0) {
        setShowFloatMenu(false);
      }
    };

    window.addEventListener("mousedown", handleLeftButton);

    return () => {
      window.removeEventListener("keydown", handleEsc);
      window.addEventListener("mousedown", handleLeftButton);
    };
  }, [showFloatMenu]);

  async function autoSave() {
    const documentDetailsSave = {
      ...documentDetails,
      projectFields: [],
      docTypeProject: [],
    };
    autoSaveDocument(authToken, documentDetailsSave);
    setIsAutoSave(false);
  }

  useEffect(() => {
    if (
      !pathname.split("/").includes("completed") &&
      !pathname.split("/").includes("view") &&
      !isSubmitting &&
      !showAccessVerificationFailedModal &&
      documentDetails._id !== "" &&
      !isVerificationCallRequested
    ) {
      let interval = setInterval(async () => {
        if (!showAccessVerificationFailedModal) {
          const start = Date.now();
          await autoSave();
          const end = Date.now();
          console.log(`UI Autosave took ${end - start}ms to execute.`);
        }
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [documentDetails]);

  const handleAccessVerificationFailedModal = () => {
    return handleDocumentRedirect(project_id);
  };

  const handleChangePriority = (document_id: string, priority: any) => {
    changePriority(authToken, document_id, !priority)
      .then((res) => {
        if (res.success) {
          history.goBack();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validations = {
    "pNaChequeHold": (documents: any[]) => {
      let isValidate = true

      documents.forEach(doc => {
        doc.fields.forEach((field: any) => {
          if (field.key === "status") {
            field.fieldValues.forEach((column: any, index: number) => {
              if (column.qcUpdatedValue.toLowerCase() === "hold") {
                let checkNumberFieldValues = doc.fields.find((item: any) => item.key === "check_number").fieldValues
                if ((checkNumberFieldValues[index].qcUpdatedCoordinates && checkNumberFieldValues[index].qcUpdatedCoordinates.length) || (checkNumberFieldValues[index].coordinates && checkNumberFieldValues[index].coordinates.length)) {
                  console.log("Check bb validated")
                } else {
                  console.log("Check bb not present")
                  isValidate = false
                }
              }
            })
          }
        })
      });

      return {
        error: !isValidate,
        message: "Please add bounding box for cheque with status hold"
      }
    }
  }

  const handleFieldChange = (value: string, fieldKey: string) => {
    let fieldValueChange: any = {};

    setDocumentDetails((prevState) => {
      const segment = prevState.documents[
        currentPage.documentIndex
      ] as documentPageSegmentsT;

      if (segment) {
        const fields = segment.fields.map((field: documentPageSegmentFieldsT) =>
          field.key === fieldKey ? { ...field, qcUpdatedValue: value } : field
        );

        let segmentFields = { ...segment, fields };

        prevState.documents[currentPage.documentIndex] = segmentFields;
      }

      fieldValueChange = prevState;

      return prevState;
    });

    if (Object.keys(fieldValueChange).length) {
      const fieldsContainer = fieldValueChange.documents[
        `${currentPage.documentIndex}`
      ].fields.sort(
        (
          a: documentPageSegmentFieldsT,
          b: documentPageSegmentFieldsT
        ) =>
          Math.round(new Date(a.createdAt).getTime()) -
          Math.round(new Date(b.createdAt).getTime())
      )
      setFieldsToContainer(fieldsContainer)
    }

    let mandatory = checkMandatory(
      documentDetails.documents,
      documentDetails.docTypeProject,
      documentDetails.sections
    );
    setShowSubmit(mandatory);
  };


  useEffect(() => {
    if (documentDetails._id !== "") {
      const fieldsContainer = documentDetails.documents[
        `${currentPage.documentIndex}`
      ].fields.sort(
        (
          a: documentPageSegmentFieldsT,
          b: documentPageSegmentFieldsT
        ) =>
          Math.round(new Date(a.createdAt).getTime()) -
          Math.round(new Date(b.createdAt).getTime())
      )
      setFieldsToContainer(fieldsContainer)
    }
  }, [documentDetails, currentPage, handleFieldChange])

  const handleSubmit = () => {
    if (!validateMandatory(documentDetails)) {
      setShowValidationError(true)
      return
    }
    const updateDocumetType = documentDetails.docTypeProject.map((docType: any) => {
      const { depedency_value } = docType
      const updateDependencyValue = Object.keys(depedency_value).map((depedency) => {
        return { ...depedency_value, [depedency]: dependency_dropdownValues[depedency] }
      })
      return { ...docType, depedency_value: updateDependencyValue }
    })
    if (documentDetails.status !== 'mark_for_review') {
      if (documentDetails.customValidation) {
        if (documentDetails.customValidation in validations) {
          // @ts-ignore
          const { error, message } = validations[documentDetails.customValidation](documentDetails.documents)
          if (error) {
            toastify('failure', message)
            return
          }
        }
      }
    }
    setLoading(true)
    const start = Date.now()
    setIsSubmitting(true);
    triggerActivity("submit");
    if (documentDetails.status === "mark_for_review") {
      updateDocument(authToken, {
        ...documentDetails,
        qcCompletedTime: new Date(),
        qcStartedTime: new Date(localStorage.getItem("$qcstartingtime") || ""),
      })
        .then(() => {
          localStorage.removeItem("$startTime");
          setIsSubmitting(false);
          toastify("success", "Document Submission successful");
          nextInReviewDocByTime(
            authToken,
            documentDetails.projectId,
            documentDetails.createdAt
          )
            .then((res) => {
              if (res.nextDocument) {
                // localStorage.removeItem('$qcstartingtime')
                handleDocRedirect(documentDetails.projectId, res.nextDocument);
                setLoading(true);
              } else {
                // localStorage.removeItem('$qcstartingtime')
                history.replace("/projects");
              }
            })
            .catch((err) => {
              history.replace("/projects");
            });
          setCurrentPageIndex(0);
        })
        .catch((error) => setIsSubmitting(true));
    } else {
      accessVerification(authToken, document_id)
        .then((res) => {
          const submitDocumentDetails = {
            ...documentDetails,
            docTypeProject: updateDocumetType,
            projectFields: []
          };
          updateDocument(authToken, {
            ...submitDocumentDetails,
            qcCompletedTime: new Date(),
            qcStartedTime: new Date(
              localStorage.getItem("$qcstartingtime") || ""
            ),
          }, queue_type || undefined)
            .then((res) => {
              toastify("success", "Document Submission successful");
              setCurrentPageIndex(0);
              handlependingDocumentRedirect(project_id, queue_type || undefined);
              setIsSubmitting(false);
              setLoading(true);
            })
            .catch((error) => setIsSubmitting(true));
        })
        .catch((error) => {
          setShowAccessVerificationFailedModal(true);
          setIsSubmitting(false);
          setLoading(false);
        });
    }
    const end = Date.now();
    console.log(`UI handle Submit took ${end - start}ms to execute.`);
  };

  const handleMarkForReview = () => {
    const start = Date.now();
    triggerActivity("mark_for_review");
    const markForReviewData = {
      ...documentDetails,
      projectFields: [],
      docTypeProject: [],
    };
    setIsSubmitting(true);
    markReview(authToken, {
      ...markForReviewData,
      markedForReviewOn: new Date(),
      reviewComment: reviewComment,
    })
      .then(() => {
        if (documentDetails.status === "pending") {
          toastify("success", "Review submission successful");
          setIsSubmitting(false);
          handlependingDocumentRedirect(project_id);
          setLoading(true);
        } else {
          if (documentDetails.status !== "completed") {
            changeFileStatus(
              authToken,
              document_id,
              documentDetails.projectId,
              "pending"
            )
              .then((res) => {
                if (res) {
                  toastify("success", "document moved to pending");
                  setIsSubmitting(false);
                }
              })
              .catch((err) => {
                toastify("failure", "document status change failed");
                console.log(err);
              });
          }
          nextInReviewDocByTime(
            authToken,
            documentDetails.projectId,
            documentDetails.createdAt
          )
            .then((res) => {
              if (res.nextDocument) {
                handleDocRedirect(documentDetails.projectId, res.nextDocument);
                setIsSubmitting(false);
              } else {
                toastify("success", "Review completed for the last document");
                history.replace("/projects");
              }
            })
            .catch((err) => {
              history.replace("/projects");
            });
        }
        setReviewCont(false);
        setReviewComment("");
        setCurrentPageIndex(0);
      })
      .catch((err) => {
        console.log(err);
        setReviewCont(false);
        setReviewCont(true);
      });
    const end = Date.now();
    console.log(`UI handle Mark for Review took ${end - start}ms to execute.`);
  };

  const handlependingDocumentRedirect = (document: string, queue_type?: string) => {
    const start = Date.now();
    setLoading(true);
    verifyChangeDocument(authToken, document, "false", queue_type)
      .then((res) => {
        showAccessVerificationFailedModal &&
          setShowAccessVerificationFailedModal(false);

        if (queue_type) {
          history.replace(
            `/projects/${project_id}/document/${res.data}/pendingdoc?queue_type=${queue_type}`
          );
        } else {
          history.replace(
            `/projects/${project_id}/document/${res.data}/pendingdoc`
          );
        }

        // setDocumentDetails(initialValue)
        // setDocumentDetails(res.data);
        setLoading(false);
      })
      .catch((error) => {
        toastify("success", "No documents available now");
        showAccessVerificationFailedModal &&
          setShowAccessVerificationFailedModal(false);
        history.replace("/projects");
        console.log(error);
      });
    const end = Date.now();
    console.log(
      `UI Handle pending Document Redirect took ${end - start}ms to execute.`
    );
  };

  const handleDocumentRedirect = (document: string) => {
    const start = Date.now();
    verifyChangeDocument(authToken, document, "false")
      .then((res) => {
        showAccessVerificationFailedModal &&
          setShowAccessVerificationFailedModal(false);
        history.replace(
          `/projects/${document}/document/review/${res.data}/pendingdoc`
        );
        // setDocumentDetails(res.data);
      })
      .catch((error) => {
        toastify("success", "No documents available now");
        showAccessVerificationFailedModal &&
          setShowAccessVerificationFailedModal(false);
        history.replace("/projects");
        console.log(error);
      });
    const end = Date.now();
    console.log(
      `UI handle Document Redirect took ${end - start}ms to execute.`
    );
  };

  const handleShowValidationModal = () => {
    //setTriggerNotValidateFieldsIndication(true);
    setShowValidationError(false)
  }

  const handleDocRedirect = (project: string, document: string) => {
    history.replace(
      `/projects/${project}/document/review/${document}/pendingdoc`
    );
  };

  const onDragEnd = (result: any) => {
    console.log("documentIndex", result);
    const { source, destination } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (checkReorderPageValid(documentDetails, source, destination)) {
      setDocumentDetails(() =>
        reorderPage(documentDetails, source, destination)
      );
    } else {
      toastify("failure", "The Ordered Page not valid");
      return;
    }
  };

  const handleMenuOption = (e: any) => {
    switch (e.target.innerText) {
      case "Mark page(s) as a separate Document":
        setDocTypeFloatToastShow(true);
        setWarn(validateDocumentsType(selectionList, documentDetails));
        setTypeOfToast("newDocument");
        break;
      case "Disassociate page(s) and move up":
        if (!selectionList.length) {
          let selectdocumentId =
            documentDetails.documents[currentPage.documentIndex].documentId;
          const dissociateDetail = dissociate(
            documentDetails,
            selectionList,
            currentPage.documentIndex
          );
          let document = multiplePageSelectionMove(
            dissociateDetail,
            documentDetails.documents[currentPage.documentIndex].pageNumber,
            documentDetails.documents[currentPage.documentIndex].pageNumber,
            "start",
            selectdocumentId
          );
          setDocumentDetails(document);
          cleanUp(true);
          break;
        }
        if (validateAdjacentPage(pageNumberSelection)) {
          let selectdocumentId = documentDetails.documents.filter(
            (item: any) => {
              if (item.id == selectionList[0]) return item.documentId;
            }
          )[0];
          let startandEndArray = documentDetails.documents.filter(
            (item: any) => {
              if (
                item.id == selectionList[0] ||
                item.id === selectionList[selectionList.length - 1]
              )
                return item;
            }
          );
          const dissociateDetail = dissociate(
            documentDetails,
            selectionList,
            currentPage.documentIndex
          );
          if (
            selectionList.length !== documentDetails.documents.length ||
            documentDetails.documents.length !== 1
          ) {
            let document = multiplePageSelectionMove(
              dissociateDetail,
              startandEndArray[0].pageNumber,
              startandEndArray[startandEndArray.length - 1].pageNumber,
              "start",
              selectdocumentId.documentId
            );
            setDocumentDetails(document);
            cleanUp(true);
            break;
          }
          setDocumentDetails(dissociateDetail);
        } else {
          toastify("failure", "Selected Pages are not adjacent");
        }
        break;
      case "Disassociate page(s) and move down":
        if (!selectionList.length) {
          let selectdocumentId =
            documentDetails.documents[currentPage.documentIndex].documentId;
          const dissociateDetail = dissociate(
            documentDetails,
            selectionList,
            currentPage.documentIndex
          );
          let document = multiplePageSelectionMove(
            dissociateDetail,
            documentDetails.documents[currentPage.documentIndex].pageNumber,
            documentDetails.documents[currentPage.documentIndex].pageNumber,
            "end",
            selectdocumentId
          );
          setDocumentDetails(document);
          cleanUp(true);
          break;
        }
        if (validateAdjacentPage(pageNumberSelection)) {
          let selectdocumentId1 = documentDetails.documents.filter(
            (item: any) => {
              console.log("itemId", item.id);
              if (item.id == selectionList[0]) return item.documentId;
            }
          )[0];
          let startandEndArray1 = documentDetails.documents.filter(
            (item: any) => {
              if (
                item.id == selectionList[0] ||
                item.id === selectionList[selectionList.length - 1]
              )
                return item;
            }
          );
          let dissociateDetail1 = dissociate(
            documentDetails,
            selectionList,
            currentPage.documentIndex
          );
          if (
            selectionList.length !== documentDetails.documents.length ||
            documentDetails.documents.length !== 1
          ) {
            let document = multiplePageSelectionMove(
              dissociateDetail1,
              startandEndArray1[0].pageNumber,
              startandEndArray1[startandEndArray1.length - 1].pageNumber,
              "end",
              selectdocumentId1.documentId
            );
            setDocumentDetails(document);
            cleanUp(true);
            break;
          }
          setDocumentDetails(dissociateDetail1);
        } else {
          toastify("failure", "Selected Pages are not adjacent");
        }
        break;
      case "Select all pages of file":
        setAction("longpress");
        setSelectMode(true);
        setSelectionList(() =>
          Array.from(
            new Set(documentDetails.documents.map((item: any) => item.id))
          )
        );
        setPageNumberSelection(() =>
          Array.from(
            new Set(
              documentDetails.documents.map((item: any) => item.pageNumber)
            )
          )
        );
        break;
    }
    setShowFloatMenu(false);
  };

  useEffect(() => {
    if (restrictedMode && triggerMode) {
      const validateAddPage =
        documentDetails.documents[currentPage.documentIndex];

      if (
        validateAddPage.documentId !== null &&
        validateAddPage.caseId !== null
      ) {
        if (selectionList.length) {
          const addedPage = addPage(
            documentDetails,
            currentPage.documentIndex,
            selectionList
          );
          setDocumentDetails({ ...documentDetails, documents: addedPage });
        } else {
          const addedPage = addPage(
            documentDetails,
            currentPage.documentIndex,
            [],
            addedPageDocumentIndex
          );
          setDocumentDetails({ ...documentDetails, documents: addedPage });
          setRestrictedMode(false);
          setTypeOfToast("");
          setTriggerMode(false);
          setSelectionList([]);
          cleanUp(true);
        }
      } else {
        toastify("failure", "Selected Non document Instance Page");
        setTypeOfToast("addpage");
        setTriggerMode(false);
        setRestrictedMode(true);
      }
    }
  }, [triggerMode]);

  const handleChangeDocument: any = () => {
    const docId = randomCaseIdGenerator("docId");
    if (!selectionList.length) {
      let caseIdExtractionByLabel = Object.entries(casesWithLabel).map(
        (item) => {
          if (item[1] === caseSelectOption) return item[0];
        }
      );

      const document = documentDetails.documents.map(
        (item: any, index: number) => {
          if (index === currentPage.documentIndex) {
            return {
              ...item,
              docType1: selectOption,
              caseId: caseIdExtractionByLabel,
              documentId: docId,
            };
          }
          return item;
        }
      );
      setDocumentDetails({
        ...documentDetails,
        documents: document,
      });
      setColorCodeForDocument(() =>
        assigningColorForDocument(colorCodeForDocument, document)
      );
      setDocTypeFloatToastShow(false);
      setSelectOption("");
      setCaseSelectOption("");
      setReload(true);
      return;
    }

    let selection = selectionList[0];
    let caseIdExtractionByLabel = Object.entries(casesWithLabel).map((item) => {
      if (item[1] === caseSelectOption) return item[0];
    });
    if (selectionList.length <= 1) {
      const changedocument = documentDetails.documents.map((item: any) => {
        if (item.id === selection) {
          return {
            ...item,
            docType1: selectOption,
            caseId: caseIdExtractionByLabel,
            documentId: docId,
          };
        }
        return item;
      });
      setDocumentDetails(() => {
        return { ...documentDetails, documents: changedocument };
      });
      setColorCodeForDocument(() =>
        assigningColorForDocument(colorCodeForDocument, changedocument)
      );
    } else {
      let data: any = [];
      const selectionList2 = R.flatten(data);
      const data1 = Array.from(
        new Set(selectionList2.map((doc: any) => doc.documentId))
      );
      if (data1.length == 1) {
        let document = documentDetails.documents.map((item: any) => {
          if (selectionList.includes(item.id)) {
            return {
              ...item,
              docType1: selectOption,
              caseId: caseIdExtractionByLabel,
              documentId: docId,
            };
          }
          return item;
        });
        setDocumentDetails({ ...documentDetails, documents: document });
        setColorCodeForDocument(() =>
          assigningColorForDocument(colorCodeForDocument, document)
        );
        setDocTypeFloatToastShow(false);
        setSelectOption("");
        setCaseSelectOption("");
        return;
      } else {
        let document = documentDetails.documents.map((item: any) => {
          if (selectionList.includes(item.id)) {
            return {
              ...item,
              docType1: selectOption,
              caseId: caseIdExtractionByLabel,
              documentId: docId,
            };
          }
          return item;
        });
        setDocumentDetails({ ...documentDetails, documents: document });
        setColorCodeForDocument(() =>
          assigningColorForDocument(colorCodeForDocument, document)
        );
        setDocTypeFloatToastShow(false);
        setSelectOption("");
        setCaseSelectOption("");
        return;
      }
    }
    setDocTypeFloatToastShow(false);
    setSelectOption("");
  };

  const handleDocumentTypeChange = () => {
    try {
      let document = docType(documentDetails, currentPage, selectOption);
      setDocumentDetails(document);
      setDocTypeFloatToastShow(false);
      setSelectOption("");
    } catch (err) {
      setDocTypeFloatToastShow(false);
      toastify("failure", `${err}`);
    }
  };

  const handleCaseChange = () => {
    if (!selectionList.length) {
      const document = documentDetails.documents.map(
        (item: any, index: number) => {
          if (index === currentPage.documentIndex) {
            if (selectOption === "new") {
              let caseId = randomCaseIdGenerator("caseId");
              return { ...item, caseId: caseId };
            }
            let caseIdExtractionByLabel = Object.entries(casesWithLabel).map(
              (item) => item[1] === selectOption && item[0]
            );
            return { ...item, caseId: caseIdExtractionByLabel };
          }
          return item;
        }
      );
      setDocumentDetails({ ...documentDetails, documents: document });

      if (selectOption === "new") {
        let caseList = Array.from(
          new Set(document.map((doc: any) => doc.caseId))
        )
          .filter((item) => item !== null)
          .filter((item: any) => !Object.keys(casesWithLabel).includes(item));
        let newCaseList = labelCreator(caseList, true, casesWithLabel);
        setCasesWithLabel(newCaseList);
      }

      let newCaseLister = removeUnusedLabels(casesWithLabel, document);
      setCasesWithLabel(newCaseLister);

      setSelectOption("");
      setDocTypeFloatToastShow(false);
    }
  };

  const handleConfirmPage = () => {
    const document = documentDetails.documents.map(
      (item: any, index: number) => {
        if (currentPage.documentIndex === index)
          return { ...item, confirmPage: !item.confirmPage };
        return item;
      }
    );

    setDocumentDetails({ ...documentDetails, documents: document });

  };

  const clearBoundingBox = (pageIndex: number) => {

    setDocumentDetails((prevState) => {
      prevState.documents[pageIndex].fields.map((field: any) => {
        field.qcUpdatedCoordinates = [];
      })
      return prevState;
    })
  }

  const checkBoundingBoxExists = () => {
    const currentPage = documentDetails.documents[currentPageIndex];
    const fields = currentPage.fields;

    let boundingBoxExists = 0;

    fields.forEach((field: any) => {
      if (field.qcUpdatedCoordinates.length) {
        boundingBoxExists = 1;
      }
    })

    return boundingBoxExists;
  }

  const handleRotate = (direction: string) => {

    clearBoundingBox(currentPageIndex);

    if (direction === "clockwise") {
      setRotate((prevState) => {
        let newRotate = [...prevState];

        if (!newRotate[currentPageIndex]) {
          newRotate[currentPageIndex] = 0;
        }

        newRotate[currentPageIndex] = (newRotate[currentPageIndex] + 90) % 360;


        return newRotate
      });

    } else {
      setRotate((prevState) => {
        let newRotate = [...prevState];

        if (!newRotate[currentPageIndex]) {
          newRotate[currentPageIndex] = 0;
        }

        if (newRotate[currentPageIndex] === 0) {
          newRotate[currentPageIndex] = 360;
        }

        newRotate[currentPageIndex] = newRotate[currentPageIndex] - 90;
        return newRotate
      });
    }
  }

  const handleUndo = () => {
    undoSaveDocument(authToken, documentDetails.id)
      .then((data) => {
        if (data) {
          console.log(data.data);
          setLoading(true);
          setDocumentDetails({
            ...documentDetails,
            documents: data.data.documents,
          });
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const onClick = () => {
    console.log("click");
  };

  const onLongPress = () => {
    setAction("longpress");
    selectionList.push(documentDetails.documents[currentPage.documentIndex].id);
    pageNumberSelection.push(
      documentDetails.documents[currentPage.documentIndex].pageNumber
    );
    setSelectMode(true);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 250,
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  if (loading) return <Loader />;

  return (
    <>
      <div className="doc_verify_cont">
        <div
          className="header_cont_vc h-100"
          style={{ border: "1px solid #eee" }}
        >
          <>
            <div style={{ marginLeft: "3.5rem" }}>
              <Logo />
            </div>
            <div
              style={{
                verticalAlign: "center",
                marginTop: "0.5rem",
              }}
            >
              <h6 style={{ fontSize: "16px", fontWeight: "bolder" }}>
                VerifyMe
              </h6>
            </div>
            <div
              className="home_btn_vc"
              onClick={() => {
                if (documentDetails.status !== "completed")
                  triggerActivity("back");
                history.goBack();
              }}
            >
              <img
                src="/Icons/arrow-left-solid.svg"
                alt="left arrow"
                width="18px"
              />
            </div>
            <div className="doc_type_vc" style={{ fontSize: "16px" }}>
              Project: <span>{documentDetails.projectName}</span>
            </div>

            <div>
              <span>
                <BlueOnGreenTooltip
                  title="AutoSave"
                  arrow
                  placement="bottom"
                  style={{ color: "black" }}
                >
                  <AutoSaveIcon
                    className="showIcon"
                    style={{ color: isAutoSave ? "red" : "" }}
                  />
                </BlueOnGreenTooltip>
              </span>
            </div>

            <div>
              <BlueOnGreenTooltip
                title={documentDetails.file_name}
                arrow
                placement="bottom"
                style={{ color: "black" }}
              >
                <IconButton
                  className="showIcon"
                  sx={{
                    "&.MuiIconButton-root": {
                      padding: "0",
                    },
                  }}
                  onClick={() => {
                    const win: any = window.open(
                      window.location.origin + "/files/" + documentDetails.url,
                      "_blank"
                    );
                    // win.focus();
                  }}
                >
                  <i>
                    <FileDownloadIcon className="showIcon" />
                  </i>
                </IconButton>
              </BlueOnGreenTooltip>
            </div>

            <div>
              <span>
                <BlueOnGreenTooltip
                  title="Zoom In"
                  arrow
                  placement="bottom"
                  style={{ color: "black" }}
                >
                  <IconButton
                    className="showIcon"
                    sx={{
                      "&.MuiIconButton-root": {
                        padding: "0",
                      },
                    }}
                    onClick={() => {
                      setScale(scale * 1.2);
                      setRenderFirstTime(false);
                    }}
                  >
                    <ZoomInDocumentIcon className="showIcon" />
                  </IconButton>
                </BlueOnGreenTooltip>
              </span>
            </div>

            <div>
              <span>
                <BlueOnGreenTooltip
                  title="Zoom Out"
                  arrow
                  placement="bottom"
                  style={{ color: "black" }}
                >
                  <IconButton
                    className="showIcon"
                    sx={{
                      "&.MuiIconButton-root": {
                        padding: "0",
                      },
                    }}
                    onClick={() => {
                      setScale(scale / 1.2);
                      setRenderFirstTime(false);
                    }}
                  >
                    <ZoomOutDocumentIcon className="showIcon" />
                  </IconButton>
                </BlueOnGreenTooltip>
              </span>
            </div>

            <div>
              <span>
                <BlueOnGreenTooltip
                  title="Zoom Reset"
                  arrow
                  placement="bottom"
                  style={{ color: "black" }}
                >
                  <IconButton
                    className="showIcon"
                    sx={{
                      "&.MuiIconButton-root": {
                        padding: "0",
                      },
                    }}
                    onClick={() => {
                      setScale(1);
                    }}
                  >
                    <ZoomResetIcon className="showIcon" />
                  </IconButton>
                </BlueOnGreenTooltip>
              </span>
            </div>

            <div>
              <span>
                <BlueOnGreenTooltip
                  title="Rotate Left"
                  arrow
                  placement="bottom"
                  style={{ color: "black" }}
                >
                  <IconButton
                    className="showIcon"
                    sx={{
                      "&.MuiIconButton-root": {
                        padding: "0",
                      },
                    }}
                    onClick={() => {
                      const boundingBoxExists = checkBoundingBoxExists();
                      setRotateDirection("anticlockwise");

                      if (boundingBoxExists) {
                        setShowRotateAlert(true);
                      } else {
                        handleRotate(rotateDirection);
                      }
                    }}
                  >
                    <Rotate90DegreesCcwIcon className="showIcon" />
                  </IconButton>
                </BlueOnGreenTooltip>
              </span>
            </div>

            <div>
              <span>
                <BlueOnGreenTooltip
                  title="Rotate Right"
                  arrow
                  placement="bottom"
                  style={{ color: "black" }}
                >
                  <IconButton
                    className="showIcon"
                    sx={{
                      "&.MuiIconButton-root": {
                        padding: "0",
                      },
                    }}
                    onClick={() => {
                      const boundingBoxExists = checkBoundingBoxExists();
                      setRotateDirection("clockwise");
                      if (boundingBoxExists) {
                        setShowRotateAlert(true);
                      } else {
                        handleRotate(rotateDirection);
                      }

                    }}
                  >
                    <Rotate90DegreesCwIcon className="showIcon" />
                  </IconButton>
                </BlueOnGreenTooltip>
              </span>
            </div>

            <div
              className="d-flex justify-content-end gap-2"
              style={{ width: '35rem', marginRight: "0.5rem" }}
            >
              {pathname.split("/").includes("view") ? (
                <div></div>
              ) : (
                <>
                  <div>
                    {/* <VMButton variant="secondary">Split</VMButton> */}
                  </div>
                  <div>
                    {documentDetails.status !== "completed" && (
                      <VMButton
                        variant="secondary"
                        onClick={() => setReviewCont(true)}
                      >
                        Mark For Review
                      </VMButton>
                    )}
                  </div>
                  <div>
                    {documentDetails.status !== "completed" ? (
                      <VMButton
                        variant="primary"
                        onClick={() => handleSubmit()}
                        disabled={!showSubmit}
                      >
                        {loadingButton ? <SpinnerOutlined /> : "Submit"}
                      </VMButton>
                    ) : (
                      <VMButton
                        variant="primary"
                        disabled={triggerMandatory}
                        onClick={() => setReviewCont(true)}
                      >
                        Mark For Review
                      </VMButton>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        </div>

        <div className="review_btn_vc">
          <div
            className="review__cot"
            style={reviewCont ? { display: "block" } : { display: "none" }}
          >
            <div
              id="commentsContainer"
              style={{ height: "24rem", overflowY: "scroll" }}
            >
              {documentDetails.commentHistory &&
                documentDetails.commentHistory.length > 0 &&
                documentDetails.commentHistory.map(
                  (comment: any, index: number) => (
                    <CommentListItem
                      name={comment.commentedBy}
                      comment={comment.comment}
                      key={index}
                      time={moment(comment.commentedOn).fromNow()}
                    />
                  )
                )}
            </div>
            <label htmlFor="review__area">Enter a comment :</label>
            <TextField
              name="review__area"
              id="review__area"
              label="Multiline"
              sx={{ width: "100%" }}
              value={reviewComment}
              multiline
              maxRows={4}
              onChange={(e) => setReviewComment(e.currentTarget.value)}
              placeholder="Enter your review comment"
            />
            <VMButton
              variant="primary"
              style={{ marginTop: "1rem" }}
              disabled={reviewComment.length === 0}
              onClick={handleMarkForReview}
            >
              {documentDetails.status === "marked_for_review" && role === "lead"
                ? "Move to Pending"
                : "Mark for Review"}
            </VMButton>
            <VMButton
              variant="secondary"
              style={{ marginTop: "1rem", marginLeft: "0.5rem" }}
              onClick={() => setReviewCont(false)}
            >
              Close
            </VMButton>
          </div>
        </div>

        <div className="body_cont_vc" onContextMenu={(e) => e.preventDefault()}>
          {/* //C */}
          <div
            className={tabelDisplay ? "minimize_list_bar" : "list_bar"}
            style={{
              position: "relative",
              boxShadow:
                typeOfToast == "addpage" ? "0 10px 15px rgb(0 0 0 / 0.2)" : "",
            }}
          >
            <div className="number_elems">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="some_id">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {documentDetails.documents.map(
                        (item: any, documentIndex: any) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={documentIndex}
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <div
                                    key={documentIndex}
                                    className="sb_img_doc"
                                    style={{
                                      backgroundColor:
                                        typeOfToast === "addpage"
                                          ? selectionList.includes(item.id)
                                            ? "yellow"
                                            : ""
                                          : action === "longpress" &&
                                            selectionList.includes(item.id)
                                            ? "#87CEEB"
                                            : "",
                                      position: "relative",
                                    }}
                                    onClick={(e: any) => {
                                      if (
                                        restrictedMode &&
                                        typeOfToast === "addpage"
                                      ) {
                                        setTriggerMode(true);
                                        setAddedPageDocumentIndex(
                                          currentPage.documentIndex
                                        );
                                      }
                                      setCurrentPageIndex(documentIndex);
                                      setTableDisplay(false);
                                      setCurrentPage({
                                        documentIndex: documentIndex,
                                        pageIndex: 0,
                                      });
                                    }}
                                  >
                                    {sidebarOpen && (
                                      // eslint-disable-next-line jsx-a11y/img-redundant-alt
                                      <>
                                        {item.confirmPage && (
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: "90%",
                                              left: "87%",
                                              zIndex: "100",
                                            }}
                                          >
                                            <i style={{ color: "green" }}>
                                              <PageDoneIcon className="iconScale" />
                                            </i>
                                          </div>
                                        )}
                                        <div
                                          className={
                                            currentPage.documentIndex ===
                                              documentIndex
                                              ? "forPageDisplaying pageSelectionHighlight"
                                              : item.confirmPage
                                                ? "forPageDisplaying pageNotSelectionMode"
                                                : "forPageDisplaying pageNotSelectionMode "
                                          }
                                          style={
                                            tabelDisplay
                                              ? {
                                                width: "100%",
                                              }
                                              : {}
                                          }
                                          onClick={(e) => {
                                            if (action === "longpress") {
                                              if (
                                                selectionList.includes(item.id)
                                              ) {
                                                selectionList.splice(
                                                  selectionList.indexOf(
                                                    item.id
                                                  ),
                                                  1
                                                );
                                                pageNumberSelection.splice(
                                                  pageNumberSelection.indexOf(
                                                    item.pageNumber
                                                  ),
                                                  1
                                                );
                                              } else {
                                                selectionList.push(item.id);
                                                pageNumberSelection.push(
                                                  item.pageNumber
                                                );
                                              }
                                            }
                                          }}
                                        >
                                          {item.documentId !== null && (
                                            <div
                                              style={{
                                                borderRight: `10px solid`,
                                                borderColor: `${colorCodeForDocument[
                                                  item.documentId
                                                ]
                                                  }`,
                                                height: "303px",
                                                position: "absolute",
                                                top: "2px",
                                                left: "1px",
                                              }}
                                            ></div>
                                          )}
                                          {!tabelDisplay && (
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: '10.75rem',
                                                marginLeft: '0.7rem',
                                              }}
                                              id={documentIndex}
                                            >
                                              <div>
                                                <h6
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  Doc Type:
                                                  {item?.docType1}
                                                </h6>
                                              </div>
                                              <div>
                                                {action === "longpress" ? (
                                                  <div>
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        selectionList.includes(
                                                          item.id
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={(e) => {
                                                        if (
                                                          selectionList.includes(
                                                            item.id
                                                          )
                                                        ) {
                                                          selectionList.splice(
                                                            selectionList.indexOf(
                                                              item.id
                                                            ),
                                                            1
                                                          );
                                                          pageNumberSelection.splice(
                                                            pageNumberSelection.indexOf(
                                                              item.pageNumber
                                                            ),
                                                            1
                                                          );
                                                          console.log(
                                                            "pageNumber",
                                                            pageNumberSelection
                                                          );
                                                        } else {
                                                          selectionList.push(
                                                            item.id
                                                          );
                                                          pageNumberSelection.push(
                                                            item.pageNumber
                                                          );
                                                        }
                                                      }}
                                                    ></input>
                                                  </div>
                                                ) : (
                                                  <h6
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    {documentIndex + 1}
                                                  </h6>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                          <div
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              position: 'relative',
                                              padding: rotate[documentIndex] / 90 === 1 || rotate[documentIndex] / 90 === 3 ? `1rem 0 1rem 0` : ''
                                            }}
                                            id={documentIndex}
                                          >
                                            <div
                                              style={{ position: "absolute" }}
                                              {...longPressEvent}
                                              onContextMenu={(e: any) => {
                                                if (
                                                  e.type === "contextmenu" &&
                                                  selectMode &&
                                                  documentDetails.status !==
                                                  "completed"
                                                ) {
                                                  setShowFloatMenu(true);
                                                  setAnchorEl(e.pageX);
                                                  return;
                                                }
                                                if (
                                                  e.button === 2 &&
                                                  action !== "longpress" &&
                                                  documentDetails.status !==
                                                  "completed"
                                                ) {
                                                  let page = parseInt(
                                                    e.target.id
                                                  );
                                                  setSelectionList([]);
                                                  setCurrentPage({
                                                    documentIndex: page,
                                                  });
                                                  setTableDisplay(false);
                                                  setShowFloatMenu(true);
                                                  setAnchorEl(e.pageY);
                                                }
                                              }}
                                            ></div>
                                            {/* `${window.location.origin}/files/${item.url}` */}
                                            <img
                                              src={`${window.location.origin}/files/${item.url}`}
                                              style={{
                                                transform: `rotate(${rotate[documentIndex]}deg)`,
                                                width: rotate[documentIndex] / 90 === 1 || rotate[documentIndex] / 90 === 3 ? `calc(100% - 50px)` : `100%`,
                                                height: '100%'
                                              }}
                                              alt={`document image ${documentIndex + 1
                                                }`}
                                              {...longPressEvent}
                                              className="sidebar_images_vc"
                                              id={documentIndex}
                                              onContextMenu={(e: any) => {
                                                if (
                                                  e.type === "contextmenu" &&
                                                  selectMode &&
                                                  documentDetails.status !==
                                                  "completed"
                                                ) {
                                                  console.log("e", e);
                                                  setShowFloatMenu(true);
                                                  setAnchorEl(e.pageX);
                                                  return;
                                                }
                                                if (
                                                  e.button === 2 &&
                                                  action !== "longpress" &&
                                                  documentDetails.status !==
                                                  "completed"
                                                ) {
                                                  let page = parseInt(
                                                    e.target.id
                                                  );
                                                  setSelectionList([]);
                                                  setCurrentPage({
                                                    documentIndex: page,
                                                  });
                                                  setTableDisplay(false);
                                                  setShowFloatMenu(true);
                                                  console.log("e", e);
                                                  setAnchorEl(e.pageY);
                                                }
                                              }}
                                              ref={
                                                currentPage.documentIndex ===
                                                  documentIndex
                                                  ? target
                                                  : null
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>

          <div
            className="image_bar"
          >
            <ImageContainer
              hide={typeOfToast === "addpage" ? true : false}
              handleUndo={handleUndo}
              setTableDisplay={setTableDisplay}
              projectFields={
                documentDetails.documents[`${currentPage.documentIndex}`]
                  .docType1
                  ? documentDetails.docTypeProject.filter((item: any) => {
                    if (
                      item.docType ===
                      documentDetails.documents[
                        `${currentPage.documentIndex}`
                      ].docType1
                    )
                      return item;
                  })[0].fields
                  : []
              }
              fieldKey={fieldKey}
              boundingBox={currentBoundingBox}
              updateBoundingBox={updateBoundingBox}
              depedency_dropdown={dependency_dropdownValues}
              setDependencyDropdown={setDependency_dropdownValues}
              onCurrentBoundingBoxChange={(coordinates) => setCurrentBoundingBox(coordinates)}
              currentIndex={currentPage.documentIndex}
              showBoundingBox={showBoundingBox}
              toggleBoundingBox={setShowBoundingBox}
              lastIndex={documentDetails.documents.length - 1}
              currentImageUrl={
                documentDetails.documents[`${currentPage.documentIndex}`]?.url
              }
              scale={scale}
              rotate={rotate[currentPageIndex] || 0}
              flag={renderFirstTime}
              navigate={(index: number) => {
                setCurrentPage({ documentIndex: index });
              }}
              adminsShowBoundingBox={true}
              tableDisplay={tabelDisplay}
              tableProps={tableProps}
              setTableProps={setTableProps}
              projectType={documentDetails.projectType}
              classificationValues={
                documentDetails.documents[currentPage.documentIndex]?.docType1
              }
              onClickTableField={({ idx, key }) => {
                setFieldKey(key);
                setSelectedTableField({
                  idx,
                  key,
                })
              }}
              selectedTableField={selectedTableField}
              classifiedValue={
                documentDetails.documents[currentPage.documentIndex]
                  ?.qcClassificationType
              }
              isPageUnmappedFields={
                documentDetails.documents[currentPage.documentIndex]?.name ===
                "-1"
              }
              setQcClassifiedValue={(value) => {
                documentDetails.documents[
                  currentPage.documentIndex
                ].qcClassificationType = value;
                setDocumentDetails(documentDetails);
              }}
              setFieldsUpdated={(fileds: documentPageSegmentFieldsT[]) => {
                setDocumentDetails((prevState) => {
                  const segment =
                    prevState.documents[currentPage.documentIndex];
                  if (segment) {
                    const fields = segment.fields.map(
                      (field: documentPageSegmentFieldsT) =>
                        fileds.find((el) => el.key === field.key) !== undefined
                          ? fileds.find((el) => el.key === field.key)
                          : field
                    );


                    return {
                      ...prevState,
                      documents: prevState.documents.map(
                        (segment: documentPageSegmentsT, idx: number) =>
                          currentPage.documentIndex === idx
                            ? {
                              ...segment,
                              fields,
                            }
                            : segment
                      ),
                    } as documentDetailsT;
                  }
                  return prevState;
                });
              }}
              setDocumentDetails={setDocumentDetails}
              currentPageIndex={currentPageIndex}
            />
          </div>

          <div
            className={tabelDisplay ? "minimize_form_bar" : "form_bar"}
            // style={{ backgroundColor: typeOfToast === "addpage" ? "grey" : "" }}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tabs
                onChange={switchTab}
                value={value}
                aria-label="Tabs where selection follows focus"
                selectionFollowsFocus
              >
                <Tab label="Fields" />
              </Tabs>
              <BlueOnGreenTooltip
                title={!showMetaData ? "Meta data info" : ""}
                arrow
                placement="left"
              >
                <IconButton
                  sx={{ "&.MuiButtonBase-root:hover": "Meta Data Info" }}
                  onClick={() => setShowMetaData(!showMetaData)}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </BlueOnGreenTooltip>

              {showMetaData && (
                <div
                  className="meta-data"
                  style={{
                    position: "absolute",
                    width: "150px",
                    height: "auto",
                    left: "100px",
                    top: "20px",
                    backgroundColor: "#6C757D",
                    zIndex: "100",
                    borderRadius: "5px",
                    color: "white",
                  }}
                >
                  {documentDetails?.metadata &&
                    Object.keys(documentDetails.metadata).length > 0 ? (
                    Object.keys(documentDetails.metadata).map((k, index) => {
                      return (
                        <div key={index} style={{ margin: "10px" }}>
                          <span style={{ fontWeight: "bold" }}>
                            {k}
                            :{" "}
                          </span>
                          <span>
                            {documentDetails.metadata[k as string]}
                          </span>
                        </div>
                      );
                    })
                  ) : (
                    <p
                      style={{
                        color: "white",
                        marginTop: "15px",
                        textAlign: "center",
                      }}
                    >
                      No Tag is Assigned
                    </p>
                  )}
                </div>
              )}
            </Box>
            <TabPanel value={value} index={0}>
              {documentDetails.projectType !== "Classification" && (
                <FieldsContainer
                  hide={typeOfToast === "addpage" ? true : false}
                  lastPage={
                    currentPage.documentIndex ===
                      documentDetails?.documents.length - 1
                      ? true
                      : false
                  }
                  role={'analyst'}
                  setFieldKey={(key) => {
                    if (selectedTableField.key) {
                      setSelectedTableField({})
                    }
                    setFieldKey(key)
                  }}
                  triggerCheck={triggerNotValidateFieldsIndication}
                  setTriggerCheck={setTriggerNotValidateFieldsIndication}
                  setBoundingBox={setCurrentBoundingBox}
                  allFields={documentDetails.documents
                    .filter(
                      (item: any, index: number) =>
                        item.docType1 ===
                        documentDetails.documents[currentPage.documentIndex]
                          .docType1
                    )
                    .reduce((accum: documentPageSegmentFieldsT[], val: any) => {
                      return accum.concat(val.fields);
                    }, [])}
                  fieldsFromReviewState={fieldsToFieldsContainer}
                  projectFields={
                    documentDetails.documents[`${currentPage.documentIndex}`]
                      .docType1
                      ? documentDetails.docTypeProject.filter((item: any) => {
                        if (
                          item.docType ===
                          documentDetails.documents[
                            `${currentPage.documentIndex}`
                          ].docType1
                        )
                          return item;
                      })[0].fields
                      : []
                  }
                  depedency_dropdown={dependency_dropdownValues}
                  setDependencyDropdown={setDependency_dropdownValues}
                  showBoundingBox={showBoundingBox}
                  sections={documentDetails.sections}
                  handleFieldChange={handleFieldChange}
                  tableDisplay={tabelDisplay}
                  setTableProps={setTableProps}
                  setTableDisplay={setTableDisplay}
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  confirmPage={
                    documentDetails.documents[currentPage.documentIndex]
                      .confirmPage
                  }
                  setTriggerMandatory={setTriggerMandatory}
                  handleConfirmPage={handleConfirmPage}
                  completed={
                    documentDetails && documentDetails.status === 'completed'
                  }
                  completedMandatory={
                    documentDetails.documents[
                      `${currentPage.documentIndex}`
                    ].fields.filter((field: any) => {
                      if (field.type === "dropdown") {
                        if (field.mandatory) {
                          if (field.fieldValues.length === 0) {
                            if (
                              field.qcUpdatedValue === null ||
                              field.qcUpdatedValue === ""
                            ) {
                              return true;
                            }
                          } else {
                            if (
                              field.fieldValues.filter(
                                (val: any) =>
                                  val.qcUpdatedValue === null ||
                                  val.qcUpdatedValue === ""
                              ).length > 0
                            ) {
                              return true;
                            }
                          }
                        }
                      } else {
                        return (
                          field.mandatory === true &&
                          (field.qcUpdatedValue === null ||
                            field.qcUpdatedValue === "")
                        );
                      }
                    }).length > 0
                  }
                />
              )}
            </TabPanel>
          </div>
        </div>
      </div>

      <VMModal
        open={showAccessVerificationFailedModal}
        handleClose={handleAccessVerificationFailedModal}
      >
        <div
          style={{
            backgroundColor: "white",
            minWidth: "35rem",
            borderRadius: "2rem",
          }}
        >
          <div style={{ padding: "0.5rem", margin: "1rem" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Document Un-Assigned
            </Typography>
          </div>
          <hr />
          <div style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "1.5rem",
              }}
            >
              <Typography variant="h6">
                This document is assigned to another user due to inactivity
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "1rem",
              }}
            >
              <VMButton
                style={{ marginTop: "0.5rem" }}
                variant="primary"
                onClick={handleAccessVerificationFailedModal}
              >
                Next Document
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>

      <VMModal
        open={showValidationError}
      >
        <div
          style={{
            backgroundColor: "white",
            minWidth: "35rem",
            borderRadius: "2rem",
          }}
        >
          <div style={{ padding: "0.5rem", margin: "1rem" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Document Mandatory Error
            </Typography>
          </div>
          <hr />
          <div style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "1.5rem",
              }}
            >
              <Typography variant="h6">
                Fill Out the All the Mandatory Fields(with *)
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "1rem",
              }}
            >
              <VMButton
                style={{ marginTop: "0.5rem" }}
                variant="primary"
                onClick={handleShowValidationModal}
              >
                Accept
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>

      <VMModal
        open={showRotateAlert}
      >
        <div
          style={{
            backgroundColor: "white",
            minWidth: "35rem",
            borderRadius: "2rem",
          }}
        >
          <div style={{ padding: "0.5rem", margin: "1rem" }}>
            <Typography sx={{ fontSize: "16px" }}>
              Alert
            </Typography>
          </div>
          <hr />
          <div style={{ justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "1.5rem",
              }}
            >
              <Typography variant="h5">
                Existing bounding boxes will be lost on rotating.
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <VMButton
                style={{ marginTop: "0.5rem" }}
                variant="secondary"
                onClick={() => {
                  setShowRotateAlert(false);
                }}
              >
                Cancel
              </VMButton>
              <VMButton
                style={{ marginTop: "0.5rem" }}
                variant="primary"
                onClick={() => {
                  handleRotate(rotateDirection);
                  setShowRotateAlert(false);
                }}
              >
                Proceed
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>

      <div>
        {typeOfToast === "newDocument" && (
          <OptionMenu
            show={doctypeFloatToastShow}
            handleClose={() => setDocTypeFloatToastShow(false)}
            label="Document"
            header="Mark page(s) as a new Document"
            handleSubmit={handleChangeDocument}
            warn={warn}
            options={options}
            selectOption={selectOption}
            setSelectOption={setSelectOption}
          />
        )}

        {typeOfToast === "changeDocumentType" && (
          <OptionMenu
            show={doctypeFloatToastShow}
            handleClose={() => setDocTypeFloatToastShow(false)}
            label="Document"
            header="Change Document Type"
            handleSubmit={handleDocumentTypeChange}
            options={options}
            selectOption={selectOption}
            setSelectOption={setSelectOption}
          />
        )}
        {typeOfToast === "changeCase" && (
          <OptionMenu
            show={doctypeFloatToastShow}
            handleClose={() => setDocTypeFloatToastShow(false)}
            label="Case"
            header="Change Case"
            handleSubmit={handleCaseChange}
            options={Object.values(casesWithLabel)}
            selectOption={selectOption}
            setSelectOption={setSelectOption}
          />
        )}
      </div>

      <Overlay
        show={showFloatMenu}
        placement="right-end"
        target={target.current}
      >
        <ListGroup>
          <ListGroup.Item
            className="cursorPointer"
            onClick={(e) => handleMenuOption(e)}
          >
            Mark page(s) as a separate Document
          </ListGroup.Item>
          <ListGroup.Item
            className="cursorPointer"
            onClick={(e) => handleMenuOption(e)}
          >
            Disassociate page(s) and move up
          </ListGroup.Item>
          <ListGroup.Item
            className="cursorPointer"
            onClick={(e) => handleMenuOption(e)}
          >
            Disassociate page(s) and move down
          </ListGroup.Item>
          <ListGroup.Item
            className="cursorPointer"
            onClick={(e) => {
              setAction("longpress");
              setSelectMode(true);
              let a =
                documentDetails.documents[currentPage.documentIndex].documentId;
              let selection = Array.from(
                new Set(
                  documentDetails.documents
                    .filter((item: any) => item.documentId === a)
                    .map((item: any) => item.id)
                )
              );
              let pageNumber = Array.from(
                new Set(
                  documentDetails.documents
                    .filter((item: any) => item.documentId === a)
                    .map((item: any) => item.pageNumber)
                )
              );
              setPageNumberSelection(pageNumber);
              setSelectionList(selection);
            }}
            disabled={
              action !== "longpress" &&
                documentDetails.documents[currentPage.documentIndex]
                  .documentId === null
                ? true
                : false
            }
          >
            Select all pages of document
          </ListGroup.Item>
          <ListGroup.Item
            className="cursorPointer"
            onClick={(e) => handleMenuOption(e)}
          >
            Select all pages of file
          </ListGroup.Item>
          <ListGroup.Item className="cursorPointer">
            Re-order Document's pages
          </ListGroup.Item>
        </ListGroup>
      </Overlay>
    </>
  );
}

export default DocumentReview;
