import AccountBoxIcon from "@material-ui/icons/AccountBox";
import CloseFullscreenIcon from "@material-ui/icons/CloseTwoTone";
import DoneIcon from "@material-ui/icons/Done";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import InfoIcon from "@material-ui/icons/Info";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import RedoIcon from "@material-ui/icons/Redo";
import SearchIcon from "@material-ui/icons/Search";
import UndoIcon from "@material-ui/icons/Undo";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DownloadIcon from "@mui/icons-material/Download";
import GradingIcon from "@mui/icons-material/Grading";
import GroupsIcon from "@mui/icons-material/Groups";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LogoutIcon from "@mui/icons-material/Logout";
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

import { Analytics } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";

export const NextStepIcon = NavigateNextIcon;
export const BeforeStepIcon = NavigateBeforeIcon;
export const RevertBackIcon = UndoIcon;
export const RevertUpIcon = RedoIcon;
export const DragIndicator = DragIndicatorIcon;
export const PageDoneIcon = DoneIcon;
export const CloseFullScreen = CloseFullscreenIcon;
export const FileDownloadIcon = DownloadIcon;
export const InformationIcon = InfoIcon;
export const MenuOpenerIcon = MenuOpenIcon;
export const ProfileIcon = AccountBoxIcon;
export const AddIcon = AddCircleIcon;
export const DeleteIcon = DeleteOutlineIcon;
export const ZoomInDocumentIcon = ZoomInIcon;
export const ZoomOutDocumentIcon = ZoomOutIcon;
export const ZoomResetIcon = YoutubeSearchedForIcon;

export const AutoSaveIcon = SaveIcon;

// Icons for SideBar
export const ProjectIcon = LibraryBooksIcon;
export const UsersIcon = GroupsIcon;
export const BriefCaseIcon = BusinessCenterOutlinedIcon;
export const ProfileLogout = LogoutIcon;
export const OrganizationIcon = CorporateFareIcon;
export const ReportIcon = Analytics;
export const DocumentTypeIcon = DocumentScannerIcon;
//

export const Logo = GradingIcon;
export default SearchIcon;
