import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import CssTextField from "../common/CssTextField";
import SearchIcon from "../../utils/icons";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Fragment, useContext, useEffect, useState } from "react";
import { OrganizationCard } from "./OrganizationCard";
import UserContext from "../../context/userContext";
import { getOrganization } from "../../controllers/organization";
import { OrganizationT } from "./types";
import { useScrolls } from "../../hooks/useScrolls";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "../Spinner";

const OrganizationManagement = () => {
  const { authToken } = useContext(UserContext);
  const [organizations, setOrganizations] = useState<OrganizationT[]>([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { limit, page, hasMore, fetchMoreDocsOnScroll, setDocLength } =
    useScrolls(organizations.length);

  const fetchOrgs = () => {
    getOrganization(authToken, limit, page, search).then((res) => {
      setTimeout(() => {
        if (page > 1) {
          setOrganizations((prevOrgs) => [...prevOrgs, ...res.data]);
        } else {
          setOrganizations(res.data);
        }
        setIsLoading(false);
      }, 500);
      setDocLength(res.length);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchOrgs, [page, search]);

  return (
    <div
      className="d-flex flex-column px-5 h-100 w-100 py-1 my-1"
      style={{ paddingRight: "2rem !important" }}
    >
      <div className="d-flex justify-content-between my-1">
        <div className="d-flex align-items-center">
          <Typography sx={{ marginRight: "20px" }} variant="h4">
            Organization
          </Typography>
          <FormControl>
            <CssTextField
              id="searchOrganization"
              margin="none"
              style={{ width: "20rem" }}
              size="small"
              placeholder="Search Organization"
              onChange={(e: any) => {
                setSearch(e.target.value ? e.target.value : "");
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
      <hr />
      <InfiniteScroll
        className="doc-table-scroll"
        dataLength={organizations.length}
        next={fetchMoreDocsOnScroll}
        hasMore={hasMore}
        height={400}
        loader={isLoading && <Spinner />}
        endMessage={
          <div style={{ textAlign: "center" }}>
            <p style={{ color: "gray" }}>No More Organization!</p>
          </div>
        }
      >
        <Grid
          container
          gap={2}
          sx={{
            marginTop: "1rem",
          }}
          columnGap="2.5rem"
          rowGap="2rem"
          padding={2}
          columns={{ xs: 4, sm: 8, md: 12 }}
          maxHeight={`calc(100% - 64)`}
          overflow="auto"
        >
          {organizations.length ? (
            organizations.map((orgs, index) => {
              return (
                <Fragment key={index}>
                  <OrganizationCard
                    name={orgs.name}
                    description={orgs.description}
                  />
                </Fragment>
              );
            })
          ) : (
            <h5
              style={{ width: "100%", textAlign: "center", color: "#c3c3c3" }}
            >
              No records found
            </h5>
          )}
        </Grid>
      </InfiniteScroll>
    </div>
  );
};

export default OrganizationManagement;
