import CancelIcon from '@material-ui/icons/Cancel';
import { Fragment, useEffect, useState } from 'react';
import './TableContainer.css';
import TableFieldForm from './TableFieldForm';
// import { Button } from "react-bootstrap";
import { documentPageSegmentFieldsT, randomCaseIdGenerator, tablePropsT } from '../Review/index';

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer as VMTableContainer,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import { AddIcon } from '../../../utils/icons';

interface propsT extends tablePropsT {
  projectFields: documentPageSegmentFieldsT[];
  setDependencyDropdown: any;
  save: (el: documentPageSegmentFieldsT[]) => void;
  onCurrentBoundingBoxChange: (coordinates: number[]) => void
  currentBoundingBoxChange: number[];
  onClickTableField: (key: string, idx: number) => void
  selectedTableField: {
    key: string,
    idx: number
  },
  depedency_dropdown: any;
  setTableProps: any;
  error?: boolean;
}

const TableHeaderCell = withStyles((theme) => ({
  root: {
    backgroundColor: '#ccc',
  },
  '&.MuiTableCell-root': {
  }
}))(TableCell);

const TableCellForCancelButton = withStyles((theme) => ({
  root: {
    padding: '0px',
    height: '0px !important',
    width: '6.2rem'
  },
}))(TableCell);


const checkapplicable = (fieldkey: string, dropdownValue: any, fieldSetId: any, fields: any, row: number, column: number) => {
  const { value, path } = dropdownValue[fieldSetId];
  let retrieveDropdownValues: { error: boolean, dropdownValues: any, path: any } = {
    error: false,
    dropdownValues: [],
    path: []
  };

  if (path[`keys-${fieldkey}`].length) {
    const pathofValue = path[`keys-${fieldkey}`];
    let narrowDropdown: any = value;

    for (let i = 0; i < pathofValue.length; i++) {
      const currentPath = pathofValue[i];
      const fieldToUpdate = fields.find((fiel: any) => `keys-${fiel.key}` === currentPath);

      if (fieldToUpdate && fieldToUpdate.fieldValues[row].qcUpdatedValue !== "" && narrowDropdown[`keys-${fieldToUpdate.key}`]) {
        const updatedValue = narrowDropdown[`keys-${fieldToUpdate.key}`][fieldToUpdate.fieldValues[row].qcUpdatedValue];
        narrowDropdown = updatedValue;
      } else {
        retrieveDropdownValues.error = true
        retrieveDropdownValues.dropdownValues = []
        retrieveDropdownValues.path = currentPath
        break;
      }
    }

    if (typeof narrowDropdown === 'object' && narrowDropdown[`keys-${fieldkey}`] !== undefined) {
      if (typeof narrowDropdown[`keys-${fieldkey}`] === 'string') {
        retrieveDropdownValues.dropdownValues = [narrowDropdown[`keys-${fieldkey}`]];
      } else {
        retrieveDropdownValues.dropdownValues = Object.keys(narrowDropdown[`keys-${fieldkey}`]);
      }
    }
  } else {
    retrieveDropdownValues.dropdownValues = Object.keys(value[`keys-${fieldkey}`]);
  }

  return retrieveDropdownValues;
};

const TableContainer = ({
  rows,
  cols,
  fieldsInSection,
  showBoundingBox,
  tableDisplay,
  setFieldKey,
  setBoundingBox,
  getDateFromValue,
  getValueFromDate,
  setDependencyDropdown,
  setTableDisplay,
  setTableProps,
  save,
  depedency_dropdown,
  projectFields,
  onCurrentBoundingBoxChange,
  currentBoundingBoxChange,
  onClickTableField,
  selectedTableField,
}: propsT) => {
  const [fields, setFields] = useState<documentPageSegmentFieldsT[]>([]);
  const [rowList, setRowList] = useState<any>([]);
  const [inputValue, setInputValue] = useState(false);
  const [reload, setReload] = useState(false);
  const [initialValueBoundingBox, setInitialValueBoundingBox] = useState<number[]>([])
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [showSaveTableCellIcon, setShowSaveTableCellIcon] = useState(false);

  useEffect(() => { }, [fields, rows, depedency_dropdown])


  useEffect(() => {
    const orderingFieldSection = projectFields.filter(
      (ele: any) => ele.section === fieldsInSection[0].section
    );
    const indexingOrder: any = orderingFieldSection.reduce(
      (obj, item, index) => {
        return {
          ...obj,
          [item.name]: index,
        };
      },
      {}
    );

    const customSort = fieldsInSection.sort(
      (a: any, b: any) => indexingOrder[a.name] - indexingOrder[b.name]
    );
    const fieldsWithError = customSort.map((field) => {
      return { ...field, "error": false, "errorWithIndex": [] }
    })
    setReload(true);
    setFields(fieldsWithError);
    let updateRows = rows.map((_number: string) => randomCaseIdGenerator('row'))
    setRowList(updateRows);
  }, [fieldsInSection, reload, tableDisplay]);

  const addRow = () => {
    const addingExtraFields = fields.map((field: documentPageSegmentFieldsT) => {
      field.fieldValues.push({
        modalExtractedValue: '',
        qcUpdatedValue: '',
        modelConfidentScore: 0,
        isFieldUpdated: false,
        coordinates: []
      })
      return field
    }
    );
    let randomId = randomCaseIdGenerator('row')
    setFields(addingExtraFields);
    save(addingExtraFields)
    setRowList([...rowList, randomId]);
    setTableProps((prev: any) => { return { ...prev, rows: [...rowList, randomId], fieldsInSection: addingExtraFields } })
    setReload(true);
    //setShowSaveTableCellIcon(true);
  };

  const deleteRow = (index: number) => {
    let updateField = fields.map((field: any) => {
      let updateFieldValuesfield = field.fieldValues.filter((item: any, indexFieldValues: number) => indexFieldValues !== index)
      return { ...field, fieldValues: updateFieldValuesfield }

    });
    const updateRowList = rowList.filter((item: string, indexRowNumber: number) => indexRowNumber !== index - 1);
    setTableProps((prev: any) => { return { ...prev, rows: updateRowList, fieldsInSection: updateField } })
    setRowList(updateRowList);
    setFields(updateField);
    save(updateField)
    setReload(!reload)
    // setShowSaveTableCellIcon(false);
  };

  const handleCoordinatesChange = (coordinates: number[], i: number, j: number) => {
    // if()
    fields[j].fieldValues[i].coordinates = coordinates;
    setFields(fields);
  };

  useEffect(() => {
    if (selectedTableField.key) {
      const fieldIndex = fields.findIndex((field: any) => field.key === selectedTableField.key);
      if (fieldIndex !== -1) {
        console.log("cc", currentBoundingBoxChange, initialValueBoundingBox)
        if (currentBoundingBoxChange !== initialValueBoundingBox) {
          fields[fieldIndex].fieldValues[selectedTableField.idx].coordinates = currentBoundingBoxChange;
        }
      }
    }
  }, [selectedTableField.key, currentBoundingBoxChange])


  const handleFieldValueChange = (field: any) => (value: string | number[], i: number, j: number, key?: string) => {
    if (Array.isArray(value)) {
      if (key === 'coordinates') {
        fields[j].fieldValues[i].coordinates = value;
      }
    } else {
      if (fields[j]) {
        if (fields[j].fieldValues) {
          if (fields[j].fieldValues[i]) {
            fields[j].fieldValues[i].qcUpdatedValue = value;
          } else {
            fields[j].fieldValues[i] = {
              modalExtractedValue: '',
              qcUpdatedValue: value,
              modelConfidentScore: 0,
              isFieldUpdated: false,
              coordinates: [],
            };
          }
        } else {
          fields[j].fieldValues[i] = {
            modalExtractedValue: '',
            qcUpdatedValue: value,
            modelConfidentScore: 0,
            isFieldUpdated: false,
            coordinates: [],
          };
        }
      } else {
        fields[j].fieldValues[i] = {
          modalExtractedValue: '',
          qcUpdatedValue: value,
          modelConfidentScore: 0,
          isFieldUpdated: false,
          coordinates: [],
        };
      }
    }
    setFields(fields);
    // setReload(true);
  };


  const retrieveDependencyDropdownValue = (fieldkey: string, index: number) => {
    const dropdownValue = depedency_dropdown
    const field: any = projectFields.find((item) => item.key === fieldkey)
    const fieldSetId = field ? field.dependencydropdownvaluesetId : ""
    const { value, path } = fieldSetId !== "" ? dropdownValue[fieldSetId] : dropdownValue[Object.keys(dropdownValue)[0]]
    let skipValue = true
    let retrieveDropdownValues: { error: boolean, dropdownValues: any, path: any } = {
      error: false,
      dropdownValues: [],
      path: []
    };

    if (path[`keys-${fieldkey}`].length) {
      const pathofValue = path[`keys-${fieldkey}`];
      let narrowDropdown: any = value;

      for (let i = 0; i < pathofValue.length; i++) {
        const currentPath = pathofValue[i];
        const fieldToUpdate = fields.find((fiel: any) => `keys-${fiel.key}` === currentPath);


        if (fieldToUpdate && fieldToUpdate.fieldValues[index].qcUpdatedValue !== "" && narrowDropdown[`keys-${fieldToUpdate.key}`]) {
          const updatedValue = narrowDropdown[`keys-${fieldToUpdate.key}`][fieldToUpdate.fieldValues[index].qcUpdatedValue];
          narrowDropdown = updatedValue;
        } else {
          narrowDropdown = undefined
          break;
        }
      }


      if (narrowDropdown === undefined) {
        retrieveDropdownValues.error = true
        retrieveDropdownValues.dropdownValues = []
        let pathofValue = path[`keys-${fieldkey}`]
        retrieveDropdownValues.path = pathofValue[(pathofValue.length - 1)]
      }

      if (typeof narrowDropdown === 'object' && narrowDropdown[`keys-${fieldkey}`] !== undefined && skipValue) {
        if (typeof narrowDropdown[`keys-${fieldkey}`] === 'string') {
          retrieveDropdownValues.dropdownValues = [narrowDropdown[`keys-${fieldkey}`]];
        } else {
          retrieveDropdownValues.dropdownValues = Object.keys(narrowDropdown[`keys-${fieldkey}`]);
        }
      }
    }
    else {
      retrieveDropdownValues.dropdownValues = Object.keys(value[`keys-${fieldkey}`]);
    }

    if (retrieveDropdownValues.error) {
      const updateFields: any = fields.map((item) => {
        if (retrieveDropdownValues.path.includes(`keys-${item.key}`)) {
          return { ...item, "error": true, "errorWithIndex": index }
        }
        return item

      })
      setFields(updateFields)
      save(updateFields)
      retrieveDropdownValues.error = true
      retrieveDropdownValues.dropdownValues = []
      return retrieveDropdownValues

    } else {
      return retrieveDropdownValues;
    }

  }

  const addDropdownValue = (fieldkey: string, newAddedValue: string, index: number) => {
    let { path, value } = depedency_dropdown['65c08ba9e3242a1362d39dd9']
    let currentObj = value
    let pathArray: any = []

    if (path[`keys-${fieldkey}`].length) {
      const rootKeyValue: any = fields.find((field) => `keys-${field.key}` === path[`keys-${fieldkey}`][0])
      path[`keys-${fieldkey}`].map((item: string) => {
        let findValueFromField = fields.find((field) => `keys-${field.key}` === item)
        pathArray.push(item)
        pathArray.push(findValueFromField?.fieldValues[index].qcUpdatedValue)
      })

      for (const pathKey of pathArray) {
        currentObj = currentObj[pathKey];
        if (!currentObj) return; // Return if the key doesn't exist
      }

      if (currentObj.hasOwnProperty(`keys-${fieldkey}`)) {
        currentObj[`keys-${fieldkey}`][newAddedValue] = {};
      } else {
        currentObj[`keys-${fieldkey}`] = {};
        currentObj[`keys-${fieldkey}`][newAddedValue] = {};
      }
      currentObj = { ...value, [path[`keys-${fieldkey}`][0]]: { ...value[path[`keys-${fieldkey}`][0]], [rootKeyValue.fieldValues[index].qcUpdatedValue]: currentObj } }
    } else {
      let updatedCurrentObj = { ...currentObj[`keys-${fieldkey}`], [newAddedValue]: {} }
      currentObj = { ...currentObj, [`keys-${fieldkey}`]: updatedCurrentObj }
    }

    const updatedDependency = {
      ...depedency_dropdown,
      ['65c08ba9e3242a1362d39dd9']: {
        ...depedency_dropdown['65c08ba9e3242a1362d39dd9'],
        value: currentObj
      }
    };

    setDependencyDropdown(updatedDependency)


  }

  const removalChildDropdownValue = (fieldkey: string, userValue: string, row: number, column: number) => {
    const dropdownValue = depedency_dropdown
    const field: any = projectFields.find((item) => item.key === fieldkey)
    const fieldSetId = field ? field.dependencydropdownvaluesetId : ""
    const { value, path } = fieldSetId !== "" ? dropdownValue[fieldSetId] : dropdownValue[Object.keys(dropdownValue)[0]]

    let UpdatedFields = fields.map((item) => {
      if (item.key === fieldkey) {
        return { ...item, qcUpdatedValue: userValue }
      }
      return item
    })


    Object.keys(path).map((item: any) => {
      const children = path[item]
      if ((children[children.length - 1]) === `keys-${fieldkey}`) {
        const checksChildren = checkapplicable(item.split('-')[1], depedency_dropdown, fieldSetId, UpdatedFields, row, column)
        const findtheValueAvailable = UpdatedFields.find((field) => field.key === item.split("-")[1])
        if (findtheValueAvailable && findtheValueAvailable.fieldValues.length) {
          const findTheChildrenValue = UpdatedFields.filter((field) => field.key === item.split('-')[1])[0]
          if (findTheChildrenValue) {
            if (!checksChildren.dropdownValues.includes(findTheChildrenValue.fieldValues[row].qcUpdatedValue)) {
              let fieldsRemoveQcValue = UpdatedFields.map((field) => {
                if (field.key === item.split("-")[1]) {
                  if (field.fieldValues.length) {
                    field.fieldValues[row].qcUpdatedValue = ""
                    return field
                  }
                  return field
                }
                return field
              })
              setFields(fieldsRemoveQcValue)
              setReload(false)
              save(fieldsRemoveQcValue)
              handleFieldValueChange(field[column])
            }
          }
        }
      }
    })

  }




  return (
    <div
      // id="resizable"
      style={{ minWidth: `55rem`, marginTop: '0.5rem' }}
    >
      <VMTableContainer
        component={Paper}
        sx={{ minWidth: '56.2rem', minHeight: '35vh' }}
      >
        {deleteLoader ? null : (
          <Table
            sx={{ width: `${fields.length * 5 + 5} rem` }}
            onClick={() => setInputValue(false)}
          >
            <TableHead>
              <TableRow>
                {fields.map((field: documentPageSegmentFieldsT, idx: number) => (
                  <TableHeaderCell
                    key={idx}
                    align="center"
                    sx={{
                      padding: "8px"
                    }}
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    {field.name}
                    {field.mandatory && (
                      <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
                    )}
                  </TableHeaderCell>
                ))}
                {/* added for delete button for row */}
                <TableHeaderCell width={8}></TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowList.map((number: string, rowIdx: number) => (
                <TableRow key={number}>
                  {cols.map((col_number: string, colIdx: number) => (
                    <Fragment key={`${number}_${col_number}_${colIdx}`}>
                      <TableCellForCancelButton
                        key={colIdx}
                        align="center"
                      >
                        <TableFieldForm
                          rowIdx={rowIdx}
                          columnIdx={colIdx}
                          onClickTableField={(field: any) => {
                            onClickTableField(field.key, rowIdx)
                          }}
                          field={
                            fields.length > 0
                              ? fields[colIdx]
                                ? fields[colIdx].fieldValues
                                  ? fields[colIdx].fieldValues[rowIdx]
                                    ? {
                                      ...fields[colIdx],
                                      ...fields[colIdx].fieldValues[rowIdx],
                                    }
                                    : {
                                      ...fields[colIdx],
                                      modalExtractedValue: '',
                                      qcUpdatedValue: '',
                                      modelConfidentScore: 0,
                                      isFieldUpdated: false,
                                      coordinates: [],
                                    }
                                  : {
                                    ...fields[colIdx],
                                    modalExtractedValue: '',
                                    qcUpdatedValue: '',
                                    modelConfidentScore: 0,
                                    isFieldUpdated: false,
                                    coordinates: [],
                                  }
                                : {
                                  ...fields[colIdx],
                                  modalExtractedValue: '',
                                  qcUpdatedValue: '',
                                  modelConfidentScore: 0,
                                  isFieldUpdated: false,
                                  coordinates: [],
                                }
                              : {
                                ...fields[colIdx],
                                modalExtractedValue: '',
                                qcUpdatedValue: '',
                                modelConfidentScore: 0,
                                isFieldUpdated: false,
                                coordinates: [],
                              }
                          }
                          onCurrentBoundingBoxChange={(coordinates: number[]) => {
                            setInitialValueBoundingBox(coordinates)
                            onCurrentBoundingBoxChange(coordinates);
                          }
                          }
                          currentBoundingBox={currentBoundingBoxChange}
                          setBoundingBox={setBoundingBox}
                          setFieldKey={setFieldKey}
                          handleFieldChange={handleFieldValueChange(
                            fields[colIdx]
                          )}
                          getDateFromValue={getDateFromValue}
                          getValueFromDate={getValueFromDate}
                          iIndex={rowIdx}
                          jIndex={colIdx}
                          isTable={true}
                          tableDisplay={tableDisplay}
                          setTableDisplay={setTableDisplay}
                          addDropdownValue={addDropdownValue}
                          removalChildDropdownValue={removalChildDropdownValue}
                          retrieveDependencyDropdownValue={retrieveDependencyDropdownValue}
                          setInputValue={setInputValue}
                          inputValue={inputValue}
                          dropdownValues={projectFields}
                        />
                      </TableCellForCancelButton>
                    </Fragment>
                  ))}
                  <TableCellForCancelButton width={1}>
                    <CancelIcon
                      style={{ color: 'red', margin: '0 8px', cursor: 'pointer' }}
                      onClick={() => deleteRow(rowIdx)}
                    />
                  </TableCellForCancelButton>
                  {/* {showSaveTableCellIcon && (
                <TableCellForCancelButton width={1}>
                  <PageDoneIcon
                    style={{
                      color: 'green',
                      margin: '0 8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setInputValue(false);
                      setShowSaveTableCellIcon(false);
                    }}
                  />
                </TableCellForCancelButton>
              )} */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </VMTableContainer>
      <div>
        <div>
          <IconButton onClick={addRow}>
            <AddIcon style={{ color: '#69bec6' }} />
          </IconButton>
          {/* <span style={{ flexGrow: 1, textAlign: "left" }}>
          </span> */}
        </div>
        <div>
          {/* <VMButton
            variant="primary"
            // style={{ background: "#0052C", borderColor: "#0052CC" }}
            onClick={() => {
              save(fields);
              setTableDisplay(false);
              setShowSaveTableCellIcon(false);
            }}
          >
            Save
          </VMButton> */}
        </div>
      </div>
    </div>
  );
};
export default TableContainer;
