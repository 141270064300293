import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useEffect, useRef, useState } from "react";
import Dropdown from "react-dropdown";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "../shared/css/table.css";
import "./index.css";

import CancelIcon from "@material-ui/icons/Cancel";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Alert,
  Autocomplete,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Table as VMTable,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import SearchIcon from "../../utils/icons";
import VMButton from "../common/Button";
import VMModal from "../common/Modal";

import moment from "moment";
import UserContext from "../../context/userContext";
import { verifyDocument } from "../../controllers/documents";
import {
  createProject,
  deleteProject,
  exportProject,
  getMetadataAvailable,
  projectActiveUserList,
  projectList,
  updateProject,
} from "../../controllers/projects";
import { toastify } from "../common/notification";
import { DropDownMenu } from "../shared/DropDown";

import { MenuItem } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import sortSvg from "../../assets/icons/sort.svg";
import { tokenData } from "../../controllers/users";
import { useScrolls } from "../../hooks/useScrolls";
import { Spinner } from "../Spinner";
import ViewSampleInputDrawer from "./ViewSampleInput";

const copyToClipboard = (api: string) => {
  const el = document.createElement("textarea");
  el.value = api;
  document.body.appendChild(el);
  el.select();
  var copied = document.execCommand("copy");
  document.body.removeChild(el);
  return copied;
};

const useStyles = makeStyles((theme: any) => ({
  createButton: {
    backgroundColor: "#69bec6",
    "& .hover": {
      backgroundColor: "#46adb6",
    },
  },
  table: {
    backgroundColor: theme.palette.common.white,
  },
  root: {
    background: "red",
  },
  inputLabel: {
    borderColor: "#ccc !important",
  },
  flex: {
    display: "flex",
  },
}));

const CssTextField = withStyles({
  root: {
    "&.Mui-focused": {
      color: "black",
    },
  },
})(OutlinedInput);

interface projectForm {
  name: string;
  description: string;
  status: string;
  tagSection: boolean;
  showBoundingBox: boolean;
  projectType: string;
  sections: any[];
  classificationValues: string[];
  assignedTo: any[];
  fields: any[];
  organization: string;
  document: any[];
}

interface IMetaDataAssignment {
  _id?: string;
  key: string;
  property: string;
  values: string[];
  users: Object[];
  valueError: string;
  userError: string;
  allocationUsers: string;
}

const ProjectManagement = () => {
  const classes = useStyles();

  const history = useHistory();

  const { authToken, role, organizationIds } = useContext(UserContext);

  const [data, setData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [active_user, setActive_user] = useState<any>({});
  const [isDelete, setIsDelete] = useState(false);
  const [metadataAssign, setMetadataAssign] = useState(false);
  const [recordId, setRecordId] = useState("");
  const [userOrgs, setUserOrgs] = useState<any[]>([]);
  const [orgId, setOrgId] = useState("");
  const [isImportProject, setIsImportProject] = useState(false);
  const [projectForm, setProjectForm] = useState<projectForm>({
    name: "",
    description: "",
    status: "active",
    tagSection: false,
    showBoundingBox: false,
    projectType: "classification",
    sections: [],
    classificationValues: [""],
    assignedTo: [],
    fields: [],
    organization: "",
    document: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const [selectedProject, setSelectedProject] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<any>(null);
  const [fileName, setFileName] = useState<string>("");
  const [viewSampleInput, setViewSampleInput] = useState(false);
  const [availableMetadataValues, setAvailableMetadataValues] = useState<{
    [key: string]: string[];
  }>({});
  const [usedMetadataValues, setUsedMetadataValues] = useState<string[]>([]);
  const [usedMetdataUsers, setUsedMetadataUsers] = useState<string[]>([]);
  const [metadataProperty, setMetadataProperty] = useState("");
  const [targetMetadataProperty, setTargetMetadataProperty] = useState("");
  const [projectUsers, setProjectUsers] = useState<any>([]);
  const [initialMetadata, setInitialMetadata] = useState<IMetaDataAssignment[]>(
    []
  );
  const [metadataAssignment, setMetadataAssignment] =
    useState<IMetaDataAssignment[]>(initialMetadata);
  const [alertOpen, setAlertOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState({
    open: false,
    message: "",
  });
  const [render, setRender] = useState(1);

  const {
    limit,
    page,
    sortBy,
    hasMore,
    sortDocuments,
    fetchMoreDocsOnScroll,
    setDocLength,
  } = useScrolls(data.length);

  const exportProjectDownload = (projectId: string) => {
    exportProject(authToken, projectId)
      .then((response) => {
        const jsonData = JSON.stringify(response.data);
        const blob = new Blob([jsonData], { type: "application/json" });

        const downloadLink = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadLink;
        link.download = `${response.data.name}.json`;
        link.click();

        // Clean up
        URL.revokeObjectURL(downloadLink);
      })
      .catch((err) => console.log(err));
  };

  const handleMenu = (action: string, record: any) => {
    switch (action) {
      case "view_sample_request":
        setViewSampleInput(true);
        break;
      case "view_pendoc":
        history.push(`/projects/${record._id}/documents/pending`);
        break;
      case "edit":
        history.push(`/projects/edit/${record._id}`);
        break;
      case "delete":
        setIsDelete(true);
        setRecordId(record._id);
        break;
      case "export_project":
        exportProjectDownload(record._id);
        break;
      case "metadata_assign":
        setRecordId(record._id);
        handleGetAvailableMetadataForProject(record);
        break;
      case "view_reference_file":
        let findProject = data.find((item) => item._id === record._id);
        findProject?.isReferenceFileAvailable
          ? window.open(
              `projects/reference-file/${record._id}`,
              "_blank",
              "noopener,noreferrer"
            )
          : toastify("failure", "Reference file does not exist");
        break;
      default:
        break;
    }
  };
  const handleDelete = () => {
    deleteProject(authToken, recordId)
      .then((_res) => {
        toastify("success", "Project deleted successfully");
        projectList(authToken, limit, page, sortBy, orgId).then((res) => {
          setData(res.data);
          setIsDelete(false);
        });
      })
      .catch((err) => {
        toastify("failure", "Project Deletion failed");
        console.log(err);
      });
  };

  const handleDocumentRedirect = (projectId: string, queue_type?: string) => {
    verifyDocument(authToken, projectId, "true", queue_type)
      .then((res) => {
        if (res && res.data !== "undefined") {
          let url = `/projects/${projectId}/document/${res.data}/pendingdoc`;
          if (queue_type) {
            url += `?queue_type=${queue_type}`;
          }
          history.push(url);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toastify("success", "No documents available now");
      });
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setFileName(file.name);
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const jsonData = JSON.parse(e.target.result);
      setProjectForm((data: any) => ({
        ...data,
        tagSection: jsonData.tagSection,
        showBoundingBox: jsonData.showBoundingBox,
        projectType: jsonData.projectType,
        sections: jsonData.sections,
        classificationValues: jsonData.classificationValues,
        assignedTo: [],
        fields: jsonData.fields,
        document: jsonData.document,
      }));
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    role !== "superadmin" &&
      userOrgs.length &&
      projectList(authToken, limit, page, sortBy, userOrgs[0].value).then(
        (res) => {
          setTimeout(() => {
            if (page > 1) {
              setData((prevData) => [...prevData, ...res.data]);
            } else {
              setData(res.data);
            }
            setIsLoading(false);
          }, 500);

          setDocLength(res.length);
          projectActiveUserList(
            authToken,
            res.data.map((project: any) => project._id)
          ).then((res) => {
            setActive_user(res.data);
          });
        }
      );
  }, [authToken, limit, page, role, setDocLength, sortBy, userOrgs]);

  useEffect(() => {
    tokenData(authToken)
      .then((res) => {
        let data = res.user.organizationIds;
        let organizationForDropdown: any[] = [];
        data.forEach((item: any) => {
          organizationForDropdown.push({ value: item._id, label: item.name });
        });
        setUserOrgs(organizationForDropdown);
        setOrgId(organizationForDropdown[0].value);
      })
      .catch((err) => console.log(err));
  }, [authToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (data.length !== 0) {
        projectActiveUserList(
          authToken,
          data.map((project: any) => project._id)
        ).then((res) => {
          setActive_user(res.data);
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [authToken]);

  const handleCreateProject = () => {
    createProject(authToken, projectForm)
      .then((res) => {
        setIsImportProject(false);
        toastify("success", "Project created Successfully");
        history.go(0);
      })
      .catch((error) => {
        toastify("failure", "Project Creation Fails");
        console.log("error", error);
      });
  };

  const handleGetAvailableMetadataForProject = async (record: any) => {
    getMetadataAvailable(authToken, record._id).then((res) => {
      const userOptions = record.assignedTo.filter(
        (user: any) => user.role === "analyst"
      );

      setProjectUsers(() => {
        return [...userOptions, "other users", "any user"];
      });

      const data = res.data;

      for (let key in data) {
        data[key].push("Other values");
      }

      setAvailableMetadataValues(data);

      const initialMetadata: IMetaDataAssignment[] =
        record.metadata_assignment || [];
      const metadataProperty = record.metadataProperty;

      render &&
        initialMetadata.map((curr: IMetaDataAssignment) => {
          curr.key = generateRandomString();

          let allocationUsers =
            curr.allocationUsers === "anyone"
              ? "any user"
              : curr.allocationUsers === "others" && "other users";
          curr.values.length === 0 && curr.values.push("Other values");

          const mappedUsers = curr.users.map((userId: any) =>
            record.assignedTo.find((item: any) => item._id === userId)
          );

          if (curr.users.length && typeof curr.users[0] === "string") {
            curr.users = [...mappedUsers];
          }

          allocationUsers && curr.users.push(allocationUsers);

          console.log("curr.users", curr.users);

          curr.valueError = "";
          curr.userError = "";
          delete curr._id;
        });

      initialMetadata && setMetadataAssignment(initialMetadata);
      handleUsedMetadataValues(initialMetadata);
      handleUsedUsers(initialMetadata);
      initialMetadata.length > 0 && setMetadataProperty(metadataProperty);

      setMetadataAssign(true);
      setRender(0);
    });
  };

  const handleMetadataAssignmentClose = () => {
    setMetadataAssign(false);
    setAlertOpen(false);
    setErrorOpen({
      open: false,
      message: "",
    });
  };

  const handleAllocateUserForProject = () => {
    let doc = data.find((curr) => curr._id === recordId);
    let temp = metadataAssignment;

    if (validate()) {
      temp.map((curr) => {
        if (curr.allocationUsers.length > 0) {
          curr.users = [];
        }
        if (curr.values.includes("Other values")) {
          curr.values = [];
        }
      });

      doc.metadata_assignment = temp;
      doc.metadataProperty = metadataProperty;

      updateProject(authToken, recordId, doc).then((res) => {
        toastify("success", "User allocation updated successfully");
        setRender(1);
      });

      handleMetadataAssignmentClose();
    }
  };

  const handleUsedMetadataValues = (
    metadata_assignment: IMetaDataAssignment[]
  ) => {
    const usedValues: string[] = [];

    metadata_assignment.forEach((curr: IMetaDataAssignment) => {
      curr.values.map((val: string) => {
        if (!usedValues.includes(val)) {
          usedValues.push(val);
        }
      });
    });

    setUsedMetadataValues(usedValues);
  };

  const handleUsedUsers = (metadata_assignment: IMetaDataAssignment[]) => {
    const usedValues: string[] = [];

    metadata_assignment.forEach((curr: IMetaDataAssignment) => {
      curr.users.map((user: any) => {
        if (typeof user === "object" && !usedValues.includes(user._id)) {
          usedValues.push(user._id);
        }
      });
    });

    setUsedMetadataUsers(usedValues);
  };

  const handleMetaAssignmentValuesChange = (
    event: any,
    newValue: any,
    key: string
  ) => {
    setMetadataAssignment((prevMetadataAssignment) => {
      const duplicate = [...prevMetadataAssignment];
      const index = duplicate.findIndex((el) => el.key === key);
      const updatedObject = {
        ...duplicate[index],
        values: newValue.includes("Other values") ? ["Other values"] : newValue,
      };
      duplicate[index] = updatedObject;

      handleUsedMetadataValues(duplicate);

      return duplicate;
    });
  };

  const handleMetaAssignmentUsersChange = (
    event: any,
    newValue: any,
    key: string
  ) => {
    setMetadataAssignment((prevMetadataAssignment) => {
      const duplicate = [...prevMetadataAssignment];
      const index = duplicate.findIndex((el) => el.key === key);

      const conditions = ["other users", "any user"];

      const includes = newValue.find(
        (el: object | string) =>
          typeof el === "string" && conditions.includes(el)
      );

      const updatedObject = {
        ...duplicate[index],
        users: includes ? [includes] : newValue,
        allocationUsers: includes
          ? includes === "any user"
            ? "anyone"
            : "others"
          : "",
      };
      duplicate[index] = updatedObject;

      handleUsedUsers(duplicate);

      return duplicate;
    });
  };

  const generateRandomString = () => {
    let password = "";
    let LENGTH = 20;
    let CHARACTER = "aakash8302";

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < LENGTH; i++) {
      password += CHARACTER.charAt(
        Math.floor(Math.random() * CHARACTER.length)
      );
    }

    return password;
  };

  const validate = () => {
    let flag = 1;
    let checkOtherMapPresent = 0;
    let checkExplicitMapPresent = 0;
    let existingValues: any[] = [];

    let temp = metadataAssignment;

    //initialze
    temp.forEach((curr: IMetaDataAssignment) => {
      curr.userError = "";
      curr.valueError = "";
    });

    temp.forEach((curr: IMetaDataAssignment) => {
      if (
        (curr.users.includes("any user") ||
          curr.users.includes("other users")) &&
        temp.length === 1
      ) {
        curr.valueError = "innappropriate rule";
        curr.userError = "innappropriate rule";
        flag = 0;
      }

      if (
        !curr.users.includes("any user") &&
        !curr.users.includes("other users")
      ) {
        checkExplicitMapPresent = 1;
      }

      curr.values.map((val: string) => {
        if (!existingValues.includes(val)) {
          existingValues.push(val);
        } else {
          curr.valueError = "Values repeated";
          curr.userError = "Check Values";
          flag = 0;
        }
      });

      if (curr.values.includes("Other values")) {
        checkOtherMapPresent = checkOtherMapPresent + 1;
      }

      if (curr.users.includes("other users")) {
        if (projectUsers.length === usedMetdataUsers.length + 2) {
          curr.userError = "All users already assigned";
          flag = 0;
        }
      }

      if (curr.values.length === 0 || curr.users.length === 0) {
        !curr.values.length && (curr.valueError = "Value cannot be empty");
        !curr.users.length && (curr.userError = "Users cannot be empty");
        flag = 0;
      }
    });

    setMetadataAssignment((prevMetadataAssignment) => {
      let duplicate = [...prevMetadataAssignment];
      return duplicate;
    });

    if (checkOtherMapPresent && temp.length === 1) {
      temp[0].valueError = "This cannot be the only rule";
      temp[0].userError = "This cannot be the only rule";
      return 0;
    }

    if (!checkOtherMapPresent) {
      setErrorOpen({
        open: true,
        message: `The Rule for 'Other values' is mandatory`,
      });
      return 0;
    }

    if (!checkExplicitMapPresent) {
      setErrorOpen({
        open: true,
        message:
          "Need at least one explicit user assignment before 'Other users' can be used.",
      });

      metadataAssignment.forEach((curr: IMetaDataAssignment) => {
        curr.userError = " ";
      });

      return 0;
    }

    return flag;
  };

  const isOptionSelected = (val: string) => {
    if (usedMetadataValues.includes(val)) {
      return true;
    }
    return false;
  };

  const tableActions = [
    { name: "View Pending Documents", value: "view_pendoc" },
    { name: "Sample Push Request", value: "view_sample_request" },
    { name: "Edit", value: "edit" },
    { name: "Delete", value: "delete" },
    { name: "Export Project", value: "export_project" },
    { name: "View Reference File", value: "view_reference_file" },
    { name: "Users Allocation", value: "metadata_assign" },
  ];

  const tableHeader = [
    {
      key: "sno",
      title: "S.No",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "name",
      title: "Name",
      width: 100,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "pending",
      title: "Total Pending",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "allocated",
      title: "Allocated",
      width: 50,
      AUTH: ["analyst"],
    },
    {
      key: "review",
      title: "In Review",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "completed",
      title: "Completed",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "organization",
      title: "Organization",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "project_users",
      title: "Project Users",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "active_users",
      title: "Active Users",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    {
      key: "created_at",
      title: "Created",
      width: 50,
      AUTH: ["org_admin", "lead", "analyst"],
    },
    { key: "action", title: "Action", width: 50, AUTH: ["org_admin", "lead"] },
  ];

  return (
    <div className="d-flex flex-column h-100">
      <div className="hed-row d-flex justify-content-between py-1 my-1">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "27rem" }}
        >
          <Typography variant="h4">Projects</Typography>
          <FormControl>
            <CssTextField
              id="searchProject"
              margin="none"
              style={{ width: "20rem" }}
              size="small"
              placeholder="Search Projects"
              onChange={(e: any) => {
                setSearch(e.target.value || "");
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="d-flex justify-content-right align-items-center">
          <div
            className="form_element_cp"
            style={{ width: "100%", marginRight: "1rem" }}
          > 
            <FormControl
              style={{width: '15ch'}}
            >
              <InputLabel id="org">Organization</InputLabel>
              <Select
                labelId="org"
                label='Organization'
                value={orgId}
                onChange={(e: SelectChangeEvent)=>{
                  setOrgId(e.target.value)
                }}
              >
                  {
                    userOrgs.map((userOrg) => (
                      <MenuItem value={userOrg.value}>{userOrg.label}</MenuItem>
                    ))
                  }
              </Select>
            </FormControl>
          </div>
          {role !== "analyst" && (
            <>
              <VMButton
                variant="primary"
                onClick={() => {
                  setIsImportProject(true);
                }}
              >
                Import Project
              </VMButton>
              <VMButton
                variant="primary"
                style={{ marginLeft: "0.5rem" }}
                onClick={() => {
                  history.push("/projects/new");
                }}
              >
                Create Project
              </VMButton>
            </>
          )}
        </div>
      </div>
      <hr />
      <div className="my-2 table-wrapper">
        <InfiniteScroll
          className="doc-table-scroll"
          dataLength={data.length}
          next={fetchMoreDocsOnScroll}
          hasMore={hasMore}
          height={400}
          loader={isLoading && <Spinner />}
          endMessage={
            <div style={{ textAlign: "center" }}>
              <p style={{ color: "gray" }}>No More Documents!</p>
            </div>
          }
        >
          <TableContainer component={Paper}>
            <VMTable sx={{ minWidth: "72rem" }}>
              <TableHead>
                <TableRow>
                  {tableHeader.map((item) =>
                    item.AUTH.includes(role) ? (
                      item.key === "created_date" ? (
                        <TableCell
                          key={item.key}
                          sx={{ width: item.width, cursor: "pointer" }}
                          onClick={() => sortDocuments()}
                          align="center"
                        >
                          {item.title}
                          <img src={sortSvg} alt="sort-icon" width={20} />
                        </TableCell>
                      ) : (
                        <TableCell
                          key={item.key}
                          sx={{ width: item.width }}
                          align="center"
                        >
                          {item.title}
                        </TableCell>
                      )
                    ) : (
                      <></>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .filter((doc) => {
                    if (search.length >= 1) {
                      return doc.name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    } else if (orgId.length >= 1) {
                      return orgId.includes(doc.organizationId._id.toString());
                    } else return doc.name;
                  })
                  .map((doc, index: number) => (
                    <TableRow key={doc._id} className="p1">
                      {role === "admin" ? (
                        <TableCell
                          scope="row"
                          onClick={() => {
                            copyToClipboard(
                              `${process.env.REACT_APP_NODE_URL}/api/v2/documents/create/${doc._id}`
                            );
                          }}
                          align="center"
                        ></TableCell>
                      ) : (
                        <TableCell align="center">{index + 1}</TableCell>
                      )}
                      <TableCell align="center">{doc.name}</TableCell>
                      {/* <td className="active">{doc.status}</td> */}
                      <TableCell
                        style={{
                          cursor: role === "lead" ? "pointer" : "default",
                        }}
                        align="center"
                        onClick={() => {
                          if (role === "lead") {
                            setSelectedProject(doc._id);
                            setLoading(true);
                            handleDocumentRedirect(doc._id);
                          }

                          // if (role === "analyst") {
                          //   // request document for review
                          //   handleDocumentRedirect(doc._id);
                          // } else {
                          //   // history.push(`/projects/${doc._id}/documents/pending`);
                          //   handleDocumentRedirect(doc._id);
                          // }
                        }}
                      >
                        {selectedProject === doc._id
                          ? loading && <Spinner />
                          : doc.pending}
                      </TableCell>
                      {role === "analyst" && (
                        <TableCell
                          align="center"
                          style={{
                            cursor: role === "analyst" ? "pointer" : "default",
                          }}
                          onClick={() => {
                            if (doc.allocated !== "0") {
                              setSelectedProject(doc._id);
                              setLoading(true);
                              handleDocumentRedirect(doc._id, "allocated");
                            }
                          }}
                        >
                          {selectedProject === doc._id
                            ? loading && <Spinner />
                            : doc.allocated}
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        style={{
                          cursor: role !== "org_admin" ? "pointer" : "default",
                        }}
                        onClick={() => {
                          if (role !== "org_admin" || doc.inReview !== 0) {
                            setSelectedProject(doc._id);
                            setLoading(true);
                            history.push(
                              `/projects/${doc._id}/documents/review`
                            );
                          }
                        }}
                      >
                        {doc.inReview}
                      </TableCell>
                      <TableCell
                        style={{
                          cursor: "pointer",
                        }}
                        align="center"
                        onClick={() => {
                          history.push(
                            `/projects/${doc._id}/documents/completed`
                          );
                        }}
                      >
                        {doc.completed}
                      </TableCell>
                      <TableCell align="center">
                        {doc.organizationId.name[0].toUpperCase() +
                          doc.organizationId.name.slice(1)}
                      </TableCell>
                      <TableCell align="center">
                        {doc.assignedTo.length}
                        <img
                          src="/Icons/info-circle-solid.svg"
                          className="info"
                          alt="options"
                          data-tip={doc.assignedTo

                            .map((element: any, key: number) => {
                              const template = `${key + 1}. ${
                                element.display_name
                              } (${element.role}) <br>`;
                              return template;
                            })
                            .join()}
                          data-html="true"
                          data-for="users-list"
                        />
                        <ReactTooltip
                          id="users-list"
                          place="top"
                          effect="solid"
                        ></ReactTooltip>
                      </TableCell>
                      <TableCell align="center">
                        {active_user[doc._id] ? active_user[doc._id] : "0"}
                      </TableCell>
                      <TableCell align="center">
                        {moment(doc.createdAt).format("L")}
                      </TableCell>
                      {role !== "analyst" && (
                        <TableCell align="center">
                          <DropDownMenu
                            options={
                              role !== "org_admin"
                                ? tableActions
                                : tableActions.filter(
                                    (item) =>
                                      item.name !== "View Pending Document"
                                  )
                            }
                            record={doc}
                            handleMenu={handleMenu}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </VMTable>
          </TableContainer>
        </InfiniteScroll>
        {isDelete && (
          <VMModal open={isDelete} handleClose={() => setIsDelete(false)}>
            <div
              style={{
                backgroundColor: "white",
                minWidth: "35rem",
                borderRadius: "2rem",
              }}
            >
              <div style={{ padding: "0.5rem", margin: "1rem" }}>
                <h4>Delete User</h4>
              </div>
              <hr />
              <div
                className={classes.flex}
                style={{ justifyContent: "center" }}
              >
                <h5>Are you sure want to delete?</h5>
              </div>
              <div
                className={classes.flex}
                style={{ margin: "1rem", justifyContent: "space-around" }}
              >
                <div>
                  <VMButton onClick={() => setIsDelete(false)}>Cancel</VMButton>
                </div>
                <div>
                  <VMButton variant="primary" onClick={handleDelete}>
                    Delete
                  </VMButton>
                </div>
              </div>
            </div>
          </VMModal>
        )}
        {isImportProject && (
          <VMModal
            open={isImportProject}
            handleClose={() => setIsImportProject(false)}
          >
            <div
              style={{
                backgroundColor: "white",
                minWidth: "35rem",
                borderRadius: "2rem",
              }}
            >
              <div style={{ padding: "0.5rem", margin: "1rem" }}>
                <h4>Import Project</h4>
              </div>
              <hr />
              <div
                className={classes.flex}
                style={{ justifyContent: "center", flexDirection: "column" }}
              >
                <div className="form_element_ip">
                  <label>Name</label>
                  <input
                    className="form-control"
                    value={projectForm.name}
                    onChange={(event) =>
                      setProjectForm((data: any) => ({
                        ...data,
                        name: event.target.value,
                      }))
                    }
                    placeholder="Enter project Name"
                  />
                </div>
                <div
                  className=" form_element_ip"
                  style={{ marginTop: "-0.5rem" }}
                >
                  <label>Description</label>
                  <textarea
                    rows={4}
                    style={{ marginLeft: "8px" }}
                    className="form-control"
                    value={projectForm.description}
                    onChange={(event) =>
                      setProjectForm((data: any) => ({
                        ...data,
                        description: event.target.value,
                      }))
                    }
                    placeholder="Enter the Project description"
                  />
                </div>
                <div
                  className="form_element_ip"
                  style={{ marginTop: "-0.5rem" }}
                >
                  <label>Organization</label>
                  <Dropdown
                    options={userOrgs}
                    value={projectForm.organization}
                    onChange={(event) => {
                      setProjectForm((data: any) => ({
                        ...data,
                        organization: event.value,
                      }));
                    }}
                    placeholder="Select organization"
                  />
                </div>
                <div
                  className="upload_cont"
                  style={{
                    border: 0,
                    margin: "15px auto",
                    width: "75%",
                    padding: 0,
                  }}
                >
                  {fileName === "" ? (
                    <>
                      <input
                        type="file"
                        id="file-input"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <button
                        className="btn btn-success"
                        onClick={handleFileClick}
                      >
                        Upload the Project File
                      </button>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6>{fileName}</h6>
                      <CancelIcon
                        style={{
                          // margin: "0 8px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setFileName("");
                          fileInputRef.current = null;
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                className={classes.flex}
                style={{ margin: "1rem", justifyContent: "space-around" }}
              >
                <div>
                  <VMButton onClick={() => setIsImportProject(false)}>
                    Cancel
                  </VMButton>
                </div>
                <div>
                  <VMButton variant="primary" onClick={handleCreateProject}>
                    CreateProject
                  </VMButton>
                </div>
              </div>
            </div>
          </VMModal>
        )}
        <VMModal
          open={metadataAssign}
          handleClose={handleMetadataAssignmentClose}
        >
          <div
            style={{
              backgroundColor: "white",
              minWidth: "35rem",
              borderRadius: "2rem",
              padding: "0 2rem",
            }}
          >
            <div style={{ margin: "1rem 0 0 0" }}>
              <h4>Users Allocation</h4>
              <p>Rules for allocating pending documents to QC Analysts</p>
            </div>
            <hr />
            <div style={{ justifyContent: "center" }}>
              {Object.keys(availableMetadataValues).length > 0 ? (
                <div>
                  <Collapse in={alertOpen}>
                    <Alert
                      severity="error"
                      action={
                        <>
                          <IconButton
                            color="success"
                            size="small"
                            onClick={() => {
                              setMetadataProperty(targetMetadataProperty);
                              setUsedMetadataValues([]);
                              setMetadataAssignment((prev) => {
                                let newObj = prev.filter(
                                  (item) => item.property !== metadataProperty
                                );
                                newObj.push({
                                  key: generateRandomString(),
                                  property: targetMetadataProperty,
                                  values: [],
                                  users: [],
                                  valueError: "",
                                  userError: "",
                                  allocationUsers: "",
                                });
                                return newObj;
                              });
                              setAlertOpen(false);
                            }}
                          >
                            <CheckIcon color="success" />
                          </IconButton>
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              setAlertOpen(false);
                            }}
                          >
                            <CloseIcon color="error" />
                          </IconButton>
                        </>
                      }
                    >
                      Your Previously set rules will be deleted
                    </Alert>
                  </Collapse>
                  <FormControl fullWidth sx={{ margin: "1rem 0" }}>
                    <InputLabel>Basis File Attribute</InputLabel>
                    <Select
                      label="Basis File Attribute"
                      onChange={(e: SelectChangeEvent) => {
                        if (metadataProperty.length) {
                          setAlertOpen(true);
                          setTargetMetadataProperty(e.target.value);
                        } else {
                          setMetadataProperty(e.target.value);
                        }
                      }}
                      value={metadataProperty}
                    >
                      {Object.keys(availableMetadataValues).map(
                        (curr, index) => (
                          <MenuItem key={index} value={curr}>
                            {curr}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </div>
              ) : (
                <div>No Metadata Exists for the files of this Project</div>
              )}
              {metadataProperty &&
                projectUsers.length &&
                Object.keys(availableMetadataValues).length > 0 && (
                  <>
                    <Collapse in={errorOpen.open}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            color="error"
                            size="small"
                            onClick={() => {
                              setErrorOpen({
                                open: false,
                                message: "",
                              });
                            }}
                          >
                            <CloseIcon color="inherit" />
                          </IconButton>
                        }
                      >
                        {errorOpen.message}
                      </Alert>
                    </Collapse>
                    <TableContainer
                      component={Paper}
                      elevation={3}
                      sx={{ margin: "1rem 0", maxHeight: "400px" }}
                    >
                      <VMTable>
                        <TableHead>
                          <TableRow sx={{ textAlign: "center" }}>
                            <TableCell align="center">Value</TableCell>
                            <TableCell align="center">Users</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody sx={{ overflowY: "auto" }}>
                          {metadataAssignment
                            .filter(
                              (item) => item.property === metadataProperty
                            )
                            .map((curr) => (
                              <TableRow key={curr.key}>
                                <TableCell>
                                  <Autocomplete
                                    style={{ maxHeight: 100, overflow: "auto" }}
                                    ListboxProps={{
                                      style: {
                                        maxHeight: 200,
                                        overflow: "auto",
                                      },
                                    }}
                                    key={curr.key}
                                    multiple
                                    options={availableMetadataValues[
                                      metadataProperty
                                    ].filter((values) =>
                                      curr.values.length &&
                                      curr.values[0] !== "Other values"
                                        ? values !== "Other values"
                                        : values
                                    )}
                                    getOptionLabel={(option) => option}
                                    getOptionDisabled={(option) =>
                                      isOptionSelected(option)
                                    }
                                    value={curr.values}
                                    disableClearable={true}
                                    onChange={(event, newValue) =>
                                      handleMetaAssignmentValuesChange(
                                        event,
                                        newValue,
                                        curr.key
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Values"
                                        {...(curr.valueError.length
                                          ? {
                                              error: curr.valueError.length
                                                ? true
                                                : false,
                                              helperText: curr.valueError,
                                            }
                                          : false)}
                                      />
                                    )}
                                    sx={{ width: "12rem" }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Autocomplete
                                    ListboxProps={{
                                      style: {
                                        maxHeight: 200,
                                        overflow: "auto",
                                      },
                                    }}
                                    key={curr.key}
                                    multiple
                                    options={projectUsers}
                                    getOptionLabel={(option: any) => {
                                      if (typeof option === "string") {
                                        return option;
                                      } else if (typeof option === "object") {
                                        return `${option.display_name}`;
                                      }
                                      return "";
                                    }}
                                    value={curr.users}
                                    disableClearable={true}
                                    onChange={(event, newValue) =>
                                      handleMetaAssignmentUsersChange(
                                        event,
                                        newValue,
                                        curr.key
                                      )
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Users"
                                        {...(curr.userError.length
                                          ? {
                                              error: curr.userError.length
                                                ? true
                                                : false,
                                              helperText: curr.userError,
                                            }
                                          : false)}
                                      />
                                    )}
                                    sx={{ width: "20rem" }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton
                                    onClick={() => {
                                      let index = metadataAssignment.findIndex(
                                        (item) => item.key === curr.key
                                      );
                                      let newData = [
                                        ...metadataAssignment.slice(0, index),
                                        ...metadataAssignment.slice(index + 1),
                                      ];
                                      setMetadataAssignment(newData);
                                      handleUsedMetadataValues(newData);
                                      handleUsedUsers(newData);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </VMTable>
                    </TableContainer>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          setMetadataAssignment([
                            ...metadataAssignment,
                            {
                              key: generateRandomString(),
                              property: metadataProperty,
                              values: [],
                              users: [],
                              valueError: "",
                              userError: "",
                              allocationUsers: "",
                            },
                          ])
                        }
                      >
                        <AddBoxIcon />
                      </IconButton>
                    </div>
                  </>
                )}
            </div>
            <div
              className={classes.flex}
              style={{ margin: "1rem", justifyContent: "space-around" }}
            >
              <div>
                <VMButton onClick={handleMetadataAssignmentClose}>
                  Cancel
                </VMButton>
              </div>
              <div>
                <VMButton
                  variant="primary"
                  onClick={handleAllocateUserForProject}
                >
                  Apply
                </VMButton>
              </div>
            </div>
          </div>
        </VMModal>
        {viewSampleInput && (
          <ViewSampleInputDrawer
            openSidebar={viewSampleInput}
            onClose={setViewSampleInput}
          />
        )}
      </div>
    </div>
  );
};
export default ProjectManagement;
