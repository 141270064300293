import axios from "./axiosConfig";

const CLIENT_URL = process.env.REACT_APP_NODE_URL + "/api/v3";

export const projectFieldAverage = (
    token: string,
     projectId: string, 
     startingRange: string, 
     endingRange: string) =>
axios
  .get(`${CLIENT_URL}/accuracy/field-average`, {
    headers: {
      authorization: `bearer ${token}`,
    },
    params:{
        projectId: projectId,
        startRange: startingRange,
        endRange: endingRange
      }
  })
  .then((res) => res.data);

export const dateAccuracy = (
    token: string,
    projectId: string,
    startingRange: string,
    endingRange: string,
   
     ) =>
     
axios
  .get(`${CLIENT_URL}/accuracy/date-accuracy`, {
    headers: {
      authorization: `bearer ${token}`,
    },
    params:{
       projectId: projectId,
        startRange: startingRange,
        endRange: endingRange,
        
      }
  })
  .then((res) => res.data);


  export const fieldAccuracy = (
    token: string,
     projectId: string, 
     startingRange: string, 
     endingRange: string) =>
axios
  .get(`${CLIENT_URL}/accuracy/field-accuracy`, {
    headers: {
      authorization: `bearer ${token}`,
    },
    params:{
        projectId: projectId,
        startRange: startingRange,
        endRange: endingRange
      }
  })
  .then((res) => res.data);


  export const Accuracy = (
    token: string,
     projectId: string, 
     startingRange: string, 
     endingRange: string) =>
axios
  .get(`${CLIENT_URL}/accuracy/accuracy-logs`, {
    headers: {
      authorization: `bearer ${token}`,
    },
    params:{
        projectId: projectId,
        startRange: startingRange,
        endRange: endingRange
      }
  })
  .then((res) => res.data);


  export const CompletionReport = (
    token: string,
     projectId: string, 
     startingRange: string, 
     endingRange: string) =>
axios
  .get(`${CLIENT_URL}/file/project/completion`, {
    headers: {
      authorization: `bearer ${token}`,
    },
    params:{
        projectId: projectId,
        startRange: startingRange,
        endRange: endingRange
      }
  })
  .then((res) => res.data);