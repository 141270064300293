import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
import SampleInputAccordion from "./Accordian";

type Anchor = "top" | "left" | "bottom" | "right";

export default function ViewSampleInputDrawer({
  openSidebar,
  onClose,
}: {
  openSidebar: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [state, setState] = React.useState({
    right: openSidebar,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
      onClose(!openSidebar);
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        padding: "20px",
        width: anchor === "top" || anchor === "bottom" ? "auto" : 750,
      }}
      role="presentation"
    >
      <SampleInputAccordion />
    </Box>
  );

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <SwipeableDrawer
            sx={{
              "&.MuiPaper-root": {
                width: "500px",
              },
            }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
