import axios from "./axiosConfig";

const CLIENT_URL = process.env.REACT_APP_NODE_URL + "/api/v3";

export const getPendingFiles = (
    token: string,
    projectId: string,) =>
    axios
        .get(`${CLIENT_URL}/file/pending/${projectId}`, {
            headers: {
                authorization: `bearer ${token}`,
            },
            params: {
                projectId: projectId,
            }
        })
        .then((res) => res.data);


export const completedFileList = (
    token: string,
    project_id: string,
    limit: number,
    page: number,
    sortBy: string,
    filterUserId: string[]
) =>
    axios
        .get(
            `${CLIENT_URL}/file/completed/${project_id}/?limit=${limit}&page=${page}&sort=${sortBy}&users=${filterUserId.join(",")}`,
            {
                headers: {
                    authorization: `bearer ${token}`,
                },
            }
        )
        .then((res) => res.data);

export const completedUserList = (
    token:string,
    projectId:string
) => 
    axios
        .get(`${CLIENT_URL}/file/completed/${projectId}/users`,
        {
            headers: {
                authorization: `bearer ${token}`,
            },
        }
        )
        .then((res) => res.data);

export const reviewFileList = (
    token: string,
    project_id: string,
    limit: number,
    page: number,
    sortBy: string
) =>
    axios
        .get(
            `${CLIENT_URL}/file/inReview/${project_id}/?limit=${limit}&page=${page}&sort=${sortBy}`,
            {
                headers: {
                    authorization: `bearer ${token}`,
                },
            }
        )
        .then((res) => res.data);

export const changeFileStatus = (
    token: string,
    docId: string,
    projectId: string,
    status: string
) =>
    axios
        .put(
            `${CLIENT_URL}/file/change-status`,
            { document: docId, project: projectId, status },
            {
                headers: {
                    authorization: `bearer ${token}`,
                },
            }
        )
        .then((res) => res.data);

export const changeDocProcessingStatus = (
    token: string,
    docId: string,
    projectId: string,
    status: Boolean
) =>
    axios
        .patch(
            `${CLIENT_URL}/file/change-processing-state/${projectId}/${docId}/${status}`,
            {},
            {
                headers: {
                    authorization: `bearer ${token}`,
                },
            }
        )
        .then((res) => res.data);



export const markReview = (token: string, data: any) =>
    axios
        .put(
            `${CLIENT_URL}/file/mark-for-review`,
            { data },
            {
                headers: {
                    authorization: `bearer ${token}`,
                },
            }
        )
        .then((res) => res.data);

export const changeDocStatus = (
    token: string,
    docId: string,
    projectId: string,
    status: string
) =>
    axios
        .put(
            `${CLIENT_URL}/file/change-status`,
            { document: docId, project: projectId, status },
            {
                headers: {
                    authorization: `bearer ${token}`,
                },
            }
        )
        .then((res) => res.data)



export const addActivity = (token: string, documentId: string, data: any) =>
    axios.post(`${CLIENT_URL}/file/document-activity/${documentId}`, data, {
        headers: {
            authorization: `bearer ${token}`,
        },
    })
        .then((res) => res.data);

export const getCompletionLogs = (token: string, params = {}) => {
    return axios
        .get(`${CLIENT_URL}/documents/project/completion`, {
            headers: {
                authorization: `bearer ${token}`,
            },
            params,
        })
        .then((res) => res.data);
};


export const changePriority = (
    token: string,
    document: string,
    priority: boolean
) =>
    axios
        .post(
            `${CLIENT_URL}/file/prioritize-document`,
            { document, priority },
            {
                headers: {
                    authorization: `bearer ${token}`,
                },
            }
        )
        .then((res) => res.data);


export const getDeletionFile = (token: string, document: String) =>
    axios
        .delete(`${CLIENT_URL}/file/${document}`, {
            headers: {
                authorization: `bearer ${token}`,
            },
        })
        .then((res) => res.data);