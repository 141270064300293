import {
  BriefCaseIcon,
  DocumentTypeIcon,
  OrganizationIcon,
  ProjectIcon,
  ReportIcon,
  UsersIcon,
} from '../../../utils/icons';

const sidebarItems: any = [
  {
    label: 'Projects',
    value: 'projects',
    route: 'projects',
    image: 'ProjectIcon',
    alt: 'projects icon',
    width: '20px',
    users: ['org_admin', 'lead', 'analyst'],
    icon: <ProjectIcon />,
  },
  {
    label: 'User Management',
    value: 'userManagement',
    route: 'users',
    image: 'UsersIcon',
    alt: 'users icon',
    width: '26px',
    users: ['superadmin', 'org_admin', 'lead'],
    icon: <UsersIcon />,
  },
  {
    label: 'DocumentType',
    value: 'documentType',
    route: 'document-type',
    image: 'DocumentTypeIcon',
    alt: 'documentType icon',
    width: '26px',
    users: ['superadmin', 'org_admin'],
    icon: <DocumentTypeIcon />,
  },
  {
    label: 'Reports',
    value: 'reports',
    route: 'report',
    image: 'BriefCaseIcon',
    alt: 'reports icon',
    width: '20px',
    users: ['org_admin', 'lead', 'analyst'],
    icon: <BriefCaseIcon />,
  },
  {
    label: 'Organizations',
    value: 'organizations',
    route: 'organizations',
    image: 'OrganizationIcon',
    alt: 'organization icon',
    width: '20px',
    users: ['superadmin'],
    icon: <OrganizationIcon />,
  },
  {
    label: 'Analytics',
    value: 'Analytics',
    route: 'Analytics',
    image: 'ReportIcon',
    alt: 'analytics icon',
    width: '20px',
    users: ['org_admin', 'lead'],
    icon: <ReportIcon />,
  },
];

export default sidebarItems;
