import React, { useEffect, useState } from "react";

import { IconButton, Tooltip, Zoom } from "@material-ui/core";

// import { Button, Form, Dropdown } from "react-bootstrap";

import Draggable from "react-draggable";

import "./index.css";

import {
  BeforeStepIcon,
  CloseFullScreen,
  DragIndicator,
  NextStepIcon,
  RevertBackIcon,
  RevertUpIcon,
} from "../../../utils/icons";

import TableContainer from "../FormContainer/TableContainer";
import ImageCanvas from "./ImageCanvas";

export interface projectDocumentPageSegmentFieldsT {
  _id: string;
  key: string;
  name: string;
  type: string;
  dropDownValues: any[];
  section: string;
  mandatory: boolean;
}

type Props = {
  boundingBox: number[];
  currentIndex: number;
  lastIndex: number;
  currentImageUrl: string;
  showBoundingBox: boolean;
  fieldKey: string;
  tableDisplay: boolean;
  tableProps: any;
  setTableProps: any;
  adminsShowBoundingBox: boolean;
  projectType: string;
  classificationValues: string[];
  classifiedValue: string;
  isPageUnmappedFields: boolean;
  depedency_dropdown: any;
  updateBoundingBox: (fieldKey: string, arr: number[]) => void;
  toggleBoundingBox: (value: boolean) => void;
  setTableDisplay: (value: boolean) => void;
  navigate: (index: number) => void;
  setQcClassifiedValue: (value: string) => void;
  setFieldsUpdated: (el: any) => void;
  projectFields: projectDocumentPageSegmentFieldsT[];
  hide: boolean;
  handleUndo: any;
  scale: number;
  rotate: number;
  setDependencyDropdown: any;
  flag: boolean;
  onCurrentBoundingBoxChange: (coordinates: number[]) => void;
  onClickTableField: ({
    key,
    idx,
  }: {
    key: string;
    idx: number;
  }) => void;
  selectedTableField: { key?: string, idx?: number };
  setDocumentDetails: (value: any) => void
  currentPageIndex: number
};

const ImageContainer = ({
  lastIndex,
  currentIndex,
  currentImageUrl,
  boundingBox,
  showBoundingBox,
  fieldKey,
  tableDisplay,
  setTableDisplay,
  tableProps,
  setTableProps,
  adminsShowBoundingBox,
  depedency_dropdown,
  projectType,
  classificationValues,
  classifiedValue,
  isPageUnmappedFields,
  updateBoundingBox,
  toggleBoundingBox,
  navigate,
  setQcClassifiedValue,
  setFieldsUpdated,
  hide,
  projectFields,
  handleUndo,
  scale,
  rotate,
  flag,
  onCurrentBoundingBoxChange,
  onClickTableField,
  selectedTableField,
  setDependencyDropdown,
}: Props) => {
  const [classificationType, setClassificationType] = useState("");
  const [_boundingBox, _setBoundingBox] = useState<number[]>([]);

  useEffect(() => {
    setClassificationType(classifiedValue);
    console.log("table", tableProps)
  }, [classifiedValue, tableProps]);

  useEffect(() => { }, [currentImageUrl]);


  const [initialPos, setInitialPos] = React.useState<any>(null);
  const [initialSize, setInitialSize] = React.useState<any>(null);
  const initial = (e: any) => {
    let resizable: any = document.getElementById("resizable");
    setInitialPos(e.screenY);
    setInitialSize(resizable.offsetHeight);
  };

  const resize = (e: any) => {
    let resizable: any = document.getElementById("resizable");
    const value: any = e.clientY - initialPos;
    resizable.style.height = `${parseInt(initialSize) + parseInt(value)}px`;
  };

  return (
    <>
      <Draggable
        defaultPosition={tableDisplay ? { x: 0, y: 200 } : { x: -250, y: 430 }}
        defaultClassName={tableDisplay ? "minimiseToolbarposition" : ""}
      >
        <div
          className="nav-btn-group d-flex justify-content-between"
          style={
            tableDisplay
              ? {
                position: "absolute",
                top: "20%",
                zIndex: "15",
                backgroundColor: "black",
                borderRadius: "30px",
                height: "30vh",
                opacity: "0.8",
                display: "flex",
                flexDirection: "column",
              }
              : {
                position: "absolute",
                top: "20%",
                // left: "25%",
                zIndex: "15",
                backgroundColor: "black",
                borderRadius: "30px",
                height: "6vh",
                opacity: "0.8",
                // transform: "translate(-50%, -50%)",
              }
          }
        >
          {/* <Button
          variant="secondary"
          disabled={currentIndex === 0}
          className="button mx-3 my-0 prev-btn"
          onClick={() => navigate(currentIndex - 1)}
          data-cy-qc-screen-prev-btn
        >
          {"Prev"}
        </Button> */}
          <Tooltip title="Previous Page" TransitionComponent={Zoom} arrow>
            <IconButton
              color="primary"
              aria-label="upload picture"
              className="iconColor"
              style={{ color: "white", padding: "4px" }}
              onClick={() => {
                navigate(currentIndex - 1);
                setTableDisplay(false);
              }}
              disabled={currentIndex === 0 ? true : false}
            >
              <BeforeStepIcon
                style={{ color: currentIndex === 0 ? "grey" : "" }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Undo Changes" TransitionComponent={Zoom} arrow>
            <IconButton
              disabled={true}
              color="primary"
              aria-label="upload picture"
              className="iconColor"
              style={{ color: "grey" }}
              onClick={() => handleUndo()}
            >
              <RevertBackIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Redo Changes" TransitionComponent={Zoom} arrow>
            <IconButton
              disabled={true}
              color="primary"
              aria-label="upload picture"
              className="iconColor"
              style={{ color: "grey" }}
            >
              <RevertUpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next" TransitionComponent={Zoom} arrow>
            <IconButton
              color="primary"
              aria-label="upload picture"
              className="iconColor"
              style={{ color: "white" }}
              onClick={() => {
                navigate(currentIndex + 1);
                setTableDisplay(false);
              }}
              disabled={lastIndex === currentIndex ? true : false}
            >
              <NextStepIcon
                style={{ color: lastIndex === currentIndex ? "grey" : "" }}
              />
            </IconButton>
          </Tooltip>

          <span style={{ flexGrow: 1 }} />
          {/* <Button
          variant="secondary"
          disabled={currentIndex === lastIndex}
          className="button mx-3 my-0 next-btn"
          onClick={() => navigate(currentIndex + 1)}
          data-cy-qc-screen-next-btn
        >
          {NavigateNextIcon}
        </Button> */}
          {/* <i>{NextStepIcon}</i> */}
          {tableDisplay && (
            <Tooltip title="Exit FullScreen" TransitionComponent={Zoom} arrow>
              <IconButton
                color="primary"
                className="iconColor"
                style={{ color: "white" }}
                onClick={() => setTableDisplay(false)}
              >
                <CloseFullScreen />
              </IconButton>
            </Tooltip>
          )}

          <IconButton
            color="primary"
            aria-label="upload picture"
            className="iconColor"
            style={{ color: "white" }}
          >
            <DragIndicator />
          </IconButton>
        </div>
      </Draggable>
      <div
        id="image-container"
        className="p-2"
        style={{ opacity: hide ? "0.3" : "" }}
      >
        {!isPageUnmappedFields ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignSelf: "center",
                justifyContent: " space-between",
                width: "100vw",
              }}
            >
              {/* {projectType == "classification_and_extraction" ? */}

              {/* <Form>
              <Form.Check
                type="switch"
                id="boundingbox-switch"
                label="Show Boundingbox"
                checked={showBoundingBox}
                // disabled={!adminsShowBoundingBox}
                // title={
                //   adminsShowBoundingBox
                //     ? ""
                //     : "Admin has disabled show bounding box option"
                // }
                className={"user-select-none"}
                onChange={() => {
                  toggleBoundingBox(!showBoundingBox);
                }}
              />
            </Form> */}

              {/* ):""} */}
              {/* <Button onClick={toggleZoomOption}>Enable Zoom</Button> */}

              {projectType !== "extraction" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                    margin: "0 1rem 0 0",
                  }}
                >
                  {classificationValues && (
                    <>
                      <span id="classification-type-text">Document Type:</span>
                      <h4>{classificationValues}</h4>
                    </>
                  )}

                  {/* <Dropdown style={{ margin: 'auto', paddingLeft: '8px' }}>
                                    <Dropdown.Toggle
                                        variant='outline-primary'
                                        className='d-flex justify-content-between align-items-center'>
                                        {classificationType
                                            ? classificationType
                                            : classificationValues.length
                                            ? classificationValues[0]
                                            : 'Others'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {classificationValues.map((value, idx) => (
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setQcClassifiedValue(value);
                                                    setClassificationType(value);
                                                }}
                                                key={idx}>
                                                {value}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown> */}
                </div>
              ) : (
                ""
              )}
            </div>

            <Draggable disabled axis="y" defaultPosition={{ x: -21, y: 200 }}>
              <div
                style={
                  tableDisplay
                    ? {
                      position: "absolute",
                      zIndex: "10",
                      minWidth: "62%",
                      maxWidth: "62%",
                      top: "30%",
                      left: "15.2%",
                      backgroundColor: "#eee",
                      maxHeight: "44vh",
                    }
                    : {
                      position: "absolute",
                      zIndex: "10",
                      width: "50%",
                      maxWidth: "50%",
                      top: "50%",
                      left: "26%",
                      backgroundColor: "lightgrey",
                      maxHeight: "30vh",
                    }
                }
              >
                {/* {tableDisplay && (
                  <div>
                    <TableContainer
                      {...tableProps}
                      save={setFieldsUpdated}
                      projectFields={projectFields}
                      onCurrentBoundingBoxChange={(coordinates) => {
                        _setBoundingBox(coordinates);
                        onCurrentBoundingBoxChange(coordinates);
                      }}
                      currentBoundingBoxChange={_boundingBox}
                      onClickTableField={(key: string, idx: number) => {
                        onClickTableField({
                          key,
                          idx,
                        })
                      }}
                      selectedTableField={selectedTableField}
                    />
                  </div>
                )} */}
              </div>
            </Draggable>

            {/* <div
                  id="draggable"
                  draggable="true"
                  onDragStart={initial}
                  onDrag={resize}
                ></div> */}

            <div
              style={{
                flexDirection: tableDisplay ? "column" : "row",
              }}
              className="image-canvas-container"
            >
              <div id="image-canvas" className={tableDisplay ? scale === 1 ? "image-canvas" : "zoom-canvas" : undefined}>
                <ImageCanvas
                  imageUrl={`https://pa-verifyme.rapidautomation.ai/files/${currentImageUrl}`}
                  fieldKey={fieldKey}
                  boundingBox={boundingBox}
                  updateBoundingBox={(fieldKey, boundingBox, tableField) => {
                    _setBoundingBox(boundingBox);
                    updateBoundingBox(fieldKey, boundingBox);
                  }}
                  showBoundingBox={showBoundingBox}
                  scale={scale}
                  rotate={rotate}
                  flag={flag}
                  tableField={selectedTableField}
                  tableDisplay={tableDisplay}
                />
              </div>
              {tableDisplay && (
                <div style={{ position: "relative", zIndex: "100", bottom: 0, backgroundColor: "white" }}>
                  <TableContainer
                    {...tableProps}
                    setTableProps={setTableProps}
                    save={setFieldsUpdated}
                    projectFields={projectFields}
                    depedency_dropdown={depedency_dropdown}
                    setDependencyDropdown={setDependencyDropdown}
                    onCurrentBoundingBoxChange={(coordinates) => {
                      _setBoundingBox(coordinates);
                      onCurrentBoundingBoxChange(coordinates);
                    }}
                    currentBoundingBoxChange={_boundingBox}
                    onClickTableField={(key: string, idx: number) => {
                      onClickTableField({
                        key,
                        idx,
                      })
                    }}
                    selectedTableField={selectedTableField}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <span style={{ flexGrow: 1 }} />
        )}
      </div>
    </>
  );
};
export default ImageContainer;
