import React from "react";
// import { Image } from "react-bootstrap";

import "../css/loader.css";

const Loader = () => {
  return (
    <div className={"container holder loader-bg"}>
      <div className={"loader"}>
        {/* <Image src={"Icons/document.svg"} /> */}
      </div>
    </div>
  );
};

export default Loader;
