import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';

export const DropDownMenu = ({
  options,
  handleMenu,
  record,
}: {
  options: any;
  handleMenu: Function;
  record: object;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: any) => {
    setAnchorEl(null);
    handleMenu(value, record);
  };

  return (
    <div className={'m-0'}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={'m-0 p-1'}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '30ch',
          },
        }}
      >
        {options.map((option: any, idx: number) => (
          <MenuItem
            key={idx}
            selected={option === 'Pyxis'}
            value={options.value}
            onClick={() => {
              handleClose(option.value);
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
