import 'bootstrap/dist/css/bootstrap.min.css';
import '../../shared/css/table.css';

import {
  FormControl,
  InputAdornment,
  OutlinedInput,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table as VMTable,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import SearchIcon from '../../../utils/icons';
import VMButton from '../../common/Button';
import VMModal from '../../common/Modal';

import { useContext, useEffect, useState } from 'react';
import { getPendingFiles } from '../../../controllers/file';

// import { Table } from "reactstrap";

import UserContext from '../../../context/userContext';
import {
  changePriority,
  getActiveUsers,
  pendingDocumentList,
} from '../../../controllers/documents';

import { getDeletionFile } from '../../../controllers/file';

import { useHistory, useParams } from 'react-router';
import { toastify } from '../../common/notification';
import { DropDownMenu } from '../../shared/DropDown';

import moment from 'moment';

const CssTextField = withStyles({
  root: {
    '&.Mui-focused': {
      color: 'black',
    },
  },
})(OutlinedInput);

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
  },
}));

const PendingQueue = () => {
  const { authToken } = useContext(UserContext);

  const { project_id }: { project_id: string } = useParams();
  const classes = useStyles();

  const history = useHistory();
  const [documents, setDocuments] = useState([]);
  const [search, setSearch] = useState('');
  const [priorityChange, setPriorityChange] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [recordId, setRecordId] = useState('');

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setPriorityChange(false);
    getPendingFiles(authToken, project_id)
      .then((res: any) => {
        const docs = res;
        const docIds = res.map((el: any) => el._id);
        const data = {
          documents: docIds,
        };
        getActiveUsers(authToken, data).then((response) => {
          const docsInfo = docs.map((doc: any) => {
            const docUser = response.data.find(
              (el: any) => el.document === doc._id
            );
            if (docUser) {
              return {
                ...doc,
                active_user: docUser.user ? docUser.user : null,
              };
            }
            return { ...doc, active_user: null };
          });
          console.log('docsInfo::', docsInfo);
          setDocuments(docsInfo);
        });
        // setDocuments(docs);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [priorityChange]);

  // const tableActions = [
  //   {name:"View Document",value: "view_doc"},
  // { name: "Low Priority", value: "low" },
  // { name: "High", value: "high_priority" },
  // ];

  const handleMenu = (action: string, record: any) => {
    switch (action) {
      case 'view_doc':
        history.push(
          `/projects/${project_id}/document/review/${record._id}/view`
        );
        break;
      case 'low':
        changePriority(authToken, record._id, false)
          .then((res) => {
            if (res.success) {
              setPriorityChange(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case 'high':
        changePriority(authToken, record._id, true).then((res: any) => {
          if (res.success) {
            setPriorityChange(true);
          }
        });
        break;
      case 'del_doc':
        setIsDelete(true);
        setRecordId(record._id);
        break;
      default:
        break;
    }
  };
  const handleDelete = () => {
    getDeletionFile(authToken, recordId)
      .then((_res) => {
        toastify('success', 'Document deleted successfully');
        pendingDocumentList(authToken, project_id).then((res) => {
          setDocuments(res.data);
          setIsDelete(false);
        });
        // fectchUsers()
      })
      .catch((err) => {
        toastify('failure', 'Document Deletion failed');
        console.log(err);
      });
  };

  const tableHeader = [
    { key: 'sno', title: 'S.No', width: 50 },
    { key: 'document_name', title: 'Document Name', width: 100 },
    { key: 'priority', title: 'Priority', width: 50 },
    { key: 'active_users', title: 'Active Users', width: 50 },
    { key: 'created_at', title: 'Created At', width: 50 },
    { key: 'action', title: 'Action', width: 50 },
  ];

  return (
    <div className="d-flex flex-column h-100">
      <div className="hed-row d-flex justify-content-between py-1 my-1">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: '35rem' }}
        >
          <Typography variant="h4">Pending Documents</Typography>
          <FormControl>
            <CssTextField
              id="searchProject"
              margin="none"
              style={{ width: '20rem' }}
              size="small"
              placeholder="Search Document"
              onChange={(e: any) => {
                setSearch(e.target.value ? e.target.value : '');
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        {/* <h3 className="my-2 mx-3">Pending Documents</h3>
        <div className="d-flex justify-content-right">
          <input
            className="form-control"
            placeholder="Search Document"
            onChange={(e: any) => {
              setSearch(e.target.value ? e.target.value : "");
            }}
          />
        </div> */}
      </div>
      <hr />
      <div className="my-2 table-wrapper">
        <TableContainer component={Paper}>
          <VMTable sx={{ minWidth: '72rem' }}>
            <TableHead>
              <TableRow>
                {tableHeader.map((item) => (
                  <TableCell
                    key={item.key}
                    sx={{ width: item.width }}
                    align="center"
                  >
                    {item.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {documents
                .filter((doc: any) => {
                  return search.length >= 1
                    ? doc.name.toLowerCase().includes(search.toLowerCase())
                    : true;
                })
                .map((doc: any, key) => (
                  <TableRow key={key}>
                    <TableCell align="center">{key + 1}</TableCell>
                    <TableCell align="center">{doc.name || '-'}</TableCell>
                    <TableCell align="center">
                      {doc.priority ? <h6>High</h6> : <h6>Default</h6>}
                    </TableCell>
                    <TableCell align="center">
                      {doc.active_user ? doc.active_user.name : ' - '}
                    </TableCell>
                    <TableCell align="center">
                      {moment(doc.createdAt).format('LLL')}
                    </TableCell>
                    <TableCell align="center">
                      <DropDownMenu
                        options={
                          doc.priority
                            ? [
                              { name: 'View Document', value: 'view_doc' },
                              {
                                name: 'Change priority to low',
                                value: 'low',
                              },
                              // { name: 'Delete Document', value: 'del_doc' },
                            ]
                            : [
                              { name: 'View Document', value: 'view_doc' },
                              {
                                name: 'Change priority to high',
                                value: 'high',
                              },
                              // { name: 'Delete Document ', value: 'del_doc' },
                            ]
                        }
                        record={doc}
                        handleMenu={handleMenu}
                      />
                    </TableCell>
                    {/* <td>{doc.markedForReviewBy?.name || "-"}</td>
                  <td>{doc.reviewComment || "-"}</td>
                  <td>{moment(doc.markedForReviewOn).format("L") || "-"}</td> */}
                    {/* <td>
                    <img src="/Icons/chat.svg" alt="comments" width="20px" />
                  </td> */}
                  </TableRow>
                ))}
            </TableBody>
          </VMTable>
        </TableContainer>
      </div>
      {/* {isDelete ? (
        <ReactModal isOpen={isDelete} toggle={() => setIsDelete(false)} className="w-100 d-flex justify-content-center" contentClassName="mw-30" style={{ maxWidth: '30rem' }}>
          <Modal.Body className="py-4 overflow-hidden">
            <h5>Are you sure you want to delete document</h5>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button onClick={() => setIsDelete(false)} className="cancelbtn " variant="secondary">Cancel</Button>
            <Button onClick={handleDelete} className="deletebtn btn-primary">Delete</Button>
          </Modal.Footer>
        </ReactModal>
      ) : ""} */}
      {isDelete ? (
        <VMModal open={isDelete} handleClose={() => setIsDelete(false)}>
          <div
            style={{
              backgroundColor: 'white',
              minWidth: '35rem',
              borderRadius: '2rem',
            }}
          >
            <div style={{ padding: '0.5rem', margin: '1rem' }}>
              <h4>Delete User</h4>
            </div>
            <hr />
            <div className={classes.flex} style={{ justifyContent: 'center' }}>
              <h5>Are you sure want to delete?</h5>
            </div>
            <div
              className={classes.flex}
              style={{ margin: '1rem', justifyContent: 'space-around' }}
            >
              <div>
                <VMButton onClick={() => setIsDelete(false)}>Cancel</VMButton>
              </div>
              <div>
                <VMButton variant="primary" onClick={handleDelete}>
                  Delete
                </VMButton>
              </div>
            </div>
          </div>
        </VMModal>
      ) : (
        ''
      )}
    </div>
  );
};

export default PendingQueue;
