import {
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Table,
    Paper,
    IconButton,
    TableFooter,
    TableContainer as VMTableContainer,
    TablePagination
} from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VMButton from '../../common/Button';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles({
    paginationContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    displayedRows: {
        marginTop: "0 !important",
        marginBottom: " 0 !important",
    }
});

function CustomTablePagination(props: any) {
    const classes = useStyles();

    return (
        <TablePagination
            component="div"
            classes={{ root: classes.paginationContainer, displayedRows: classes.displayedRows }}
            {...props}
        />
    );
}


const ExcelView = ({ header, data }: any) => {
    const [mouseHoverCell, setMouseHoverCell] = useState({
        "showCopyIcon": false,
        "index": -1,
        "cellColumn": ""
    });
    const [page, setPage] = useState(0);
    const [totalDataLength, setTotalDataLength] = useState(0)
    const [dataWithoutSlice, setDataWithoutSlice] = useState([]);
    const [dataWithSlice, setDataWithSlice] = useState([]);


    useEffect(() => {
        setPage(0)
        setDataWithoutSlice(data);
        setTotalDataLength(data.length - 1)
        let startRow = page * 50;
        let copyData: any = Array.from(data);
        setDataWithSlice(copyData.splice(startRow, 50))
    }, [data])

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        let startRow = newPage * 50
        let copyData = Array.from(dataWithoutSlice);
        let spliceData = copyData.splice(startRow, 50)
        setDataWithSlice(spliceData)
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    return (
        <div>
            <VMTableContainer
                component={Paper}
                sx={{ overflow: "scroll", height: '70vh', border: '1px solid #cccc' }}
                onMouseLeave={() => setMouseHoverCell({
                    "showCopyIcon": false,
                    "index": -1,
                    "cellColumn": ""
                })}
            >
                <Table
                    size="small"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell align='center' sx={{ borderRight: '1px solid #ccc' }}>S.No</TableCell>
                            {header.map((item: string) => {
                                return (
                                    <TableCell align='center' sx={{ borderRight: '1px solid #ccc' }}>{item}</TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataWithSlice.map((rowData: any, index: number) => {
                            return <TableRow>
                                <TableCell sx={{ borderRight: '1px solid #ccc' }} align='center'>{index + 1}</TableCell>
                                {header.map((item: string) => {
                                    return (
                                        <>
                                            <TableCell sx={{ borderRight: '1px solid #ccc' }} onMouseEnter={() => setMouseHoverCell({
                                                showCopyIcon: true,
                                                index: index,
                                                cellColumn: item
                                            })}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div>
                                                        {mouseHoverCell.index === index && mouseHoverCell.showCopyIcon && mouseHoverCell.cellColumn === item && rowData[item] ? (
                                                            <IconButton onClick={(e) => navigator.clipboard.writeText(rowData[item])}>
                                                                <ContentCopyIcon fontSize='small' />
                                                            </IconButton>
                                                        ) : ""}
                                                    </div>
                                                    <div style={{ marginLeft: '0.2rem' }}>
                                                        {rowData[item] ? rowData[item] : "-"}
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </>
                                    )
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </VMTableContainer>
            <CustomTablePagination
                component="div"
                count={totalDataLength}
                rowsPerPage={50}
                page={page}
                rowsPerPageOptions={[]}
                onPageChange={handleChangePage}
            />
        </div>
    )
}


export default ExcelView;