import { createContext } from "react";

export type userContextState = {
  name: string;
  email: string;
  role: string;
  authToken: string;
  organizationIds:any;
  setUser: Function;
};

const contextDefaultValues: userContextState = {
  name: "",
  email: "",
  role: "",
  authToken: "",
  organizationIds:[],
  setUser: () => {},
};

const UserContext = createContext<userContextState>(contextDefaultValues);

export default UserContext;
