import { SetStateAction, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as XLSX from 'xlsx';

import Dropdown from 'react-dropdown';
import { useDropzone } from 'react-dropzone';

import Autocomplete from '@mui/material/Autocomplete';

import CancelIcon from '@material-ui/icons/Cancel';

import { DeleteIcon, PageDoneIcon } from '../../../utils/icons';

import {
  Checkbox,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import MenuItem from '@material-ui/core/MenuItem';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '../../../utils/icons';

import { useScrolls } from '../../../hooks/useScrolls';
import VMButton from '../../common/Button';
import CssTextField from '../../common/CssTextField';
import VMModal from '../../common/Modal';
import { toastify } from '../../common/notification';

import './index.css';

import UserContext from '../../../context/userContext';
import {
  assignUsersToProject,
  createProject,
  docTypeList,
  getProject,
  updateProject,
} from '../../../controllers/projects';
import { tokenData, userList } from '../../../controllers/users';

interface projectForm {
  name: string;
  description: string;
  status: string;
  tagSection: boolean;
  showBoundingBox: boolean;
  //organizationId: string;
  projectType: string;
  sections: any[];
  classificationValues: string[];
  assignedTo: any[];
  fields: any[];
  organization: string;
}

const useStyle = makeStyles((theme: any) => ({
  chipStyling: {
    backgroundColor: '#ccc',
    borderRadius: '50px',
  },
  chipStylingActive: {
    backgroundColor: '#69bec6',
    borderRadius: '50px',
    // border: '5px solid #69bec6',
  },
  dropDown: {
    width: '7rem',
    zIndex: '800',
  },
}));

const readExcelFile = (file: any, callback: any) => {
  if (!(file instanceof Blob)) {
    console.error('Invalid file object');
    return;
  }

  const reader = new FileReader();

  reader.onload = (e: any) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
    const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

    callback(jsonData);
  };

  reader.readAsArrayBuffer(file);
};


function CreateProject() {
  const history = useHistory();

  const { projectId }: { projectId: string } = useParams();
  const { authToken } = useContext(UserContext);
  const [docType, setDocType] = useState<any>([]);

  const classes = useStyle();

  const [currentTag, setCurrentTag] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  const [currentSection, setCurrentSection] = useState('');
  const [currentDropdownValue, setCurrentDropdownValue] = useState('');
  const [sections, setSections] = useState<object[]>([]);

  const [isClassificationType, toggleClassificationTypeModal] = useState(false);
  const [isSection, toggleSection] = useState(false);
  const [users, setUsers] = useState([]);
  const [userOrgs, setUserOrgs] = useState<string[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<string[]>([]);
  const [isAssignUsers, toggleAssignUsers] = useState(false);
  const [fileName, setFileName] = useState('');
  const [fileNameDropdown, setFileNameDropdown] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [fieldKeys, setFieldKeys] = useState([]);
  // const [isDropdown, toggleDropDown] = useState(false);
  const [currentField, setCurrentField] = useState(0);
  const [searchUsers, setsearchUsers] = useState('');
  const [organization, setOrganization] = useState<any>([]);
  const [duplicateAssignedUsers, setDuplicateAssignedUsers] = useState<any>([]);
  const [fixedAssignUsers, setFixedAssgnUsers] = useState<any>([]);

  const [addingField, setAddingField] = useState<any>({
    key: '',
    name: '',
    type: '',
    section: '',
    mandatory: false,
    isAIBuildIn: false,
  });
  const [showFieldAddRow, setShowFieldAddRow] = useState<Boolean>(false);
  const [turnEditMode, setTurnEditMode] = useState<Boolean>(false);
  const [EditlayOn, setEditlayOn] = useState<any>({
    row: 0,
    tableCell: 0,
  });

  const [projectForm, setProjectForm] = useState<projectForm>({
    name: '',
    description: '',
    status: 'active',
    tagSection: false,
    showBoundingBox: false,
    projectType: 'classification',
    sections: [],
    classificationValues: [''],
    assignedTo: [],
    fields: [],
    organization: '',
  });

  const [excelDataDetails, setExcelDataDetails] = useState({});
  const [dropDownHeader, setDropDownHeader] = useState<string[]>([]);
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const [files, setFiles] = useState<any>({})

  const [templateDocTypeSelection, setTemplateDocTypeSelection] = useState<any>(
    []
  );
  const [templateDocTypeFields, setTemplateDocTypeFields] = useState<any>([]);

  const [
    showFieldsDataForSelectedDocType,
    setShowFieldsDataForSelectedDocType,
  ] = useState<any>(0);

  const [rowSelected, setRowSelected] = useState<string>("")

  const values = useScrolls(users.length);
  const { limit, page, sortBy } = values;

  const formatName = (role: string) => {
    return role
      .toLowerCase()
      .split('_')
      .map((word: string) => {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(' ')
      .replace('Qc', 'QC');
  };

  const onDrop = (acceptedFiles: any, rejectedFiles: any) => {
    const reader = new FileReader();
    setFileName(acceptedFiles[acceptedFiles.length - 1].name);
    reader.readAsText(acceptedFiles[acceptedFiles.length - 1]);
    reader.onload = (event) => {
      if (event.target?.result) {
        const result: any = event.target.result
        const data = new Uint8Array(result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      }
    };
    reader.readAsArrayBuffer(acceptedFiles);
  };

  const onDropDropdown = (acceptedFiles: any, rejectedFiles: any) => {
    const reader = new FileReader();
    setFileNameDropdown(acceptedFiles[acceptedFiles.length - 1].name);
    reader.readAsText(acceptedFiles[acceptedFiles.length - 1]);
    reader.onload = () => {
      if (reader.result && typeof reader.result === 'string') {
        const fieldKeyValues = reader.result.split(',').map((v) => v.trim());
        const newValues: any = { ...projectForm };
        newValues.fields[currentField].dropDownValues = fieldKeyValues;
        setProjectForm(newValues);
      }
    };
  };

  const onSectionDropDropdown = (acceptedFiles: any, rejectedFiles: any) => {
    const reader = new FileReader();
    setSectionName(acceptedFiles[acceptedFiles.length - 1].name);
    reader.readAsText(acceptedFiles[acceptedFiles.length - 1]);
    reader.onload = () => {
      if (reader.result && typeof reader.result === 'string') {
        const sectionValues = reader.result.split(',').map((v) => {
          return { name: v.trim(), isSectionTable: false };
        });

        let newValues: any = [...sections];
        newValues = newValues.concat(sectionValues as any) || sectionValues;

        setSections(newValues);
      }
    };
  };

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({ onDrop });

  const {
    acceptedFiles: acceptedFilesDropdown,
    getRootProps: getRootPropsDropdown,
    getInputProps: getInputPropsDropdown,
    isDragActive: isDragActiveDropdown,
  } = useDropzone({ onDrop: onDropDropdown });

  const {
    acceptedFiles: acceptedFilesSection,
    getRootProps: getRootPropsSection,
    getInputProps: getInputPropsSection,
    isDragActive: isDragActiveSection,
  } = useDropzone({ onDrop: onSectionDropDropdown });

  const clearAllFiles = () => {
    const fileDelete = files
    delete fileDelete[`${templateDocTypeSelection[showFieldsDataForSelectedDocType]}`]
    // acceptedFiles.length = 0;
    // acceptedFiles.splice(0, acceptedFiles.length);
    // setFileName('');
    // setFieldKeys([]);
    // setProjectForm({ ...projectForm, fields: [] });
  };

  const clearAllFilesDropdown = () => {
    acceptedFilesDropdown.length = 0;
    acceptedFilesDropdown.splice(0, acceptedFilesDropdown.length);
    setFileNameDropdown('');
    // setFieldKeys([]);
    // setProjectForm({ ...projectForm, fields: [] });
  };

  const clearAllFilesSection = () => {
    acceptedFilesSection.length = 0;
    acceptedFilesSection.splice(0, acceptedFilesSection.length);
    setSectionName('');
    // setFieldKeys([]);
    // setProjectForm({ ...projectForm, fields: [] });
  };

  const consolidateData = () => {
    return {
      ...projectForm,
      classificationValues: tags,
      sections: sections,
      assignedTo: assignedUsers,
      // document: templateDocTypeFields.filter((item: any) =>
      //   templateDocTypeSelection.includes(item.docType)
      // ),
      document: templateDocTypeFields
    };
  };

  useEffect(() => {
    tokenData(authToken)
      .then((res) => {
        let data = res.user.organizationIds;
        let organizationForDropdown: Object[] = [];
        data.forEach((item: any) => {
          organizationForDropdown.push({ value: item._id, label: item.name });
        });
        setOrganization(organizationForDropdown);
      })
      .catch((err) => console.log(err));

    getProject(authToken, projectId)
      .then((res: any) => {
        setSections(res.data.sections);
        setTags(res.data.classificationValues);
        setAssignedUsers(res.data.assignedTo);
        //@ts-ignore
        setProjectForm({
          name: res.data.name,
          description: res.data.description,
          status: res.data.status,
          tagSection: res.data.tagSection,
          showBoundingBox: res.data.showBoundingBox,
          projectType: res.data.projectType,
          sections: [],
          classificationValues: [],
          assignedTo: res.data.assignedTo,
          fields: res.data.fields,
          organization: res.data.organizationId._id,
        });
        setTemplateDocTypeFields(res.data.document);
        setTemplateDocTypeSelection(() =>
          Array.from(
            new Set(res.data.document.map((item: any) => item.docType))
          )
        );
      })
      .catch((error) => console.log(error));

    docTypeList(authToken)
      .then((res) => {
        setDocType(res.data);
      })
      .catch((err) => console.log(err));

    userList(authToken, limit, page, sortBy)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const validation = () =>
    new Promise<string>((resolve, reject) => {
      const data = consolidateData();
      if (!data.name || !data.description) {
        reject('Name or Description is empty!');
      }else if(assignedUsers.length===0){
        reject('No users assigned!');
      }else {
        resolve('Validation successful.');
      }
    });

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    readExcelFile(file, (jsonData: any) => {
      const fieldsofValues: { [key: string]: any } = {}
      Object.keys(jsonData[0]).map((field: string) => {
        fieldsofValues[field] = Array.from(new Set(jsonData.map((item: any) => item[field]))).filter((item) => item !== undefined)
      })
      setFiles(() => { return { ...files, [`${templateDocTypeSelection[showFieldsDataForSelectedDocType]}`]: file } })
      const filteredDocTypeSelection = templateDocTypeFields.filter((item: any) => templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType)[0]?.fields
      const fieldsFromExcel = filteredDocTypeSelection.map((item: any) => {
        if (Object.keys(fieldsofValues).includes(item.key)) {
          return { ...item, dropDownValues: fieldsofValues[item.key] }
        }
        return item
      })
      const alterTemplateDocTypeFields = templateDocTypeFields.map((item: any) => {
        if (templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType) {
          return { ...item, fields: fieldsFromExcel }
        }
        return item
      })
      setTemplateDocTypeFields(alterTemplateDocTypeFields)
      setExcelDataDetails(fieldsofValues);
      setDropDownHeader(Object.keys(fieldsofValues));
    });
  };

  const handleCreateProject = () => {
    validation()
      .then(() => {
        createProject(authToken, consolidateData())
          .then((res) => {
            history.push('/projects');
            toastify('success', 'Project created Successfully');
          })
          .catch((error) => {
            toastify('failure', 'Project Creation Fails');
            console.log('error', error);
          });
      })
      .catch((err) => {
        toastify('failure', err);
        console.log(err);
      });
  };

  const handleUpdateProject = () => {
    validation()
      .then(() => {
        updateProject(authToken, projectId, consolidateData())
          .then((res) => {
            toastify('success', 'Project updated Successfully');
            history.goBack();
          })
          .catch((error) => {
            toastify('failure', 'Project Update Fails');
            console.log('error', error);
          });
      })
      .catch((err) => {
        toastify('failure', err);
        console.log(err);
      });
  };

  const handleChipClick = (item: any) => {
    const docTypeIndex = Array.from(
      new Set(templateDocTypeFields.map((doc: any) => doc.docType))
    );

    setShowFieldsDataForSelectedDocType(docTypeIndex.indexOf(item));
  };

  const handleRemoveTemplateDocTypeFromChip = (item: any) => {
    const indexOfElement = templateDocTypeSelection.indexOf(item);

    if (templateDocTypeSelection.length !== 1) {
      const removedTemplateDocTypeSelection = templateDocTypeSelection.splice(
        indexOfElement - 1,
        1
      );

      if (removedTemplateDocTypeSelection.length) {
        setTemplateDocTypeSelection(() => removedTemplateDocTypeSelection);
        if (indexOfElement === 0) {
          setShowFieldsDataForSelectedDocType(indexOfElement);
          handleChipClick(removedTemplateDocTypeSelection[indexOfElement]);
        } else {
          setShowFieldsDataForSelectedDocType(indexOfElement - 1);
          handleChipClick(removedTemplateDocTypeSelection[indexOfElement - 1]);
        }
      } else {
        setTemplateDocTypeSelection([]);
        setShowFieldsDataForSelectedDocType(0);
      }
    } else {
      setTemplateDocTypeSelection([]);
      setShowFieldsDataForSelectedDocType(0);
    }
  };

  const handleTags = (keyCode: number, value: string) => {
    if (keyCode === 13) {
      setTags(tags.concat([value]));
      setCurrentTag('');
    }
  };

  const removeTag = (tag: string) => {
    setTags(tags.filter((item) => item !== tag));
  };

  const removeDropdownValue = (value: string) => {
    const fields = templateDocTypeFields.filter((item: any) => templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType)[0]?.fields.map((item: any) => {
      if (item.key === rowSelected) {
        const dropdown = item.dropDownValues.filter((item: string) => item != value)
        return { ...item, dropDownValues: dropdown }
      }
      return item
    })
    const alterTemplateDocTypeFields = templateDocTypeFields.map((item: any) => {
      if (templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType) {
        return { ...item, fields }
      }
      return item
    })
    setTemplateDocTypeFields(alterTemplateDocTypeFields)
    // setProjectForm((projectForm) => {
    //   let newValues: any = { ...projectForm };
    //   newValues.fields[currentField].dropDownValues = newValues.fields[
    //     currentField
    //   ].dropDownValues.filter((item: any) => item !== value);
    //   return newValues;
    // });
  };

  const handleDropdownValue = (keyCode: number, value: string) => {
    if (keyCode === 13) {
      // const values: any = value.split(';');
      const newValues: any = { ...projectForm };
      newValues.fields[currentField].dropDownValues = newValues.fields[
        currentField
      ].dropDownValues.concat(value) || [value];

      setProjectForm(newValues);
      setCurrentDropdownValue('');
    }
  };

  const handleSections = (keyCode: number, value: string) => {
    if (keyCode === 13) {
      setSections(sections.concat([{ name: value, isSectionTable: false }]));
      setCurrentSection('');
    }
  };

  const removeSection = (section: string) => {
    setSections(sections.filter((item: any) => item.name !== section));
  };

  const toggleSectionTable = (section: string) => {
    setSections(
      sections.map((item: any) =>
        item.name === section
          ? { ...item, isSectionTable: !item.isSectionTable }
          : item
      )
    );
  };

  const assignUsers = (user: any) => {
    if (assignedUsers.includes(user._id)) {
      setAssignedUsers(assignedUsers.filter((cUser) => user._id !== cUser));
    } else {
      setAssignedUsers(assignedUsers.concat([user._id]));
    }
  };

  const handleTemplateDocTypeSelection = (e: any) => {
    const {
      target: { value },
    } = e;

    setTemplateDocTypeSelection(
      typeof value === 'string' ? value.split(',') : value
    );
    let templateFilter = Array.from(
      new Set(templateDocTypeFields.map((item: any) => item.docType))
    );
    let value1 = value.filter((item: any) => !templateFilter.includes(item));
    let filteringDocType = docType.filter((item: any) =>
      value1.includes(item.docType)
    );
    let docTypeFilter = filteringDocType.map((item: any) => {
      templateDocTypeFields.push(item);
    });
  };

  const projectAssign = () => {
    assignUsersToProject(authToken, projectId, assignedUsers).then((res) => { });
  };

  const handleAddField = () => {
    const newUserField =
      templateDocTypeFields[showFieldsDataForSelectedDocType].fields.concat(
        addingField
      );
    const newDocType = {
      ...templateDocTypeFields[showFieldsDataForSelectedDocType],
      fields: newUserField,
    };
    templateDocTypeFields[showFieldsDataForSelectedDocType] = newDocType;
    setShowFieldAddRow(false);
    setAddingField({
      key: '',
      name: '',
      type: '',
      section: '',
    });
  };


  const handleDropDown = (keyCode: number, value: string) => {
    if (keyCode === 13) {
      const fields = templateDocTypeFields.filter((item: any) => templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType)[0]?.fields.map((item: any) => {
        if (item.key === rowSelected) {
          const dropdown = item.dropDownValues.concat(value)
          return { ...item, dropDownValues: dropdown }
        }
        return item
      })
      const alterTemplateDocTypeFields = templateDocTypeFields.map((item: any) => {
        if (templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType) {
          return { ...item, fields }
        }
        return item
      })
      setTemplateDocTypeFields(alterTemplateDocTypeFields)
      setCurrentSection('');
    }
  };

  const handleEditingField = (index: any) => {
    templateDocTypeFields[showFieldsDataForSelectedDocType].fields[index] =
      addingField;

    setTurnEditMode(false);
    setEditlayOn({ row: 0, tableCell: 0 });
  };

  const handleFieldDelete = (indexOfElement: any) => {
    let removingField = templateDocTypeFields[
      showFieldsDataForSelectedDocType
    ].fields.filter((item: any, index: number) => indexOfElement !== index);
    templateDocTypeFields[showFieldsDataForSelectedDocType].fields =
      removingField;
    setTurnEditMode(false);
    setEditlayOn({ row: 0, tableCell: 0 });
    setAddingField({ key: '', name: '', type: '', section: '' });
  };

  const EnablingEditingField = (index: any) => {
    let field =
      templateDocTypeFields[showFieldsDataForSelectedDocType].fields[index - 1];
    setAddingField({
      key: field.key,
      name: field.name,
      type: field.type,
      section: field.section,
    });
  };

  const singleSaveField = (index: any, type: string, data: any) => {
    let fields1 = [
      ...templateDocTypeFields[showFieldsDataForSelectedDocType].fields,
    ];
    fields1[index] = { ...fields1[index], [type]: data };
    let modified = {
      ...templateDocTypeFields[showFieldsDataForSelectedDocType],
      fields: fields1,
    };
    setTemplateDocTypeFields({
      ...templateDocTypeFields,
      [showFieldsDataForSelectedDocType]: modified,
    });
  };


  const handleAssignUsers = (event: any,newValue: any[]) => {
    const newUserIds = newValue.map((user:any) => user._id);
    setAssignedUsers([
      ...fixedAssignUsers,
      ...newUserIds
    ])

    setDuplicateAssignedUsers(newValue);
  }

  useEffect(() => {
    const assignedUserList = users.filter((user:any) => assignedUsers.includes(user._id));

    setDuplicateAssignedUsers((prevState:any) => {
      prevState = assignedUserList;
      return prevState
    });

    const fixedValues:string[] = []
    assignedUsers.map(id => {
      const status = users.find((user:any) => user._id === id);
      if(!status){
        fixedValues.push(id);
      }
    })
    setFixedAssgnUsers(fixedValues);
  }, [users, assignedUsers]);

  return (
    <div
      className="w-100 d-flex flex-column align-items-center"
      style={{ overflowY: 'scroll' }}
    >
      <div className="heading_cont my-3">
        <h1 style={{ fontWeight: 600, fontSize: '2.6rem' }}>
          {!projectId ? 'Create Project' : 'Edit Project'}
        </h1>
      </div>
      <div className="form_container_cp">
        <div className="form_element_cp">
          <label>Name</label>
          <input
            className="form-control"
            value={projectForm.name}
            onChange={(event) =>
              setProjectForm((data) => ({
                ...data,
                name: event.target.value,
              }))
            }
            placeholder="Enter project Name"
          />
        </div>

        <div className=" form_element_cp">
          <label>Description</label>
          <textarea
            rows={5}
            style={{ marginLeft: '8px' }}
            className="form-control"
            value={projectForm.description}
            onChange={(event) =>
              setProjectForm((data) => ({
                ...data,
                description: event.target.value,
              }))
            }
            placeholder="Enter the Project description"
          />
        </div>

        <div className="form_element_cp">
          <label>Organization</label>
          <Dropdown
            options={organization}
            value={projectForm.organization}
            onChange={(event) => {
              setProjectForm((data) => ({
                ...data,
                organization: event.value,
              }));
            }}
            placeholder="Select organization"
          />
        </div>

        <div className="form_element_cp">
          <label>Project Type</label>
          <Dropdown
            options={[
              { value: 'classification', label: 'Classification' },
              { value: 'extraction', label: 'Extraction' },
              {
                value: 'classification_and_extraction',
                label: 'Classification & Extraction',
              },
            ]}
            value={projectForm.projectType}
            onChange={(event) =>
              setProjectForm((data) => ({
                ...data,
                projectType: event.value,
              }))
            }
            placeholder="Select an option"
          />
        </div>

        {(projectForm.projectType === 'extraction' ||
          projectForm.projectType === 'classification_and_extraction') && (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center classification mb-3">
              <div className="check_cont">
                <div className="form-group d-flex align-items-center">
                  <label htmlFor="tagSection" style={{ marginBottom: '0.5px' }}>
                    Tag Section
                  </label>
                  <input
                    id="tagSection"
                    type="checkbox"
                    checked={projectForm.tagSection === true}
                    onChange={(event) =>
                      setProjectForm((data) => ({
                        ...data,
                        tagSection: !projectForm.tagSection,
                      }))
                    }
                  />
                </div>

                <div className="form-group d-flex align-items-center">
                  <label htmlFor="bboxes" style={{ marginBottom: '0.5px' }}>
                    Show Bounding Box
                  </label>
                  <input
                    id="bboxes"
                    type="checkbox"
                    checked={projectForm.showBoundingBox === true}
                    onChange={(event) =>
                      setProjectForm((data) => ({
                        ...data,
                        showBoundingBox: !projectForm.showBoundingBox,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="df_values mb-5 mt-3">
                {/* <VMButton
                  // className='btn-custom-primary'
                  disabled={projectForm.organization.length ? false : true}
                  onClick={() => toggleAssignUsers(true)}
                >
                  Assign Users
                </VMButton> */}
                <label>Assign Users</label>
                <Autocomplete
                    multiple
                    disabled={projectForm.organization.length ? false : true}
                    sx={{width: "35rem"}}
                    options={users}
                    value={duplicateAssignedUsers}
                    onChange={handleAssignUsers}
                    getOptionLabel={(option:any) => `${option.display_name}: ${option.role}`}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Users" />
                    )}
                  />
              </div>
              <div className="df_values my-3">
                {projectForm.tagSection === true && (
                  <VMButton
                    // className='btn-custom-primary'
                    onClick={() => toggleSection(true)}
                  >
                    Define Section
                  </VMButton>
                )}
              </div>
              <h5>Upload values as CSV</h5>
              <a
                href="/sample-csv/sample.csv"
                className="sample_csv_tags"
                download
              >
                Sample CSV Format
              </a>
              <div
                className="upload_tag_csv text-center w-100"
                {...getRootProps()}
                data-cy-field-keys-dropzone
              >
                {files[templateDocTypeSelection[showFieldsDataForSelectedDocType]] ? (
                  <div
                    className="uploadedFileCont upload_cont align-items-center gap-3"
                  // style={{
                  //   border: 0,
                  //   margin: 0,
                  //   width: "100%",
                  //   padding: 0,
                  // }}
                  >
                    {/* <input {...getInputProps()} className="drop-input" /> */}
                    <img
                      src="/Icons/document.svg"
                      alt="document images"
                      width="84px"
                    />
                    <span className="file-name" data-cy-field-keys-file>
                      {files[templateDocTypeSelection[showFieldsDataForSelectedDocType]]?.name}
                      <ClearIcon
                        onClick={clearAllFiles}
                        style={{ color: 'red', fontSize: '1.5em' }}
                      />
                    </span>
                  </div>
                ) : (
                  <div className="fileUploadCont">
                    {/* <h5>Upload values as CSV</h5>
                  <a href='/sample-csv/sample.csv' className="sample_csv_tags" download>
                    Sample CSV Format
                  </a> */}
                    <div className="upload_cont">
                      {isDragActive ? (
                        <div className="d-flex flex-column align-items-center gap-3">
                          {/* <img
                            src="/Icons/document.svg"
                            className="dropbox-image"
                            alt="dropbox document"
                            width="84px"
                          />
                          <span>Uploading a file...</span> */}
                        </div>
                      ) : (
                        <div
                          className="upload_cont"
                          style={{
                            border: 0,
                            margin: 0,
                            width: '100%',
                            padding: 0,
                          }}
                        >
                          <input type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange} className="drop-input" />
                          {/* <button className="btn btn-success">
                            Upload a CSV file
                            <input type="file"
                              accept=".xlsx, .xls"
                              onChange={handleFileChange} className="drop-input" />
                          </button>
                          <span>OR</span>
                          <span>Drag & Drop a file here.</span> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="w-100 d-flex flex-column align-items-center justify-content-center extraction">
              <div className="form-group">
                <label>Upload a CSV file or Drag and drop - Extraction</label>
                <input
                  type="file"
                  onChange={() => handleInputChange()}
                  className="form-control"
                />
              </div>
            </div> */}

              {templateDocTypeSelection.length > 0 && (
                <div
                  className="d-flex w-100 justify-content-center"
                  style={{ margin: '1rem' }}
                >
                  <div style={{ margin: '0.5rem' }}>
                    <VMButton
                      variant="primary"
                      onClick={() => {
                        setShowFieldAddRow(true);
                      }}
                    >
                      Add field
                    </VMButton>
                  </div>
                  <div style={{ margin: '0.5rem' }}>
                    <VMButton
                      variant="primary"
                      onClick={() => {
                        const lastLength =
                          templateDocTypeFields[showFieldsDataForSelectedDocType]
                            .fields[
                          templateDocTypeFields[
                            showFieldsDataForSelectedDocType
                          ].fields.length - 1
                          ];
                        if (lastLength.isAIBuildIn) {
                          toastify('failure', 'Cannot Remove AI Fields');
                          return;
                        }
                        const emptyFieldAssign =
                          templateDocTypeFields[
                            showFieldsDataForSelectedDocType
                          ].fields.pop();
                        setTemplateDocTypeFields({
                          ...templateDocTypeFields,
                          showFieldsDataForSelectedDocType: emptyFieldAssign,
                        });
                      }}
                    >
                      Remove fields
                    </VMButton>
                  </div>
                </div>
              )}

              <div className="w-100 mb-4 ">
                <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                  <InputLabel id="demo-select-small">Document Type</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    label="Document Type"
                    multiple
                    value={templateDocTypeSelection}
                    onChange={(e) => handleTemplateDocTypeSelection(e)}
                    renderValue={(selected) => selected.join(',')}
                  >
                    {docType.map((item: any) => (
                      <MenuItem value={item.docType}>
                        <Checkbox
                          checked={templateDocTypeSelection.includes(
                            item.docType
                          )}
                        />
                        <ListItemText primary={item.docType} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div style={{ marginTop: '1rem' }}>
                  <Stack direction="row" spacing={1}>
                    {templateDocTypeSelection.map(
                      (item: String[], index: number) => (
                        <Chip
                          label={item}
                          style={{ marginLeft: '1rem' }}
                          onClick={() => handleChipClick(item)}
                          onDelete={() => {
                            handleRemoveTemplateDocTypeFromChip(item);
                          }}
                          variant="outlined"
                          className={
                            showFieldsDataForSelectedDocType === index
                              ? classes.chipStylingActive
                              : classes.chipStyling
                          }
                        />
                      )
                    )}
                  </Stack>
                </div>
              </div>

              {templateDocTypeSelection.length > 0 && (
                <TableContainer component={Paper} style={{ width: "70rem" }}>
                  <Table sx={{ minWidth: 840 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Field Key</TableCell>
                        <TableCell>Field Name</TableCell>
                        <TableCell>Field Type</TableCell>
                        <TableCell>Field Section</TableCell>
                        <TableCell>DropDown Value</TableCell>
                        <TableCell>AI Field</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {templateDocTypeFields[
                        showFieldsDataForSelectedDocType
                      ].fields.map((row: any, index: Number) => {
                        return turnEditMode && index === EditlayOn.row ? (
                          <TableRow
                          // key={row.name}
                          // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell component="th" scope="row">
                              <TextField
                                id="standard-basic"
                                label="Field key"
                                variant="standard"
                                sx={{ width: '6rem' }}
                                value={row.key}
                                onChange={(e) =>
                                  singleSaveField(
                                    index,
                                    'key',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <TextField
                                id="standard-basic"
                                label="Field Name"
                                variant="standard"
                                sx={{ width: '6rem' }}
                                value={row.name}
                                onChange={(e) =>
                                  singleSaveField(
                                    index,
                                    'name',
                                    e.currentTarget.value
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Dropdown
                                options={[
                                  { value: 'short_text', label: 'Short text' },
                                  { value: 'date', label: 'Date' },
                                  { value: 'dropdown', label: 'Dropdown' },
                                  { value: 'long_text', label: 'Long Text' },
                                ]}
                                onChange={(target) => {
                                  singleSaveField(index, 'type', target.value);
                                }}
                                value={row.type}
                                className={classes.dropDown}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Dropdown
                                options={Array.from(
                                  new Set(sections.map((item: any) => item.name))
                                )}
                                onChange={(target) => {
                                  singleSaveField(index, 'section', target.value);
                                }}
                                value={row.section}
                                className={classes.dropDown}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope='row'
                              width={5}
                            >
                              <Dropdown
                                options={dropDownHeader}
                                // onChange={(target) => {
                                //   singleSaveField(index, 'section', target.value);
                                // }}
                                // value={row.section}
                                disabled={dropDownHeader.includes(row.key) ? true : false}
                                className={classes.dropDown}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              width={5}
                            ></TableCell>
                            <TableCell component="th" scope="row">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <DeleteIcon
                                  style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleFieldDelete(index)}
                                />
                                <hr />
                                <CancelIcon
                                  style={{
                                    // margin: "0 8px",
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setTurnEditMode(false)}
                                />
                              </div>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              width={5}
                            ></TableCell>
                          </TableRow>
                        ) : (
                          <TableRow
                          // key={row.name}
                          // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              onClick={(e: any) => {
                                if (!row.isAIBuildIn) {
                                  EnablingEditingField(
                                    e.target.parentElement.rowIndex
                                  );
                                  setTurnEditMode(true);
                                  setEditlayOn({
                                    row: e.target.parentElement.rowIndex - 1,
                                    tableCell: e.currentTarget.cellIndex,
                                  });
                                }
                              }}
                            >
                              {row.key}
                            </TableCell>
                            <TableCell
                              onClick={(e: any) => {
                                if (!row.isAIBuildIn) {
                                  EnablingEditingField(
                                    e.target.parentElement.rowIndex
                                  );
                                  setTurnEditMode(true);
                                  setEditlayOn({
                                    row: e.target.parentElement.rowIndex - 1,
                                    tableCell: e.currentTarget.cellIndex,
                                  });
                                }
                              }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              onClick={(e: any) => {
                                if (!row.isAIBuildIn) {
                                  EnablingEditingField(
                                    e.target.parentElement.rowIndex
                                  );
                                  setTurnEditMode(true);
                                  setEditlayOn({
                                    row: e.target.parentElement.rowIndex - 1,
                                    tableCell: e.currentTarget.cellIndex,
                                  });
                                }
                              }}
                            >
                              {row.type}
                            </TableCell>
                            <TableCell
                              onClick={(e: any) => {
                                if (!row.isAIBuildIn) {
                                  EnablingEditingField(
                                    e.target.parentElement.rowIndex
                                  );
                                  setTurnEditMode(true);
                                  setEditlayOn({
                                    row: e.target.parentElement.rowIndex - 1,
                                    tableCell: e.currentTarget.cellIndex,
                                  });
                                }
                              }}
                            >
                              {row.section}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope='row'
                              width={5}
                            >
                              {row.type === "dropdown" ? (
                                <VMButton variant="secondary" style={{ innerWidth: '2rem' }}
                                  disabled={dropDownHeader.includes(row.key) ? true : false}
                                  onClick={() => { setToggleDropDown(true); setRowSelected(row.key) }}
                                >Add</VMButton>
                              ) : ""}
                            </TableCell>
                            <TableCell>
                              {row.isAIBuildIn ? <Checkbox checked /> : ''}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {showFieldAddRow ? (
                        <TableRow
                        // key={row.name}
                        // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <TextField
                              id="standard-basic"
                              label="Field key"
                              variant="standard"
                              sx={{ width: '6rem' }}
                              value={addingField.key}
                              onChange={(e) =>
                                setAddingField({
                                  ...addingField,
                                  key: e.currentTarget.value,
                                })
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <TextField
                              id="standard-basic"
                              label="Field Name"
                              variant="standard"
                              sx={{ width: '6rem' }}
                              value={addingField.name}
                              onChange={(e) =>
                                setAddingField({
                                  ...addingField,
                                  name: e.currentTarget.value,
                                })
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Dropdown
                              options={[
                                { value: 'short_text', label: 'Short text' },
                                { value: 'date', label: 'Date' },
                                { value: 'dropdown', label: 'Dropdown' },
                                { value: 'long_text', label: 'Long Text' },
                              ]}
                              onChange={(target) => {
                                setAddingField({
                                  ...addingField,
                                  type: target.value,
                                });
                              }}
                              value={addingField.type}
                              className={classes.dropDown}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Dropdown
                              options={Array.from(
                                new Set(sections.map((item: any) => item.name))
                              )}
                              onChange={(target) => {
                                setAddingField({
                                  ...addingField,
                                  section: target.value,
                                });
                              }}
                              value={addingField.section}
                              className={classes.dropDown}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              {showFieldAddRow && (
                                <DeleteIcon
                                  style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                  }}
                                />
                              )}
                              <hr />
                              <CancelIcon
                                style={{
                                  // margin: "0 8px",
                                  cursor: 'pointer',
                                }}
                                onClick={() => setShowFieldAddRow(false)}
                              />
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row" width={5}>
                            <PageDoneIcon
                              style={{
                                color: 'green',
                                margin: '0 8px',
                                cursor: 'pointer',
                              }}
                              onClick={handleAddField}
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        ''
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <div style={{ minWidth: '45rem', backgroundColor: 'pink' }}></div>
            </div>
          )}

        {(projectForm.projectType === 'classification' ||
          projectForm.projectType === 'classification_and_extraction') && (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center classification mb-3">
              {projectForm.projectType === 'classification' && (
                <div className="df_values mb-5 mt-3">
                  {/* <VMButton
                    variant="primary"
                    // className='btn-custom-primary'
                    disabled={projectForm.organization.length ? false : true}
                    onClick={() => toggleAssignUsers(true)}
                  >
                    Assign Users
                  </VMButton> */}
                  <label>Assign Users</label>
                  <Autocomplete
                      sx={{width: '35rem'}}
                      multiple
                      disabled={projectForm.organization.length ? false : true}
                      value={duplicateAssignedUsers}
                      options={users}
                      onChange={handleAssignUsers}
                      getOptionLabel={(option:any) => `${option.display_name}: ${option.role}`}
                      renderInput={(params) => (
                        <TextField {...params} label="Users" placeholder="Select Users" />
                      )}
                    />
                </div>
              )}
              <VMButton
                variant="primary"
                // className='btn-custom-primary'
                onClick={() => toggleClassificationTypeModal(true)}
              >
                Define Classification Types
              </VMButton>
              {tags.length > 0 && (
                <>
                  <h5 className="text-center my-3">Classification Values</h5>
                  <div className="tags_list_cl w-50">
                    {tags.map((item, key) => (
                      <div key={key} className="tag_elem_cl">
                        <span className="mx-2 m-0">{item}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <div className="d-flex mt-2">
                {projectForm.classificationValues.map((element, key) => {
                  return (
                    <span key={key} className="badge badge-pill badge-secondary">
                      {element}
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        <div className="d-flex w-100 justify-content-center gap-3 mb-5">
          <div className="col-sm-3 mt-3">
            <VMButton
              variant="primary"
              //   className="mt-5 btn-custom-primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </VMButton>
          </div>
          <div className="col-sm-3 mt-3">
            {!projectId ? (
              <VMButton
                variant="primary"
                //   className="mt-3 btn-custom-primary"
                onClick={handleCreateProject}
              >
                Create Project
              </VMButton>
            ) : (
              <VMButton
                variant="primary"
                // className="mt-3 btn-custom-primary"
                onClick={handleUpdateProject}
              >
                Update Project
              </VMButton>
            )}
          </div>
        </div>
      </div>

      <VMModal
        open={isClassificationType}
        handleClose={() => toggleClassificationTypeModal(false)}
      >
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '40rem',
            height: '35rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Define Classification Values</h4>
          </div>
          <hr />
          <div className="d-flex flex-column createModal">
            <div style={{ padding: '1rem' }}>
              <input
                style={{ width: '37rem' }}
                className="form-control"
                placeholder="Enter Classification Types"
                value={currentTag}
                onChange={(e) => setCurrentTag(e.target.value)}
                onKeyUp={(e: any) => {
                  handleTags(e.keyCode, e.target.value);
                }}
              />
              {tags.length > 0 && (
                <div className="tags_list_cl">
                  {tags.map((item, key) => (
                    <div key={key} className="tag_elem_cl">
                      <span className="mx-2 m-0">{item}</span>
                      <span
                        className="tag_elem_close_cl"
                        onClick={() => removeTag(item)}
                      >
                        <img
                          src="/Icons/close_icon.svg"
                          alt="close icon"
                          width="10px"
                          style={{ filter: 'invert(1)' }}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              )}
              <div className="or_line">-----------OR-----------</div>
              <div className="upload_tag_csv text-center">
                <h5>Upload values as CSV</h5>
                <a
                  href="/sample-csv/sample.csv"
                  className="sample_csv_tags"
                  download
                >
                  Sample CSV Format
                </a>
                <div className="upload_cont">
                  <button className="btn btn-success">Upload a CSV file</button>
                  <span>OR</span>
                  <span>Drag & Drop a file here.</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex w-100 justify-content-center">
              <VMButton
                variant="primary"
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                onClick={() => toggleClassificationTypeModal(false)}
              >
                Define
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>

      <VMModal open={isSection} handleClose={() => toggleSection(false)}>
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '50rem',
            height: '45rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Define Section</h4>
          </div>
          <hr />
          <div className="d-flex flex-column createModal">
            <div style={{ padding: '1rem' }}>
              <input
                className="form-control"
                placeholder="Enter Section Name"
                style={{ width: '49rem' }}
                value={currentSection}
                onChange={(e) => setCurrentSection(e.target.value)}
                onKeyUp={(e: any) => {
                  handleSections(e.keyCode, e.target.value);
                }}
              />
              {sections.length > 0 && (
                <div
                  className="section_list_cl"
                  style={{ height: '8rem', overflow: 'scroll' }}
                >
                  <div className="section_head_cl">
                    <span>Section Name</span>
                    <span>Mark as Table</span>
                  </div>
                  {sections.map((item: any, key) => (
                    <div key={key} className="section_elem_cl">
                      <div className="mx-2 m-0">{item.name}</div>
                      <div className="section_tb_actions">
                        <input
                          type="checkbox"
                          className="m-0"
                          checked={item.isSectionTable}
                          onChange={() => toggleSectionTable(item.name)}
                        />
                        <span
                          className="tag_elem_close_cl"
                          onClick={() => removeSection(item.name)}
                        >
                          <img
                            src="/Icons/close_icon.svg"
                            alt="close icon"
                            width="10px"
                            style={{ filter: 'invert(1)' }}
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <div className="or_line">-----------OR-----------</div>
              <div
                className="upload_tag_csv text-center w-100"
                // {...getRootPropsSection()}
                data-cy-field-keys-dropzone
              >
                {sectionName ? (
                  <div className="uploadedFileCont upload_cont align-items-center gap-3">
                    <img
                      src="/Icons/document.svg"
                      alt="document images"
                      width="84px"
                    />
                    <span className="file-name" data-cy-field-keys-file>
                      {sectionName}
                      <ClearIcon
                        onClick={clearAllFilesSection}
                        style={{ color: 'red', fontSize: '1.5em' }}
                      />
                    </span>
                  </div>
                ) : (
                  <div className="fileUploadCont">
                    <h5>Upload values as CSV</h5>
                    <a
                      href="/sample-csv/sample.csv"
                      className="sample_csv_tags"
                      download
                    >
                      Sample CSV Format
                    </a>
                    <div className="upload_cont" {...getRootPropsSection()}>
                      {isDragActiveSection ? (
                        <div className="d-flex flex-column align-items-center gap-3">
                          <img
                            src="/Icons/document.svg"
                            className="dropbox-image"
                            alt="dropbox document"
                            width="84px"
                          />
                          <span>Uploading a file...</span>
                        </div>
                      ) : (
                        <div
                          className="upload_cont"
                          style={{
                            border: 0,
                            margin: 0,
                            width: '100%',
                            padding: 0,
                          }}
                        >
                          <button className="btn btn-success">
                            Upload a CSV file
                            <input
                              {...getInputPropsSection()}
                              className="drop-input"
                            />
                          </button>
                          <span>OR</span>
                          <span>Drag & Drop a file here.</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex w-100 justify-content-center">
              <VMButton
                variant="primary"
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                onClick={() => toggleSection(false)}
              >
                Define
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>

      <VMModal open={toggleDropDown} handleClose={() => setToggleDropDown(false)}>
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '50rem',
            height: '45rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Define DropDownValue</h4>
          </div>
          <hr />
          <div className="d-flex flex-column createModal">
            <div style={{ padding: '1rem' }}>
              <input
                className="form-control"
                placeholder="Enter DropDown Option"
                style={{ width: '49rem' }}
                value={currentSection}
                onChange={(e) => setCurrentSection(e.target.value)}
                onKeyUp={(e: any) => {
                  handleDropDown(e.keyCode, e.target.value);
                }}
              />
              {templateDocTypeFields.length > 0 && rowSelected !== "" && (
                <div
                  className="section_list_cl"
                  style={{ height: '25rem', overflow: 'scroll' }}
                >
                  <div className="section_head_cl">
                    <span>DropDown Option</span>
                    {/* <span>Mark as Table</span> */}
                  </div>
                  {templateDocTypeFields.filter((item: any) => templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType)[0]?.fields.filter((item: any) => item.key === rowSelected)[0].dropDownValues.length > 50 ?
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                      <h5>!!Exceed the More Length of DropDown!!</h5>
                    </div> :
                    templateDocTypeFields.filter((item: any) => templateDocTypeSelection[showFieldsDataForSelectedDocType] === item.docType)[0]?.fields.filter((item: any) => item.key === rowSelected)[0].dropDownValues.map((item: any) => (
                      <div className="section_elem_cl">
                        <div className="mx-2 m-0">{item}</div>
                        <div className="section_tb_actions">
                          {/* <input
                          type="checkbox"
                          className="m-0"
                          checked={item.isSectionTable}
                          onChange={() => toggleSectionTable(item.name)}
                        /> */}
                          <span
                            className="tag_elem_close_cl"
                            onClick={() => removeDropdownValue(item)}
                          >
                            <img
                              src="/Icons/close_icon.svg"
                              alt="close icon"
                              width="10px"
                              style={{ filter: 'invert(1)' }}
                            />
                          </span>
                          <hr />
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="d-flex w-100 justify-content-center">
              <VMButton
                variant="primary"
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                onClick={() => setToggleDropDown(false)}
              >
                Close
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>

      {/* Define Users */}
      <VMModal
        open={isAssignUsers}
        handleClose={() => toggleAssignUsers(false)}
      >
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '35rem',
            borderRadius: '2rem',
          }}
        >
          <div style={{ padding: '0.5rem', margin: '1rem' }}>
            <h4>Assign Users</h4>
          </div>
          <hr />
          <div className="d-flex flex-column createModal">
            <div style={{ display: 'flex' }}>
              <Typography variant="h6" sx={{ margin: '1rem' }}>
                Search Users
              </Typography>
              <FormControl>
                <CssTextField
                  id="searchUsers"
                  margin="none"
                  style={{ width: '20rem', marginTop: '0.5rem' }}
                  size="small"
                  placeholder="Search User"
                  onChange={(e: any) => {
                    setsearchUsers(e.target.value);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="form-control border-0" style={{height:'200px', overflowY:'auto', marginBottom:'1rem'}}>
              <div>
                {users
                  .filter((user: any) => {
                    return searchUsers.length >= 1
                      ? user.display_name
                        .toLowerCase()
                        .startsWith(searchUsers.toLowerCase())
                      : true;
                  })
                  .map((user: any, key: number) => {
                    return (
                      user.role !== 'superadmin' &&
                      user.organizationIds.map((userOrg: any) => {
                        return (
                          userOrg._id.toString() ===
                          projectForm.organization && (
                            <div key={key} style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                checked={assignedUsers.includes(user._id)}
                                id={`user_${key}`}
                                onChange={() => {
                                  assignUsers(user);
                                }}
                              />
                              <label
                                htmlFor={`user_${key}`}
                                className="m-1 d-flex align-items-center"
                              >
                                {user.display_name} : {user.role}
                              </label>
                            </div>
                          )
                        );
                      })
                    );
                  })}
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex w-100 justify-content-center">
              <VMButton
                variant="primary"
                style={{ marginTop: '0.5rem', marginBottom: '1.5rem' }}
                onClick={() => {
                  toggleAssignUsers(false);
                }}
              >
                Assign to Project
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>
    </div>
  );
}

export default CreateProject;
