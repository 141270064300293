import React, { useContext, useState, useEffect } from "react";
import VMButton from "../common/Button";
import UserContext from "../../context/userContext";
import { Container, TextField, Typography, FormControl } from "@mui/material";
import { tokenData, userLogin } from "../../controllers/users";
import { Redirect, useHistory } from "react-router-dom";

import { toastify } from "../common/notification";

import "./Login.css";

const Login = () => {
  return (
    <Container maxWidth="xl" className="p-0">
      <div className="rowContainer" style={{ display: "flex" }}>
        <div className="leftside" style={{ width: "90vh" }}>
          <LeftPanel />
        </div>
        <div className="rightside" style={{ width: "90vh" }}>
          <RightPanel />
        </div>
      </div>
    </Container>
  );
};

const RightPanel: React.FC = () => {
  const history = useHistory();
  const { setUser } = useContext(UserContext);

  const [email, setEmail] = useState<string>(" ");
  const [password, setPassword] = useState<any>(" ");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const authToken = localStorage.getItem("$AuthToken");
    if (authToken) {
      tokenData(authToken)
        .then((data) => {
          if (!data) {
            localStorage.removeItem("$AuthToken");
          } else {
            setUser({
              name: data.user.name,
              email: data.user.email,
              role: data.user.role,
              authToken: authToken,
            });
          }
        })
        .then(() => history.push("/"))
        .catch((error) => console.log(error));
    }
  }, []);

  const handleLogin = (e: React.SyntheticEvent) => {
    e.preventDefault();
    userLogin(email, password)
      .then((data) => {
        if (data.first_login) {
          const authToken = data.auth;
          tokenData(authToken)
            .then((data) => {
              setUser({
                name: data.user.name,
                email: data.user.email,
                role: data.user.role,
                authToken: authToken,
              });
            })
            .then(() => {
              localStorage.setItem("$AuthToken", authToken);
              toastify("success", "Login Successful");
              setIsSuccess(true);
              history.push("/update-password");
            });
        } else {
          const authToken = data.auth;
          tokenData(authToken)
            .then((data) => {
              setUser({
                name: data.user.name,
                email: data.user.email,
                role: data.user.role,
                authToken: authToken,
              });
            })
            .then(() => {
              localStorage.setItem("$AuthToken", authToken);
              toastify("success", "Login Successful");
              setIsSuccess(true);
              history.push("/");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toastify("failure", err.response.data.message);
        } else {
          toastify("failure", "Something's Wrong");
        }
        setIsSuccess(false);
      });

    //setIsSuccess(true);
  };

  return (
    <div>
      {isSuccess ? (
        <Redirect to="/login" />
      ) : (
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh", flexDirection: "column" }}
        >
          <div
            style={{
              marginTop: "2%",
              width: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3">Login Account</Typography>
            <Typography variant="subtitle2" className="mt-3">
              Enter the email and password
            </Typography>
          </div>
          <div
            style={{
              height: "250px",
              width: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "5%",
              marginLeft: "10%",
            }}
          >
            <form onSubmit={handleLogin} id="login">
              <div style={{ margin: "5%" }}>
                <div style={{ margin: "3%" }}>
                  {/* <InputGroup className="input">
                    <FormControl
                      placeholder="Email"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      id="email"
                    />
                  </InputGroup>
                  <InputGroup className="input">
                    <FormControl
                      placeholder="Password"
                      aria-label="password"
                      name="password"
                      type="password"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup> */}
                  <FormControl>
                    <div>
                      <TextField
                        variant="outlined"
                        
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        sx={{ width: "22rem" }}
                      />
                    </div>
                    <div style={{marginTop:"1rem"}}>
                    <TextField
                      variant="outlined"
                      label="Password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      fullWidth
                      sx={{ width: "22rem" }}
                    />
                    </div>
                  </FormControl>
                </div>
                <div>
                  <VMButton
                    variant="primary"
                    type="submit"
                    style={{
                      width: "85%",
                      marginLeft: "4%",
                      padding: "3%",
                      marginTop: "3%",
                    }}
                  >
                    Login
                  </VMButton>
                  <br />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "11%",
                      marginTop: "12px",
                    }}
                  >
                    <a
                      href="/forgot-password"
                      // onClick={() => history.push("/forgot-password")}
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Container>
      )}
    </div>
  );
};

const LeftPanel = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{ height: "90vh" }}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <img
          src={"/Icons/verify-me.svg"}
          alt="Verify Me icon"
          style={{ marginLeft: 30, width: "24%" }}
        />
        <div style={{ marginTop: "10%" }}>
          <h1 style={{ color: "#FFFF", marginLeft: 30 }}>
            Welcome to Verify Me
          </h1>
        </div>
      </div>
      <div style={{ paddingBottom: "5%", height: "10vh" }}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div style={{ width: "20vh" }}>
            <p style={{ color: "#FFFF" }}>&#169; 2021 Verify Me</p>
          </div>
          <div style={{ width: "35vh" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Privacy</p>
                </a>
              </div>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Terms</p>
                </a>
              </div>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Contact</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
