import axios from "./axiosConfig";

const CLIENT_URL = process.env.REACT_APP_NODE_URL + "/api/v3";

export const tokenData = (token: string) =>
  axios
    .get(`${CLIENT_URL}/users/me`, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => console.log(error));

export const userLogin = (email: string, password: string) =>
  axios
    .post(`${CLIENT_URL}/users/login`, {
      email,
      password,
    })
    .then((res) => res.data);

export const forgotPassword = (email: string) =>
  axios
    .post(`${CLIENT_URL}/users/forgot-password`, { email })
    .then((res) => res.data);

export const resetPassword = (token: string, password: string) =>
  axios
    .post(
      `${CLIENT_URL}/users/reset-password`,
      { password },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

    export const updatePassword = (token: string, password: string) =>
  axios
    .post(
      `${CLIENT_URL}/users/update-password`,
      { password },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const createUser = (user: any, token: string) =>
  axios
    .post(`${CLIENT_URL}/users/register`, user, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const userList = (token: string, limit:number, page: number, sortBy: string) =>
  axios
    .get(`${CLIENT_URL}/users/all/?limit=${limit}&page=${page}&sort=${sortBy}`, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

    export const updateUser = (token: string, UserId: String, data: any) =>
    axios
      .put(`${CLIENT_URL}/users/update/${UserId}`, data, {
        headers: {
          authorization: `bearer ${token}`,
        },
      })
      .then((res) => res.data);  

    export const deleteUser = (token: string, UserId: String) =>
    axios
      .put(
        `${CLIENT_URL}/users/delete/${UserId}`,
        {},
        {
          headers: {
            authorization: `bearer ${token}`,
          },
        }
      )
      .then((res) => res.data);    
