import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";

type Props = {
  imageUrl: string;
  boundingBox: number[];
  showBoundingBox: boolean;
  fieldKey: string;
  scale: number;
  rotate:number;
  flag: boolean;
  updateBoundingBox: (fieldKey: string, arr: number[], tableField?: {
    idx?: number;
    key?: string;
  }) => void;
  tableField: {
    idx?: number;
    key?: string;
  };
  tableDisplay: boolean;
};

interface CustomEvent<T extends Event> extends fabric.IEvent {
  e: T;
}

const ImageCanvas = ({
  imageUrl,
  boundingBox,
  showBoundingBox,
  fieldKey,
  updateBoundingBox,
  scale,
  rotate,
  flag,
  tableField,
  tableDisplay
}: Props) => {
  const canvasWidth = 1000;
  const canvasHeight = 500;
  const canvas = useRef<fabric.Canvas>(null);
  let isDragging = false;
  let lastPosX: number;
  let lastPosY: number;

  const [scaleFactor, setScaleFactor] = useState(1);
  // const [rect, setRect] = useState<fabric.Rect | null>(null);

  console.log("boundingBox:::", boundingBox);
  //Render Image on Canvas
  useEffect(() => {
    if (canvas) {
      fabric.Image.fromURL(
        imageUrl,
        (img) => {
          const imageObject = img.set({
            left: 0,
            top: 0,
            angle: 0,
            stroke: "#aeaeaf",
            strokeWidth: 4,
          });
          const imgRect = imageObject.getBoundingRect();
          setScaleFactor(canvasWidth / imgRect.width);
          imageObject.scale(canvasWidth / imgRect.width);
          (canvas as any).current.setHeight(imageObject.getScaledHeight());
          (canvas as any).current.clear();
          (canvas as any).current.setViewportTransform([1, 0, 0, 1, 0, 0]);
          (canvas as any).current.add(imageObject);

          imageObject.rotate(rotate);
        },
        { selectable: false }
      );
    }
  }, [imageUrl, canvas]);

  //For Zoom on Scroll
  useEffect(() => {
    // flag indicates the page has render for the first time and we create lower canvas on top of upper canvas
    if (flag && scale === 1) {
      (canvas as any).current = new fabric.Canvas("canvas", {
        height: canvasHeight,
        width: canvasWidth,
      });
    }

    const rectObject = (canvas as any).current.getObjects("rect")[0];

    //to check for reset
    if (!flag && scale === 1) {
      (canvas as any).current.setViewportTransform([1, 0, 0, 1, 0, 0]);

      if (rectObject) {
        rectObject.selectable = true;
        rectObject.hasControls = true;
        rectObject.setCoords();
        canvas.current?.renderAll();
      }
    }

    if (scale !== 1 && !flag && canvas.current) {

      (canvas as any).current.on("mouse:down", handleMouseDown);

      (canvas as any).current.on("mouse:up", handleMouseUp);

      (canvas as any).current.on("mouse:move", handleMouseMove);

      console.log("zoom level", (canvas as any).current.getZoom());

      (canvas as any).current.setZoom(scale);

      console.log("cs", (canvas as any).current)


      if (rectObject) {
        rectObject.selectable = false;
        rectObject.hasControls = false;
        rectObject.setCoords();
        canvas.current.renderAll();
      }

    } else {
      if (canvas.current) {
        (canvas as any).current.off("mouse:down", () => {
          isDragging = false;
        });
        (canvas as any).current.off("mouse:up", () => {
          isDragging = false;
        });
        (canvas as any).current.off("mouse:move");
        const vpt = (canvas as any).current.viewportTransform;
        vpt[4] = 0;
        vpt[5] = 0;
        (canvas as any).current.requestRenderAll();
      }
    }
  }, [scale]);

  useEffect(() => {

    const imageObject = (canvas as any).current.getObjects("image")[0];
    if (imageObject && canvas.current) {

      (canvas as any).current.remove(
        ...(canvas as any).current.getObjects("rect")
      );

      const imageBoundingRect = imageObject.getBoundingRect()
      console.log("Canvas", (canvas as any))

      let orientation = imageBoundingRect.width > imageBoundingRect.height ? "potrait" : "landscape";

      const viewpoint = calculateViewportTransform();
      (canvas).current.setViewportTransform(viewpoint);

      imageObject.rotate(rotate);

      (canvas as any).current.add(imageObject);
    }

  }, [rotate]);


  const handleMouseDown = (opt: CustomEvent<MouseEvent>) => {
    const evt = opt.e;
    (canvas as any).current.selection = false;
    isDragging = true;
    lastPosX = evt.clientX;
    lastPosY = evt.clientY;
  };

  const handleMouseUp = () => {
    (canvas as any).current.selection = true;
    (canvas as any).current._objects.map((ele: any) => {
      if (ele.type === "rect") (canvas as any).current.setActiveObject(ele);
    });
    isDragging = false;
  };

  const handleMouseMove = (opt: CustomEvent<MouseEvent>) => {
    if (isDragging) {
      const e = opt.e;
      const vpt = (canvas as any).current.viewportTransform;
      vpt[4] += e.clientX - lastPosX;
      vpt[5] += e.clientY - lastPosY;
      (canvas as any).current.requestRenderAll();
      lastPosX = e.clientX;
      lastPosY = e.clientY;
    }
  };

  //Viewpoint Transformation on rotate
  const calculateViewportTransform = (): number[] => {
    let viewpoint: number[] = [];

    if(rotate/90 === 1 || rotate/90 === 3){
      viewpoint = [0.75,0,0,0.75,165,100];
    }else{
      viewpoint = [0.95, 0, 0, 0.95, 0, 0];
    }

    return viewpoint;
  };


  //Draw Bounding Box
  useEffect(() => {
    if (canvas.current) {
      (canvas as any).current.remove(
        ...(canvas as any).current.getObjects("rect")
      );
    }
    if (boundingBox?.length === 4 && showBoundingBox && canvas.current) {
      const rect = new fabric.Rect({
        left: boundingBox[0] * scaleFactor,
        top: boundingBox[1] * scaleFactor,
        width: boundingBox[2] * scaleFactor,
        height: boundingBox[3] * scaleFactor,
        fill: "transparent",
        backgroundColor: "blue",
        selectable: scale === 1,
        hasControls: scale === 1,
        opacity: 0.2,
      });
      // prevent corner resizing
      (canvas as any).current.setActiveObject(rect);
      rect.setCoords();


      if (tableDisplay) {

        (document as any)
          .querySelector("#image-canvas")
          .scrollTo(
            boundingBox[0] * scaleFactor - 100,
            boundingBox[1] * scaleFactor - 100
          );
      } else {
        (document as any)
          .querySelector(".image-canvas-container")
          .scrollTo(
            boundingBox[0] * scaleFactor - 100,
            boundingBox[1] * scaleFactor - 100
          );
      }

      // Drag and Resize Bounding Box
      (canvas as any).current.on("object:modified", function () {
        const { left, top } = rect;
        const { width, height } = rect.getBoundingRect();

        rect.setCoords();
        updateBoundingBox(fieldKey, [
          Math.round((left as any) / scaleFactor),
          Math.round((top as any) / scaleFactor),
          Math.round(width / scaleFactor),
          Math.round(height / scaleFactor),
        ], tableField);
      });

      (canvas as any).current.on("object:scaled", function () {
        const { top, left } = rect;
        const { width, height } = rect.getBoundingRect();
        rect.setCoords();

        updateBoundingBox(fieldKey, [
          Math.round((left as any) / scaleFactor),
          Math.round((top as any) / scaleFactor),
          Math.round(width / scaleFactor),
          Math.round(height / scaleFactor),
        ], tableField);
      });

      (canvas as any).current.add(rect);
    }

    if (canvas.current) {
      (canvas as any).current.renderAll();
    }
  }, [boundingBox, showBoundingBox]);

  // const rotateCanvas = () => {
  //   rotations = (rotations + 1) % 4;
  //   (canvas as any).current.getObjects().forEach((obj) => {
  //     switch (rotations) {
  //       case 0: {
  //         obj.top = 0;
  //         obj.left = 0;
  //         break;
  //       }
  //       case 1: {
  //         obj.top = 0;
  //         obj.left = canvasWidth;
  //         break;
  //       }
  //       case 2: {
  //         obj.top = canvasHeight;
  //         obj.left = canvasWidth;
  //         break;
  //       }
  //       case 3: {
  //         obj.top = canvasHeight;
  //         obj.left = 0;
  //         break;
  //       }
  //     }
  //     obj.angle = (obj.angle + 90) % 360;
  //     (canvas as any).current.renderAll();
  //   });
  // };

  return (
    <canvas
      id="canvas"
      width={canvasWidth}
      height={canvasHeight}
    />
  );
};

export default ImageCanvas;