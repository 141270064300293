import { Tooltip } from "@mui/material";
import { withStyles } from '@mui/styles';


const TextOnlyTooltip = withStyles({
  tooltip: {
    color: "white !important",
    backgroundColor: "#000 !important",
    padding: "8px 21px !important",
    fontSize: "13px !important",
    borderRadius: "3px !important",
  }
})(Tooltip);


const VMTooltip = ({ children, title, placement, ...props }: { children: any, title: string, placement: any }) => {
  return (
    <TextOnlyTooltip
      enterNextDelay={800}
      title={title}
      placement={placement}
      {...props}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "common.black !important",
            '& .MuiTooltip-arrow': {
              color: 'common.black',
            },
          },
        },
      }}
      arrow
    >
      {children}
    </TextOnlyTooltip>
  )
}

export default VMTooltip;