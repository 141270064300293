import React, { useEffect, useContext, useState } from "react";
import ExcelView from "./ExcelView";
import { getReferenceFile } from "../../../controllers/projects";
import UserContext from "../../../context/userContext";
import { useParams } from "react-router";
import { IconButton, Modal, Box, Select, MenuItem, TextField, Autocomplete, Chip } from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import VMButton from "../../common/Button";


const ReferenceFile = () => {
    const { projectId }: { projectId: string } = useParams();
    const [referenceFileData, setReferenceFileData] = useState([]);
    const [header, setHeader] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filterHeader, setFilterHeader] = useState([]);
    const [filterBasedOnColumn, setFilterBasedOnColumn] = useState("");
    const [tagValues, setTagValues] = useState<string>("");

    // const excelData = [
    //     {
    //         A: 'Payee name in Bank',
    //         B: 'Payee name',
    //         C: 'GL account',
    //         // D: 'GLL Account',
    //         // E: 'GLL Account',
    //         // F: 'GLL Account',
    //         // G: 'GLL Account',
    //         // H: 'GLL Account',
    //         // I: 'GLL Account',
    //         // J: 'GLL Account',
    //         // K: 'GLL Account',
    //         // L: 'GLL Account',
    //         // M: 'GLL Account',
    //         // N: 'GLL Account',
    //         // O: 'GLL Account',
    //         // P: 'GLL Account',
    //         // Q: 'GLL Account'
    //     },
    //     {
    //         A: 'MERCHANTS WINE & LIQUORS',
    //         B: 'Merchants Wine',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'HIGH GRADE',
    //         B: 'High Grade Beverage Corp',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'Seafish, LLCd/b/a ',
    //         B: 'Seafish LLC',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Ocean Seafood Depot',
    //         B: 'Ocean Seafood Inc',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     { A: 'Deposit', B: 'Deposit', C: '5000 · Sales' },
    //     { A: 'UBER USA FBO', B: 'empty', C: '5000 · Sales' },
    //     {
    //         A: 'BOROUGH OF SAYREVILLE WATER DE',
    //         B: 'Borough of Sayreville',
    //         C: '6390 · Utilities:6390-1 · Water'
    //     },
    //     {
    //         A: 'Ocean Frost Corporation',
    //         B: 'Ocean Frost Corp',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Mermini Meats',
    //         B: 'Mermini Meats',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Triunfo Foods',
    //         B: 'Triunfo Corp',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     { A: 'DoorDash', B: 'Deposit', C: '5000 · Sales' },
    //     {
    //         A: 'EB Express Provisions INC.',
    //         B: 'EB Express Provisions, Inc',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Simone International ',
    //         B: 'Simone International',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'STATE OF NJ LOTT ',
    //         B: 'State of NJ Lottery',
    //         C: '7030 · NJ Lottery Income:7036 · NJ Lottery State Withdrawals'
    //     },
    //     {
    //         A: 'Shore Point',
    //         B: 'Shore Point',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     { A: 'Nastos', B: 'Nastos Co', C: '6010 · Purchases:6010-2 · Food' },
    //     {
    //         A: 'Avanti Filco',
    //         B: 'Avanti Filco',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Aidil Wines & Liquors',
    //         B: 'Aidil Wines & Liquors',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'Cash Deposit Processing',
    //         B: 'empty',
    //         C: '6120 · Bank Service Charges'
    //     },
    //     { A: 'Coast Cleaning ', B: 'Coast Cleaning', C: '6340 · Sanitation' },
    //     {
    //         A: 'PEREIRA AZEVEDO',
    //         B: 'Pereira & Azevedo, LLC',
    //         C: '6220 · Professional - Fees:6221 · Accounting Expense'
    //     },
    //     {
    //         A: 'CLEANSLATE GROUP LLC',
    //         B: 'empty',
    //         C: '6300 · Repairs and Maintenance'
    //     },
    //     {
    //         A: 'H & J Security Systems',
    //         B: 'H&J Security Systems Inc.',
    //         C: '6345 · Security'
    //     },
    //     {
    //         A: 'MYSSURA TRADING ',
    //         B: 'Myssura Trading',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'Borough of Sayreville Liquor',
    //         B: '6230 · Licenses and Permits',
    //         C: 'Liquor License'
    //     },
    //     {
    //         A: 'Rego Fine Wine Import LLC',
    //         B: 'Rego Fine Wine Import LLC',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'D & D Cash Register',
    //         B: 'D&D Cash Register',
    //         C: '6245 · Office Expense'
    //     },
    //     {
    //         A: 'Tri Vin Imports Inc',
    //         B: 'Tri-Vin Imports Inc',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'East Coast Refrigeration',
    //         B: 'East Coast Refrigeration',
    //         C: '6300 · Repairs and Maintenance'
    //     },
    //     {
    //         A: 'EMENUINTERNATION',
    //         B: 'eMenu International',
    //         C: '6160 · Dues and Subscriptions'
    //     },
    //     {
    //         A: 'Ron Soporowski',
    //         B: 'Ron Soporowski',
    //         C: '6300 · Repairs and Maintenance'
    //     },
    //     { A: 'NJ WEB ', B: 'Sales & Use Tax', C: '3040 · Sales Tax Payable' },
    //     {
    //         A: 'JR and Son World Trading',
    //         B: 'JR & Son World Trading',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Borough of Sayreville Water ',
    //         B: 'Borough of Sayreville',
    //         C: '6390 · Utilities:6390-1 · Water'
    //     },
    //     {
    //         A: 'Republic Services',
    //         B: 'Republic Services',
    //         C: '6340 · Sanitation'
    //     },
    //     {
    //         A: 'Maffei Cutlery',
    //         B: 'Maffei Cutlery',
    //         C: '6300 · Repairs and Maintenance'
    //     },
    //     {
    //         A: 'ACB Produce',
    //         B: 'ACB Produce Inc',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Ocean Seafood',
    //         B: 'Ocean Seafood Inc',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     {
    //         A: 'Praia Da Rocha',
    //         B: 'Praia Da Rocha',
    //         C: '6010 · Purchases:6010-2 · Food'
    //     },
    //     { A: 'El Emigrante', B: 'El Emigrante Inc', C: '6350 · Supplies' },
    //     {
    //         A: 'Federal Wine ',
    //         B: 'Federal Wine & Liquor',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'Brazilian Immune Coffee',
    //         B: 'Brazilian Immune Coffee',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'JERSEY CENTRAL POWER AND LIGHT Bill Payment',
    //         B: 'JCP&L',
    //         C: '6390 · Utilities:6390-2 · Gas & Electricity'
    //     },
    //     {
    //         A: 'AUTHNET GATEWAY',
    //         B: 'AuthNet Gateway',
    //         C: '6145 · Credit Card Service Fees'
    //     },
    //     { A: 'iTRUEREVIEW', B: 'Itrue Review LLC', C: '6107 · Advertising' },
    //     {
    //         A: 'Gateway-Perrone',
    //         B: 'Gateway Perrone',
    //         C: '6010 · Purchases:6010-1 · Beverages'
    //     },
    //     {
    //         A: 'PSE&G',
    //         B: 'PSE&G Corp',
    //         C: '6390 · Utilities:6390-2 · Gas & Electricity'
    //     },
    //     {
    //         A: 'NJ WEB PMT',
    //         B: 'Sales & Use Tax',
    //         C: '3040 · Sales Tax Payable'
    //     },
    //     { A: 'Harry Ayala', B: 'Harry Ayala', C: '6170 · Equipment Rental' },
    //     { A: 'Online scheduled payment', B: 'empty', C: '2905 · BOA-4547' },
    //     {
    //         A: 'Advanced Air Duct Cleaning',
    //         B: 'Advanced Air Duct Cleaning',
    //         C: '6300 · Repairs and Maintenance'
    //     },
    //     { B: 'empty', C: '8005 · Uncategorized transactions' }
    // ]

    const { authToken, role, organizationIds } = useContext(UserContext);

    useEffect(() => {
        setLoading(true);
        getReferenceFile(authToken, projectId).then((res: any) => {
            let responseFileData = res.FileData
            setHeader(res.FileData['header']);
            const newObj = Object.keys(responseFileData).reduce((acc: any, key) => {
                if (key !== 'header') {
                    let acc1: any = {};
                    acc1[key] = responseFileData[key];
                    acc.push(acc1[key])
                }
                return acc;
            }, []);
            setFilterHeader(Object.values(newObj[1]))
            setReferenceFileData(newObj);
            setLoading(false)
        }).catch((err: any) => {
            console.log(err)
        })
    }, [])


    console.log(
        referenceFileData.length ? referenceFileData.filter((item: any) => {
            if (item['A']) {
                if (item['A'].includes('BL'))
                    return item
            }
        }) : ""
    )

    return (
        <div style={{ width: '90vw', height: '90vh', overflow: 'hidden' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <h5 style={{ color: '#b7b7b7' }}>Reference File</h5>
                </div>
                <div>
                    <IconButton aria-label="filter" onClick={() => setIsModalOpen(true)} >
                        <FilterListIcon />
                    </IconButton>
                </div>
            </div>
            {loading ? "" :
                <ExcelView
                    header={header}
                    data={tagValues !== "" ?
                        referenceFileData.filter((item: any) => {
                            if (item[filterBasedOnColumn]) {
                                if (item[filterBasedOnColumn].toLowerCase().includes(tagValues.toLowerCase())) {
                                    return item
                                }
                            }
                        })
                        : referenceFileData}
                />}
            <div>
                <Modal
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                >
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 500,
                        bgcolor: 'background.paper',
                        border: '2px solid #ccc',
                        boxShadow: 24,
                        p: 4,
                    }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h6>Filters</h6>
                                <VMButton onClick={() => setTagValues("")}>
                                    Clear Filter
                                </VMButton>
                            </div>
                            <hr />
                            <div style={{ width: '28vw' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
                                    <h6>Filter Based On Column</h6>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        sx={{ width: '10rem' }}
                                        value={filterBasedOnColumn}
                                        onChange={(e: any) => setFilterBasedOnColumn(e.target.value)}
                                    >
                                        {header.map((item: any) => {
                                            return <MenuItem value={item}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem' }}>
                                    <h6>Filter Column Value</h6>
                                    <TextField value={tagValues} onChange={(e: any) => setTagValues(e.target.value)} sx={{ width: '10rem' }} />
                                </div>
                            </div>
                            {/* <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end' }}>
                                <VMButton variant="primary">Apply</VMButton>
                            </div> */}
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default ReferenceFile;