import { IconButton } from "@material-ui/core";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useCopyToClipboard } from "../../../../hooks/useCopyToClipboard";
import docType1 from "../../../../sample-input/doctype1.json";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function SampleInputAccordion() {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [value, copy] = useCopyToClipboard();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  React.useEffect(() => {
    console.log("doctype1", docType1);
  }, []);

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>
            Sample request for a file with multiple Documents
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <b>
              Lets assume the DocTypes used in this example have
              the following fields and sections defined:
            </b>
            <br />
            <p>
              <a href="sample-push-request/PO_INV.pdf" download="sample1.pdf">
                Download Sample Input pdf
              </a>
            </p>
            <p><b>DocType: Purchase Agreement</b></p>
            <table style={{ border: "1px solid" }} cellPadding={4}>
              <thead>
                <th style={{ background: "#474747", color: "#fff" }}>
                  Field Key
                </th>
                <th style={{ background: "#474747", color: "#fff" }}>Section</th>
                <th style={{ background: "#474747", color: "#fff" }}>
                  Section Type
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>Vendor Name</td>
                  <td>Vendor Info</td>
                  <td>Regular Field</td>
                </tr>
                <tr style={{ background:'rgba(236, 236, 236, 0.764)'}}>
                  <td>Date</td>
                  <td>Vendor Info</td>
                  <td>Regular Field</td>
                </tr>
                <tr>
                  <td>Name_Line_Items</td>
                  <td>Line Items</td>
                  <td>Table</td>
                </tr>
                <tr style={{ background:'rgba(236, 236, 236, 0.764)'}}>
                  <td>Quantity_Line_Items</td>
                  <td>Line Items</td>
                  <td>Table</td>
                </tr>
                <tr>
                  <td>Amount_Line_Items</td>
                  <td>Line Items</td>
                  <td>Table</td>
                </tr>
                <tr style={{ background:'rgba(236, 236, 236, 0.764)'}}>
                  <td>Zip</td>
                  <td>Property Info</td>
                  <td>Regular Field</td>
                </tr>
                <tr>
                  <td>Property Address</td>
                  <td>Property Info</td>
                  <td>Regular Field</td>
                </tr>
              </tbody>
            </table>
            <br />
            <p style={{marginTop: '1rem'}}><b>DocType: Invoice</b></p>
            <table style={{ border: "1px solid" }} cellPadding={4}>
              <thead>
                <th style={{ background: "#474747", color: "#fff" }}>
                  Field Key
                </th>
                <th style={{ background: "#474747", color: "#fff" }}>Section</th>
                <th style={{ background: "#474747", color: "#fff" }}>
                  Section Type
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>Invoice Name</td>
                  <td>General</td>
                  <td>Regular Field</td>
                </tr>
                <tr style={{ background:'rgba(236, 236, 236, 0.764)'}}>
                  <td>Invoice Date</td>
                  <td>General</td>
                  <td>Regular Field</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>General</td>
                  <td>Regular Field</td>
                </tr>
                <tr style={{ background:'rgba(236, 236, 236, 0.764)'}}>
                  <td>Invoice Number</td>
                  <td>General</td>
                  <td>Regular Field</td>
                </tr>
              </tbody>
            </table>
            <p>
              Let's assume the file being pushed has the following structure
            </p>
            <p>
              Page-1 of Document 1 (with DocType "PO")
              <br />
              Page-2 of Document 1
              <br />
              Page-1 of Document 2 (with DocType "invoice")
            </p>
            <p>
              For the above assumptions, the API request should have the
              following format
            </p>
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <pre>
              <code>{JSON.stringify(docType1, null, 2)}</code>
            </pre>
            <Tooltip
              title={
                value === JSON.stringify(docType1, null, 2) ? "copied" : ""
              }
            >
              <IconButton
                onClick={() => copy(JSON.stringify(docType1, null, 2))}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
