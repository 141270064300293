import React from "react";

// import { Toast, ToastContainer, Button } from "react-bootstrap";

import VMModal from "./Modal";
import VMButton from "./Button";

import { Typography } from "@mui/material";

interface props {
  show: any;
  handleClose: any;
  label: String;
  header: String;
  handleSubmit: any;
  options: any;
  selectOption: any;
  setSelectOption: any;
  warn?:any
}

function OptionMenu({
  show,
  handleClose,
  label,
  header,
  handleSubmit,
  options,
  selectOption,
  setSelectOption,
  warn
}: props) {
  return (
    <>
      <VMModal
        open={show}
        onClose={handleClose}
        // style={{ backgroundColor: "#ffff", color: "black" }}
      >
        <div
          style={{
            backgroundColor: "white",
            minWidth: "35rem",
            borderRadius: "2rem",
          }}
        >
          <div style={{ padding: "0.5rem", margin: "1rem" }}>
            <Typography sx={{ fontSize: "16px" }}>{header}</Typography>
          </div>
          <hr />
          <div>
            <div>Choose the {label} Type:</div>
            {label === "Case" && (
              <>
                <input
                  id="new"
                  value="new"
                  type="radio"
                  style={{ margin: 10 }}
                  checked={selectOption === "new" ? true : false}
                  onChange={(e) => setSelectOption(e.target.value)}
                />
                <label id="new">New Case</label>
              </>
            )}
            {options.map((item: any) => {
              return (
                <div>
                  <input
                    id={item}
                    value={item}
                    type="radio"
                    style={{ margin: 10 }}
                    checked={selectOption === item ? true : false}
                    onChange={(e) => setSelectOption(e.target.value)}
                  />
                  <label id={item}>{item}</label>
                </div>
              );
            })}
          </div>
          <div className="d-flex row" style={{ width: "10px" }}>
            <div className="col-sm-1">
              <VMButton size="sm" variant="primary" onClick={handleSubmit}>
                Save
              </VMButton>
            </div>
            <div className="col-sm-1">
              <VMButton size="sm" variant="secondary" onClick={handleClose}>
                Cancel
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>

      <VMModal open={show} onClose={handleClose}>
        <div
          style={{
            backgroundColor: "white",
            minWidth: "35rem",
            borderRadius: "2rem",
          }}
        >
          <div style={{ padding: "0.5rem", margin: "1rem" }}>
            <Typography sx={{ fontSize: "16px" }}>{header}</Typography>
          </div>
          <hr />
          <div>
            <div style={{ display: "flex", justifyContent: "flex-start",marginLeft:'3.5rem' }}>
              <div>
                <div>Choose the {label} Type:</div>
                {label === "Case" && (
                  <>
                    <input
                      id="new"
                      value="new"
                      type="radio"
                      style={{ margin: 10 }}
                      checked={selectOption === "new" ? true : false}
                      onChange={(e) => setSelectOption(e.target.value)}
                    />
                    <label id="new">New Case</label>
                  </>
                )}
                {options.map((item: any) => {
                  return (
                    <div>
                      <input
                        id={item}
                        value={item}
                        type="radio"
                        style={{ margin: 10 }}
                        checked={selectOption === item ? true : false}
                        onChange={(e) => setSelectOption(e.target.value)}
                      />
                      <label id={item}>{item}</label>
                    </div>
                  );
                })}
              </div>
            </div>
            {warn ? (
              <div
                style={{
                  marginTop: "2rem",
                  marginLeft: "2rem",
                  color: "#DEC20B",
                }}
              >
                <span>!!! Warning !!!</span>
                <div>You selected the different DocTypes</div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "1rem",
            }}
          >
            <div>
              <VMButton variant="secondary" size="sm" onClick={handleClose}>
                Cancel
              </VMButton>
            </div>
            <div>
              <VMButton variant="primary" size="sm" onClick={handleSubmit}>
                Save
              </VMButton>
            </div>
          </div>
        </div>
      </VMModal>
    </>
  );
}

export default OptionMenu;
