import "react-day-picker/dist/style.css";

import { ClickAwayListener } from "@mui/base";
import { Typography } from "@mui/material";
import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Popper from "@mui/material/Popper";
import type { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { endOfDay, format, startOfDay, sub } from "date-fns";
import type { SyntheticEvent } from "react";
import { useState } from "react";
import type { DateRange } from "react-day-picker";
import { DayPicker } from "react-day-picker";
import VMButton from "./Button";

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const DatePickerRangeFooter = ({ range }: { range: DateRange | undefined }) => {
  let Footer = (
    <Typography variant="subtitle2" color="grey">
      Please pick the first day.
    </Typography>
  );
  if (range?.from) {
    if (!range.to) {
      Footer = (
        <Typography variant="caption" color="grey">
          {format(range.from, "PPP")}
        </Typography>
      );
    } else if (range.to) {
      Footer = (
        <Typography variant="caption" color="grey">
          {format(range.from, "PPP")}–{format(range.to, "PPP")}
        </Typography>
      );
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      {Footer}
    </Box>
  );
};

const SingleDatePickerFooter = ({ day }: { day: Date | undefined }) => {
  const footer = day ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <Typography variant="subtitle2" color="grey">
        You selected {format(day, "PPP")}.
      </Typography>
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
      }}
    >
      <Typography variant="subtitle2" color="grey">
        Please pick a day.
      </Typography>
    </Box>
  );
  return footer;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type Props = {
  openPopper: boolean;
  dateFilterPopperAnchorEl: null | HTMLElement;
  onChangeDateFilter: (args: { startDate: string; endDate: string }) => void;
  onClickAway: (event: MouseEvent | TouchEvent) => void;
};

const DateFilterPopper = ({
  openPopper,
  dateFilterPopperAnchorEl,
  onChangeDateFilter,
  onClickAway,
}: Props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [relativeDateCount, setRelativeDateCount] = useState<string>("0");
  const [relativeDateMenu, setRelativeDateMenu] = useState("days");
  const [relativeStartDate, setRelativeStartDate] = useState<Date | string>(
    "-"
  );

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const handleRelativeDateMenuChange = (event: SelectChangeEvent) => {
    const startDate = sub(new Date(), {
      [event.target.value]: parseInt(relativeDateCount, 10),
    });
    if (parseInt(event.target.value, 10) === 0) {
      setRelativeStartDate(new Date());
      setRelativeDateMenu(event.target.value);
    } else {
      setRelativeStartDate(startDate);
      setRelativeDateMenu(event.target.value);
    }
  };

  const handleApplyFilter = () => {
    switch (tabIndex) {
      case 0:
        onChangeDateFilter({
          startDate: format(
            startOfDay(new Date(selectedDate as Date)),
            "yyyy-MM-dd'T'HH:mm:ss'Z'"
          ),
          endDate: format(
            endOfDay(new Date(selectedDate as Date)),
            "yyyy-MM-dd'T'HH:mm:ss'Z'"
          ),
        });
        break;
      case 1:
        const startDate = sub(new Date(), {
          [relativeDateMenu]: relativeDateCount,
        });
        onChangeDateFilter({
          startDate: format(startOfDay(startDate), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
          endDate: format(endOfDay(new Date()), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        });
        break;
      case 2:
        onChangeDateFilter({
          startDate: dateRange
            ? format(
                startOfDay(dateRange.from as Date),
                "yyyy-MM-dd'T'HH:mm:ss'Z'"
              )
            : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
          endDate: dateRange
            ? format(endOfDay(dateRange.to as Date), "yyyy-MM-dd'T'HH:mm:ss'Z'")
            : format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        });
        break;

      default:
        throw new Error(`tab not handled ${tabIndex}`);
    }
  };

  const handleClearFilter = () => {
    setSelectedDate(undefined);
    setDateRange(undefined);
    setRelativeDateCount("0");
    setRelativeDateMenu("days");
    setRelativeStartDate(new Date());

    onChangeDateFilter({
      startDate: "",
      endDate: "",
    });
  };

  return (
    <Popper
      id="date-filter-popper"
      open={openPopper}
      anchorEl={dateFilterPopperAnchorEl}
      sx={(theme) => ({ zIndex: theme.zIndex.modal })}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
        },
      ]}
      nonce={undefined}
      onResize={undefined}
      onResizeCapture={undefined}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Box
          sx={(theme) => ({
            p: 0.25,
            bgcolor: "background.paper",
            borderRadius: 0.75,
            mt: 1,
            mr: "1rem",
            boxShadow: theme.shadows[24],
          })}
        >
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="select date filter options"
              centered
            >
              <Tab
                label="Absolute"
                sx={{ textTransform: "none" }}
                {...a11yProps(0)}
              />
              <Tab
                label="Relative"
                sx={{ textTransform: "none" }}
                {...a11yProps(1)}
              />
              <Tab
                label="Range"
                sx={{ textTransform: "none" }}
                {...a11yProps(2)}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={tabIndex}
            index={0}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <DayPicker
              mode="single"
              selected={selectedDate}
              disabled={{
                after: new Date(),
              }}
              modifiersStyles={{
                selected: { backgroundColor: "#69bec6" },
              }}
              onSelect={(day, selectedDay) =>
                setSelectedDate(selectedDay || day)
              }
              required
              footer={<SingleDatePickerFooter day={selectedDate} />}
            />
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index={1}
            sx={{
              width: "20rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                margin: "1rem 0.25rem",
                maxWidth: 470,
              }}
            >
              <TextField
                size="small"
                sx={{ maxWidth: 150 }}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                }}
                value={relativeDateCount}
                onChange={(e) => {
                  setRelativeDateCount(e.target.value);
                  const startDate = sub(new Date(), {
                    [relativeDateMenu]: e.target.value,
                  });
                  setRelativeStartDate(startDate);
                }}
                type="number"
              />
              <FormControl sx={{ width: 200 }} size="small">
                <Select
                  displayEmpty
                  value={relativeDateMenu}
                  native
                  sx={{
                    textOverflow: "ellipsis",
                  }}
                  onChange={handleRelativeDateMenuChange}
                >
                  <option value="days">Days from now</option>
                  <option value="weeks">Weeks from now</option>
                  <option value="months">Months from now</option>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                maxWidth: 450,
              }}
            >
              <Typography
                variant="button"
                sx={{ m: 1, textTransform: "none" }}
                color="grey"
              >
                Start Date:{" "}
                {typeof relativeStartDate === "string"
                  ? relativeStartDate
                  : relativeStartDate.toDateString()}
              </Typography>
            </Box>
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <DayPicker
              mode="range"
              selected={dateRange}
              disabled={{
                after: new Date(),
              }}
              modifiersStyles={{
                selected: { backgroundColor: "#69bec6" },
              }}
              onSelect={setDateRange}
              footer={<DatePickerRangeFooter range={dateRange} />}
            />
          </TabPanel>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
              mx: 2,
              my: 1,
            }}
          >
            <VMButton
              variant="outlined"
              size="small"
              sx={{
                verticalAlign: "text-top",
              }}
              color="info"
              onClick={handleClearFilter}
            >
              Clear
            </VMButton>
            <VMButton
              variant="contained"
              size="small"
              sx={{
                verticalAlign: "text-top",
              }}
              onClick={handleApplyFilter}
            >
              Apply
            </VMButton>
          </Box>
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};

export default DateFilterPopper;
