import React, { useEffect, useRef, useState } from 'react';
import VMTooltip from '../../common/Tooltip';
import ListboxComponent from '../../common/Autocomplete';

import { Autocomplete, FormGroup, TextField, Typography, createFilterOptions } from '@mui/material';
import { makeStyles } from '@mui/styles';

import './ds.css';

const filter = createFilterOptions<any>();

const useStyles = makeStyles((theme) => ({
  endAdornment: {
    right: '0px',
    top: '-4px'
  },
  customTooltip: {
    backgroundColor: 'red', // Change this to your desired background color
    color: 'white', // Change the text color if needed
  },
}));

const FieldForm = ({
  field,
  setBoundingBox,
  handleFieldChange,
  getDateFromValue,
  getValueFromDate,
  setFieldKey,
  iIndex,
  jIndex,
  isTable,
  tableDisplay,
  setTableDisplay,
  completed,
  dropdownValues,
  addDropdownValue,
  retrieveDependencyDropdownValue,
  removalChildDropdownValue
}: any) => {
  const [fieldValues, setFieldValues] = useState(field.qcUpdatedValue)
  const [inputValue, setinputValue] = useState<string>('');
  const [inbuiltDropdown, setInBuiltDropdown] = useState(dropdownValues.dropDownValues)
  const [validationOnDropdown, setValidationDropdown] = useState<any>({ error: false, dropdown: [], path: [] });
  const inputRef = useRef(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const acceptNewValue = true
  const classes = useStyles();

  useEffect(() => {
    setinputValue("")
  }, [field, validationOnDropdown])


  return (
    <FormGroup
      key={isTable ? `${Math.floor(Math.random() * 100)}` : `${iIndex}`}
      // controlId={isTable ? `${Math.floor(Math.random() * 100)}` : `${iIndex}`}
      className="m-0"
      data-cy-qc-screen-field={iIndex}
    >
      {!isTable && (
        <Typography variant="h6">
          {field.name}{' '}
          {field.mandatory && (
            <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
          )}
        </Typography>
      )}
      {(() => {
        switch (field?.type) {
          case 'dropdown':
          case 'dependency_dropdown':
            return (
              <>
                <VMTooltip
                  title={inputValue === "" ? field.qcUpdatedValue : inputValue}
                  placement="left"
                >
                  <Autocomplete
                    disablePortal
                    sx={{
                      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                        borderColor: field.error ? '#FF0000' : "", // Change to the desired border color
                      },
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
                        backgroundColor: "#cccc",
                      },
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']:hover":
                      {
                        backgroundColor: "#cccc",
                      },
                      color: "red"
                    }}
                    id="autocomplete"
                    size="small"
                    disableClearable={true}
                    value={inputValue === "" ? field.qcUpdatedValue : inputValue}
                    readOnly={completed}
                    classes={{ endAdornment: classes.endAdornment }}
                    ListboxComponent={ListboxComponent}
                    options={inbuiltDropdown ? inbuiltDropdown : []}
                    filterOptions={(options, params) => {
                      let filtered = filter(options, params);

                      const { inputValue } = params;

                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option);
                      if (inputValue !== '' && !isExisting && acceptNewValue) {
                        filtered.push(`Add-${inputValue}`);
                      }

                      return filtered;
                    }}
                    renderInput={(params) =>
                      <TextField {...params} label={field.Name} inputRef={inputRef}
                        value={textFieldValue}
                        sx={{ color: "red" }}
                        onChange={(e) => setTextFieldValue(e.target.value)}
                      />
                    }
                    renderOption={(props, option, state) =>
                      [props, option, state.inputValue] as React.ReactNode
                    }
                    onChange={(e) => {
                      let target: any = e.target
                      if (target.textContent.includes('Add')) {
                        addDropdownValue(field.key, target.textContent.split("-")[1])
                      }
                      let dropdownValue = target.textContent.split("-")
                      let splittingValue = dropdownValue.includes('Add') ? dropdownValue[1] : dropdownValue[0]
                      setinputValue(splittingValue);
                      isTable
                        ? handleFieldChange(splittingValue, iIndex, jIndex)
                        : handleFieldChange(splittingValue, field.key);

                      if (field.type === "dependency_dropdown") {
                        removalChildDropdownValue(field.key, splittingValue)
                      }
                    }}
                    onFocus={(e) => {
                      let { error, dropdownValues, path } = retrieveDependencyDropdownValue(field.key);
                      setValidationDropdown({ error: error, dropdown: dropdownValues, path: path })
                      if (!dropdownValues.includes(textFieldValue)) {
                        return
                      }
                      setInBuiltDropdown(dropdownValues)
                    }}
                    renderGroup={(params) => params as any}
                  />
                </VMTooltip>
              </>
            );
          case 'non-dependency_dropdown':
            return (
              <>
                <VMTooltip
                  title={inputValue === "" ? field.qcUpdatedValue : inputValue}
                  placement="left"
                >
                  <Autocomplete
                    disablePortal
                    sx={{
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
                        backgroundColor: "#cccc",
                      },
                      "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']:hover":
                      {
                        backgroundColor: "#cccc",
                      },
                    }}
                    id="autocomplete"
                    size="small"
                    disableClearable={true}
                    value={inputValue === "" ? field.qcUpdatedValue : inputValue}
                    readOnly={completed}
                    classes={{ endAdornment: classes.endAdornment }}
                    ListboxComponent={ListboxComponent}
                    options={inbuiltDropdown ? inbuiltDropdown : []}
                    filterOptions={(options, params) => {
                      let filtered = filter(options, params);

                      const { inputValue } = params;

                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option);
                      if (inputValue !== '' && !isExisting && acceptNewValue) {
                        filtered.push(`Add-${inputValue}`);
                      }

                      return filtered;
                    }}
                    renderInput={(params) =>
                      <TextField {...params} label={field.Name} inputRef={inputRef}
                        value={textFieldValue}
                        onChange={(e) => setTextFieldValue(e.target.value)}
                      />
                    }
                    renderOption={(props, option, state) =>
                      [props, option, state.inputValue] as React.ReactNode
                    }
                    onChange={(e) => {
                      let target: any = e.target
                      let dropdownValue = target.textContent.split("-")
                      let splittingValue = dropdownValue.includes('Add') ? dropdownValue[1] : dropdownValue[0]
                      setinputValue(splittingValue);
                      isTable
                        ? handleFieldChange(splittingValue, iIndex, jIndex)
                        : handleFieldChange(splittingValue, field.key);

                      if (field.type === "dependency_dropdown") {
                        removalChildDropdownValue(field.key)
                      }
                    }}
                    onFocus={(e) => {
                      let { error, dropdownValues, path } = retrieveDependencyDropdownValue(field.key);
                      setValidationDropdown({ error: error, dropdown: dropdownValues, path: path })
                      if (!dropdownValues.includes(textFieldValue)) {
                        //dropdownValueFromContainer.unshift(`Add-${textFieldValue}`)
                      }
                      setInBuiltDropdown(dropdownValues)
                    }}
                    renderGroup={(params) => params as any}
                  />
                </VMTooltip>
              </>
            );
          case 'Date':
            return (
              <TextField
                variant="outlined"
                size="small"
                inputProps={{
                  style: { cursor: completed ? 'pointer' : 'text' },
                  readOnly: completed,
                }}
                className={
                  !(
                    field.qcUpdatedValue ||
                    (field.qcUpdatedCoordinates &&
                      field.qcUpdatedCoordinates.length > 0)
                  )
                    ? 'field-error'
                    : 'field'
                }
                defaultValue={
                  field.qcUpdatedValue
                    ? getDateFromValue(field.qcUpdatedValue)
                    : null
                }
                sx={{
                  marginBottom: '1rem',
                }}
                onFocus={() => {
                  if (!isTable && tableDisplay) setTableDisplay(false);
                  setFieldKey(field.key);
                  setBoundingBox(field.qcUpdatedCoordinates);
                }}
                onChange={(e) => {
                  isTable
                    ? handleFieldChange(
                      getValueFromDate(e.target.value),
                      iIndex,
                      jIndex
                    )
                    : handleFieldChange(
                      getValueFromDate(e.target.value),
                      field.key
                    );
                }}
              />
            );

          case 'Long Text':
            return (
              <TextField
                className={
                  !(
                    field.qcUpdatedValue ||
                    (field.qcUpdatedCoordinates &&
                      field.qcUpdatedCoordinates.length > 0)
                  )
                    ? 'field-area field-error'
                    : 'field-area field'
                }
                variant="outlined"
                inputProps={{
                  style: { cursor: completed ? 'pointer' : 'text' },
                  readOnly: completed,
                }}
                size="small"
                sx={{
                  marginBottom: '1rem',
                }}
                defaultValue={field.qcUpdatedValue}
                onFocus={() => {
                  if (!isTable && tableDisplay) setTableDisplay(false);
                  // setFieldIndex(iIndex);
                  setFieldKey(field.key);
                  setBoundingBox(field.qcUpdatedCoordinates);
                }}
                onChange={(e) => {
                  isTable
                    ? handleFieldChange(e.target.value, iIndex, jIndex)
                    : handleFieldChange(e.target.value, field.key);
                }}
              />
            );

          default:
            return (
              <>
                <VMTooltip
                  title={fieldValues}
                  placement="left"
                >
                  <TextField
                    variant="outlined"
                    size="small"
                    sx={{
                      marginBottom: '1rem',
                      cursor: 'default',
                    }}
                    inputProps={{
                      style: { cursor: completed ? 'pointer' : 'text' },
                      readOnly: completed,
                    }}
                    defaultValue={field.qcUpdatedValue}
                    onFocus={() => {
                      if (!isTable && tableDisplay) setTableDisplay(false);
                      if (!isTable) {
                        setFieldKey(field.key);
                        setBoundingBox(field.qcUpdatedCoordinates);
                      }
                    }}
                    onChange={(e) => {
                      setFieldValues(e.target.value)
                      isTable
                        ? handleFieldChange(e.target.value, iIndex, jIndex)
                        : handleFieldChange(e.target.value, field.key);
                    }}
                  />
                </VMTooltip>
              </>
            );
        }
      })()}
    </FormGroup>
  );
};

export default FieldForm;