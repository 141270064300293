import axios from "./axiosConfig";

const CLIENT_URL = process.env.REACT_APP_NODE_URL + "/api/v3";

export const completedDocumentList = (
  token: string,
  project_id: string,
  limit: number,
  page: number,
  sortBy: string
) =>
  axios
    .get(
      `${CLIENT_URL}/documents/completed/${project_id}/?limit=${limit}&page=${page}&sort=${sortBy}`,
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const pendingDocumentList = (token: string, project_id: string) =>
  axios
    .get(`${CLIENT_URL}/documents/pending/${project_id}`, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const reviewDocumentList = (
  token: string,
  project_id: string,
  limit: number,
  page: number,
  sortBy: string
) =>
  axios
    .get(
      `${CLIENT_URL}/documents/inReview/${project_id}/?limit=${limit}&page=${page}&sort=${sortBy}`,
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const changeDocsStatusAddComment = (
  status: string,
  data: object,
  token: string
) =>
  axios
    .put(`${CLIENT_URL}/file/change/${status}`, data, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log(err));

export const getDocument = (token: string, document_id: string) =>
  axios
    .get(`${CLIENT_URL}/file/${document_id}`, {
      headers: {
        authorization: `bearer ${token}`,
        'Cache-Control': 'no-store',
        'Pragma': 'no-cache'
      },
    })
    .then((res) => res.data);

export const updateDocument = (token: string, data: any, queue_type?: string) =>
  axios
    .put(
      `${CLIENT_URL}/file/update` + (queue_type ? `?queue_type=${queue_type}` : ''),
      { data },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const autoSaveDocument = (token: string, data: any) =>
  axios
    .post(
      `${CLIENT_URL}/file/autosave`,
      { data },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => { return res });

export const autoSaveDocumentConfirm = (token: string, data: any) =>
  axios
    .post(
      `${CLIENT_URL}/file/autosave/confirm`,
      { data },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res);

export const undoSaveDocument = (token: string, fileId: string) =>
  axios
    .get(`${CLIENT_URL}/file/${fileId}/undo`, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => {
      return res.data;
    });

export const markReview = (token: string, data: any) =>
  axios
    .put(
      `${CLIENT_URL}/documents/mark-for-review`,
      { data },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const getDeletionDocument = (token: string, document: String) =>
  axios
    .delete(`${CLIENT_URL}/documents/${document}`, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const verifyDocument = (token: string, projectId: string,onlyId : string, queue_type?:string) => {

  let url = `${CLIENT_URL}/file/pending/verify/${projectId}/unchanged?onlyId=${onlyId}`
  url += queue_type === "allocated" ? `&queue_type=${queue_type}` : '';

  const data = axios
    .get(url,
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

  return data;
} 

export const verifyChangeDocument = (token: string, projectId: string, onlyId:string, queue_type?:string) => {
  
  let url = `${CLIENT_URL}/file/pending/verify/${projectId}/change?onlyId=${onlyId}`
  url += queue_type === "allocated" ? `&queue_type=${queue_type}` : '';
  
  const data = axios
    .get(url,{
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

  return data;
}
  

export const accessVerification = (token: string, docId: string) =>
  axios
    .put(
      `${CLIENT_URL}/documents/verifying/${docId}`,
      {},
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const changeDocStatus = (
  token: string,
  docId: string,
  projectId: string,
  status: string
) =>
  axios
    .put(
      `${CLIENT_URL}/documents/change-status`,
      { document: docId, project: projectId, status },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const changeDocProcessingStatus = (
  token: string,
  docId: string,
  projectId: string,
  status: Boolean
) =>
  axios
    .patch(
      `${CLIENT_URL}/documents/change-processing-state/${projectId}/${docId}/${status}`,
      {},
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const nextInReviewDoc = (
  token: string,
  projectId: string,
  currentDoc: string
) =>
  axios
    .get(
      `${CLIENT_URL}/documents/next-mfr-document/${projectId}/${currentDoc}`,
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const nextInReviewDocByTime = (
  token: string,
  projectId: string,
  createdAt: string
) =>
  axios
    .post(
      `${CLIENT_URL}/file/next-mfr-document`,
      { projectId, createdAt },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const changePriority = (
  token: string,
  document: string,
  priority: boolean
) =>
  axios
    .post(
      `${CLIENT_URL}/file/prioritize-document`,
      { document, priority },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const getActiveUsers = (token: string, data: any) =>
  axios
    .post(`${CLIENT_URL}/documents/active-users`, data, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);
