import { useState, useRef } from 'react';
// import Form from "react-bootstrap/Form";
import ListboxComponent from '../../common/Autocomplete';

import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import VMTooltip from '../../common/Tooltip';
import './ds.css';

const filter = createFilterOptions<any>();

const useStyles = makeStyles((theme) => ({
  endAdornment: {
    right: '0px',
    top: '1px'
  }
}));

const TableFieldForm = ({
  field,
  setBoundingBox,
  handleFieldChange,
  getDateFromValue,
  getValueFromDate,
  setFieldKey,
  iIndex,
  jIndex,
  isTable,
  tableDisplay,
  setTableDisplay,
  completed,
  dropdownValues,
  depedency_dropdown,
  columnIdx,
  rowIdx,
  onCurrentBoundingBoxChange,
  currentBoundingBox,
  retrieveDependencyDropdownValue,
  removalChildDropdownValue,
  addDropdownValue,
  onClickTableField
}: any) => {
  const [turnEditMode] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(field.qcUpdatedValue);
  const [inputValueofText, setInputValue] = useState("");
  const [inbuiltDropdown, setInBuiltDropdown] = useState<string[]>([]);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [validationOnDropdown, setValidationDropdown] = useState<any>({ error: false, dropdown: [], path: [] });
  const classes = useStyles();
  const inputRef = useRef(null);
  const acceptNewValue = true

  return (
    <>
      {!turnEditMode ? (
        field.type === "dependency_dropdown" || field.type === "non-dependency_dropdown" ? (
          <VMTooltip
            title={inputValueofText === "" ? field.qcUpdatedValue : inputValueofText}
            placement="left"
          >
            {field.type === 'dependency_dropdown' ?
              (
                <Autocomplete
                  disablePortal
                  sx={{
                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                      borderColor: field.error && field.errorWithIndex === iIndex ? '#FF0000' : "", // Change to the desired border color
                    },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
                      backgroundColor: "#cccc",
                    },
                    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']:hover":
                    {
                      backgroundColor: "#cccc",
                    },
                    color: "red",
                    minWidth: "10rem"
                  }}
                  id="autocomplete"
                  size="small"
                  disableClearable={true}
                  value={inputValueofText === "" ? field.qcUpdatedValue : inputValueofText}
                  readOnly={completed}
                  classes={{ endAdornment: classes.endAdornment }}
                  ListboxComponent={ListboxComponent}
                  options={inbuiltDropdown ? inbuiltDropdown : []}
                  filterOptions={(options, params) => {
                    let filtered = filter(options, params);

                    const { inputValue } = params;

                    if (inputValue === "") {
                      let isExisting = options.some((option) => inputValueofText === option);
                      if (inputValueofText !== '' && !isExisting && acceptNewValue) {
                        filtered.push(`Add-${inputValue}`);
                      }
                      return filtered
                    } else {
                      let isExisting = options.some((option) => inputValue === option);
                      if (inputValue !== '' && !isExisting && acceptNewValue) {
                        filtered.push(`Add-${inputValue}`);
                      }

                      return filtered;
                    }
                  }}
                  renderInput={(params) =>
                    <TextField {...params} label={field.Name} inputRef={inputRef}
                      value={textFieldValue}
                      sx={{ color: "red" }}
                      onChange={(e) => setTextFieldValue(e.target.value)}
                    />
                  }
                  renderOption={(props, option, state) =>
                    [props, option, state.inputValue] as React.ReactNode
                  }
                  onChange={(e: any) => {
                    let target: any = e.target
                    if (target.textContent.includes('Add')) {
                      addDropdownValue(field.key, target.textContent.split("-")[1], iIndex)
                    }
                    let dropdownValue = target.textContent.split("-")
                    let splittingValue = dropdownValue.includes('Add') ? dropdownValue[1] : dropdownValue[0]
                    setInputValue(splittingValue);
                    e.target.defaultValue = splittingValue
                    isTable
                      ? handleFieldChange(splittingValue, iIndex, jIndex)
                      : handleFieldChange(splittingValue, field.key);

                    if (field.type === "dependency_dropdown") {
                      removalChildDropdownValue(field.key, splittingValue, iIndex, jIndex)
                    }
                  }}
                  onFocus={(e: any) => {
                    const value = e.target.defaultValue
                    let { error, dropdownValues, path } = retrieveDependencyDropdownValue(field.key, iIndex);
                    setValidationDropdown({ error: error, dropdown: dropdownValues, path: path })
                    if (!dropdownValues.includes(value) && value !== "" && !error) {
                      //setInBuiltDropdown([`Add-${value}`]);
                      setInBuiltDropdown(dropdownValues)
                      return
                    }
                    setInBuiltDropdown(dropdownValues)
                  }}
                  renderGroup={(params) => params as any}
                />
              )
              :
              (
                <Autocomplete
                  disablePortal
                  disableClearable={true}
                  size="small"
                  readOnly={completed}
                  classes={{ endAdornment: classes.endAdornment }}
                  ListboxComponent={ListboxComponent}
                  value={inputValueofText === "" ? field.qcUpdatedValue : inputValueofText}
                  renderOption={(props, option, state) =>
                    [props, option, state.inputValue] as React.ReactNode
                  }
                  style={{ minWidth: "10rem" }}
                  options={dropdownValues.filter((item: any) => item.key === field.key)[0].dropDownValues}
                  onChange={(e) => {
                    let target: any = e.target
                    let dropdownValue = dropdownValues.filter((item: any) => item.key === field.key)[0]
                    setInputValue(dropdownValue.dropDownValues[target.dataset.optionIndex])
                    handleFieldChange(dropdownValue.dropDownValues[target.dataset.optionIndex], iIndex, jIndex)
                  }}
                  renderInput={(params) => <TextField {...params} label={field.Name} />}
                />
              )
            }
          </VMTooltip>
        ) : (
          <VMTooltip
            placement="left"
            title={updatedValue}
          >
            <TextField
              defaultValue={field.qcUpdatedValue}
              variant="outlined"
              onChange={(e) => {
                setUpdatedValue(e.target.value);
                handleFieldChange(e.target.value, iIndex, jIndex);
              }}
              onClick={() => {
                const selectedField = dropdownValues.find((item: any) => item.key === field.key)
                if (field.fieldValues[rowIdx]["coordinates"]?.length > 0 || field.fieldValues[rowIdx]["qcUpdatedCoordinates"]?.length > 0) {
                  if (field.fieldValues[rowIdx]["qcUpdatedCoordinates"]?.length > 0) {
                    onCurrentBoundingBoxChange(field.fieldValues[rowIdx]["qcUpdatedCoordinates"])
                  } else {
                    onCurrentBoundingBoxChange(field.fieldValues[rowIdx]["coordinates"])
                  }
                } else {
                  if (selectedField["canAddBoundingBox"]) {
                    const imageCanvasContainer = document.querySelector(".upper-canvas")
                    if (imageCanvasContainer) {
                      const { bottom, left } = imageCanvasContainer.getBoundingClientRect()
                      console.log(imageCanvasContainer, imageCanvasContainer.getBoundingClientRect())
                      onCurrentBoundingBoxChange([left, bottom - 200, 100, 100])
                    }
                  }
                }
                onClickTableField(field)
              }}
              style={field.type === "long_text" ? { minWidth: "17rem" } : { minWidth: "7rem" }}
              size='small'
            />
          </VMTooltip>
        )
      ) : (
        <div>
          <div>
            {field.type === "dependency_dropdown" || field.type === "non-dependency_dropdown" ? (
              <Autocomplete
                disablePortal
                disableClearable={true}
                sx={{
                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover": {
                    backgroundColor: "#cccc",
                  },
                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']:hover":
                  {
                    backgroundColor: "#cccc",
                  },
                }}
                size="small"
                readOnly={completed}
                classes={{ endAdornment: classes.endAdornment }}
                renderOption={(props, option, state) =>
                  [props, option, state.inputValue] as React.ReactNode
                }
                ListboxComponent={ListboxComponent}
                value={inputValueofText === "" ? field.qcUpdatedValue : inputValueofText}
                options={dropdownValues.filter((item: any) => item.key === field.key)[0].dropDownValues}
                onChange={(e) => {
                  let target: any = e.target
                  let dropdownValue = dropdownValues.filter((item: any) => item.key === field.key)[0]
                  setInputValue(dropdownValue.dropDownValues[target.dataset.optionIndex])
                  handleFieldChange(dropdownValue.dropDownValues[target.dataset.optionIndex], iIndex, jIndex)
                }}
                renderInput={(params) => <TextField {...params} label={field.Name} />}
              />
            ) : (
              <VMTooltip
                placement="bottom"
                title={updatedValue}
              >
                <TextField
                  defaultValue={field.qcUpdatedValue}
                  variant="outlined"
                  size='small'
                  style={field.type === "long_text" ? { minWidth: "17rem" } : { minWidth: '7rem' }}
                  onChange={(e) => {
                    setUpdatedValue(e.target.value);
                    handleFieldChange(e.target.value, iIndex, jIndex);
                  }}
                  fullWidth
                />
              </VMTooltip>
            )}
          </div>
          {/* <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div>
              <CancelIcon
                style={{
                  // margin: "0 8px",
                  cursor: 'pointer',
                }}
                onClick={() => setTurnEditMode(false)}
              />
            </div>
            <div>
              <PageDoneIcon
                style={{
                  color: 'green',
                  margin: '0 8px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleFieldChange(updatedValue, iIndex, jIndex);
                  setTurnEditMode(false);
                }}
              />
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default TableFieldForm;
