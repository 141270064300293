import { useState } from "react"

export const useScrolls = (currentDocLength: number) => {
  const limit = 10;
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('asc');
  const [docLength, setDocLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreDocsOnScroll = () => {
    if(currentDocLength === docLength) {
      setHasMore(false)
      return;
    }
    setPage((currPage)=> currPage+1)
  }

  const sortDocuments = () => {
    setPage(1)
    setHasMore(true)
    setSortBy((currSortBy)=> (currSortBy==='asc')?'desc':'asc')
  }

  return {
    limit,
    hasMore,
    docLength,
    page,
    currentDocLength,
    sortBy,
    setPage,
    setDocLength,
    sortDocuments,
    setSortBy,
    fetchMoreDocsOnScroll
  }
}

