import {Modal, withStyles} from '@mui/material';

import { makeStyles } from '@mui/styles';

interface Props{
    open?:Boolean,
    handleClose?:any
}

const useStyles=makeStyles((theme)=>({
    root:{
        display:"flex",
        justifyContent:'center',
        alignItems:'center',
    }
}))

const VMModal = ({open,handleClose,children}:any)=>{
    const classes= useStyles();


    return (
        <Modal
        open={open}
        onClose={handleClose}
        className={classes.root}
        >
            {children}
        </Modal>
    )
}

export default VMModal;