import { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import {
  Popover,
  Typography,
  IconButton,
} from "@mui/material";

import "./sidebar.css";

import sidebarItems from "./SideBarlements";

import { ProfileIcon, Logo, } from "../../../utils/icons";

import UserContext from "../../../context/userContext";

function Sidebar() {
  const history = useHistory();
  const location = useLocation();
  const [userIconHover] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { role, name, } = useContext(UserContext);

  // const onHover = () => {
  //   setUserIconHover(true);
  // };

  // const onLeave = () => {
  //   setUserIconHover(false);
  // };

  return (
    <>
      <div className="position-absolute" style={{border:"1px solid #eee"}}>
        <div className="header" >
          <div className="d-flex justify-content-between align-items-center h-100 iconBox">
            <div
              style={{
                marginLeft: "6rem",
                verticalAlign: "center",
                marginTop: "0.5rem",
              }}
            >
              <h6 style={{ fontSize: "16px", fontWeight: "bolder" }}>
                VerifyMe
              </h6>
            </div>
            <div
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                setAnchorEl(e.currentTarget)
              }
            >
              <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                <ProfileIcon className="icon profile" fontSize="large" />
              </IconButton>
            </div>
            {/* <p className="mr-4 header-name">Hi, {name}</p>
          <span className="badge badge-primary">
            {name
              .split(/\s/)
              .reduce((response, word) => (response += word.slice(0, 1)), "")
              .toUpperCase()}
          </span> */}
            {/* <Avatar>
            {name
              .split(/\s/)
              .reduce((response, word) => (response += word.slice(0, 1)), "")
              .toUpperCase()}
          </Avatar> */}
          </div>
        </div>
        <div className="main_sidebar">
          <div className="sidebar_item">
            <div className="sidebar_user">
              {/* <img src={Logo} alt="User Icon" className="logo" /> */}
              <Logo className="icon" style={{ color: "#000" }} />
              <div
                className="user_card"
                style={userIconHover === false ? { display: "none" } : {}}
              >
                <div className="circle_name">
                  <p className="title_name_text">{"A"}</p>
                </div>
              </div>
            </div>
            <div className="hrule" />
            <div className="sidebar_elements">
              {sidebarItems
                .filter((item: any) => item.users.includes(role))
                .map((item: any, index: number) => (
                  <div
                    key={index}
                    className={
                      location.pathname.replace("/", "") === item.route
                        ? "sdr_item sdr_active"
                        : "sdr_item"
                    }
                    onClick={() => history.push(`/${item.route}`)}
                  >
                    {/* <img src={item.image} alt={item.alt} width={item.width} /> */}
                    {/* <Icon>{item.image}</Icon> */}
                    <span>{item.icon}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className="logout_element mb-4">
            <a
              href="/login"
              onClick={() => {
                localStorage.clear();
              }}
            >
              <img src="/Icons/logout-icn.svg" alt="signout icon" />
            </a>
          </div>
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ margin: "1rem" }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography>Username:{'  '}{name}</Typography>
              <Typography>Role:{'  '}{role}</Typography>
              {/* <Typography variant="subtitle1">Username: </Typography> */}
              {/* <Typography variant="subtitle1">Role: </Typography> */}
            </div>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography>{`  ${name}`}</Typography>
              <Typography>{role}</Typography>
            </div> */}
          </div>
          {/* <div className="d-flex align-items-center">
            <div>
              <IconButton
                onClick={() => {
                  history.push("/login")
                  localStorage.removeItem("$AuthToken");
                  setUser({
                    name: "",
                    email:"",
                    role:"",
                    authToken: "",
                  });
                }}
              >
                <ProfileLogout fontSize="small" />
              </IconButton>
            </div>
            <div className="mt-1">
              <h6>Logout</h6>
            </div>
          </div> */}
        </div>
      </Popover>
    </>
  );
}

export default Sidebar;
