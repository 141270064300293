import React, { useContext, useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";

import { Container,FormControl,TextField } from "@mui/material";

import VMButton from "../common/Button";
import { toastify } from "../common/notification";


import { forgotPassword, tokenData, userLogin } from "../../controllers/users";
import UserContext from "../../context/userContext";
import "./index.css";






const ForgetPassword = () => {
  return (
    <Container maxWidth="xl" className="p-0">
      <div className="rowContainer" style={{ display: "flex" }}>
        <div className="leftside" style={{ width: "90vh" }}>
          <LeftPanel />
        </div>
        <div className="rightside" style={{ width: "90vh" }}>
          <RightPanel />
        </div>
      </div>
    </Container>
  );
};

const RightPanel: React.FC = () => {
  const history = useHistory();
  const { setUser } = useContext(UserContext);

  const [email, setEmail] = useState<string>(" ");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const authToken = localStorage.getItem("$AuthToken");
    if (authToken) {
      tokenData(authToken)
        .then((data) => {
          if (!data) {
            localStorage.removeItem("$AuthToken");
          } else {
            setUser({
              name: data.user.name,
              email: data.user.email,
              role: data.user.role,
              authToken: authToken,
            });
          }
        })
        .then(() => history.push("/"))
        .catch((error) => console.log(error));
    }
  }, []);

  const handleForgetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();
    forgotPassword(email)
      .then((res) => {
        console.log(res);
        toastify("success", "Link will be mailed to you!");
        setTimeout(() => {
          history.push("/login");
        }, 3000);
      })
      .catch((err) => {
        toastify("failure", "Email validation failed");
        console.log(err);
      });
  };

  return (
    <div>
      {isSuccess ? (
        <Redirect to="/" />
      ) : (
        <Container
          className="d-flex align-items-center justify-content-center"
          style={{ minHeight: "100vh", flexDirection: "column" }}
        >
          <div
            style={{
              marginTop: "2%",
              width: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>Forgot Password</h1>
            <h5 className="subtext">Enter your email to continue</h5>
          </div>
          <div
            style={{
              height: "250px",
              width: "450px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // marginTop: "5%",
              marginLeft: "10%",
            }}
          >
            <form onSubmit={handleForgetPassword} id="login">
              <div style={{ margin: "3%" }}>
                <div style={{margin:"3%"}}>
                  <FormControl>
                    <TextField variant="outlined" label="Email" onChange={(e) => setEmail(e.target.value)} fullWidth sx={{width:"23rem"}}/>
                  </FormControl>
                  {/* <InputGroup className="input">
                    <FormControl
                      placeholder="Email"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      id="email"
                    />
                  </InputGroup> */}
                </div>
                <div>
                  <VMButton
                    variant="primary"
                    type="submit"
                    style={{
                      width: "85%",
                      marginLeft: "4%",
                      padding: "3%",
                      marginTop: "3%",
                    }}
                  >
                    Submit
                  </VMButton>
                  {/* <Button
                    className="button1"
                    size="lg"
                    
                  >
                    Submit
                  </Button> */}
                  <p style={{ marginLeft: "4%", marginTop: "24px" }}>
                    Note: An email will be sent to given email address.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </Container>
      )}
    </div>
  );
};

const LeftPanel = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{ height: "90vh" }}
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <img
          src={"/Icons/verify-me.svg"}
          alt="Verify Me icon"
          style={{ marginLeft: 30, width: "24%" }}
        />
        <div style={{ marginTop: "10%" }}>
          <h1 style={{ color: "#FFFF", marginLeft: 30 }}>
            Welcome to Verify Me
          </h1>
        </div>
      </div>
      <div style={{ paddingBottom: "5%", height: "10vh" }}>
        <div
          className="row"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div style={{ width: "20vh" }}>
            <p style={{ color: "#FFFF" }}>&#169; 2021 Verify Me</p>
          </div>
          <div style={{ width: "35vh" }}>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Privacy</p>
                </a>
              </div>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Terms</p>
                </a>
              </div>
              <div>
                <a href="/#" style={{ textDecoration: "none" }}>
                  <p style={{ color: "#FFFF" }}>Contact</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgetPassword;
