import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { stringAvatar } from "../../../utils/helper";

type Props = {
  name: string;
  description: string;
};

export const OrganizationCard = ({ name, description }: Props) => {
  return (
    <>
      <Box>
        <Card
          sx={{
            width: 250,
            height: 200,
          }}
        >
          <CardContent
            sx={{
              paddingTop: "2rem",
              height: 150,
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "1rem",
                width: "100%",
              }}
            >
              <Avatar {...stringAvatar(name)}>
                {name?.split(" ")[0][0].toUpperCase()}
              </Avatar>
            </Box>
            <Typography
              variant="h6"
              component="h5"
              sx={{
                textAlign: "center",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
            >
              {name}
            </Typography>
            <Tooltip title={description}>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                }}
                noWrap
                color="text.secondary"
              >
                {description}
              </Typography>
            </Tooltip>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
