export const validateInput = (userForm: any) => {
  let response = true;
  if (!userForm.name && userForm.name.length <= 0) {
    userForm.nameError = "Name is required";
    response = false;
  } else if (!/[A-Za-z]/.test(userForm.name)) {
    userForm.nameError = "Name is invalid";
    response = false;
  } else {
    userForm.nameError = "";
  }

  if (!userForm.email && userForm.email.length <= 0) {
    userForm.emailError = "Email is required";
    response = false;
  } else if (!/\S+@\S+\.\S+/.test(userForm.email)) {
    userForm.emailError = "Email is invalid";
    response = false;
  } else {
    userForm.emailError = "";
  }
  // if (!userForm?.password && userForm?.password.length <= 0) {
  //   userForm.passwordError = "Password is required";
  //   response = false;
  // } else if (userForm.password.length < 8 || userForm.password.length >= 20) {
  //   //else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(userForm.password))
  //   userForm.passwordError = "Password must have 8 characters";
  //   response = false;
  // }
  if (userForm.organizationIds.length < 0) {
    userForm.organizationError = "select atleast one organization";
    response = false;
  } else {
    userForm.passwordError = "";
  }

  return userForm;
};
