// import axios from "axios";
import axios from "./axiosConfig";

const CLIENT_URL = process.env.REACT_APP_NODE_URL + "/api/v3";

export const projectList = (
  token: string,
  limit: number,
  page: number,
  sortBy: string,
  organizationId?:string
) => {

  return axios
    .get(
      `${CLIENT_URL}/projects/all/?limit=${limit}&page=${page}&sort=${sortBy}&organizationId=${organizationId}`,
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);
};

export const projectActiveUserList = (token: string, projectIds: string[]) =>
  axios
    .post(
      `${CLIENT_URL}/projects/active-users`,
      {
        projectIds,
      },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const getProject = (token: string, project_id: string) =>
  axios
    // .get(`${CLIENT_URL}/projects/${project_id}`, {
    .get(`${CLIENT_URL}/projects`, {
      headers: {
        authorization: `bearer ${token}`,
      },
      params: {
        project_id: project_id,
      },
    })
    .then((res) => res.data);

export const createProject = (token: string, data: any) =>
  axios
    .post(
      `${CLIENT_URL}/projects/create`,
      {
        name: data.name,
        description: data.description,
        status: data.status,
        tagSection: data.tagSection,
        sections: data.sections,
        fields: data.fields,
        showBoundingBox: data.showBoundingBox,
        projectType: data.projectType,
        classificationValues: data.classificationValues,
        assignedTo: data.assignedTo,
        document: data.document,
        organization: data.organization,
      },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);


export const getReferenceFile = (token:string,project_id:string) =>
    axios.get(`${CLIENT_URL}/projects/referencefile/${project_id}`,{
      headers:{
        authorization: `bearer ${token}`
      }
    }).then((res)=> res.data)

export const updateProject = (token: string, projectId: String, data: any) =>
  axios
    .put(`${CLIENT_URL}/projects/update/${projectId}`, data, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const deleteProject = (token: string, projectId: String) =>
  axios
    .put(
      `${CLIENT_URL}/projects/delete/${projectId}`,
      {},
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const docTypeList = (token: string) =>
  axios
    .get(`${CLIENT_URL}/docTypes`, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);


export const createCustomDocTypeList = (token: string, data: any) =>
  axios
    .post(`${CLIENT_URL}/docTypes/create`, data, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const exportProject = (token: string, projectId: string) =>
  axios
    .get(`${CLIENT_URL}/projects/export/${projectId}`, {
      headers: {
        authorization: `bearer ${token}`,
      },
    })
    .then((res) => res.data);

export const assignUsersToProject = (
  token: string,
  projectId: string,
  users: any
) =>
  axios
    .put(
      `${CLIENT_URL}/projects/update/${projectId}`,
      { users },
      {
        headers: {
          authorization: `bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);

export const getMetadataAvailable = (token: string, projectId: String) =>
axios
  .get(
    `${CLIENT_URL}/projects/metadata-available/${projectId}`,
    {
      headers: {
        authorization: `bearer ${token}`,
      },
    }
  )
  .then((res) => res.data);