import { useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import Dropdown from 'react-dropdown';

import CancelIcon from '@material-ui/icons/Cancel';

import { DeleteIcon, PageDoneIcon } from '../../utils/icons';
import VMButton from '../common/Button';

const useStyle = makeStyles((theme: any) => ({
  chipStyling: {
    backgroundColor: '#ccc',
    borderRadius: '50px',
  },
  dropDown: {
    width: '7rem',
    zIndex: '800',
  },
}));

const AddingFieldTableContainer = ({
  sections,
  setCustomDocTypeDefine,
  customDocTypeDefine,
}: any) => {
  const classes = useStyle();
  const [userDefinedFields, setUserDefinedFields] = useState<any>([]);
  const [addingField, setAddingField] = useState<any>({
    key: '',
    name: '',
    type: '',
    section: '',
    mandatory: false,
    isAIBuildIn: false,
  });
  const [showFieldAddRow, setShowFieldAddRow] = useState<Boolean>(false);
  const [turnEditMode, setTurnEditMode] = useState<Boolean>(false);
  const [EditlayOn, setEditlayOn] = useState<any>({
    row: 0,
    tableCell: 0,
  });

  const EnablingEditingField = (index: any) => {
    let field = userDefinedFields[index - 1];
    setAddingField({
      key: field.key,
      name: field.name,
      type: field.type,
      section: field.section,
    });
  };

  const handleFieldDelete = (indexOfElement: any) => {
    let removingField = userDefinedFields.filter(
      (item: any, index: number) => indexOfElement !== index
    );
    console.log('delete', removingField);
    setUserDefinedFields(removingField);
    setCustomDocTypeDefine((data: any) => ({ ...data, fields: removingField }));
    setTurnEditMode(false);
    setEditlayOn({ row: 0, tableCell: 0 });
    setAddingField({ key: '', name: '', type: '', section: '' });
  };

  const handleAddField = () => {
    const newUserField = userDefinedFields.concat(addingField);
    // const newDocType = {
    //   ...userDefinedFields,
    //   newUserField,
    // };
    setUserDefinedFields(newUserField);
    setCustomDocTypeDefine((data: any) => ({ ...data, fields: newUserField }));
    // setTemplateDocTypeFields(
    //   ...templateDocTypeFields,
    //   [`${showFieldsDataForSelectedDocType}`]: newDocType,
    // });
    // setTemplateDocTypeFields()
    setShowFieldAddRow(false);
    setAddingField({
      key: '',
      name: '',
      type: '',
      section: '',
    });
  };

  const handleEditingField = (index: any) => {
    let userdefined = userDefinedFields;
    userdefined[index] = addingField;
    setUserDefinedFields(userdefined);
    setCustomDocTypeDefine((data: any) => ({ ...data, fields: userdefined }));
    setTurnEditMode(false);
    setAddingField({
      key: '',
      name: '',
      type: '',
      section: '',
    });
    setEditlayOn({ row: 0, tableCell: 0 });
  };

  return (
    <div>
      <div
        className="d-flex w-100 justify-content-center"
        style={{ margin: '1rem' }}
      >
        <div style={{ margin: '0.5rem' }}>
          <VMButton
            variant="primary"
            onClick={() => {
              setShowFieldAddRow(true);
            }}
          >
            Add field
          </VMButton>
        </div>
        <div style={{ margin: '0.5rem' }}>
          <VMButton
            variant="primary"
            // onClick={() => {
            //   // setProjectForm((data) => ({
            //   //   ...data,
            //   //   fields: [],
            //   // }));
            //   const emptyFieldAssign = userDefinedFields.pop();
            //   setUserDefinedFields({
            //     ...userDefinedFields,
            //     emptyFieldAssign,
            //   });
            // }}
          >
            Remove fields
          </VMButton>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 680 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* {docType[0].fields.map((keyFields)=>(
             Object.keys(keyFields).map((item)=>(
                item === 'key' || item === 'name' || item === 'type' && (
                  <TableCell>{item}</TableCell>
                )
              ))
            ))} */}
              <TableCell>Field Key</TableCell>
              <TableCell>Field Name</TableCell>
              <TableCell>Field Type</TableCell>
              <TableCell>Field Section</TableCell>
              {/* <TableCell>AI Field</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {userDefinedFields.map((row: any, index: Number) => {
              return turnEditMode && index === EditlayOn.row ? (
                <TableRow
                // key={row.name}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <TextField
                      id="standard-basic"
                      label="Field key"
                      variant="standard"
                      sx={{ width: '6rem' }}
                      value={addingField.key}
                      onChange={(e) =>
                        setAddingField({
                          ...addingField,
                          key: e.currentTarget.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <TextField
                      id="standard-basic"
                      label="Field Name"
                      variant="standard"
                      sx={{ width: '6rem' }}
                      value={addingField.name}
                      onChange={(e) =>
                        setAddingField({
                          ...addingField,
                          name: e.currentTarget.value,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {/* <TextField
                              id="standard-basic"
                              label="Field Type"
                              variant="standard"
                              sx={{ width: "8rem" }}
                              value={addingField.type}
                              onChange={(e) =>
                                setAddingField({
                                  ...addingField,
                                  type: e.currentTarget.value,
                                })
                              }
                            /> */}
                    <Dropdown
                      options={[
                        { value: 'short_text', label: 'Short text' },
                        { value: 'date', label: 'Date' },
                        { value: 'dropdown', label: 'Dropdown' },
                        { value: 'long_text', label: 'Long Text' },
                      ]}
                      onChange={(target) => {
                        // setProjectForm((projectForm) => {
                        //   const fields: any = [...projectForm.fields];
                        //   fields[idx] = {
                        //     ...fields[idx],
                        //     type: target.value,
                        //   };
                        //   return { ...projectForm, fields };
                        // });
                        setAddingField({
                          ...addingField,
                          type: target.value,
                        });
                      }}
                      value={addingField.type}
                      className={classes.dropDown}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {/* <TextField
                              id="standard-basic"
                              label="Field Type"
                              variant="standard"
                              sx={{ width: "8rem" }}
                              value={addingField.type}
                              onChange={(e) =>
                                setAddingField({
                                  ...addingField,
                                  type: e.currentTarget.value,
                                })
                              }
                            /> */}

                    <Dropdown
                      options={Array.from(
                        new Set(sections.map((item: any) => item.name))
                      )}
                      onChange={(target) => {
                        // setProjectForm((projectForm) => {
                        //   const fields: any = [...projectForm.fields];
                        //   fields[idx] = {
                        //     ...fields[idx],
                        //     type: target.value,
                        //   };
                        //   return { ...projectForm, fields };
                        // });
                        setAddingField({
                          ...addingField,
                          section: target.value,
                        });
                      }}
                      value={addingField.section}
                      className={classes.dropDown}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <DeleteIcon
                        style={{
                          color: 'red',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleFieldDelete(index)}
                      />
                      <hr />
                      <CancelIcon
                        style={{
                          // margin: "0 8px",
                          cursor: 'pointer',
                        }}
                        onClick={() => setTurnEditMode(false)}
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" width={5}>
                    {/* <VMButton
                              variant="primary"
                              size="sm"
                              onClick={handleAddField}
                              style={{width:"3rem"}}
                            >
                              Save
                            </VMButton> */}
                    <PageDoneIcon
                      style={{
                        color: 'green',
                        margin: '0 8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleEditingField(index)}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                // key={row.name}
                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onDoubleClick={(e: any) => {
                      if (!row.isAIBuildIn) {
                        EnablingEditingField(e.target.parentElement.rowIndex);
                        setTurnEditMode(true);
                        setEditlayOn({
                          row: e.target.parentElement.rowIndex - 1,
                          tableCell: e.currentTarget.cellIndex,
                        });
                      }
                    }}
                  >
                    {row.key}
                  </TableCell>
                  <TableCell
                    onDoubleClick={(e: any) => {
                      if (!row.isAIBuildIn) {
                        EnablingEditingField(e.target.parentElement.rowIndex);
                        setTurnEditMode(true);
                        setEditlayOn({
                          row: e.target.parentElement.rowIndex - 1,
                          tableCell: e.currentTarget.cellIndex,
                        });
                      }
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    onDoubleClick={(e: any) => {
                      if (!row.isAIBuildIn) {
                        EnablingEditingField(e.target.parentElement.rowIndex);
                        setTurnEditMode(true);
                        setEditlayOn({
                          row: e.target.parentElement.rowIndex - 1,
                          tableCell: e.currentTarget.cellIndex,
                        });
                      }
                    }}
                  >
                    {row.type}
                  </TableCell>
                  <TableCell
                    onDoubleClick={(e: any) => {
                      if (!row.isAIBuildIn) {
                        EnablingEditingField(e.target.parentElement.rowIndex);
                        setTurnEditMode(true);
                        setEditlayOn({
                          row: e.target.parentElement.rowIndex - 1,
                          tableCell: e.currentTarget.cellIndex,
                        });
                      }
                    }}
                  >
                    {row.section}
                  </TableCell>
                  {/* <TableCell>
                    {row.isAIBuildIn ? <Checkbox checked /> : ''}
                  </TableCell> */}
                </TableRow>
              );
            })}
            {showFieldAddRow ? (
              <TableRow
              // key={row.name}
              // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <TextField
                    id="standard-basic"
                    label="Field key"
                    variant="standard"
                    sx={{ width: '6rem' }}
                    value={addingField.key}
                    onChange={(e) =>
                      setAddingField({
                        ...addingField,
                        key: e.currentTarget.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <TextField
                    id="standard-basic"
                    label="Field Name"
                    variant="standard"
                    sx={{ width: '6rem' }}
                    value={addingField.name}
                    onChange={(e) =>
                      setAddingField({
                        ...addingField,
                        name: e.currentTarget.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* <TextField
                    id="standard-basic"
                    label="Field Type"
                    variant="standard"
                    sx={{ width: "8rem" }}
                    value={addingField.type}
                    onChange={(e) =>
                      setAddingField({
                        ...addingField,
                        type: e.currentTarget.value,
                      })
                    }
                  /> */}
                  <Dropdown
                    options={[
                      { value: 'short_text', label: 'Short text' },
                      { value: 'date', label: 'Date' },
                      { value: 'dropdown', label: 'Dropdown' },
                      { value: 'long_text', label: 'Long Text' },
                    ]}
                    onChange={(target) => {
                      // setProjectForm((projectForm) => {
                      //   const fields: any = [...projectForm.fields];
                      //   fields[idx] = {
                      //     ...fields[idx],
                      //     type: target.value,
                      //   };
                      //   return { ...projectForm, fields };
                      // });
                      setAddingField({
                        ...addingField,
                        type: target.value,
                      });
                    }}
                    value={addingField.type}
                    className={classes.dropDown}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {/* <TextField
                    id="standard-basic"
                    label="Field Type"
                    variant="standard"
                    sx={{ width: "8rem" }}
                    value={addingField.type}
                    onChange={(e) =>
                      setAddingField({
                        ...addingField,
                        type: e.currentTarget.value,
                      })
                    }
                  /> */}

                  <Dropdown
                    options={Array.from(
                      new Set(sections.map((item: any) => item.name))
                    )}
                    onChange={(target) => {
                      // setProjectForm((projectForm) => {
                      //   const fields: any = [...projectForm.fields];
                      //   fields[idx] = {
                      //     ...fields[idx],
                      //     type: target.value,
                      //   };
                      //   return { ...projectForm, fields };
                      // });
                      setAddingField({
                        ...addingField,
                        section: target.value,
                      });
                    }}
                    value={addingField.section}
                    className={classes.dropDown}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    {showFieldAddRow && (
                      <DeleteIcon
                        style={{
                          color: 'red',
                          cursor: 'pointer',
                        }}
                      />
                    )}
                    <hr />
                    <CancelIcon
                      style={{
                        // margin: "0 8px",
                        cursor: 'pointer',
                      }}
                      onClick={() => setShowFieldAddRow(false)}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" width={5}>
                  {/* <VMButton
                    variant="primary"
                    size="sm"
                    onClick={handleAddField}
                    style={{width:"3rem"}}
                  >
                    Save
                  </VMButton> */}
                  <PageDoneIcon
                    style={{
                      color: 'green',
                      margin: '0 8px',
                      cursor: 'pointer',
                    }}
                    onClick={handleAddField}
                  />
                </TableCell>
              </TableRow>
            ) : (
              ''
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AddingFieldTableContainer;
